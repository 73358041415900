import pathOr from 'ramda/es/pathOr';

import { showcaseV2Actions } from 'store/actions';
import { Action } from 'types/Action';
import {
    ShowcaseV2State,
    ShowcaseV2Payload
} from 'types/store/ShowcaseV2State';


const initState: ShowcaseV2State = {
    pageType: 'HOME',
    action: 'NONE',
    typeOfSelected: 'NONE',
    previewPage: 'HOME',
    idOfSelected: undefined,
    idOfSelectedArticle: null,
    isSubmit: false,
    isOpenSnackbar: false,
    snackbarStatus: 0,
    isCreateNew: false,
    newShowcaseName: '',
    isFeatured: false,
    isHighlight: false,
    isArticleBelongToHome: false,
    pendingAction: undefined,
    isEmptyFields: true,
    handleCheckFields: false,
    isResetForm: undefined
}

const initAction: Action<ShowcaseV2Payload> = {
    type: '',
    payload: {}
}

export default (state = initState, action = initAction): any => {
    const reducer = () => {
        return {
            ...state,
            ...action.payload
        }
    }

    return pathOr(state, [action.type], {
        [showcaseV2Actions.UPDATE]: reducer(),
        [showcaseV2Actions.CLEAR]: initState
    })
}