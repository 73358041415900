import React from 'react';

import { SVGProps } from './SVG.props';

const MailCode = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || '32'}
      height={height || '32'}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.0013 15.0785C27.6477 15.0785 27.3085 15.2189 27.0585 15.469C26.8084 15.719 26.668 16.0582 26.668 16.4118V23.9998C26.668 24.3535 26.5275 24.6926 26.2774 24.9426C26.0274 25.1927 25.6883 25.3332 25.3346 25.3332H6.66797C6.31435 25.3332 5.97521 25.1927 5.72516 24.9426C5.47511 24.6926 5.33464 24.3535 5.33464 23.9998V11.2132L13.1746 19.0665C13.9246 19.8156 14.9413 20.2363 16.0013 20.2363C17.0613 20.2363 18.078 19.8156 18.828 19.0665L21.2508 16.6437C21.5019 16.3926 21.6429 16.0521 21.6429 15.697C21.6429 15.3419 21.5019 15.0014 21.2508 14.7503C20.9997 14.4993 20.6592 14.3582 20.3041 14.3582C19.9491 14.3582 19.6085 14.4993 19.3575 14.7503L16.9346 17.1732C16.6854 17.4175 16.3503 17.5543 16.0013 17.5543C15.6523 17.5543 15.3172 17.4175 15.068 17.1732L7.21463 9.33317H16.3346C16.6883 9.33317 17.0274 9.19269 17.2774 8.94265C17.5275 8.6926 17.668 8.35346 17.668 7.99984C17.668 7.64622 17.5275 7.30708 17.2774 7.05703C17.0274 6.80698 16.6883 6.6665 16.3346 6.6665H6.66797C5.6071 6.6665 4.58969 7.08793 3.83954 7.83808C3.0894 8.58822 2.66797 9.60564 2.66797 10.6665V23.9998C2.66797 25.0607 3.0894 26.0781 3.83954 26.8283C4.58969 27.5784 5.6071 27.9998 6.66797 27.9998H25.3346C26.3955 27.9998 27.4129 27.5784 28.1631 26.8283C28.9132 26.0781 29.3346 25.0607 29.3346 23.9998V16.4118C29.3346 16.0582 29.1942 15.719 28.9441 15.469C28.6941 15.2189 28.3549 15.0785 28.0013 15.0785Z"
        fill={fill || '#070550'}
      />
      <line
        x1="25.526"
        y1="7.80307"
        x2="24.1089"
        y2="12.1012"
        stroke="#070550"
        strokeWidth="1.8"
        strokeLinecap="round"
      />
      <path
        d="M21.7418 7.35645L19.2148 10.0162L21.7418 12.676"
        stroke="#070550"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3559 7.35596L30.8828 10.0157L28.3559 12.6755"
        stroke="#070550"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MailCode;
