import React from 'react';

import { SVGProps } from './SVG.props';

const Written = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 10}
      height={height || 8}
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.5 7h-5a.5.5 0 000 1h5a.5.5 0 100-1zm-5-5h4a.5.5 0 100-1h-4a.5.5 0 000 1zm0 2h4a.5.5 0 100-1h-4a.5.5 0 000 1zm9 1h-9a.5.5 0 000 1h9a.5.5 0 100-1zm0-5h-3a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5zM9 3H7V1h2v2z"
        fill={fill || '#070550'}
      />
      <path d="M6.688 3.438V.624h2.625v2.813H6.687z" fill={fill || '#070550'} />
    </svg>
  );
};

export default Written;
