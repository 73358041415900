import { put, call, takeLatest, select } from 'redux-saga/effects';

import { guestCommentOnContentPackage } from 'services/content-package';
import { AsyncAction } from 'types/Action';
import { GuestCommentOnContentPackageMeta, GuestCommentOnContentPackagePayload } from 'types/store/GuestCommentOnContentPackage';
import { Store } from 'types/store/Store';

import { guestCommentOnContentPackageActions } from '../actions';

function* guestCommentOnContentPackageRequest(action: AsyncAction<GuestCommentOnContentPackageMeta, GuestCommentOnContentPackagePayload>): any {
    try {
      const { data } = yield call(guestCommentOnContentPackage, action.meta);
      yield put(guestCommentOnContentPackageActions.success(data));
    } catch (e: any) {
      yield put(guestCommentOnContentPackageActions.failed(e.message));
    }
}

// function* guestCommentOnContentPackageSuccess(action: AsyncAction<GuestCommentOnContentPackageMeta, GuestCommentOnContentPackagePayload>): any {
//   yield put()
// }

function* guestCommentOnContentPackageWatcher(): any {
  yield takeLatest(guestCommentOnContentPackageActions.REQUEST, guestCommentOnContentPackageRequest);
  // yield takeLatest(guestCommentOnContentPackageActions.SUCCESS, guestCommentOnContentPackageSuccess);
}

export default guestCommentOnContentPackageWatcher;
