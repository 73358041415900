import { put, call, takeLatest, select } from 'redux-saga/effects';

import { addGetSupportNotification } from 'services/notifications';
import { AsyncAction } from 'types/Action';
import {
  AddGetSupportNotificationStateMeta,
  AddGetSupportNotificationStatePayload,
} from 'types/store/AddGetSupportNotificationState';
import { Store } from 'types/store/Store';

import {
  addGetSupportNotificationActions,
} from '../actions';

function* addGetSupportNotificationRequest(
  action: AsyncAction<
    AddGetSupportNotificationStateMeta,
    AddGetSupportNotificationStatePayload
  >
) {
  const state: Store = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        addGetSupportNotification,
        action.meta,
        state.auth.token
      );
      yield put(addGetSupportNotificationActions.success(data));
    } catch (e: any) {
      yield put(addGetSupportNotificationActions.failed(e.message));
    }
  } else {
    yield put(addGetSupportNotificationActions.failed('Token not found'));
  }
}

function* addGetSupportNotificationWatcher(): any {
  yield takeLatest(
    addGetSupportNotificationActions.REQUEST,
    addGetSupportNotificationRequest
  );
}

export default addGetSupportNotificationWatcher;
