import { put, call, takeLatest, select } from 'redux-saga/effects';

import { upsertContentMarketplace } from 'services/marketplace';
import { uploadCompanyLogo } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpsertContentMarketplaceMeta,
  UpsertContentMarketplacePayload,
} from 'types/store/UpsertContentMarketplace';

import { upsertContentMarketplaceActions } from '../actions';

function* upsertContentMarketplaceRequest(
  action: AsyncAction<
    UpsertContentMarketplaceMeta,
    UpsertContentMarketplacePayload
  >,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const marketplace = action.meta?.marketplace;
      if (!marketplace) {
        throw new Error('Marketplace data is undefined');
      }
      const { marketplace_attachments: ma, video_form } = marketplace;
      for (let i = 0; i < ma.length; i++) {
        if (ma[i].url instanceof File) {
          const { data } = yield call(
            uploadCompanyLogo,
            ma[i].url,
            state.auth.token,
          );

          ma[i].url = data.data?.image?.Location || '';
        }
      }
      if (video_form?.logo_url instanceof File) {
        const { data } = yield call(
          uploadCompanyLogo,
          video_form?.logo_url,
          state.auth.token,
        );
        action.meta.marketplace.video_form.logo_url =
          data.data?.image?.Location || '';
      }
      const { data } = yield call(
        upsertContentMarketplace,
        action.meta,
        state.auth.token,
      );
      yield put(upsertContentMarketplaceActions.success(data));
    } catch (e: any) {
      yield put(upsertContentMarketplaceActions.failed(e.message));
    }
  }
}

// function* upsertContentMarketplaceSuccess(action: AsyncAction<UpsertContentMarketplaceMeta, UpsertContentMarketplacePayload>): any {
//   yield put()
// }

function* upsertContentMarketplaceWatcher(): any {
  yield takeLatest(
    upsertContentMarketplaceActions.REQUEST,
    upsertContentMarketplaceRequest,
  );
  // yield takeLatest(upsertContentMarketplaceActions.SUCCESS, upsertContentMarketplaceSuccess);
}

export default upsertContentMarketplaceWatcher;
