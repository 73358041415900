import React from 'react';

import { SVGProps } from './SVG.props';

const Ribbon = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 16}
      height={height || 28}
      viewBox="0 0 16 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 26.765V4a4 4 0 014-4h8a4 4 0 014 4v22.765c0 .216-.052.43-.152.618a1.19 1.19 0 01-.42.452 1.08 1.08 0 01-.57.16 1.08 1.08 0 01-.572-.16L8 24.922l-6.286 2.913a1.08 1.08 0 01-.571.165c-.2 0-.398-.057-.572-.165a1.19 1.19 0 01-.419-.452A1.304 1.304 0 010 26.765z"
        fill={fill || '#FFCF5C'}
      />
    </svg>
  );
};

export default Ribbon;
