import React from 'react';

import { SVGProps } from './SVG.props';

const Jobs = (props: SVGProps): React.JSX.Element => {
  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  const { width, height, fill } = props;
  return (
    <svg
      width={width || '16'}
      height={height || '17'}
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5.20964C5 4.84145 5.29848 4.54297 5.66667 4.54297H12.3333C12.7015 4.54297 13 4.84145 13 5.20964C13 5.57783 12.7015 5.8763 12.3333 5.8763H5.66667C5.29848 5.8763 5 5.57783 5 5.20964Z"
        fill="#070550"
      />
      <path
        d="M3.66667 5.8763C4.03486 5.8763 4.33333 5.57783 4.33333 5.20964C4.33333 4.84145 4.03486 4.54297 3.66667 4.54297C3.29848 4.54297 3 4.84145 3 5.20964C3 5.57783 3.29848 5.8763 3.66667 5.8763Z"
        fill="#070550"
      />
      <path
        d="M5 8.3763C5 8.00811 5.29848 7.70964 5.66667 7.70964H12.3333C12.7015 7.70964 13 8.00811 13 8.3763C13 8.74449 12.7015 9.04297 12.3333 9.04297H5.66667C5.29848 9.04297 5 8.74449 5 8.3763Z"
        fill="#070550"
      />
      <path
        d="M3.66667 9.04297C4.03486 9.04297 4.33333 8.74449 4.33333 8.3763C4.33333 8.00811 4.03486 7.70964 3.66667 7.70964C3.29848 7.70964 3 8.00811 3 8.3763C3 8.74449 3.29848 9.04297 3.66667 9.04297Z"
        fill="#070550"
      />
      <path
        d="M5 11.543C5 11.1748 5.29848 10.8763 5.66667 10.8763H12.3333C12.7015 10.8763 13 11.1748 13 11.543C13 11.9112 12.7015 12.2096 12.3333 12.2096H5.66667C5.29848 12.2096 5 11.9112 5 11.543Z"
        fill="#070550"
      />
      <path
        d="M3.66667 12.2096C4.03486 12.2096 4.33333 11.9112 4.33333 11.543C4.33333 11.1748 4.03486 10.8763 3.66667 10.8763C3.29848 10.8763 3 11.1748 3 11.543C3 11.9112 3.29848 12.2096 3.66667 12.2096Z"
        fill="#070550"
      />
    </svg>
  );
};

export default Jobs;
