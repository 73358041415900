import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { closeToast } from 'store/actions/toast.action';
import { Store } from 'types/store/Store';

import Snackbar from '../Snackbar';

const Toast = () => {
  const { message, type, open, headline } = useSelector(
    (state: Store) => state.ToastReducer,
  );
  const dispatch = useDispatch();
  return (
    <Snackbar
      open={open}
      detailMessage={message}
      message={headline}
      variant={type}
      onClose={() => dispatch(closeToast())}
    />
  );
};

export default React.memo(Toast);
