import React from 'react';

import { SVGProps } from './SVG.props';

const India = (props: SVGProps): React.JSX.Element => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 30.4444C0 32.8989 1.99 34.8889 4.44444 34.8889H35.5556C38.01 34.8889 40 32.8989 40 30.4444V26H0V30.4444Z"
        fill="#138808"
      />
      <path d="M0 14.8887H40V25.9998H0V14.8887Z" fill="#EEEEEE" />
      <path
        d="M40 14.8889V10.4444C40 7.99 38.01 6 35.5556 6H4.44444C1.99 6 0 7.99 0 10.4444V14.8889H40Z"
        fill="#FF9933"
      />
      <path
        d="M20.0001 24.8889C22.4547 24.8889 24.4446 22.899 24.4446 20.4444C24.4446 17.9898 22.4547 16 20.0001 16C17.5455 16 15.5557 17.9898 15.5557 20.4444C15.5557 22.899 17.5455 24.8889 20.0001 24.8889Z"
        fill="#000080"
      />
      <path
        d="M19.9998 23.778C21.8408 23.778 23.3332 22.2856 23.3332 20.4447C23.3332 18.6037 21.8408 17.1113 19.9998 17.1113C18.1589 17.1113 16.6665 18.6037 16.6665 20.4447C16.6665 22.2856 18.1589 23.778 19.9998 23.778Z"
        fill="#EEEEEE"
      />
      <path
        d="M19.9998 17.1113L20.1621 19.6269L21.2743 17.3658L20.4632 19.7513L22.3565 18.0869L20.6932 19.9813L23.0787 19.1691L20.8176 20.2824L23.3332 20.4447L20.8176 20.6069L23.0787 21.7191L20.6932 20.908L22.3565 22.8013L20.4632 21.138L21.2743 23.5236L20.1621 21.2624L19.9998 23.778L19.8376 21.2624L18.7243 23.5236L19.5365 21.138L17.6421 22.8013L19.3065 20.908L16.9209 21.7191L19.1821 20.6069L16.6665 20.4447L19.1821 20.2824L16.9209 19.1691L19.3065 19.9813L17.6421 18.0869L19.5365 19.7513L18.7243 17.3658L19.8376 19.6269L19.9998 17.1113Z"
        fill="#6666B3"
      />
      <path
        d="M19.9998 21.5557C20.6134 21.5557 21.1109 21.0583 21.1109 20.4446C21.1109 19.831 20.6134 19.3335 19.9998 19.3335C19.3861 19.3335 18.8887 19.831 18.8887 20.4446C18.8887 21.0583 19.3861 21.5557 19.9998 21.5557Z"
        fill="#000080"
      />
    </svg>
  );
};

export default India;
