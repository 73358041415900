import { put, call, takeLatest } from 'redux-saga/effects';

import { getCustomeField } from 'services/auth';
import { AsyncAction } from 'types/Action';
import { GetCustomeFieldMeta, GetCustomeFielPayload } from 'types/store/GetCustomerField';

import { getCustomeFieldActions, getDashboardStoriesActions } from '../actions';

function* getCustomeFieldRequest(action: AsyncAction<GetCustomeFieldMeta, GetCustomeFielPayload>) {
  try {
    const { data } = yield call(getCustomeField, action.meta.companyId);
    data.data = data.data.map((item: any) => ({
      ...item,
      name: item.label.replace(/\s/g, ''),
      custom_field_valuables: item.custom_field_valuables.map((i: any) => ({
        value: i.id, label: i.value, ...i
      }))
    }))
    yield put(getCustomeFieldActions.success(data));
  } catch (e: any) {
    yield put(getCustomeFieldActions.failed(e.message));
  }
}

function* getCustomeFieldWatcher(): any {
  yield takeLatest(getCustomeFieldActions.REQUEST, getCustomeFieldRequest);
}

export default getCustomeFieldWatcher;
