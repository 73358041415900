import React from 'react';

import { SVGProps } from './SVG.props';

const UploadIcon = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || 21}
      height={height || 20}
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        d="M7.21 5.71087L9.5 3.41087V13.0009C9.5 13.2661 9.60536 13.5204 9.79289 13.708C9.98043 13.8955 10.2348 14.0009 10.5 14.0009C10.7652 14.0009 11.0196 13.8955 11.2071 13.708C11.3946 13.5204 11.5 13.2661 11.5 13.0009V3.41087L13.79 5.71087C13.883 5.8046 13.9936 5.879 14.1154 5.92976C14.2373 5.98053 14.368 6.00667 14.5 6.00667C14.632 6.00667 14.7627 5.98053 14.8846 5.92976C15.0064 5.879 15.117 5.8046 15.21 5.71087C15.3037 5.61791 15.3781 5.50731 15.4289 5.38545C15.4797 5.26359 15.5058 5.13288 15.5058 5.00087C15.5058 4.86886 15.4797 4.73816 15.4289 4.6163C15.3781 4.49444 15.3037 4.38384 15.21 4.29087L11.21 0.290873C11.1149 0.199832 11.0028 0.128467 10.88 0.0808729C10.6365 -0.0191451 10.3635 -0.0191451 10.12 0.0808729C9.99725 0.128467 9.8851 0.199832 9.79 0.290873L5.79 4.29087C5.69676 4.38411 5.6228 4.4948 5.57234 4.61662C5.52188 4.73845 5.49591 4.86901 5.49591 5.00087C5.49591 5.13273 5.52188 5.2633 5.57234 5.38512C5.6228 5.50694 5.69676 5.61763 5.79 5.71087C5.88324 5.80411 5.99393 5.87807 6.11575 5.92853C6.23757 5.97899 6.36814 6.00496 6.5 6.00496C6.63186 6.00496 6.76243 5.97899 6.88425 5.92853C7.00607 5.87807 7.11676 5.80411 7.21 5.71087ZM19.5 10.0009C19.2348 10.0009 18.9804 10.1062 18.7929 10.2938C18.6054 10.4813 18.5 10.7357 18.5 11.0009V17.0009C18.5 17.2661 18.3946 17.5204 18.2071 17.708C18.0196 17.8955 17.7652 18.0009 17.5 18.0009H3.5C3.23478 18.0009 2.98043 17.8955 2.79289 17.708C2.60536 17.5204 2.5 17.2661 2.5 17.0009V11.0009C2.5 10.7357 2.39464 10.4813 2.20711 10.2938C2.01957 10.1062 1.76522 10.0009 1.5 10.0009C1.23478 10.0009 0.98043 10.1062 0.792893 10.2938C0.605357 10.4813 0.5 10.7357 0.5 11.0009V17.0009C0.5 17.7965 0.816071 18.5596 1.37868 19.1222C1.94129 19.6848 2.70435 20.0009 3.5 20.0009H17.5C18.2956 20.0009 19.0587 19.6848 19.6213 19.1222C20.1839 18.5596 20.5 17.7965 20.5 17.0009V11.0009C20.5 10.7357 20.3946 10.4813 20.2071 10.2938C20.0196 10.1062 19.7652 10.0009 19.5 10.0009Z"
        fill={fill || '#B5ADB0'}
      />
    </svg>
  );
};

export default UploadIcon;
