import {
  GetStoryRepurposedEventLogMeta,
  GetStoryRepurposedEventLogPayload,
} from 'types/store/GetStoryRepurposedEventLog';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_STORY_REPURPOSED_EVENT_LOG_NS';

const asyncAction = createAsyncAction<
  GetStoryRepurposedEventLogMeta,
  GetStoryRepurposedEventLogPayload
>(ns);

const getStoryRepurposedEventLogActions = { ...asyncAction };

export default getStoryRepurposedEventLogActions;
