import { DoubleQuote, UploadImage } from 'components/base/SVG/';
import styled from 'utils/styled';
import { Theme } from 'types/Theme';

export const Container = styled.div<{
  height: string;
  error?: string | false | undefined;
  width?: string;
  padding?: string;
  margin?: string;
}>`
  height: ${({ height }) => {
    return `${height}`;
  }};

  width: ${({ width }) => width || '100%'};
  display: flex;
  justify-content: center;
  margin: ${({ margin }) => margin || '0 16px 0 0'};
  align-items: center;
  background: #ffffff;
  border: 1px dashed
    ${({ error, theme }) => (error ? theme.brand.error : theme.grey.shade6)};
  padding: ${({ padding }) => padding || '28px 0'};
  border-radius: 12px;

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;

    .svg-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      // margin-right: 10px;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ProfileImageContainer = styled.div<{
  size: number;
  isNew?: boolean;
}>`
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isNew, theme }) => {
    if (isNew) {
      return `
      border: 1px dashed ${theme.grey.shade6};
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 8px 16px 8px 0px;
      `;
    }
  }}
`;

export const DoubleQuoteIcon = styled(DoubleQuote)`
  background-color: ${({ theme }: { theme: Theme }) => theme.grey.shade3};
  border-radius: 16px;
  padding: 32px 32px;
  box-sizing: content-box;
`;

export const UploadImageIconContainer = styled.div<{ isNew?: boolean }>`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(${({ isNew }) => (isNew ? '25%, 25%' : '-10%, -10%')});
  padding: 8.5px;
  box-shadow: 0px 6px 12px rgba(181, 173, 176, 0.2);
  background: #ffffff;
  border-radius: 50%;

  svg {
    display: block;
    margin: auto;
  }
`;

export const UploadImageIcon = styled(UploadImage)``;

export const BlankAvatar = styled.div``;
