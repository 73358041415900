import axios from 'axios';

import { API } from 'consts';
import { GetRecipeDiscussionByEmailMeta } from 'types/store/GetRecipeDiscussionByEmail';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const MAIN_URL = `${BASE_URL}/recipe-detail-discussion`;

export const getRecipeDiscussionByEmail = (data: GetRecipeDiscussionByEmailMeta):any => {
    return axios({
        method: 'get',
        url: `${MAIN_URL}/by-recipe-detail-and-email`,
        params: data
    })
}