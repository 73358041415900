import React, { useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import * as service from '../../services/canvaAuth';

interface CanvaAuthorizeResponse {
  code: string;
}

let params: any;

export const useCanvaAuthorize = () => {
  const [queryParams, setQueryParams] = useState<Record<string, string>>({});
  const token = useSelector((store: any) => store?.auth?.token);
  const query = useQuery<CanvaAuthorizeResponse>({
    queryKey: ['canvaAuthorize', queryParams],
    // enabled: Object.keys(queryParams).length > 0,
    queryFn: async () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      params = Object.fromEntries(urlSearchParams.entries());
      const response = await service.createCanvaAuthorize(params, token);
      if (!response) {
        throw new Error('Failed to authorize with Canva');
      }
      return response;
    },
    retry: false,
    onSuccess: (data) => {
      const redirectLocation = `${params.redirect_uri}?code=${encodeURIComponent(
        data.code,
      )}&state=${encodeURIComponent(params.state)}`;
      window.location.replace(redirectLocation);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return query;
};
