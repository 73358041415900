import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getLibraryAssets } from 'services/library';
import { AsyncAction } from 'types/Action';
import {
  GetLibraryAssetsMeta,
  GetLibraryAssetsPayload,
} from 'types/store/GetLibraryAssetsState';
import { Store } from 'types/store/Store';

import {
  getLibraryAssetsActions,
} from '../actions';

function* getLibraryAssetsRequest(
  action: AsyncAction<GetLibraryAssetsMeta, GetLibraryAssetsPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getLibraryAssets, state.auth.token);
      yield put(getLibraryAssetsActions.success(data));
    } catch (e: any) {
      yield put(getLibraryAssetsActions.failed(e.message));
    }
  }
}

function* getLibraryAssetsSuccess(
  action: AsyncAction<GetLibraryAssetsMeta, GetLibraryAssetsPayload>
) {
  // yield put(getLibraryAssetsActions.clear());
}

function* getLibraryAssetsWatcher(): any {
  yield takeLatest(getLibraryAssetsActions.REQUEST, getLibraryAssetsRequest);
  yield takeLatest(getLibraryAssetsActions.SUCCESS, getLibraryAssetsSuccess);
}

export default getLibraryAssetsWatcher;
