import React from 'react';

import { useTheme } from 'utils/Theme';

import { SVGProps } from './SVG.props';

const Donut = (props: SVGProps): React.JSX.Element => {
  const theme = useTheme();
  const { width, height, fill } = props;
  return (
    <svg
      width={52 || width}
      height={53 || height}
      viewBox="0 0 52 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M35.181 16.819c5.078 5.078 5.078 13.304 0 18.383-2.539 2.538-2.539 6.662 0 9.18a6.51 6.51 0 009.202 0c10.156-10.155 10.156-26.609 0-36.765L35.18 16.82z"
        fill={'#EA4335'}
      />
      <path
        d="M44.362 7.617c-10.156-10.156-26.589-10.156-36.745 0-10.156 10.156-10.156 26.61 0 36.766l9.202-9.181c-5.078-5.079-5.078-13.305 0-18.383 5.078-5.078 13.304-5.078 18.383 0a6.51 6.51 0 009.201 0c2.498-2.54 2.498-6.663-.04-9.202z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M44.363 44.384l-9.202-9.18c-5.078 5.077-13.304 5.077-18.382 0l-9.202 9.18c10.197 10.157 26.63 10.157 36.786 0z"
        fill="#FBBC04"
      />
      <path
        d="M35.181 35.201a6.485 6.485 0 019.181 0 6.51 6.51 0 010 9.202 6.485 6.485 0 01-9.18 0 6.51 6.51 0 010-9.202z"
        fill="#EA4335"
      />
      <path
        d="M7.617 35.201a6.485 6.485 0 019.181 0 6.51 6.51 0 010 9.202 6.485 6.485 0 01-9.18 0 6.51 6.51 0 010-9.202z"
        fill="#34A853"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={0}
          y1={22.191}
          x2={46.272}
          y2={22.191}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#246FDB" />
          <stop offset={0.055} stopColor="#2973DF" />
          <stop offset={0.278} stopColor="#377DEB" />
          <stop offset={0.549} stopColor="#3F83F2" />
          <stop offset={1} stopColor="#4285F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Donut;
