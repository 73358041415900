import { Reducer } from 'redux';

import { GenericResponse } from 'types/GenericResponse';
import { createAsyncReducer } from 'utils/Redux';
import { generateJobAIContentActions } from 'store/actions';
import {
  GET_ALL_JOB_GENERATE,
  JOB_GENERATE,
  SYNC_TO_EDIT,
} from 'store/actions/jobs/generateContent';
import { Action, ActionStoryReducer } from 'types/Action';
import {
  JobGenerateMeta,
  JobGeneratePayLoad,
  JobsStore,
} from 'types/store/JobsState';

const initState: JobsStore = {
  isDownloadAll: false,
  isLoading: false,
  isScanningLink: false,
  downloadTemplateContent: false,
  isDownloadContent: false,
  isGenerate: false,
  limitedAISupport: false,
  AIbuttonLimit: 0,
  wordCound: 0,
  currentAnswer: '',
  isStep2: 0,
  oldValue: '',
  notesAI: '',
  notesAIError: '',
  aiSupportContent: '',
  advocateAnswer: {},
  employeeProfile: {},
  keyPoint: '',
  listQuestionCustom: [],
  AIready: false,
  audient_id: '',
  isContentRecipes: false,
  uploadLinkLikedInMessage: [],
  uploadLinkData: { status: 0, message: '', data: {} },
  magicLink: '',
  isUploadingLink: false,
  titleFile: '',
  isOpenModalFailed: false,
  isOpenWatingGenerate: false,
  countError: 0,
  jobDetail: '',
  jobDecription: '',
  jobQualifications: '',
  aditional: '',
  sycnJob: undefined,
  jobTitleEdit: '',
  videos: [],
};

export const JobsReducer: Reducer<JobsStore, ActionStoryReducer> = (
  state = initState,
  { type, payload, wordCound, error },
) => {
  switch (type) {
    case SYNC_TO_EDIT.SYNC_TO_EDIT_REQUEST: {
      return {
        ...state,
        sycnJob: payload,
      };
    }
    case GET_ALL_JOB_GENERATE.ADITIONAL: {
      return {
        ...state,
        aditional: payload,
      };
    }
    case GET_ALL_JOB_GENERATE.TITLE: {
      return {
        ...state,
        jobTitleEdit: payload,
      };
    }
    case GET_ALL_JOB_GENERATE.VIDEOS: {
      const newsVideo = state.videos;
      if (payload.index) {
        newsVideo[payload.index] = payload.value;
      }
      return {
        ...state,
        videos: newsVideo,
      };
    }
    case GET_ALL_JOB_GENERATE.SYNC_VIDEO: {
      return {
        ...state,
        videos: payload,
      };
    }
    case GET_ALL_JOB_GENERATE.JOB_DESCRIPTION: {
      return {
        ...state,
        jobDecription: payload,
      };
    }
    case GET_ALL_JOB_GENERATE.JOB_DETAIL: {
      return {
        ...state,
        jobDetail: payload,
      };
    }
    case GET_ALL_JOB_GENERATE.QUALIFICATIONS: {
      return {
        ...state,
        jobQualifications: payload,
      };
    }
    case generateJobAIContentActions.REQUEST: {
      return {
        ...state,
        isLoading: true,
        notesAIError: '',
        isGenerate: false,
      };
    }
    case generateJobAIContentActions.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        notesAI: payload,
        notesAIError: '',
        isGenerate: true,
      };
    }
    case generateJobAIContentActions.FAILED: {
      return {
        ...state,
        isLoading: false,
        notesAI: '',
        notesAIError: error,
      };
    }
    case generateJobAIContentActions.CLEAR: {
      return {
        ...state,
        isGenerate: false,
      };
    }
    case JOB_GENERATE.JOB_GENERATE_CLEAR: {
      return {
        ...state,
        isLoading: false,
        notesAI: '',
        notesAIError: '',
      };
    }
    default: {
      return state;
    }
  }
};

export default createAsyncReducer<JobGenerateMeta, JobGeneratePayLoad>(
  generateJobAIContentActions,
);
