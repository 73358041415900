import { put, call, takeLatest, select } from 'redux-saga/effects';

import { addApproversV2 } from 'services/team';
import { AsyncAction } from 'types/Action';
import { AddApproversV2Meta, AddApproversV2Payload } from 'types/store/AddApproversV2';
import { Store } from 'types/store/Store';

import { addApproversV2Actions } from '../actions';

function* addApproversV2Request(action: AsyncAction<AddApproversV2Meta, AddApproversV2Payload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(addApproversV2, action.meta, state.auth.token);
      yield put(addApproversV2Actions.success(data));
    } catch (e: any) {
      yield put(addApproversV2Actions.failed(e.message));
    }
  }
}

// function* addApproversV2Success(action: AsyncAction<AddApproversV2Meta, AddApproversV2Payload>): any {
//   yield put()
// }

function* addApproversV2Watcher(): any {
  yield takeLatest(addApproversV2Actions.REQUEST, addApproversV2Request);
  // yield takeLatest(addApproversV2Actions.SUCCESS, addApproversV2Success);
}

export default addApproversV2Watcher;
