import axios, { AxiosPromise } from 'axios';

import { API } from 'consts';
import { GenericResponse } from 'types/GenericResponse';
import {
  HeadlineDynamicFilter,
  RepurposeContentParams,
  RepurposeData,
  TagsDynamicFilter,
  UpdateRepurpose,
} from 'types/store/RepurposeContent';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const STORY = `${BASE_URL}/story`;
const REPURPOSING_CONTENT = `${BASE_URL}/repurposed-story`;

export const getListRepurpose = (
  params: RepurposeContentParams,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${REPURPOSING_CONTENT}/get-original-story-answer-list`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
};

export const getListStoryStatus = (
  token: string,
): AxiosPromise<GenericResponse<string[]>> => {
  return axios({
    method: 'get',
    url: `${STORY}/get-list-story-status`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getListHeadline = (
  token: string,
): AxiosPromise<GenericResponse<HeadlineDynamicFilter[]>> => {
  return axios({
    method: 'get',
    url: `${STORY}/get-list-repurposable-story-headline`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getListTags = (
  token: string,
): AxiosPromise<GenericResponse<TagsDynamicFilter[]>> => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/tag/get-list`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getListQuestionGroup = (
  params: RepurposeContentParams,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${REPURPOSING_CONTENT}/get-list-answer-group-by-question`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
};

export const createRepurpose = (
  data: RepurposeData,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${REPURPOSING_CONTENT}/create`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const updateRepurpose = (
  data: UpdateRepurpose,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'put',
    url: `${REPURPOSING_CONTENT}/update`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
