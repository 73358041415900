import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getRecipes } from 'services/homepage';
import { AsyncAction } from 'types/Action';
import { GetRecipesMeta, GetRecipesPayload } from 'types/store/GetRecipes';
import { Store } from 'types/store/Store';

import { getRecipesActions } from '../actions';

function* getRecipesRequest(action: AsyncAction<GetRecipesMeta, GetRecipesPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getRecipes, action.meta, state.auth.token);
      yield put(getRecipesActions.success(data));
    } catch (e: any) {
      yield put(getRecipesActions.failed(e.message));
    }
  }
}

// function* getRecipesSuccess(action: AsyncAction<GetRecipesMeta, GetRecipesPayload>): any {
//   yield put()
// }

function* getRecipesWatcher(): any {
  yield takeLatest(getRecipesActions.REQUEST, getRecipesRequest);
  // yield takeLatest(getRecipesActions.SUCCESS, getRecipesSuccess);
}

export default getRecipesWatcher;
