// utils/handleAxiosError.ts
import { AxiosError } from 'axios';

// Custom type guard for AxiosError
function isAxiosError(e: unknown): e is AxiosError {
  return (
    typeof e === 'object' &&
    e !== null &&
    (e as AxiosError).isAxiosError === true
  );
}

export function handleAxiosError(e: unknown): string | null | undefined {
  if (isAxiosError(e)) {
    // Return the message, which could be string, null, or undefined
    return e.response?.data.message;
  }
  return undefined;
}

export interface ErrorResponse {
  status: number;
  message?: string;
}
