import { put, call, takeLatest, select } from 'redux-saga/effects';

import { uploadRevisedVideo } from 'services/question';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UploadRevisedVideoMeta,
  UploadRevisedVideoPayload,
} from 'types/store/UploadRevisedVideo';

import { uploadRevisedVideoActions } from '../actions';

function* uploadRevisedVideoRequest(
  action: AsyncAction<UploadRevisedVideoMeta, UploadRevisedVideoPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        uploadRevisedVideo,
        action.meta,
        state.auth.token
      );
      yield put(uploadRevisedVideoActions.success(data));
    } catch (e: any) {
      yield put(uploadRevisedVideoActions.failed(e.message));
    }
  }
}

function* uploadRevisedVideoSuccess(
  action: AsyncAction<UploadRevisedVideoMeta, UploadRevisedVideoPayload>
) {
  //
}

function* uploadRevisedVideoWatcher(): any {
  yield takeLatest(
    uploadRevisedVideoActions.REQUEST,
    uploadRevisedVideoRequest
  );
  yield takeLatest(
    uploadRevisedVideoActions.SUCCESS,
    uploadRevisedVideoSuccess
  );
}

export default uploadRevisedVideoWatcher;
