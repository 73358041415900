import React from 'react';

import { SVGProps } from './SVG.props';

const Italic = (props: SVGProps): React.JSX.Element => {
  const { width, height } = props;

  return (
    <svg
      width={width || '18'}
      height={height || '18'}
      viewBox="0 0 18 18"
      fill="#070550"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6538 3.78606C11.0032 3.90822 11.192 4.30459 11.0757 4.67136L8.189 13.771C8.07265 14.1378 7.69515 14.3361 7.34582 14.2139C6.9965 14.0918 6.80764 13.6954 6.92399 13.3286L9.81066 4.22897C9.92701 3.86219 10.3045 3.66389 10.6538 3.78606Z"
        fill={'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66667 4.45017C8.66667 4.06358 8.96514 3.75019 9.33333 3.75019H11.3333C11.7015 3.75019 12 4.06358 12 4.45017C12 4.83675 11.7015 5.15014 11.3333 5.15014H9.33333C8.96514 5.15014 8.66667 4.83675 8.66667 4.45017Z"
        fill={'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 13.5498C6 13.1633 6.29848 12.8499 6.66667 12.8499H8.66667C9.03486 12.8499 9.33333 13.1633 9.33333 13.5498C9.33333 13.9364 9.03486 14.2498 8.66667 14.2498H6.66667C6.29848 14.2498 6 13.9364 6 13.5498Z"
        fill={'currentColor'}
      />
    </svg>
  );
};

export default Italic;
