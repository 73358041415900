import { put, call, takeLatest, select } from 'redux-saga/effects';

import { createTags } from 'services/tags';
import createTagsActions, {
  ICreateTagsMeta,
} from 'store/actions/library/createTags';
import { openToast } from 'store/actions';
import { AsyncAction } from 'types/Action';
import { GenericResponse } from 'types/GenericResponse';
import { Store } from 'types/store/Store';

function* createTagsSaga(
  action: AsyncAction<ICreateTagsMeta, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(createTags, action.meta, state.auth.token);
      yield put(createTagsActions.success(data));
      yield put(
        openToast(
          data?.message ?? 'Tag(s) created successfully',
          'success',
          'Success',
        ),
      );
      yield put(createTagsActions.clear());
    } catch (e: any) {
      const errorMessage = e.response?.data?.message || e.message;
      yield put(createTagsActions.failed(errorMessage));
      yield put(openToast(errorMessage, 'error', 'Error'));
      yield put(createTagsActions.clear());
    }
  }
}

function* createTagsWatcher(): any {
  yield takeLatest(createTagsActions.REQUEST, createTagsSaga);
}

export default createTagsWatcher;
