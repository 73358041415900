import axios, { AxiosPromise } from 'axios';

import { API } from 'consts';
import {
  SuggestionTopicPayload,
  CreateNewStoryPayLoad,
  CreatePromptPayload,
  downloadContentPayload,
  ParagraphVersionPayload,
  SaveParagraphVersionPayload,
  StoryVersionPayload,
  UpdateStoryPayLoad,
  TranslatedStoryPayload,
  FetchStoryTransalatePayload,
  FetchSoundbitePayload,
  FetchHeadlinePayload,
  markReadyToShare,
} from 'store/actions/storyDetailV2/storyDetailV2.action';
import { GenericResponse } from 'types/GenericResponse';

const BASE_URL = `${API.URL}/${API.VERSION}`;

export const getSuggestionTopicList = (
  payLoad: SuggestionTopicPayload,
  token: string,
): AxiosPromise<GenericResponse> => {
  const listIds = payLoad.exclude_ids
    ?.map((item: any) => item?.key)
    .join('&exclude_ids[]=');

  let url = `${BASE_URL}/prompt/list?story_id=${payLoad.story_id}&status=${payLoad.status}&prompt=${payLoad.prompt}&type=${payLoad.type}&per_page=${payLoad.per_page}`;

  if (listIds?.length) {
    url = `${BASE_URL}/prompt/list?story_id=${payLoad.story_id}&status=${payLoad.status}&prompt=${payLoad.prompt}&type=${payLoad.type}&per_page=${payLoad.per_page}&exclude_ids[]=${listIds}`;
  }

  return axios({
    method: 'get',
    url: url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createNewStoryContent = (
  data: CreateNewStoryPayLoad,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/story-ai-blog`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
export const updateStoryContent = (
  data: UpdateStoryPayLoad,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'put',
    url: `${BASE_URL}/story-ai-blog`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

// get existing translated record
export const getTranslatedStoryByCode = (
  data: TranslatedStoryPayload,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/story-ai-blog/get-content/translate`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

// fetch for data translate
export const fetchTranslateStoryByCode = (
  data: FetchStoryTransalatePayload,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/story-ai-blog/aw-blog-translate`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

// generate soundbite
export const generateBlogSoundbite = (
  data: FetchSoundbitePayload,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/story-ai-blog/aw-generate-soundbite`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const generateBlogHeadline = (
  data: FetchHeadlinePayload,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/story-ai-blog/aw-generate-headline`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const prevNextVerParagraph = (
  data: ParagraphVersionPayload,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/story-ai-blog/get-paragraph/version`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    // data,
    params: data,
  });
};

export const storyVersion = (
  data: StoryVersionPayload,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/story-ai-blog/get-content/versions`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const saveCurrentParagraphVersion = (
  paragraphId: string,
  data: SaveParagraphVersionPayload,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/story-ai-blog/paragraph/${paragraphId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const createCustomPrompt = (
  data: CreatePromptPayload,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/prompt/create`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const downloadContentStory = (
  data: downloadContentPayload,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/story/generate-docx`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const storyOwner = (token: string): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/story/get-all-owner`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const generateArgumentWriting = (
  payload: any,
  token: string,
): AxiosPromise<GenericResponse> => {
  // base on data type to decide the endpoint call
  const { type, data } = payload;

  let endpoint = '';

  switch (type.toUpperCase()) {
    case 'INDIVIDUAL':
      endpoint = `${BASE_URL}/story-ai-blog/aw-generate-individual`;
      break;
    case 'COMPANY':
      endpoint = `${BASE_URL}/story-ai-blog/aw-generate-company`;
      break;
  }

  return axios({
    method: 'post',
    url: endpoint,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const markContentReadyToShare = (
  data: markReadyToShare,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'put',
    url: `${BASE_URL}/story/mark-content-ready-to-share`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const markContentNotReadyToShare = (
  data: markReadyToShare,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'put',
    url: `${BASE_URL}/story/mark-content-not-ready-to-share`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
