import React from 'react';

import { SVGProps } from './SVG.props';

const SkipCircleV3 = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || 80}
      height={height || 80}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54 20C52.9392 20 51.9217 20.4214 51.1716 21.1716C50.4214 21.9217 50 22.9391 50 24V30.76L34 21.52C32.7845 20.8182 31.4057 20.4486 30.0021 20.4482C28.5985 20.4478 27.2195 20.8168 26.0036 21.518C24.7877 22.2191 23.7776 23.2279 23.0749 24.4429C22.3722 25.6579 22.0015 27.0364 22 28.44V51.56C22.0015 52.9629 22.3718 54.3408 23.0739 55.5554C23.7759 56.77 24.7851 57.7786 26 58.48C27.2162 59.1822 28.5957 59.5518 30 59.5518C31.4043 59.5518 32.7839 59.1822 34 58.48L50 49.24V56C50 57.0609 50.4214 58.0783 51.1716 58.8284C51.9217 59.5786 52.9392 60 54 60C55.0609 60 56.0783 59.5786 56.8284 58.8284C57.5786 58.0783 58 57.0609 58 56V24C58 22.9391 57.5786 21.9217 56.8284 21.1716C56.0783 20.4214 55.0609 20 54 20ZM50 40L30 51.56V28.44L50 39.96V40ZM40 0C32.0888 0 24.3552 2.34596 17.7772 6.74122C11.1992 11.1365 6.07234 17.3836 3.04484 24.6927C0.0173314 32.0017 -0.774802 40.0444 0.768607 47.8036C2.31202 55.5629 6.12165 62.6902 11.7157 68.2843C17.3098 73.8784 24.4372 77.688 32.1964 79.2314C39.9556 80.7748 47.9983 79.9827 55.3074 76.9552C62.6164 73.9277 68.8635 68.8008 73.2588 62.2228C77.6541 55.6449 80 47.9113 80 40C80 34.7471 78.9654 29.5457 76.9552 24.6927C74.945 19.8396 71.9986 15.4301 68.2843 11.7157C64.5699 8.00139 60.1604 5.05501 55.3074 3.04482C50.4543 1.03463 45.2529 0 40 0ZM40 72C33.671 72 27.4841 70.1232 22.2218 66.607C16.9594 63.0908 12.8579 58.0931 10.4359 52.2459C8.01387 46.3986 7.38016 39.9645 8.61489 33.7571C9.84962 27.5497 12.8973 21.8479 17.3726 17.3726C21.8479 12.8973 27.5497 9.8496 33.7571 8.61487C39.9645 7.38015 46.3987 8.01385 52.2459 10.4359C58.0931 12.8579 63.0908 16.9594 66.607 22.2218C70.1232 27.4841 72 33.671 72 40C72 48.4869 68.6286 56.6263 62.6274 62.6274C56.6263 68.6286 48.4869 72 40 72Z"
        fill={fill || '#DCF3E8'}
      />
    </svg>
  );
};

export default SkipCircleV3;
