import React from 'react';

import { SVGProps } from './SVG.props';

const Eye = (props: SVGProps): React.JSX.Element => {
  return (
    <svg width={10} height={14} viewBox="0 0 10 14" fill="none" {...props}>
      <path
        d="M7.667.333H2.333a2 2 0 00-2 2V13a.667.667 0 001 .58L5 11.46l3.667 2.12a.667.667 0 00.333.086.667.667 0 00.333-.086.667.667 0 00.334-.58V2.333a2 2 0 00-2-2zm.666 11.513l-3-1.733a.667.667 0 00-.666 0l-3 1.733V2.333a.667.667 0 01.666-.667h5.334a.667.667 0 01.666.667v9.513z"
        fill="#070550"
      />
    </svg>
  );
};

export default Eye;
