import { v4 as uuid } from 'uuid';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const handleChangeFileName = (
  url: string,
  newName: string,
  extensionName: string,
  xhrResponse?: any,
): void => {
  const xhr = new XMLHttpRequest();
  const a = document.createElement('a');
  let file = null;
  if (url) xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    file = new Blob([xhrResponse ? xhrResponse : xhr.response], {
      type: 'application/octet-stream',
    });
    a.href = window.URL.createObjectURL(file);
    a.download = `${newName}.${extensionName}`; // Set to whatever file name you want
    // Now just click the link you created
    // Note that you may have to append the a element to the body somewhere
    // for this to work in Firefox
    a.click();
  };
  xhr.send();
};

export function downloadLink(filename: string, url: string) {
  const id = uuid();
  const element = document.createElement('a');
  element.setAttribute('id', id);
  element.setAttribute('href', url);
  element.setAttribute('download', filename);
  document.body.appendChild(element);
  element.click();
  const elementById = document.getElementById(id);
  document.body.removeChild(elementById || element);
}


export async function toDataURL(url: string) {
  const blob = await fetch(url).then(res => res.blob());
  return URL.createObjectURL(blob);
}

export async function downloadImageLink(imageLink: string, name: string) {
  const a = document.createElement("a");
  a.href = await toDataURL(imageLink);
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export async function copyImageBlob(imageLink: string) {
  try {
    // Convert image to base64 using canvas
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Wait for image to load
    const image = new Image();
    image.crossOrigin = "anonymous"; // To handle CORS issues
    image.src = imageLink;

    const enlarge = 2;

    image.onload = async function () {
      canvas.width = image.naturalWidth * enlarge;
      canvas.height = image.naturalHeight * enlarge;

      if (image != null && ctx) {
        ctx.drawImage(image, 0, 0, image.naturalWidth * enlarge, image.naturalHeight * enlarge);

        // Convert to PNG Blob
        const blob = await new Promise<Blob | null>((resolve) =>
          canvas.toBlob(resolve, "image/png") // Force PNG
        );

        if (!blob) {
          console.error("Failed to convert image to Blob.");
          return;
        }

        console.log("Blob size (bytes):", blob.size, "Type:", blob.type); // Debugging

        // Write PNG to clipboard
        const item = new ClipboardItem({ "image/png": blob });
        await navigator.clipboard.write([item]);

        console.log("Image copied successfully as PNG.");
      }
    };
  }catch(e){
    console.error(e);
  }
}

export const generalFileName = (baName: string, headline: string): string =>
  `${baName === 'Responses' ? baName : `${baName}_responses`}_${headline !== undefined
    ? headline.length < 50
      ? headline
      : headline.substring(0, 49)
    : ''
  }_${new Date().getTime()}`;
