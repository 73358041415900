import React from 'react';

import { SVGProps } from './SVG.props';

const UserCurse = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      width={40}
      height={40}
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9997 4.66667C11.9997 6.87581 10.2088 8.66667 7.99967 8.66667C5.79054 8.66667 3.99967 6.87581 3.99967 4.66667C3.99967 2.45753 5.79054 0.666668 7.99967 0.666668C10.2088 0.666668 11.9997 2.45753 11.9997 4.66667ZM10.6663 4.66667C10.6663 6.13943 9.47243 7.33333 7.99967 7.33333C6.52692 7.33333 5.33301 6.13943 5.33301 4.66667C5.33301 3.19391 6.52692 2 7.99967 2C9.47243 2 10.6663 3.19391 10.6663 4.66667Z"
        fill="#060548"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99972 9.33333C6.04297 9.33333 4.63214 9.73923 3.6201 10.3478C2.60403 10.9589 2.03332 11.7483 1.71816 12.4387C1.45251 13.0207 1.57342 13.6138 1.92637 14.0364C2.26282 14.4392 2.78594 14.6667 3.33304 14.6667H12.6663C13.2134 14.6667 13.7366 14.4392 14.073 14.0364C14.426 13.6139 14.5469 13.0208 14.2812 12.4388C13.9661 11.7483 13.3954 10.9589 12.3793 10.3478C11.3673 9.73923 9.95648 9.33333 7.99972 9.33333ZM2.93111 12.9924C3.14988 12.5131 3.55174 11.9448 4.30724 11.4905C5.06678 11.0337 6.22653 10.6667 7.99972 10.6667C9.77293 10.6667 10.9327 11.0337 11.6922 11.4905C12.4477 11.9448 12.8495 12.5131 13.0683 12.9924C13.0941 13.049 13.093 13.083 13.0904 13.1004C13.0874 13.1202 13.0777 13.1481 13.0497 13.1816C12.9896 13.2536 12.8556 13.3333 12.6663 13.3333H3.33304C3.14375 13.3333 3.00978 13.2536 2.94968 13.1816C2.92169 13.1481 2.91196 13.1202 2.909 13.1004C2.9064 13.083 2.90527 13.049 2.93111 12.9924Z"
        fill="#060548"
      />
    </svg>
  );
};

export default UserCurse;
