import { put, call, takeLatest, select } from 'redux-saga/effects';
import { AxiosError } from 'axios';

import { getStoryLinkRq } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  GetStoryLinkMeta,
  GetStoryLinkPayload,
} from 'types/store/GetStoryLink';
import { Store } from 'types/store/Store';
import { API } from 'consts';
import { ErrorResponse } from 'utils/handleAxiosError';

import { getStoryLink, openToast, showFloatMessage } from '../actions';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const STORY_URL = `${BASE_URL}/story`;

function* getStoryLinkRequest(
  action: AsyncAction<GetStoryLinkMeta, GetStoryLinkPayload>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getStoryLinkRq,
        action.meta,
        state.auth.token,
      );
      // yield put(getStoryLink.success(data));
      if (data.data.advocateInviteLink) {
        navigator.clipboard.write([data.data.advocateInviteLink]);
        yield put(
          showFloatMessage({
            message: 'Link copied to clipboard',
          }),
        );

        // const text = new ClipboardItem({
        //   'text/plain': fetch(`${STORY_URL}/share-link`, {
        //     method: 'POST',
        //     headers: {
        //       Authorization: `Bearer ${state.auth.token}`,
        //       Accept: 'application/json',
        //       'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({ storyId: action.meta.story_id }),
        //   })
        //     .then((response) => response.json())
        //     .then(
        //       (data) =>
        //         new Blob([JSON.stringify(data.data.advocateInviteLink)], {
        //           type: 'text/plain',
        //         }),
        //     ),
        // });
        // navigator.clipboard.write([text]);
        yield put(
          showFloatMessage({
            message: 'Link copied to clipboard',
          }),
        );
      }
    } catch (e: any) {
      console.error(e.message);
      const errorMessage = e as AxiosError<ErrorResponse>;
      yield put(
        openToast(
          errorMessage?.response?.data.message ??
            'An unexpected error occurred',
          'error',
        ),
      );
    }
  }
}

function* getStoryLinkWatcher(): any {
  yield takeLatest(getStoryLink.REQUEST, getStoryLinkRequest);
}

export default getStoryLinkWatcher;
