import pathOr from 'ramda/es/pathOr';

import { Action } from 'types/Action';
import {
  StoryBuilderState,
  StoryBuilderPayload,
} from 'types/store/StoryBuilderState';

import { storyBuilderActions } from '../actions';

const DEFAULT_STATE: StoryBuilderState = {};

const DEFAULT_ACTION: Action<StoryBuilderPayload> = {
  type: '',
  payload: {},
};

export default (state = DEFAULT_STATE, action = DEFAULT_ACTION): any => {
  const localReducer = (): StoryBuilderState => {
    return {
      ...state,
      ...action.payload,
    };
  };
  return pathOr(state, [action.type], {
    [storyBuilderActions.UPDATE]: localReducer(),
    [storyBuilderActions.CLEAR]: DEFAULT_STATE,
  });
};
