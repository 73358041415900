import { put, call, takeLatest, select } from 'redux-saga/effects';

import { chargeCard } from 'services/payment';
import { AsyncAction } from 'types/Action';
import {
  ChargeCardMeta,
  ChargeCardPayload
} from 'types/store/ChargeCard';
import { Store } from 'types/store/Store';

import {
  chargeCardActions, getCompanyCreditsActions, getTransactionByStripeIdActions, getUnpaidStoriesActions
} from '../actions';

function* chargeCardRequest(
  action: AsyncAction<ChargeCardMeta, ChargeCardPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(chargeCard, action.meta, state.auth.token);
      yield put(chargeCardActions.success(data));
    } catch (e: any) {
      yield put(chargeCardActions.failed(e.message));
    }
  }
}

function* chargeCardSuccess(
  action: AsyncAction<ChargeCardMeta, ChargeCardPayload>
) {
  yield put(getUnpaidStoriesActions.request({}));
  yield put(getCompanyCreditsActions.request({}));
  yield put(getTransactionByStripeIdActions.request({}));
  yield put(chargeCardActions.clear());
}

function* chargeCardWatcher(): any {
  yield takeLatest(chargeCardActions.REQUEST, chargeCardRequest);
  yield takeLatest(chargeCardActions.SUCCESS, chargeCardSuccess);
}

export default chargeCardWatcher;
