import React from 'react';

import { SVGProps } from './SVG.props';

const ArrowFrontierLeft = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M1.99992 2.6665C2.36811 2.6665 2.66659 2.96498 2.66659 3.33317V12.6665C2.66659 13.0347 2.36811 13.3332 1.99992 13.3332C1.63173 13.3332 1.33325 13.0347 1.33325 12.6665V3.33317C1.33325 2.96498 1.63173 2.6665 1.99992 2.6665Z"
        fill={fill || '#060548'}
      />
      <path
        d="M8.47132 4.1951C8.73167 4.45545 8.73167 4.87756 8.47132 5.13791L6.27606 7.33317H13.9999C14.3681 7.33317 14.6666 7.63165 14.6666 7.99984C14.6666 8.36803 14.3681 8.6665 13.9999 8.6665H6.27606L8.47132 10.8618C8.73167 11.1221 8.73167 11.5442 8.47132 11.8046C8.21097 12.0649 7.78886 12.0649 7.52851 11.8046L4.19518 8.47124C3.93483 8.21089 3.93483 7.78878 4.19518 7.52843L7.52851 4.1951C7.78886 3.93475 8.21097 3.93475 8.47132 4.1951Z"
        fill={fill || '#060548'}
      />
    </svg>
  );
};

export default ArrowFrontierLeft;
