import { put, call, takeLatest, select } from 'redux-saga/effects';

import { transcribeLibraryVideo } from 'services/library';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import { TranscribeLibraryVideoMeta, TranscribeLibraryVideoPayload } from 'types/store/TranscribeLibraryVideo';

import { transcribeLibraryVideoActions } from '../actions';

function* transcribeLibraryVideoRequest(action: AsyncAction<TranscribeLibraryVideoMeta, TranscribeLibraryVideoPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(transcribeLibraryVideo, action.meta, state.auth.token);
      yield put(transcribeLibraryVideoActions.success(data));
    } catch (e: any) {
      yield put(transcribeLibraryVideoActions.failed(e.message));
    }
  }
}

// function* transcribeLibraryVideoSuccess(action: AsyncAction<TranscribeLibraryVideoMeta, TranscribeLibraryVideoPayload>): any {
//   yield put()
// }

function* transcribeLibraryVideoWatcher(): any {
  yield takeLatest(transcribeLibraryVideoActions.REQUEST, transcribeLibraryVideoRequest);
  // yield takeLatest(transcribeLibraryVideoActions.SUCCESS, transcribeLibraryVideoSuccess);
}

export default transcribeLibraryVideoWatcher;
