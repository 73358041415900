import { put, call, takeLatest, select } from 'redux-saga/effects';

import { editApprover } from 'services/team';
import { AsyncAction } from 'types/Action';
import { EditApproverMeta, EditApproverPayload } from 'types/store/EditApprover';
import { Store } from 'types/store/Store';

import { editApproverActions } from '../actions';

function* editApproverRequest(action: AsyncAction<EditApproverMeta, EditApproverPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(editApprover, action.meta, state.auth.token);
      yield put(editApproverActions.success(data));
    } catch (e: any) {
      yield put(editApproverActions.failed(e.message));
    }
  }
}

// function* editApproverSuccess(action: AsyncAction<EditApproverMeta, EditApproverPayload>): any {
//   yield put()
// }

function* editApproverWatcher(): any {
  yield takeLatest(editApproverActions.REQUEST, editApproverRequest);
  // yield takeLatest(editApproverActions.SUCCESS, editApproverSuccess);
}

export default editApproverWatcher;
