import axios, { AxiosPromise } from 'axios';

import { API } from 'consts';
import { UpdateTrafficLink } from 'routes/Employer/StoryEditor/StoryEditorDetail/StoryEditorDetailV2/Publish/TrackingLink/components/ModalTrackingLink/ModalTrackingLink.props';
import { GenericResponse } from 'types/GenericResponse';
import { ShareLinkResponse } from 'types/Library';

const BASE_URL = `${API.URL}/${API.VERSION}`;

export const getListLibrary = (urlQuery: string, token: string): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/story-share/list-media?${urlQuery}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getSharedList = (
  data: {
    story_id?: string;
    library_id?: string;
  },
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/story-share/list-user-shared`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getInvitationList = (
  data: {
    story_id?: string;
    library_id?: string;
  },
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/story-share/list-user-invited`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const resendInvite = (
  data: {
    story_share_id?: string;
  },
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/story-share/resend-invite`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const embedEmail = (data: any, token: string): any => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/story-share/email`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const getEmbedJSCodeLibrary = (queryURL: string, token: string): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/story-share/website?${queryURL}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getListEmail = (token: string): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/story-share/list-email`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const shareMagicLink = (queryURL: string, token: string): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/advocate/get-share-link?${queryURL}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateSSO = (
  params: {
    certificate?: File;
    signatureAlgorithm?: string;
    issuer: string | '';
    entryPoint: string | '';
    provider: string | 'OKTA';
  },
  token: string,
): AxiosPromise => {
  const data = new FormData();
  params?.certificate && data.append('certificate', params?.certificate);
  params?.signatureAlgorithm &&
    data.append('signatureAlgorithm', params?.signatureAlgorithm);
  data.append('issuer', params.issuer || '');
  data.append('entryPoint', params.entryPoint || '');
  data.append('provider', params.provider);
  return axios({
    method: 'PUT',
    url: `${BASE_URL}/company-settings/sso`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const renewSSOVerificationCert = (token: string): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${BASE_URL}/company-settings/sso/renew-verification-cert`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {},
  });
};

export const getSSOSetting = (token: string): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/company-settings/sso`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getHTMLGmailCode = (
  data: {
    content: string;
    libraryId?: string;
    storyId?: string;
  },
  token: string,
): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${BASE_URL}/story-share/copy-embed-email`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const removeSSOSetting = (token: string): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${BASE_URL}/company-settings/delete-sso`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const generateGif = (queryURL: string, token: string): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/story-share/get-gif?${queryURL}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateTrafficLink = (
  data: UpdateTrafficLink,
  token: string,
): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${BASE_URL}/analytics/update-website-link`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const listShareContent = (
  data: { library_id?: string; story_id?: string },
  token: string,
): Promise<GenericResponse<ShareLinkResponse>> => {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/story-share/list-shareable-content`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  }).then((res) => res.data);
};
