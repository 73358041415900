import i18next from 'i18next';

import { translations } from 'locales/i18n';
import { FONT_STYLE } from 'types/store/Widget';

export const TITLE = {
  CAREER_SITE: 'career-site',
  LADING_PAGE: 'landing-page',
  JOB_DESCRIPTION: 'job-description',
  EMAIL: 'EMAIL',
};

export enum PAGE_API {}

export const SETTING_TITLE = {
  [TITLE.CAREER_SITE]: 'Career Site',
  [TITLE.LADING_PAGE]: 'Landing Page',
  [TITLE.JOB_DESCRIPTION]: 'Job Description',
  [TITLE.EMAIL]: 'Email',
};

export const SETTING_API = {
  [TITLE.CAREER_SITE]: 'CAREER_SITE',
  [TITLE.LADING_PAGE]: 'LADING_PAGE',
  [TITLE.JOB_DESCRIPTION]: 'JOB_DESCRIPTION',
  [TITLE.EMAIL]: 'EMAIL',
};

export const defaultSettingForm = {
  tags: [],
  title: true,
  button: true,
  advocateName: true,
  tag: true,
  role: true,
  linkBtn: '',
  mgnButton: '',
  contentSrc: 'auto',
  colorTheme: '#070550',
  colorButton: '#91DBB6',
  colorButtonMessage: '#070550',
  landingType: 'EMBED',
  layoutStyle: {
    label: '1 Story',
    value: 1,
  },
  visualCard: 'card',
  headline: 'Headline',
  subtitle: 'SUBTITLE',
  itemsIds: [],
  contentHighlights: [],
  snipet: '',
  subTitlePopup: 'SUBTITLE',
  titlePopup: i18next.t(translations.EnterText),
  domainAccess: [],
  font: FONT_STYLE.GOOGLE_FONT,
  fontGoogle: undefined,
  fontCustome: '',
};
