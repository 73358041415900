import { AxiosError } from 'axios';
import i18next from 'i18next';
import { takeLatest, put, call } from 'redux-saga/effects';

import { translations } from 'locales/i18n';
import { registerGlobalEvent } from 'services/auth';
import { checkEmailRegister } from 'services/team';
import { loginActions, openToast } from 'store/actions';
import {
  IRegisterGlobal,
  REGISTER_GLOBAL,
} from 'store/actions/registerGlobalEvent';
import { ActionCallback } from 'types/Action';
import { ErrorResponse } from 'utils/handleAxiosError';

function* registerGlobal(action: ActionCallback<IRegisterGlobal>) {
  try {
    const { data: result } = yield checkEmailRegister({
      email: action.payload.email,
    });
    if (result.data?.isExist) {
      action.callback('email', i18next.t(translations.ExistEmailMessage));
      yield put({
        type: REGISTER_GLOBAL.REGISTER_GLOBAL_EMAIL_EROR,
      });
    } else {
      const { data } = yield call(registerGlobalEvent, action.payload);
      yield put({
        type: REGISTER_GLOBAL.REGISTER_GLOBAL_SUCCESS,
      });
      yield put(loginActions.success({ ...data }));
    }
  } catch (error: any) {
    const errorMessage = error as AxiosError<ErrorResponse>;
    if (errorMessage.response?.data.status === 400) {
      const message = errorMessage.response?.data?.message;
      yield put(openToast(message ?? 'An unexpected error occurred', 'error'));
    }
  }
}

function* watchRegisterGlobal(): any {
  yield takeLatest(REGISTER_GLOBAL.REGISTER_GLOBAL_REQUEST, registerGlobal);
}

export default watchRegisterGlobal;
