import React from 'react';

import { SVGProps } from './SVG.props';

const AddV2 = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.99935 1.66602C8.35118 1.66602 6.74001 2.15476 5.3696 3.07044C3.99919 3.98611 2.93109 5.2876 2.30036 6.81032C1.66963 8.33304 1.5046 10.0086 1.82614 11.6251C2.14769 13.2416 2.94136 14.7265 4.1068 15.8919C5.27223 17.0573 6.75709 17.851 8.3736 18.1726C9.99011 18.4941 11.6657 18.3291 13.1884 17.6983C14.7111 17.0676 16.0126 15.9995 16.9283 14.6291C17.8439 13.2587 18.3327 11.6475 18.3327 9.99935C18.3327 8.905 18.1171 7.82137 17.6983 6.81032C17.2796 5.79927 16.6657 4.88061 15.8919 4.10679C15.1181 3.33297 14.1994 2.71914 13.1884 2.30035C12.1773 1.88156 11.0937 1.66602 9.99935 1.66602ZM9.99935 16.666C8.68081 16.666 7.39188 16.275 6.29555 15.5425C5.19922 14.8099 4.34474 13.7687 3.84016 12.5506C3.33557 11.3324 3.20355 9.99195 3.46078 8.69875C3.71802 7.40554 4.35296 6.21765 5.28531 5.2853C6.21766 4.35295 7.40554 3.71802 8.69875 3.46078C9.99196 3.20355 11.3324 3.33557 12.5506 3.84015C13.7687 4.34474 14.8099 5.19922 15.5425 6.29555C16.275 7.39188 16.666 8.68081 16.666 9.99935C16.666 11.7675 15.9636 13.4632 14.7134 14.7134C13.4632 15.9636 11.7675 16.666 9.99935 16.666ZM13.3327 9.16602H10.8327V6.66602C10.8327 6.445 10.7449 6.23304 10.5886 6.07676C10.4323 5.92048 10.2204 5.83268 9.99935 5.83268C9.77834 5.83268 9.56638 5.92048 9.4101 6.07676C9.25382 6.23304 9.16602 6.445 9.16602 6.66602V9.16602H6.66602C6.44501 9.16602 6.23304 9.25381 6.07676 9.41009C5.92048 9.56637 5.83269 9.77834 5.83269 9.99935C5.83269 10.2204 5.92048 10.4323 6.07676 10.5886C6.23304 10.7449 6.44501 10.8327 6.66602 10.8327H9.16602V13.3327C9.16602 13.5537 9.25382 13.7657 9.4101 13.9219C9.56638 14.0782 9.77834 14.166 9.99935 14.166C10.2204 14.166 10.4323 14.0782 10.5886 13.9219C10.7449 13.7657 10.8327 13.5537 10.8327 13.3327V10.8327H13.3327C13.5537 10.8327 13.7657 10.7449 13.9219 10.5886C14.0782 10.4323 14.166 10.2204 14.166 9.99935C14.166 9.77834 14.0782 9.56637 13.9219 9.41009C13.7657 9.25381 13.5537 9.16602 13.3327 9.16602Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default AddV2;
