import { put, call, takeLatest, select } from 'redux-saga/effects';

import { uploadFinalStory } from 'services/story';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UploadFinalStoryMeta,
  UploadFinalStoryPayload,
} from 'types/store/UploadFinalStoryState';

import { uploadFinalStoryActions } from '../actions';

function* uploadFinalStoryRequest(
  action: AsyncAction<UploadFinalStoryMeta, UploadFinalStoryPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        uploadFinalStory,
        action.meta,
        state.auth.token
      );
      yield put(uploadFinalStoryActions.success(data));
    } catch (e: any) {
      yield put(uploadFinalStoryActions.failed(e.message));
    }
  }
}

function* uploadFinalStorySuccess(
  action: AsyncAction<UploadFinalStoryMeta, UploadFinalStoryPayload>
) {
  //
}

function* uploadFinalStoryWatcher(): any {
  yield takeLatest(uploadFinalStoryActions.REQUEST, uploadFinalStoryRequest);
  yield takeLatest(uploadFinalStoryActions.SUCCESS, uploadFinalStorySuccess);
}

export default uploadFinalStoryWatcher;
