import { put, call, takeLatest, select } from 'redux-saga/effects';

import { maskAsDefaultSmsTemplate } from 'services/sms-template';
import { AsyncAction } from 'types/Action';
import { MaskAsDefaultSmsTemplateMeta, MaskAsDefaultSmsTemplatePayload } from 'types/store/MaskAsDefaultSmsTemplate';
import { Store } from 'types/store/Store';

import { maskAsDefaultSmsTemplateActions } from '../actions';

function* maskAsDefaultSmsTemplateRequest(action: AsyncAction<MaskAsDefaultSmsTemplateMeta, MaskAsDefaultSmsTemplatePayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(maskAsDefaultSmsTemplate, action.meta, state.auth.token);
      yield put(maskAsDefaultSmsTemplateActions.success(data));
    } catch (e: any) {
      yield put(maskAsDefaultSmsTemplateActions.failed(e.message));
    }
  }
}

// function* maskAsDefaultSmsTemplateSuccess(action: AsyncAction<MaskAsDefaultSmsTemplateMeta, MaskAsDefaultSmsTemplatePayload>): any {
//   yield put()
// }

function* maskAsDefaultSmsTemplateWatcher(): any {
  yield takeLatest(maskAsDefaultSmsTemplateActions.REQUEST, maskAsDefaultSmsTemplateRequest);
  // yield takeLatest(maskAsDefaultSmsTemplateActions.SUCCESS, maskAsDefaultSmsTemplateSuccess);
}

export default maskAsDefaultSmsTemplateWatcher;
