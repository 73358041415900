import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getAdvocateInfo } from 'services/advocate';
import { AsyncAction } from 'types/Action';
import { GetAdvocateDetailsMeta, GetAdvocateDetailsPayload } from 'types/store/GetAdvocateDetails';
import { Store } from 'types/store/Store';

import { getAdvocateDetailsActions } from '../actions';

function* getAdvocateDetailsRequest(action: AsyncAction<GetAdvocateDetailsMeta, GetAdvocateDetailsPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(getAdvocateInfo, action.meta, state.auth.token);
      yield put(getAdvocateDetailsActions.success(data));
    } catch (e: any) {
      yield put(getAdvocateDetailsActions.failed(e.message));
    }
  }
}

// function* getAdvocateDetailsSuccess(action: AsyncAction<GetAdvocateDetailsMeta, GetAdvocateDetailsPayload>): any {
//   yield put()
// }

function* getAdvocateDetailsWatcher(): any {
  yield takeLatest(getAdvocateDetailsActions.REQUEST, getAdvocateDetailsRequest);
  // yield takeLatest(getAdvocateDetailsActions.SUCCESS, getAdvocateDetailsSuccess);
}

export default getAdvocateDetailsWatcher;
