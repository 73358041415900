import { put, call, takeLatest, select } from 'redux-saga/effects';
import { AxiosError } from 'axios';

import { updateStoryForBuilder } from 'services/story';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateStoryForBuilderMeta,
  UpdateStoryForBuilderPayload,
} from 'types/store/UpdateStoryForBuilder';
import { handleAxiosError } from 'utils/handleAxiosError';

import { openToast, updateStoryForBuilderActions } from '../actions';
import { SHARE_STORY_LINK } from '../actions/storyHub.action';

function* updateStoryForBuilderRequest(
  action: AsyncAction<UpdateStoryForBuilderMeta, UpdateStoryForBuilderPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateStoryForBuilder,
        action.meta,
        state.auth.token,
      );
      yield put(updateStoryForBuilderActions.success(data));

      yield put({
        type: SHARE_STORY_LINK.SHARE_STORY_LINK_SUCCESS,
        payload: data.data?.advocateInviteLink,
      });

    } catch (e: unknown) {
      // yield put(updateStoryForBuilderActions.failed(e.message));
      // const errorMessage = e as AxiosError;
      const errorMessage = handleAxiosError(e);
      yield put(openToast(errorMessage ?? 'Error', 'error'));
    }
  }
}

function* updateStoryForBuilderSuccess(
  action: AsyncAction<UpdateStoryForBuilderMeta, UpdateStoryForBuilderPayload>,
) {
  // yield put()
}

function* updateStoryForBuilderWatcher(): any {
  yield takeLatest(
    updateStoryForBuilderActions.REQUEST,
    updateStoryForBuilderRequest,
  );
  yield takeLatest(
    updateStoryForBuilderActions.SUCCESS,
    updateStoryForBuilderSuccess,
  );
}

export default updateStoryForBuilderWatcher;
