import { put, call, takeLatest, select } from 'redux-saga/effects';

import { EMPLOYER_ROUTES } from 'consts';
import { updatePage, checkUrl } from 'services/page';
import { uploadPhoto } from 'services/photo';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import { UpdatePageMeta, UpdatePagePayload } from 'types/store/UpdatePageState';
import navigationService from 'utils/navigationService';

import {
  updatePageActions,
  getPagesbyCompanyIdActions,
  previewPageActions,
  checkUrlActions,
  getPageActions,
} from '../actions';

let pageUrl = '';
let status = '';

function* updatePageRequest(
  action: AsyncAction<UpdatePageMeta, UpdatePagePayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    let urlCheckResults;
    let updateShouldProceed = true;

    //checks if url is unique
    if (state.getPage.data?.data.url !== action.meta.url) {
      // @ts-ignore
      urlCheckResults = yield call(
        checkUrl,
        { url: action.meta.url },
        state.auth.token,
      );
      updateShouldProceed = urlCheckResults.data.data.isAvailable;
    }

    if (updateShouldProceed === true) {
      try {
        let logoUrl = '';
        let heroUrl = '';
        let avatarUrl = '';

        if (action.meta.logo_image instanceof File) {
          const { data } = yield call(
            uploadPhoto,
            action.meta.logo_image,
            state.auth.token,
          );
          logoUrl = data.status === 200 ? data.data : '';
        } else if (action.meta.logo_image !== '') {
          logoUrl = action.meta.logo_image;
        }

        if (action.meta.hero_image instanceof File) {
          const { data } = yield call(
            uploadPhoto,
            action.meta.hero_image,
            state.auth.token,
          );
          heroUrl = data.status === 200 ? data.data : '';
        } else if (action.meta.hero_image !== '') {
          heroUrl = action.meta.hero_image;
        }

        if (action.meta.avatar_image instanceof File) {
          const { data } = yield call(
            uploadPhoto,
            action.meta.avatar_image,
            state.auth.token,
          );
          avatarUrl = data.status === 200 ? data.data : '';
        } else if (action.meta.avatar_image !== '') {
          avatarUrl = action.meta.avatar_image;
        }

        for (let x = 0; x < action.meta.photo_list.length; x++) {
          if (action.meta.photo_list[x].url instanceof File) {
            const { data } = yield call(
              uploadPhoto,
              action.meta.photo_list[x].url as File,
              state.auth.token,
            );
            action.meta.photo_list[x].url = data.data;
          }
        }

        for (let x = 0; x < action.meta.values.length; x++) {
          if (action.meta.values[x].image instanceof File) {
            const { data } = yield call(
              uploadPhoto,
              action.meta.values[x].image as File,
              state.auth.token,
            );
            action.meta.values[x].image = data.data;
          }
        }

        for (let x = 0; x < action.meta.stories.length; x++) {
          if (action.meta.stories[x].image instanceof File) {
            const { data } = yield call(
              uploadPhoto,
              action.meta.stories[x].image as File,
              state.auth.token,
            );
            action.meta.stories[x].image = data.data;
          }
        }

        action.meta.logo = logoUrl;
        action.meta.hero = heroUrl;
        action.meta.avatar_image = avatarUrl;
        pageUrl = action.meta.url;
        status = action.meta.status;

        const { data } = yield call(updatePage, action.meta, state.auth.token);
        yield put(updatePageActions.success(data));
        yield put(getPageActions.success({ data: action.meta }));
      } catch (e: any) {
        yield put(updatePageActions.failed(e.message));
      }
    } else {
      yield put(updatePageActions.failed('URL Uniqueness Test Fail'));
      yield put(checkUrlActions.success(urlCheckResults.data));
    }
  }
}

function* updatePageSuccess(
  action: AsyncAction<UpdatePageMeta, UpdatePagePayload>,
) {
  yield put(getPagesbyCompanyIdActions.request({}));
  yield put(previewPageActions.clear());

  if (status === 'PUBLISHED') {
    yield call(navigationService.goTo, EMPLOYER_ROUTES.INSIDE_LOOK);
    window.open(`${window.location.origin}/company/${pageUrl}`);
    window.location.reload();
  }

  //refactor this
  // window.location.reload();
}

function* updatePageWatcher(): any {
  yield takeLatest(updatePageActions.REQUEST, updatePageRequest);
  yield takeLatest(updatePageActions.SUCCESS, updatePageSuccess);
}

export default updatePageWatcher;
