import React from 'react';

import { SVGProps } from './SVG.props';

const Nudge = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2546 6.07061C16.5786 5.72013 17.8756 6.71849 17.8756 8.08814V8.57864L20.9778 6.40855C21.201 6.25235 21.5443 6.1434 21.9023 6.28917C22.2604 6.43493 22.43 6.75264 22.4807 7.02037C22.5303 7.28266 22.4823 7.53421 22.4021 7.73105C22.322 7.92789 22.1807 8.14147 21.962 8.29449L19.1227 10.2807H20.8024C21.4012 10.2807 21.8867 10.7661 21.8867 11.3649C21.8867 11.9638 21.4012 12.4492 20.8024 12.4492H19.2529L21.9602 14.3431C22.179 14.4962 22.3203 14.7097 22.4004 14.9066C22.4805 15.1034 22.5286 15.355 22.4789 15.6172C22.4282 15.885 22.2587 16.2027 21.9006 16.3485C21.5426 16.4942 21.1993 16.3853 20.976 16.2291L17.8756 14.0602V15.2604C17.8756 16.6537 16.5364 17.6557 15.1997 17.2626L9.33528 15.5377C8.84499 17.0436 7.43029 18.1315 5.76103 18.1315C4.1134 18.1315 2.71472 17.0719 2.20685 15.5974C1.49411 15.2654 1 14.5433 1 13.7047V9.69368C1 8.54106 1.93439 7.60667 3.08701 7.60667H4.42402C5.12695 7.60667 5.7487 7.95418 6.12687 8.48677L15.2546 6.07061ZM7.17954 10.4513V12.6433L15.7071 15.1515V8.19401L7.17954 10.4513ZM4.93141 15.7296C5.1731 15.8777 5.45728 15.963 5.76103 15.963C6.44498 15.963 7.02809 15.531 7.25241 14.9251L6.2893 14.6419C6.01964 15.1775 5.52603 15.5811 4.93141 15.7296ZM21.8376 7.63765C21.8377 7.63756 21.8375 7.63774 21.8376 7.63765V7.63765ZM3.16851 9.77518V13.6232H4.34253V9.77518H3.16851Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Nudge;
