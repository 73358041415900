import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getCategoryByStoryId } from 'services/category';
import { AsyncAction } from 'types/Action';
import {
  GetCategoryByStoryIdMeta,
  GetCategoryByStoryIdPayload
} from 'types/store/GetCategoryByStoryId';
import { Store } from 'types/store/Store';

import { getCategoryByStoryIdActions } from '../actions';

function* getCategoryByStoryIdRequest(
  action: AsyncAction<GetCategoryByStoryIdMeta, GetCategoryByStoryIdPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getCategoryByStoryId, action.meta, state.auth.token);
      yield put(getCategoryByStoryIdActions.success(data));
    } catch (e: any) {
      yield put(getCategoryByStoryIdActions.failed(e.message));
    }
  } else {
    yield put(getCategoryByStoryIdActions.failed('Token not found'));
  }
}

function* getCategoryByStoryIdSuccess(
  action: AsyncAction<GetCategoryByStoryIdMeta, GetCategoryByStoryIdPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getCategoryByStoryIdWatcher(): any {
  yield takeLatest(getCategoryByStoryIdActions.REQUEST, getCategoryByStoryIdRequest);
  yield takeLatest(getCategoryByStoryIdActions.SUCCESS, getCategoryByStoryIdSuccess);
}

export default getCategoryByStoryIdWatcher;
