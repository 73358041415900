import React from 'react';

import { SVGProps } from './SVG.props';

const Refrest = (props: SVGProps): React.JSX.Element => {
  const { width = 20, height = 20, fill } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="refrest">
        <g id="line">
          <path
            d="M3.49886 8.51709C4.1725 5.54834 6.82867 3.33268 10.0003 3.33268C12.4196 3.33268 14.5392 4.62118 15.708 6.55235L13.5882 5.87247C13.1499 5.73192 12.6807 5.97325 12.5401 6.4115C12.3996 6.84975 12.6409 7.31896 13.0792 7.45952L17.2458 8.79583C17.4993 8.87713 17.7764 8.83243 17.9914 8.67556C18.2065 8.51868 18.3337 8.26851 18.3337 8.00231V3.33266C18.3337 2.87243 17.9606 2.49933 17.5003 2.49933C17.0401 2.49933 16.667 2.87243 16.667 3.33266V4.99875C15.1473 2.97597 12.7273 1.66602 10.0003 1.66602C6.03338 1.66602 2.71563 4.43706 1.87351 8.14828C1.77167 8.5971 2.05295 9.04351 2.50178 9.14536C2.95061 9.2472 3.39701 8.96592 3.49886 8.51709Z"
            fill="#F8F7F8"
          />
          <path
            d="M18.1272 11.8504C18.229 11.4016 17.9477 10.9552 17.4989 10.8533C17.0501 10.7515 16.6037 11.0328 16.5018 11.4816C15.8282 14.4504 13.172 16.666 10.0003 16.666C7.55342 16.666 5.41304 15.3478 4.25287 13.3798L7.29821 14.1411C7.74471 14.2527 8.19715 13.9813 8.30878 13.5348C8.4204 13.0883 8.14893 12.6358 7.70244 12.5242L2.70244 11.2742C2.45349 11.212 2.18975 11.2679 1.98748 11.4258C1.78522 11.5837 1.66699 11.826 1.66699 12.0827V16.666C1.66699 17.1262 2.04009 17.4993 2.50033 17.4993C2.96056 17.4993 3.33366 17.1262 3.33366 16.666L3.33366 14.9999C4.85333 17.0227 7.27332 18.3327 10.0003 18.3327C13.9673 18.3327 17.285 15.5616 18.1272 11.8504Z"
            fill="#F8F7F8"
          />
        </g>
      </g>
    </svg>
  );
};

export default Refrest;
