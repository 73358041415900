import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getInvitedTeams } from 'services/team';
import { AsyncAction } from 'types/Action';
import { GetInvitedTeamsMeta, GetInvitedTeamsPayload } from 'types/store/GetInvitedTeams';
import { Store } from 'types/store/Store';

import { getInvitedTeamsActions } from '../actions';

function* getInvitedTeamsRequest(action: AsyncAction<GetInvitedTeamsMeta, GetInvitedTeamsPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(getInvitedTeams, action.meta, state.auth.token);
      yield put(getInvitedTeamsActions.success(data));
    } catch (e: any) {
      yield put(getInvitedTeamsActions.failed(e.message));
    }
  }
}

// function* getInvitedTeamsSuccess(action: AsyncAction<GetInvitedTeamsMeta, GetInvitedTeamsPayload>): any {
//   yield put()
// }

function* getInvitedTeamsWatcher(): any {
  yield takeLatest(getInvitedTeamsActions.REQUEST, getInvitedTeamsRequest);
  // yield takeLatest(getInvitedTeamsActions.SUCCESS, getInvitedTeamsSuccess);
}

export default getInvitedTeamsWatcher;
