import React from 'react';

import { SVGProps } from './SVG.props';

const CheckBox = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_5148_29892)">
        <path
          d="M12.4717 5.80482C12.7321 5.54447 12.7321 5.12236 12.4717 4.86201C12.2114 4.60166 11.7893 4.60166 11.5289 4.86201L6.66699 9.72394L4.47173 7.52868C4.21138 7.26833 3.78927 7.26833 3.52892 7.52868C3.26857 7.78903 3.26857 8.21114 3.52892 8.47149L6.19559 11.1382C6.45594 11.3985 6.87805 11.3985 7.1384 11.1382L12.4717 5.80482Z"
          fill={fill || '#060548'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66699 0.666748C1.56242 0.666748 0.666992 1.56218 0.666992 2.66675V13.3334C0.666992 14.438 1.56242 15.3334 2.66699 15.3334H13.3337C14.4382 15.3334 15.3337 14.438 15.3337 13.3334V2.66675C15.3337 1.56218 14.4382 0.666748 13.3337 0.666748H2.66699ZM2.00033 2.66675C2.00033 2.29856 2.2988 2.00008 2.66699 2.00008H13.3337C13.7018 2.00008 14.0003 2.29856 14.0003 2.66675V13.3334C14.0003 13.7016 13.7018 14.0001 13.3337 14.0001H2.66699C2.2988 14.0001 2.00033 13.7016 2.00033 13.3334V2.66675Z"
          fill={fill || '#060548'}
        />
      </g>
      <defs>
        <clipPath id="clip0_5148_29892">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckBox;
