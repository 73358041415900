import React from 'react';

import { SVGProps } from './SVG.props';

const Share = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 21}
      height={height || 21}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 13.001a4 4 0 00-3.08 1.48l-5.1-2.35a3.64 3.64 0 000-2.26l5.1-2.35a4 4 0 10-.85-1.81l-5.28 2.43a4 4 0 100 5.72l5.28 2.43a4.166 4.166 0 00-.07.71 4 4 0 104-4zm0-10a2 2 0 110 4 2 2 0 010-4zm-12 10a2 2 0 110-4 2 2 0 010 4zm12 6a2 2 0 110-3.999 2 2 0 010 4z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Share;
