import { Reducer } from 'redux';
import _get from 'lodash/get';

import {
  CREATE_AI_PROMP,
  DRAFT_AI,
  DRAFT_AI_SK,
  UPDATE_PROMP_AI,
  GENERATE_KEY_TOPIC,
  APPLY_PROMPT,
  INDEX_APPLY_PROMPT,
  DRAFT_AI_REWRITE,
  GENERATE_INTRODUCTION,
  GET_BRAND_GUIDELINE,
  NAVBAR_NAVIGATE,
  UPDATE_BRAND_GUIDELINE,
  GENERATE_CONCLUSION,
  DRAFT_PREVIEW,
  DOWNLOAD_CONTENT,
  DRAFT_FORM_AI_BLOG,
  LIST_QUESTION_ANSWER,
  GET_PARAGRAPH_VERSION,
  GET_STORY_VERSION,
  DRAFTAI,
  DRAFT_AI_RESTFUL,
} from 'store/actions/storyDetailV2/storyDetailV2.enum';
import { Action } from 'types/Action';
import { StoryEditorV2 } from 'types/store/StoryEditorV2';
import { StoryAction } from 'routes/Employer/StoryEditor/StoryEditorDetail/StoryEditorDetailV2/PrompAI/GenerateQuestion/QuestionBlock/QuestionBlock.props';

const initState: StoryEditorV2 = {
  AIPrompData: undefined,
  brandGuidelineInit: undefined,
  contentLanguage: '',
  dataFormikAIBlog: undefined,
  draftAIShouldDo: { action: '' },
  generateStylingAndReview: undefined,
  haveCreateBrandGuideLine: false,
  indexApplyPrompt: -3,
  isApplyPrompt: false,
  isCreatePromp: false,
  isDownloadContentAction: undefined,
  isDraftAI: false,
  isGenerateConclusion: false,
  isGenerateIntroduction: false,
  isGetLinkContent: undefined,
  isLoading: false,
  isLoadingGetPrompt: false,
  isLoadingVersion: false,
  isTranslateLoading: false,
  languagesTranslate: undefined,
  listQuestionAnswer: undefined,
  listSectionsDraft: undefined,
  navigateStep3: false,
  navigateStep4: false,
  overallPrompt: [],
  paragraphVerion: undefined,
  promptConclusionSuggestions: [],
  promptHeadlineSuggestions: [],
  promptIntroductionSuggestions: [],
  promptSuggestions: [],
  responseDraftAI: undefined,
  responseDraftAIInit: undefined,
  responseDraftAINewSection: undefined,
  responseDraftAIUpdated: undefined,
  rewriteLoading: false,
  showAIPrompMenu: false,
  skipStep4: false,
  storyVerion: undefined,
  stylingPreviewDraft: undefined,
  translateSuccess: false,
  triggerDownloadContent: false,
  versionStoryId: '',
};

const storyDetailV2: Reducer<StoryEditorV2, Action> = (
  state = initState,
  { type, payload },
) => {
  switch (type) {
    case DRAFTAI.DRAFTAI_ACTION: {
      return {
        ...state,
        draftAIShouldDo: payload,
      };
    }
    case DRAFTAI.DRAFTAI_VERSION_STORY_ID: {
      return {
        ...state,
        versionStoryId: payload,
      };
    }
    case DRAFTAI.LANGUAGE_TRANSLATE: {
      return {
        ...state,
        languagesTranslate: payload,
      };
    }
    case DRAFTAI.CONTENT_LANGUAGE: {
      return {
        ...state,
        contentLanguage: payload,
      };
    }
    case CREATE_AI_PROMP.CREATE_AI_PROMP_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isCreatePromp: false,
        isDraftAI: false,
      };
    }
    case GENERATE_KEY_TOPIC.GENERATE_KEY_TOPIC_CUSTOM_SUCCESS: {
      return {
        ...state,
        customPromptRes: payload,
      };
    }
    case CREATE_AI_PROMP.CREATE_AI_PROMP_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isCreatePromp: true,
      };
    }
    case CREATE_AI_PROMP.CREATE_AI_PROMP_CLEAR: {
      return {
        ...state,
        isCreatePromp: false,
        // isDraftAI: false,
        rewriteLoading: false,
        isLoading: false,
      };
    }
    case DRAFT_AI.DRAFT_AI_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isDraftAI: false,
      };
    }
    case DRAFT_AI.DRAFT_AI_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isDraftAI: true,
        rewriteLoading: false,
      };
    }
    case DRAFT_AI_SK.DRAFT_AI_SK_REQUEST: {
      return {
        ...state,
        isDraftAI: true,
        isLoading: true,
        AIPrompData: payload,
      };
    }
    case DRAFT_AI_SK.DRAFT_AI_SK_SUCCESS: {
      //TODO: cần check lại fn này có cần thiết nữa ko
      const cloneVal = {
        ...payload,
        sections: payload?.sections,
      };
      return {
        ...state,
        isDraftAI: true,
        isLoading: false,
        isTranslateLoading: false,
        responseDraftAI: cloneVal,
        responseDraftAINewSection: cloneVal,
        skipStep4: false,
      };
    }
    case DRAFT_AI_RESTFUL.DRAFT_AI_RESTFUL_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isDraftAI: false,
      };
    }
    case DRAFT_AI_RESTFUL.DRAFT_AI_RESTFUL_ERROR: {
      return {
        ...state,
        isLoading: false,
        isDraftAI: true,
      };
    }
    case DRAFT_AI_RESTFUL.DRAFT_AI_RESTFUL_SUCCESS:{
      const amendVal = {
        ...payload?.data,
        sections: payload?.data?.sections,
      };

      let versionVal = {}

      if(payload?.content_version_id){
        versionVal = {
          versionStoryId: payload?.content_version_id,
          draftAIShouldDo: { action: StoryAction.OVERWRITE },
        }
      }

      return {
        ...state,
        isDraftAI: true,
        isLoading: false,
        responseDraftAI: amendVal,
        responseDraftAINewSection: amendVal,
        rewriteLoading: false,
        ...versionVal,
      }
    }
    case DRAFT_AI_SK.TRANSLATE_REQUEST: {
      return {
        ...state,
        isTranslateLoading: true,
        translateSuccess: false,
      };
    }
    case DRAFT_AI_SK.TRANSLATE_SUCCESS: {
      return {
        ...state,
        translateSuccess: true,
      };
    }
    case UPDATE_PROMP_AI.UPDATE_PROMP_AI_REQUEST: {
      return {
        ...state,
        AIPrompData: payload,
      };
    }
    case GENERATE_KEY_TOPIC.GENERATE_KEY_TOPIC_REQUEST: {
      return {
        ...state,
        isLoadingGetPrompt: true,
      };
    }
    case GENERATE_KEY_TOPIC.GENERATE_KEY_TOPIC_SUCCESS: {
      return {
        ...state,
        isLoadingGetPrompt: false,
        promptSuggestions: payload.rows,
      };
    }
    case GENERATE_KEY_TOPIC.GENERATE_KEY_TOPIC_HEADLINE_REQUEST: {
      return {
        ...state,
        promptHeadlineSuggestions: payload.rows,
      };
    }
    case GENERATE_KEY_TOPIC.GENERATE_KEY_TOPIC_INTRODUCTION_REQUEST: {
      return {
        ...state,
        promptIntroductionSuggestions: payload.rows,
      };
    }
    case GENERATE_KEY_TOPIC.GENERATE_KEY_TOPIC_CONCLUSION_REQUEST: {
      return {
        ...state,
        promptConclusionSuggestions: payload.rows,
      };
    }
    case APPLY_PROMPT.APPLY_PROMPT_REQUEST: {
      return {
        ...state,
        isApplyPrompt: true,
      };
    }
    case APPLY_PROMPT.APPLY_PROMPT_CLEAR: {
      return {
        ...state,
        isApplyPrompt: false,
      };
    }
    case DRAFT_AI_SK.DRAFT_AI_SK_UPDATE: {
      return {
        ...state,
        responseDraftAI: payload,
      };
    }
    case DRAFT_AI_SK.ADD_DRAFT_CONTENT_FROM_STORY_DETAIL: {
      return {
        ...state,
        responseDraftAI: payload,
      };
    }
    case DRAFT_AI_SK.DRAFT_AI_SK_UPDATE_GENERATED: {
      return {
        ...state,
        // responseDraftAI: payload,
        responseDraftAIUpdated: payload,
      };
    }
    case INDEX_APPLY_PROMPT.INDEX_APPLY_PROMPT_REQUEST: {
      return {
        ...state,
        indexApplyPrompt: payload,
      };
    }
    case INDEX_APPLY_PROMPT.INDEX_APPLY_PROMPT_CLEAR: {
      return {
        ...state,
        indexApplyPrompt: -3,
      };
    }
    case DRAFT_AI_REWRITE.DRAFT_AI_REWRITE_REQUEST: {
      return {
        ...state,
        rewriteLoading: true,
      };
    }
    case GENERATE_INTRODUCTION.GENERATE_INTRODUCTION_REQUEST: {
      return {
        ...state,
        isGenerateIntroduction: true,
      };
    }
    case GENERATE_INTRODUCTION.GENERATE_INTRODUCTION_CLEAR: {
      return {
        ...state,
        isGenerateIntroduction: false,
      };
    }
    case GET_BRAND_GUIDELINE.GET_BRAND_GUIDELINE_SUCCESS: {
      return {
        ...state,
        brandGuidelineInit: payload,
      };
    }
    case NAVBAR_NAVIGATE.STEP_AI_PROMP: {
      return {
        ...state,
        navigateStep4: true,
        navigateStep3: false,
      };
    }
    case NAVBAR_NAVIGATE.STYLING_REVIEW: {
      return {
        ...state,
        navigateStep4: false,
        navigateStep3: true,
      };
    }
    case NAVBAR_NAVIGATE.SHOW_AI_PROMP: {
      return {
        ...state,
        showAIPrompMenu: true,
      };
    }
    case NAVBAR_NAVIGATE.SKIP_STYLING_REVIEW: {
      return {
        ...state,
        skipStep4: true,
      };
    }
    case NAVBAR_NAVIGATE.MOVE_STYLING_REVIEW: {
      const saveEditedValue = JSON.parse(
        JSON.stringify({ ...state.stylingPreviewDraft }),
      );
      return {
        ...state,
        responseDraftAI: saveEditedValue,
        skipStep4: false,
      };
    }
    case GENERATE_KEY_TOPIC.GENERATE_KEY_TOPIC_OVERALL_SUCCESS: {
      return {
        ...state,
        isLoadingGetPrompt: false,
        overallPrompt: payload.rows,
      };
    }
    case UPDATE_BRAND_GUIDELINE.UPDATE_BRAND_GUIDELINE_SUCCESS: {
      return {
        ...state,
        haveCreateBrandGuideLine: true,
      };
    }
    case UPDATE_BRAND_GUIDELINE.UPDATE_BRAND_GUIDELINE_ERROR: {
      return {
        ...state,
        haveCreateBrandGuideLine: false,
      };
    }
    case NAVBAR_NAVIGATE.CLEAR_ALL: {
      return {
        ...state,
        AIPrompData: undefined,
        brandGuidelineInit: undefined,
        dataFormikAIBlog: undefined,
        generateStylingAndReview: undefined,
        indexApplyPrompt: -3,
        isApplyPrompt: false,
        isCreatePromp: false,
        isDownloadContentAction: undefined,
        isDraftAI: false,
        isGenerateConclusion: false,
        isGenerateIntroduction: false,
        isGetLinkContent: undefined,
        isLoading: false,
        listQuestionAnswer: undefined,
        listSectionsDraft: undefined,
        navigateStep3: false,
        navigateStep4: false,
        promptHeadlineSuggestions: [],
        promptSuggestions: [],
        responseDraftAI: undefined,
        responseDraftAIInit: undefined,
        responseDraftAINewSection: undefined,
        responseDraftAIUpdated: undefined,
        rewriteLoading: false,
        showAIPrompMenu: false,
        skipStep4: false,
        stylingPreviewDraft: undefined,
        triggerDownloadContent: false,
      };
    }
    case GENERATE_CONCLUSION.GENERATE_CONCLUSION_REQUEST: {
      return {
        ...state,
        isGenerateConclusion: true,
      };
    }
    case GENERATE_CONCLUSION.GENERATE_CONCLUSION_CLEAR: {
      return {
        ...state,
        isGenerateConclusion: false,
      };
    }
    case NAVBAR_NAVIGATE.NEXT_DRAFTAI: {
      return {
        ...state,
        isDraftAI: true,
      };
    }
    case NAVBAR_NAVIGATE.BACK_AIPROMP: {
      return {
        ...state,
        isDraftAI: false,
      };
    }
    case DRAFT_AI.DRAFT_AI_REFINE: {
      return {
        ...state,
        AIPrompData: payload,
      };
    }
    case DRAFT_PREVIEW.DRAFT_PREVIEW_TOGGLE_HIDDEN: {
      const responseDraftAIClone = { ...state.responseDraftAI };

      if (payload.type === 'HEADLINE') {
        if (responseDraftAIClone['headline']) {
          responseDraftAIClone['headline']['hidden'] = payload.hidden;
        }
      }

      if (payload.type === 'CONCLUSION') {
        if (responseDraftAIClone['conclusion']) {
          responseDraftAIClone['conclusion']['hidden'] = payload.hidden;
        }
      }

      if (payload.type === 'INTRODUCTION') {
        if (responseDraftAIClone['introduction']) {
          responseDraftAIClone['introduction']['hidden'] = payload.hidden;
        }
      }

      if (payload.type === 'PARAGRAPH') {
        if (responseDraftAIClone['sections']) {
          responseDraftAIClone['sections'][payload.idx]['hidden'] =
            payload.hidden;
        }
      }
      return {
        ...state,
        stylingPreviewDraft: responseDraftAIClone,
        // add data toggle hidden to draft for count word
        responseDraftAINewSection: responseDraftAIClone,
      };
    }
    case DRAFT_PREVIEW.DRAFT_PREVIEW_REQUEST: {
      const stylingPreviewDraftClone = { ...state.responseDraftAI };
      if (
        payload.type === 'HEADLINE' &&
        typeof stylingPreviewDraftClone?.headline === 'string'
      ) {
        stylingPreviewDraftClone.headline === payload.value;
      } else if (payload.type === 'HEADLINE') {
        if (stylingPreviewDraftClone['headline']) {
          stylingPreviewDraftClone['headline']['data'] = payload.value;
        }
      }

      if (payload.type === 'CONCLUSION') {
        if (stylingPreviewDraftClone['conclusion']) {
          stylingPreviewDraftClone['conclusion']['data'] = payload.value;
        }
      }

      if (payload.type === 'INTRODUCTION') {
        if (stylingPreviewDraftClone['introduction']) {
          stylingPreviewDraftClone['introduction']['data'] = payload.value;
        }
      }

      if (payload.type === 'PARAGRAPH') {
        if (stylingPreviewDraftClone['sections']) {
          stylingPreviewDraftClone['sections'][payload.idx]['data'] =
            payload.value;
        }
      }
      return {
        ...state,
        stylingPreviewDraft: stylingPreviewDraftClone,
        // add data  to draft for count word
        responseDraftAINewSection: stylingPreviewDraftClone,
      };
    }
    case DRAFT_PREVIEW.DRAFT_PREVIEW_SUCCESS: {
      return {
        ...state,
        stylingPreviewDraft: payload,
      };
    }
    case DOWNLOAD_CONTENT.DOWNLOAD_CONTENT_REQUEST: {
      return {
        ...state,
        isGetLinkContent: true,
        isDownloadContentAction: payload?.isDownload,
      };
    }
    case DOWNLOAD_CONTENT.DOWNLOAD_CONTENT_SUCCESS: {
      return {
        ...state,
        isGetLinkContent: false,
        generateStylingAndReview: payload?.data,
      };
    }
    case DOWNLOAD_CONTENT.DOWNLOAD_CONTENT_TRIGGER: {
      return {
        ...state,
        triggerDownloadContent: payload,
      };
    }
    case DOWNLOAD_CONTENT.DOWNLOAD_CONTENT_CLEAR: {
      return {
        ...state,
        generateStylingAndReview: undefined,
        triggerDownloadContent: false,
      };
    }
    case DRAFT_FORM_AI_BLOG.DRAFT_FORM_AI_BLOG_REQUEST: {
      return {
        ...state,
        dataFormikAIBlog: payload,
      };
    }
    case DRAFT_FORM_AI_BLOG.DRAFT_FORM_AI_BLOG_CLEAR: {
      return {
        ...state,
        dataFormikAIBlog: undefined,
      };
    }
    case LIST_QUESTION_ANSWER.LIST_QUESTION_ANSWER_UPDATE: {
      return {
        ...state,
        listQuestionAnswer: payload,
      };
    }
    case LIST_QUESTION_ANSWER.LIST_QUESTION_ANSWER_INIT: {
      return {
        ...state,
        listQuestionAnswerInit: payload,
      };
    }
    case LIST_QUESTION_ANSWER.LIST_QUESTION_ANSWER_CLEAR: {
      return {
        ...state,
        listQuestionAnswer: undefined,
        listQuestionAnswerInit: undefined,
      };
    }
    case DRAFT_PREVIEW.DRAFT_PREVIEW_NEW_SECTION_SUCCESS: {
      return {
        ...state,
        responseDraftAINewSection: payload,
      };
    }
    case DRAFT_PREVIEW.DRAFT_PREVIEW_NEW_SECTION_CLEAR: {
      return {
        ...state,
        responseDraftAINewSection: undefined,
      };
    }
    case DRAFT_PREVIEW.DRAFT_LIST_SECTIONS: {
      return {
        ...state,
        listSectionsDraft: payload,
      };
    }
    case GET_PARAGRAPH_VERSION.GET_PARAGRAPH_VERSION_SUCCESS: {
      return {
        ...state,
        paragraphVerion: payload,
      };
    }
    case GET_STORY_VERSION.GET_STORY_VERSION_REQUEST: {
      return {
        ...state,
        isLoadingVersion: true,
      };
    }
    case GET_STORY_VERSION.GET_STORY_VERSION_SUCCESS: {
      return {
        ...state,
        isLoadingVersion: false,
        storyVerion: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default storyDetailV2;
