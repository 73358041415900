import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getUserSettings } from 'services/settings';
import { AsyncAction } from 'types/Action';
import {
  GetUserSettingMeta,
  GetUserSettingPayload,
} from 'types/store/GetUserSettingState';
import { Store } from 'types/store/Store';

import { getUserSettingsActions } from '../actions';

function* getUserSettingsRequest(
  action: AsyncAction<GetUserSettingMeta, GetUserSettingPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getUserSettings,
        action.meta,
        state.auth.token
      );
      yield put(getUserSettingsActions.success(data));
    } catch (e: any) {
      yield put(getUserSettingsActions.failed(e.message));
    }
  } else {
    yield put(getUserSettingsActions.failed('Token not found'));
  }
}

function* getUserSettingsSuccess(
  action: AsyncAction<GetUserSettingMeta, GetUserSettingPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getUserSettingsWatcher(): any {
  yield takeLatest(getUserSettingsActions.REQUEST, getUserSettingsRequest);
  yield takeLatest(getUserSettingsActions.SUCCESS, getUserSettingsSuccess);
}

export default getUserSettingsWatcher;
