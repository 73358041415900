import React from 'react';

import { SVGProps } from './SVG.props';

const StarUnfill = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || '9'}
      height={height || '9'}
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_25134_48631)">
        <path
          d="M4.74609 0.279297L4.48588 1.02279L3.70116 3.26562L1.32514 3.25532L0.537109 3.25161L1.16432 3.72912L3.05457 5.16867L2.31027 7.4247L2.06367 8.17313L2.7115 7.72447L4.66444 6.37152L6.58068 7.77645L7.21614 8.24242L6.98934 7.48779L6.30564 5.21196L8.23425 3.82396L8.87382 3.36335L8.08621 3.34603L5.7106 3.29242L4.98649 1.0298L4.74609 0.279297ZM4.72382 1.9407L5.26526 3.63262L5.32258 3.81199L5.51061 3.81612L7.28664 3.8557L5.84503 4.89362L5.69204 5.0033L5.74647 5.1835L6.25739 6.88491L4.82485 5.83462L4.67309 5.72328L4.51845 5.8305L3.05828 6.84203L3.61497 5.15507L3.67353 4.97651L3.52384 4.8623L2.11109 3.78645L3.88713 3.79428L4.07516 3.79511L4.13742 3.61738L4.72382 1.9407Z"
          fill={fill || '#B5ADB0'}
        />
      </g>
      <defs>
        <clipPath id="clip0_25134_48631">
          <rect
            width="8.33671"
            height="8.33671"
            fill="white"
            transform="translate(0.537109 0.0917969)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StarUnfill;
