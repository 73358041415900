import { put, call, takeLatest, select } from 'redux-saga/effects';

import { createEmailTemplate } from 'services/email-template';
import { AsyncAction } from 'types/Action';
import { CreateEmailTemplateMeta, CreateEmailTemplatePayload } from 'types/store/CreateEmailTemplate';
import { Store } from 'types/store/Store';

import { createEmailTemplateActions } from '../actions';

function* createEmailTemplateRequest(action: AsyncAction<CreateEmailTemplateMeta, CreateEmailTemplatePayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(createEmailTemplate, action.meta, state.auth.token);
      yield put(createEmailTemplateActions.success(data));
    } catch (e: any) {
      yield put(createEmailTemplateActions.failed(e.message));
    }
  }
}

// function* createEmailTemplateSuccess(action: AsyncAction<CreateEmailTemplateMeta, CreateEmailTemplatePayload>): any {
//   yield put()
// }

function* createEmailTemplateWatcher(): any {
  yield takeLatest(createEmailTemplateActions.REQUEST, createEmailTemplateRequest);
  // yield takeLatest(createEmailTemplateActions.SUCCESS, createEmailTemplateSuccess);
}

export default createEmailTemplateWatcher;
