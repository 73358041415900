import React from 'react';

import { SVGProps } from './SVG.props';

const Visa = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '33'}
      height={height || '11'}
      viewBox="0 0 33 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.019.183l-2.161 9.995h-2.615L13.405.183h2.614zm10.998 6.454l1.377-3.754.791 3.754h-2.168zm2.919 3.54h2.417L30.24.184h-2.23a1.19 1.19 0 00-1.113.732l-3.923 9.262h2.745l.545-1.492h3.354l.317 1.492zM23.11 6.915c.011-2.638-3.687-2.784-3.662-3.963.008-.358.353-.74 1.108-.837.375-.048 1.408-.086 2.579.447l.458-2.12A7.119 7.119 0 0021.148 0c-2.584 0-4.402 1.358-4.417 3.304-.016 1.439 1.3 2.241 2.288 2.72 1.02.49 1.361.804 1.356 1.242-.007.67-.813.967-1.563.979-1.315.02-2.078-.352-2.685-.632l-.475 2.191c.612.277 1.74.518 2.907.53 2.747 0 4.544-1.341 4.552-3.42zM12.283.183l-4.235 9.995H5.284L3.2 2.2c-.127-.49-.237-.67-.621-.878C1.95.985.912.669 0 .473l.062-.29H4.51c.567 0 1.077.373 1.206 1.018l1.101 5.784L9.537.183h2.746z"
        fill="#182E66"
      />
    </svg>
  );
};

export default Visa;
