import pathOr from 'ramda/es/pathOr';

import { Action } from 'types/Action';
import { AdvocatePortalState } from 'types/store/AdvocatePortal';

import {
  commonActions,
  advocateStoryDetailsActions,
  advocatePortalActions,
  advocatePortalStatisticalActions,
  advocateUpdateStoryActions,
} from '../actions';

const DEFAULT_STATE: AdvocatePortalState = {
  storyDetailLoading: true,
  storyOverviewFilterState: [],
  statistical: {
    discussionStoryQuantity: 0,
    discussionStoryUnreadCommentQuantity: 0,
  },
  allStories: [],
  selectedStory: {},
  updateStoryLoading: null,
  submitStoryLoading: null,
  updateStoryError: null,
  getStoryDetailError: null,
};

const DEFAULT_ACTION: any = {
  type: '',
  payload: {
    allStories: [],
  },
};

export default (state = DEFAULT_STATE, action = DEFAULT_ACTION): any => {
  return pathOr(state, [action.type], {
    [advocateStoryDetailsActions.REQUEST]: {
      ...state,
      storyDetailLoading: true,
    },
    [advocateStoryDetailsActions.SUCCESS]: {
      ...state,
      selectedStory: action.payload?.data || {},
      storyDetailLoading: false,
    },
    [advocateStoryDetailsActions.FAILED]: {
      ...state,
      getStoryDetailError: action.error,
    },
    [advocateStoryDetailsActions.CLEAR]: {
      ...state,
      selectedStory: {},
      getStoryDetailError: null,
    },
    [advocatePortalStatisticalActions.SUCCESS]: {
      ...state,
      statistical: action.payload?.data || {},
    },
    [advocateUpdateStoryActions.REQUEST]: {
      ...state,
      ...(action.meta?.action !== undefined
        ? action.meta?.action === 'submit'
          ? { submitStoryLoading: true }
          : { updateStoryLoading: true }
        : {}),
    },
    [advocateUpdateStoryActions.SUCCESS]: {
      ...state,
      ...(action.payload?.action !== undefined
        ? action.payload?.action === 'submit'
          ? { submitStoryLoading: false }
          : { updateStoryLoading: false }
        : {}),
    },
    [advocateUpdateStoryActions.FAILED]: {
      ...state,
      updateStoryLoading: null,
      submitStoryLoading: null,
      updateStoryError: action.error,
    },
    [advocateUpdateStoryActions.CLEAR]: {
      ...state,
      updateStoryLoading: null,
      submitStoryLoading: null,
      updateStoryError: null,
    },
    [advocatePortalActions.UPDATE]: {
      ...state,
      ...action.payload,
    },
    [commonActions.UPDATE]: {
      ...state,
      ...action.payload,
    },
  });
};
