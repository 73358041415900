import { put, call, takeLatest, select } from 'redux-saga/effects';

import { updatePagePosition } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateShowcasePagePositionMeta,
  UpdateShowcasePagePositionPayload,
} from 'types/store/UpdateShowcasePagePositionState';

import {
  updateShowcasePagePositionActions,
  getShowcaseCompanyActions,
} from '../actions';

function* updateShowcasePagePositionRequest(
  action: AsyncAction<
    UpdateShowcasePagePositionMeta,
    UpdateShowcasePagePositionPayload
  >,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updatePagePosition,
        action.meta,
        state.auth.token,
      );
      yield put(updateShowcasePagePositionActions.success(data));
    } catch (e: any) {
      yield put(updateShowcasePagePositionActions.failed(e.message));
    }
  }
}

function* updateShowcasePagePositionSuccess(
  action: AsyncAction<
    UpdateShowcasePagePositionMeta,
    UpdateShowcasePagePositionPayload
  >,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
) {
  yield put(updateShowcasePagePositionActions.clear());
  // yield put(getShowcaseCompanyActions.request({}));
}

function* updateShowcasePagePositionWatcher(): any {
  yield takeLatest(
    updateShowcasePagePositionActions.REQUEST,
    updateShowcasePagePositionRequest,
  );
  yield takeLatest(
    updateShowcasePagePositionActions.SUCCESS,
    updateShowcasePagePositionSuccess,
  );
}

export default updateShowcasePagePositionWatcher;
