import React from 'react';

import { SVGProps } from './SVG.props';

const Plus = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '12'}
      height={height || '12'}
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill={fill || '#070632'}
        d="M10.668 5.333h-4v-4a.667.667 0 00-1.333 0v4h-4a.667.667 0 100 1.334h4v4a.667.667 0 101.333 0v-4h4a.667.667 0 000-1.334z"
      ></path>
    </svg>
  );
};

export default Plus;
