import { put, call, takeLatest, select } from 'redux-saga/effects';

import { deleteShowcase } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import { DeleteShowcaseMeta, DeleteShowcasePayload } from 'types/store/DeleteShowcaseState';
import { Store } from 'types/store/Store';

import { deleteShowcaseActions } from '../actions';

function* deleteShowcaseRequest(
  action: AsyncAction<DeleteShowcaseMeta, DeleteShowcasePayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(deleteShowcase, action.meta, state.auth.token);
      yield put(deleteShowcaseActions.success(data));
    } catch (e: any) {
      yield put(deleteShowcaseActions.failed(e.message));
    }
  }
}

function* deleteShowcaseSuccess(
  action: AsyncAction<DeleteShowcaseMeta, DeleteShowcasePayload>
) {
  // yield put(getPagesbyCompanyIdActions.request({}));
}

function* deleteShowcaseWatcher(): any {
  yield takeLatest(deleteShowcaseActions.REQUEST, deleteShowcaseRequest);
  yield takeLatest(deleteShowcaseActions.SUCCESS, deleteShowcaseSuccess);
}

export default deleteShowcaseWatcher;
