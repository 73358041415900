import axios, { AxiosPromise } from 'axios';

import { API } from 'consts';
import { GenericResponse } from 'types/GenericResponse';
import { GetCategoriesMeta } from 'types/store/GetCategories';
import { GetCategoryByStoryIdMeta } from 'types/store/GetCategoryByStoryId';
import { GetInterestsByCategoryIdMeta } from 'types/store/GetInterestsByCategoryId';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const CATEGORY_URL = `${BASE_URL}/category`;

//company_id is already embeded in the token
export const getCategories = (data: GetCategoriesMeta, token: string): any => {
  return axios({
    method: 'get',
    url: `${CATEGORY_URL}/getCategories`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getCategoryByStoryId = (data: GetCategoryByStoryIdMeta, token: string): AxiosPromise<GenericResponse>  => {
  return axios({
    method: 'get',
    url: `${CATEGORY_URL}/getCategoryByStoryId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getInterestsByCategoryId = (
  data: GetInterestsByCategoryIdMeta,
  token: string
): any => {
  return axios({
    method: 'get',
    url: `${CATEGORY_URL}/${data.id}/interest`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};