import { put, call, takeLatest, select } from 'redux-saga/effects';

import { updateBillingAddress } from 'services/payment';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateBillingAddressMeta,
  UpdateBillingAddressPayload
} from 'types/store/UpdateBillingAddress';

import { getBillingAddressActions, updateBillingAddressActions } from '../actions';

function* updateBillingAddressRequest(
  action: AsyncAction<UpdateBillingAddressMeta, UpdateBillingAddressPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateBillingAddress,
        action.meta,
        state.auth.token
      );
      yield put(updateBillingAddressActions.success(data));
    } catch (e: any) {
      yield put(updateBillingAddressActions.failed(e.message));
    }
  }
}

function* updateBillingAddressSuccess(
  action: AsyncAction<UpdateBillingAddressMeta, UpdateBillingAddressPayload>
) {
  yield put(getBillingAddressActions.request({}));

}

function* updateBillingAddressWatcher(): any {
  yield takeLatest(updateBillingAddressActions.REQUEST, updateBillingAddressRequest);
  yield takeLatest(updateBillingAddressActions.SUCCESS, updateBillingAddressSuccess);
}

export default updateBillingAddressWatcher;
