import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getDataShowcasePublicHomepage } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import {
  GetDataShowcasePublicHomepageMeta,
  GetDataShowcasePublicHomepagePayload,
} from 'types/store/GetDataShowcasePublicHomepageState';
import { Store } from 'types/store/Store';

import { getDataShowcasePublicHomepageActions } from '../actions';

function* getDataShowcasePublicHomepageRequest(
  action: AsyncAction<GetDataShowcasePublicHomepageMeta, GetDataShowcasePublicHomepagePayload>
) {
  try {
    const { data } = yield call(
      getDataShowcasePublicHomepage,
      action.meta
    );

    yield put(getDataShowcasePublicHomepageActions.success(data));
  } catch (e: any) {
    yield put(getDataShowcasePublicHomepageActions.failed(e.message));
  }
}

function* getDataShowcasePublicHomepageSuccess(
  action: AsyncAction<GetDataShowcasePublicHomepageMeta, GetDataShowcasePublicHomepagePayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getDataShowcasePublicHomepageWatcher(): any {
  yield takeLatest(getDataShowcasePublicHomepageActions.REQUEST, getDataShowcasePublicHomepageRequest);
  yield takeLatest(getDataShowcasePublicHomepageActions.SUCCESS, getDataShowcasePublicHomepageSuccess);
}

export default getDataShowcasePublicHomepageWatcher;
