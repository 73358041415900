import React from 'react';

import { SVGProps } from './SVG.props';

const AudienceResearch = (props: SVGProps): React.JSX.Element => {
  return (
    <svg width={14} height={14} viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M13.113.374a8.732 8.732 0 00-1.446-.127A8.627 8.627 0 007 1.614 8.627 8.627 0 002.333.28 8.733 8.733 0 00.887.407a.667.667 0 00-.554.667v8a.667.667 0 00.78.666 7.267 7.267 0 015.5 1.274l.08.046h.074a.607.607 0 00.466 0h.074l.08-.046a7.268 7.268 0 015.5-1.347.667.667 0 00.78-.667V1a.667.667 0 00-.554-.626zm-6.78 8.86a8.58 8.58 0 00-4-.987h-.666V1.58c.222-.012.444-.012.666 0a7.24 7.24 0 014 1.2v6.454zm6-.96h-.666a8.58 8.58 0 00-4 .986V2.78a7.24 7.24 0 014-1.2 5.79 5.79 0 01.666 0v6.694zm.78 2.766a8.735 8.735 0 00-1.446-.126A8.627 8.627 0 007 12.28a8.627 8.627 0 00-4.667-1.366 8.736 8.736 0 00-1.446.126.668.668 0 00-.554.767.667.667 0 00.78.527 7.267 7.267 0 015.5 1.273.667.667 0 00.774 0 7.264 7.264 0 015.5-1.273.667.667 0 00.78-.527.668.668 0 00-.554-.767z"
        fill="#070550"
      />
    </svg>
  );
};

export default AudienceResearch;
