import axios from 'axios';

import { API } from 'consts';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const UPLOAD_URL = `${BASE_URL}/upload`;

interface UploadFileOptions {
  file: File;
  token: string;
  folder?: string;
  customFileName?: string;
  onUploadProgress?: (received: number, total: number) => void;
}

export const uploadFile = async ({
  file,
  token,
  folder,
  customFileName,
}: UploadFileOptions): Promise<string> => {
  try {
    // Prepare query parameters
    const params = new URLSearchParams();
    if (folder) {
      params.append('folder', folder);
    }
    params.append('fileType', file.type);
    params.append('fileName', customFileName || file.name);

    // Fetch the pre-signed URL
    const { data, status } = await axios.get(
      `${UPLOAD_URL}/get-upload-link?${params}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    if (status !== 200 || !data?.data?.url) {
      throw new Error('Failed to retrieve upload link');
    }

    const { url, publicUrl } = data.data;

    // Upload the file using the pre-signed URL
    await uploadFileByPresignedUrl({ file, url });
    return publicUrl;
  } catch (err: any) {
    throw new Error(
      err?.response?.statusText || err.message || 'Upload failed',
    );
  }
};

interface PresignedUploadOptions {
  file: File;
  url: string;
}

export const uploadFileByPresignedUrl = async ({
  file,
  url,
}: PresignedUploadOptions): Promise<void> => {
  try {
    const fileData = await readFileAsArrayBuffer(file);

    const uploadRes = await axios.put(url, fileData, {
      headers: { 'Content-Type': file.type },
    });

    if (uploadRes.status !== 200 && uploadRes.status !== 204) {
      throw new Error('Failed to upload file');
    }
  } catch (err: any) {
    throw new Error(err.message || 'Presigned upload failed');
  }
};

const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as ArrayBuffer);
    reader.onerror = () => reject(reader.error);
    reader.readAsArrayBuffer(file);
  });
};
