import { put, call, takeLatest, select } from 'redux-saga/effects';
import { AxiosError } from 'axios';

import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import { getDynamicFieldFilter } from 'services/auth';
import { GenericResponse } from 'types/GenericResponse';
import { FieldValue, FilterOption } from 'types/FilterDynamic';

import { dynamicFilterActions } from '../actions';

function* getDynamicFilterRequest(
  action: AsyncAction<Record<string, never>, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getDynamicFieldFilter, state.auth.token);
      const filterFieldHaveNotData = data.filter(
        (i: FilterOption) => i?.field_values?.length || i.childs?.length,
      );
      yield put(
        dynamicFilterActions.success(
          filterFieldHaveNotData.map((i: FilterOption) => ({
            ...i,
            field_values:
              i?.field_values?.map((j: FieldValue) => ({
                ...j,
                label: j.value,
              })) ||
              i?.childs?.map((j: any) => ({
                ...j,
                label: j.field_name === 'date_joined_from' ? 'From' : 'To',
              })),
          })) || [],
        ),
      );
    } catch (e: unknown) {
      const errorMessage = e as AxiosError;
      console.log(errorMessage);
    }
  }
}

function* dynamicFilterWatcher(): any {
  yield takeLatest(dynamicFilterActions.REQUEST, getDynamicFilterRequest);
}

export default dynamicFilterWatcher;
