import { put, call, takeLatest, select } from 'redux-saga/effects';

import { answerMultiQuestion } from 'services/question';
import { AsyncAction } from 'types/Action';
import { AnswerMultiQuestionMeta, AnswerMultiQuestionPayload } from 'types/store/AnswerMultiQuestion';
import { Store } from 'types/store/Store';

import { answerMultiQuestionActions, getStoryByIdActions, storyEditorActions } from '../actions';

function* answerMultiQuestionRequest(action: AsyncAction<AnswerMultiQuestionMeta, AnswerMultiQuestionPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(answerMultiQuestion, action.meta, state.auth.token);

      // Update storyEditor status
      const storyEditorState = state.storyEditor;
      const newStoryEditorState: any = {
        ...storyEditorState,
        activeStory: {
          ...storyEditorState.activeStory,
          status: data.data.story_status
        }
      };

      yield put(getStoryByIdActions.request({
        only_status: ['ACCEPTED', 'IN_PROGRESS', 'REVIEW', 'IN_EDIT', 'IN_APPROVAL', 'READY_TO_PUBLISH', 'TO_PUBLISH'],
        story_id: data.data.story_id,
      }));

      yield put(storyEditorActions.update(newStoryEditorState));

      yield put(answerMultiQuestionActions.success(data));
    } catch (e: any) {
      yield put(answerMultiQuestionActions.failed(e.message));
    }
  }
}

function* answerMultiQuestionWatcher(): any {
  yield takeLatest(answerMultiQuestionActions.REQUEST, answerMultiQuestionRequest);
}

export default answerMultiQuestionWatcher;
