import { put, call, takeLatest, select } from 'redux-saga/effects';

import { AsyncAction } from 'types/Action';
import { RegisterUploadVideoLinkedinMeta, RegisterUploadVideoLinkedinPayload } from 'types/store/RegisterUploadVideoLinkedin';
import { Store } from 'types/store/Store';

import { registerUploadVideoLinkedinActions } from '../actions';

function* registerUploadVideoLinkedinRequest(action: AsyncAction<RegisterUploadVideoLinkedinMeta, RegisterUploadVideoLinkedinPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // const { data } = yield call(registerUploadVideoLinkedin, action.meta, state.auth.token);
      // yield put(registerUploadVideoLinkedinActions.success(data));
    } catch (e: any) {
      yield put(registerUploadVideoLinkedinActions.failed(e.message));
    }
  }
}

// function* registerUploadVideoLinkedinSuccess(action: AsyncAction<RegisterUploadVideoLinkedinMeta, RegisterUploadVideoLinkedinPayload>): any {
//   yield put()
// }

function* registerUploadVideoLinkedinWatcher(): any {
  yield takeLatest(registerUploadVideoLinkedinActions.REQUEST, registerUploadVideoLinkedinRequest);
  // yield takeLatest(registerUploadVideoLinkedinActions.SUCCESS, registerUploadVideoLinkedinSuccess);
}

export default registerUploadVideoLinkedinWatcher;
