import Typography from 'components/base/Typography';
import { ProgressBorder } from 'routes/Employer/StoryEditor/StoryEditorDetail/StoryEditorDetailV2/Review/ReviewContent.style';
import styled from 'utils/styled';

export const AddImgBorder = styled.div`
  border-radius: 12px;
  border: 1px dashed var(--ritchie-bros-neutral-n-20, #ccc);
  background: var(--grey-grey-2, #faf7f8);
  padding: 22px 34px 20px 34px;
  text-align: center;
  cursor: pointer;
`;

export const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TextUploadDecoration = styled(Typography)`
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  width: max-content;
`;

export const ImgPreview = styled.img`
  width: 180px;
  height: 97px;
  border-radius: 12px;
  object-fit: cover;
`;

export const ImgPreviewContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  width: 24px;
  height: 24px;
  background: ${({ theme }) => theme.altGrey.noshade};
  z-index: 222;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DownloadImageContainer = styled(ImgPreviewContainer)`
  top: 40px;
  cursor: pointer;
`;

export const ProcessingDownload = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  border-radius: 12px;
  width: 100%;
  height: 101px;
  gap: 8px;

  svg {
    animation: rotation 10s infinite linear;
    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

export const Container = styled.div`
  position: relative;
  border-radius: 12px;

  &:hover .action_btn {
    opacity: 1;
  }

  video {
    height: 101px;
    border-radius: 12px;
  }

  .background-blur {
    z-index: 1;
    opacity: 0.8;
    background: #070632;
  }

  .zIndex-2 {
    z-index: 2;
  }
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 97px;
  background: white;
  opacity: 0.9;
`;

export const NoneDisplayImgPreview = styled.div`
  width: 180px;
  min-width: 180px;
`;

export const ProgressBar = styled.div`
  width: 70%;
  height: 4px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.3);
  top: 50%;
  left: 50%;
`;

export const FloatingAlertContainer = styled.div<{
  open: boolean;
}>`
  opacity: 0;
  transition: 0.5s;
  ${({ open }) => (open ? 'opacity: 1;' : 'opacity: 0;')}
`;
