import { put, call, takeLatest, select } from 'redux-saga/effects';

import { ebCommentOnRecipe } from 'services/recipe-detail';
import { AsyncAction } from 'types/Action';
import { EbCommentOnRecipeMeta, EbCommentOnRecipePayload } from 'types/store/EbCommentOnRecipe';
import { Store } from 'types/store/Store';

import { ebCommentOnRecipeActions } from '../actions';

function* ebCommentOnRecipeRequest(action: AsyncAction<EbCommentOnRecipeMeta, EbCommentOnRecipePayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(ebCommentOnRecipe, action.meta, state.auth.token);
      yield put(ebCommentOnRecipeActions.success(data));
    } catch (e: any) {
      yield put(ebCommentOnRecipeActions.failed(e.message));
    }
  }
}

// function* ebCommentOnRecipeSuccess(action: AsyncAction<EbCommentOnRecipeMeta, EbCommentOnRecipePayload>): any {
//   yield put()
// }

function* ebCommentOnRecipeWatcher(): any {
  yield takeLatest(ebCommentOnRecipeActions.REQUEST, ebCommentOnRecipeRequest);
  // yield takeLatest(ebCommentOnRecipeActions.SUCCESS, ebCommentOnRecipeSuccess);
}

export default ebCommentOnRecipeWatcher;
