import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getAdmins } from 'services/team';
import { AsyncAction } from 'types/Action';
import { GetAdminsMeta, GetAdminsPayload } from 'types/store/GetAdmins';
import { Store } from 'types/store/Store';

import { getAdminsActions } from '../actions';

function* getAdminsRequest(action: AsyncAction<GetAdminsMeta, GetAdminsPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getAdmins, action.meta, state.auth.token);
      yield put(getAdminsActions.success(data));
    } catch (e: any) {
      yield put(getAdminsActions.failed(e.message));
    }
  }
}

// function* getAdminsSuccess(action: AsyncAction<GetAdminsMeta, GetAdminsPayload>): any {
//   yield put()
// }

function* getAdminsWatcher(): any {
  yield takeLatest(getAdminsActions.REQUEST, getAdminsRequest);
  // yield takeLatest(getAdminsActions.SUCCESS, getAdminsSuccess);
}

export default getAdminsWatcher;
