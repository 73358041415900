import React from 'react';

import { SVGProps } from './SVG.props';

const CreativeStudioIcon = (props: SVGProps): React.JSX.Element => {
  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  const { width, height, fill } = props;
  return (
    <svg
      width={width || '15'}
      height={height || '8'}
      viewBox="0 0 15 8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7578 0.766667C13.6564 0.708155 13.5415 0.677351 13.4245 0.677351C13.3074 0.677351 13.1925 0.708155 13.0911 0.766667L10.7378 1.92667C10.7188 1.40905 10.4997 0.918982 10.1267 0.559626C9.75366 0.20027 9.25576 -0.00034785 8.73779 4.52771e-07H2.73779C2.20736 4.52771e-07 1.69865 0.210714 1.32358 0.585787C0.948507 0.96086 0.737793 1.46957 0.737793 2V6C0.737793 6.53043 0.948507 7.03914 1.32358 7.41421C1.69865 7.78929 2.20736 8 2.73779 8H8.73779C9.25576 8.00035 9.75366 7.79973 10.1267 7.44037C10.4997 7.08102 10.7188 6.59095 10.7378 6.07333L13.1111 7.26C13.202 7.30659 13.3024 7.33168 13.4045 7.33333C13.5293 7.33373 13.6517 7.29909 13.7578 7.23333C13.8539 7.17329 13.9331 7.08975 13.9879 6.9906C14.0427 6.89145 14.0714 6.77996 14.0711 6.66667V1.33333C14.0714 1.22004 14.0427 1.10855 13.9879 1.0094C13.9331 0.910255 13.8539 0.826714 13.7578 0.766667ZM9.40446 6C9.40446 6.17681 9.33422 6.34638 9.2092 6.4714C9.08417 6.59643 8.9146 6.66667 8.73779 6.66667H2.73779C2.56098 6.66667 2.39141 6.59643 2.26639 6.4714C2.14136 6.34638 2.07113 6.17681 2.07113 6V2C2.07113 1.82319 2.14136 1.65362 2.26639 1.5286C2.39141 1.40357 2.56098 1.33333 2.73779 1.33333H8.73779C8.9146 1.33333 9.08417 1.40357 9.2092 1.5286C9.33422 1.65362 9.40446 1.82319 9.40446 2V6ZM12.7378 5.58667L10.7378 4.58667V3.41333L12.7378 2.41333V5.58667Z"
        fill={fill || 'none'}
      />
    </svg>
  );
};

export default CreativeStudioIcon;
