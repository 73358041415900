import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getStoryEvents } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  GetStoryEventsMeta,
  GetStoryEventsPayload,
} from 'types/store/GetStoryEventsState';
import { Store } from 'types/store/Store';

import { getStoryEventsActions } from '../actions';

function* getStoryEventsRequest(
  action: AsyncAction<GetStoryEventsMeta, GetStoryEventsPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getStoryEvents,
        action.meta,
        state.auth.token
      );
      yield put(getStoryEventsActions.success(data));
    } catch (e: any) {
      yield put(getStoryEventsActions.failed(e.message));
    }
  } else {
    yield put(getStoryEventsActions.failed('Token not found'));
  }
}

// function* getStoryByIdSuccess(
//   action: AsyncAction<GetStoryByIdMeta, GetStoryByIdPayload>
// ) {
// }

function* getStoryEventsWatcher(): any {
  yield takeLatest(getStoryEventsActions.REQUEST, getStoryEventsRequest);
  // yield takeLatest(getStoryByIdActions.SUCCESS, getStoryByIdSuccess);
}

export default getStoryEventsWatcher;
