import React, { Suspense, lazy, useEffect, useRef } from 'react';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
  RouteObject,
} from 'react-router-dom';

import Loading from 'components/module/Loading';
import StoryEditorUpselling from 'components/module/StoryEditorUpselling';
import {
  API,
  MAIN_ROUTES,
  EMPLOYER_ROUTES,
  ADVOCATE_ROUTES,
  COMPANY_ROUTES,
} from 'consts';
import { i18n } from 'locales/i18n';
import { store } from 'store';
import {
  authActions,
  getOnboardActions,
  onboardActions,
  getSharingActions,
  getAllPlatformActions,
  autoTrackLoginAction,
} from 'store/actions';
import { Routes as RoutesType } from 'types/Routes';
import { Store } from 'types/store/Store';
import addIdentity from 'utils/addIdentity';
import { VIDEOS } from 'consts/routes';
import { TrackAutoType } from 'types/store/TrackLoginMeta';
import ErrorBoundary from 'components/module/ErrorBoundary';

import SSOAuth from './Page/SSOAuth';
import { CanvaAuthorizePage } from './CanvaAuth/authorize.oauth';

const LazyViewPage = lazy(() => import('./Company/ViewPage'));
const NotFound = lazy(() => import('./NotFound'));
const Landing = lazy(() => import('./Landing'));
const EmployerRoutes = lazy(() => import('./Employer/employer.routes'));
const Login = lazy(() => import('./Auth/Employer/Login'));
const Register = lazy(() => import('./Auth/Employer/Register'));
const ForgotPassword = lazy(() => import('./Auth/Employer/ForgotPassword'));
const Onboarding = lazy(() => import('./Auth/Employer/Onboarding'));
const PreviewPage = lazy(() => {
  return import('./Employer/InsideLookCreate/PreviewPage');
});
const ResetPassword = lazy(() => import('./Auth/ResetPassword'));
const LoginAdvocate = lazy(() => import('./Auth/Advocate/Login'));
const AdvocateOnboarding = lazy(() => import('./Auth/Advocate/Onboarding'));
const ForgotPasswordAdvocate = lazy(() => {
  return import('./Auth/Advocate/ForgotPassword');
});
const RegisterAdvocate = lazy(() => import('./Auth/Advocate/Register'));
const AdvocateRoutes = lazy(() => import('./Advocate/advocate.routes'));
const Homepage = lazy(() => import('./Page'));
const ContentPlanner = lazy(() => import('./ContentPlanner'));
const RecipeDetail = lazy(() => import('./RecipeDetail'));
const SsoLoginPage = lazy(() => import('./Auth/Employer/SSO/LoginCallBack'));
const SSO_Register = lazy(
  () => import('./Auth/Employer/SSO/MagicLinkRegister'),
);
const EmailCompany = lazy(() => import('./Auth/Employer/SSO/EmailCompany'));
const SsoLogoutPage = lazy(() => import('./Auth/Employer/SSO/LogOutCallBack'));
const VideosPublic = lazy(() => import('./Videos/VideosPublics'));
const RegisterProfile = lazy(
  () => import('./Auth/Advocate/Register/UpdateProfile'),
);
const NoAppNoLogin = lazy(() => import('./NoAppNoLogin'));
const CanvaAuthPage = lazy(() => import('./CanvaAuth'));

export const ROUTES: Record<string, any> = {
  LANDING: {
    path: MAIN_ROUTES.LANDING,
    element: <Landing />,
  },
  EMPLOYER_LOGIN: {
    path: EMPLOYER_ROUTES.LOGIN,
    element: <Login />,
  },
  EMPLOYER_CANVA_CONNECT: {
    path: EMPLOYER_ROUTES.CANVA_AUTH,
    element: <CanvaAuthPage />,
    protected: true,
  },
  EMPLOYER_CANVA_AUTHORIZE: {
    path: EMPLOYER_ROUTES.CANVA_AUTHORIZE,
    children: <CanvaAuthorizePage />,
    protected: true,
  },
  EMPLOYER_CANVA_AUTHORIZE_CALLBACK: {
    path: EMPLOYER_ROUTES.CANVA_AUTHORIZE_CALLBACK,
    children: <CanvaAuthorizePage />,
    protected: true,
  },
  EMPLOYER_REGISTER: {
    path: EMPLOYER_ROUTES.REGISTER,
    element: <Register />,
  },
  EMPLOYER_FORGOT_PASSWORD: {
    path: EMPLOYER_ROUTES.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  EMPLOYER_SSO: {
    path: EMPLOYER_ROUTES.SSO,
    element: <EmailCompany />,
  },
  EMPLOYER_SSO_LOGIN: {
    path: EMPLOYER_ROUTES.OKTALOGIN,
    element: <SsoLoginPage />,
  },
  EMPLOYER_SSO_REGISTER: {
    path: EMPLOYER_ROUTES.SSO_REGISTER,
    element: <SSO_Register />,
  },
  EMPLOYER_SSO_LOGOUT: {
    path: EMPLOYER_ROUTES.OKATLOGOUT,
    element: <SsoLogoutPage />,
  },
  PUBLIC_VIDEOS: {
    path: VIDEOS.VIDEOS_PUBLIC,
    element: <VideosPublic />,
  },
  EMPLOYER_ONBOARDING: {
    path: EMPLOYER_ROUTES.ONBOARDING,
    element: <Onboarding />,
  },
  EMPLOYER_INSIDE_LOOK_VIEW: {
    path: EMPLOYER_ROUTES.INSIDE_LOOK_VIEW,
    element: <PreviewPage />,
  },
  STORY_UPSELLING: {
    path: EMPLOYER_ROUTES.STORY_UPSELLING,
    element: <StoryEditorUpselling />,
  },
  // EMPLOYER: {
  //   path: EMPLOYER_ROUTES.ROOT,
  //   element: <EmployerRoutes />,
  // },
  EMPLOYER: {
    path: `${EMPLOYER_ROUTES.ROOT}/*`, // Add wildcard for nested routes
    element: <EmployerRoutes />,
    protected: true,
  },
  RESET_PASSWORD: {
    path: '/reset-password/:reference/:token',
    element: <ResetPassword />,
  },
  VIEW_PAGE: {
    path: COMPANY_ROUTES.VIEW_PAGE(),
    element: <LazyViewPage />,
  },
  ADVOCATE_LOGIN: {
    path: ADVOCATE_ROUTES.LOGIN,
    element: <LoginAdvocate />,
  },
  ADVOCATE_SSO_REGISTER: {
    path: ADVOCATE_ROUTES.SSO_REGISTER,
    element: <SSO_Register />,
  },
  ADVOCATE_SSO_REGISTER_INVITE: {
    path: ADVOCATE_ROUTES.SSO_REGISTER_INVITE,
    element: <SSO_Register />,
  },
  ADVOCATE_ONBOARDING: {
    path: ADVOCATE_ROUTES.ONBOARDING,
    element: <AdvocateOnboarding />,
  },
  ADVOCATE_FORGOT_PASSWORD: {
    path: ADVOCATE_ROUTES.FORGOT_PASSWORD,
    element: <ForgotPasswordAdvocate />,
  },
  ADVOCATE_REGISTER: {
    path: ADVOCATE_ROUTES.REGISTER(),
    element: <RegisterAdvocate />,
  },
  REGISTER_RECIPES: {
    path: ADVOCATE_ROUTES.REGISTER_RECIPES(),
    element: <RegisterAdvocate />,
  },
  REGISTER_UPDATE_PROFILE: {
    path: ADVOCATE_ROUTES.REGISTER_PROFILE(),
    element: <RegisterProfile />,
  },
  ADVOCATE_REGISTER_SWITCH_PLATFORM: {
    path: ADVOCATE_ROUTES.REGISTER_DEFAULT,
    element: <RegisterAdvocate />,
  },
  ADVOCATE: {
    path: `${ADVOCATE_ROUTES.ROOT}/*`,
    element: <AdvocateRoutes />,
  },
  NANL: {
    path: MAIN_ROUTES.NANL,
    element: <NoAppNoLogin />,
    protected: true,
  },
  NOT_FOUND: {
    path: MAIN_ROUTES.NOT_FOUND,
    element: <NotFound />,
  },
  CONTENT_PLANNER: {
    path: MAIN_ROUTES.CONTENT_PLANNER,
    element: <ContentPlanner />,
  },
  SSO_AUTH: {
    path: MAIN_ROUTES.SSO_AUTH,
    element: <SSOAuth />,
  },
  RECIPE_DETAIL: {
    path: MAIN_ROUTES.RECIPE_DETAIL,
    element: <RecipeDetail />,
  },
  PUBLIC_PAGE: {
    path: MAIN_ROUTES.PUBLIC_PAGE(),
    element: <Homepage />,
  },
};

const RoutesComponent = (): React.JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.href.includes('canva')) {
      sessionStorage.setItem('canva_url', window.location.href);
    }
  }, [window.location.href]);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const redirect: string = query.get('redirect') || '';

  let existingPromise: any = undefined;
  const requestRefreshToken = (refreshToken: any) => {
    if (existingPromise) return existingPromise;
    else
      return (existingPromise = axios
        .post(`${API.URL}/${API.VERSION}/auth/token/refresh`, {
          refreshToken: refreshToken,
        })
        .then((response) => {
          const auth = response?.data?.data;
          dispatch(
            authActions.update({
              token: auth?.token,
              platform: auth?.platform,
              refresh_token: auth?.refresh_token,
            }),
          );
          if (auth?.language?.code) i18n(auth?.language?.code);
          return response;
        })
        .catch((error) => {
          dispatch(authActions.clear());
          localStorage.setItem('login_redirect', window.location.href);
          if (location.pathname !== EMPLOYER_ROUTES.LOGIN) {
            navigate(EMPLOYER_ROUTES.LOGIN);
          }
          return error;
        })
        // Not support IE
        .finally(() => (existingPromise = undefined)));
  };
  // const profile = useSelector((state: Store) => state.getUserById?.data?.data?.profile);
  // const companyId = useSelector((state: Store) => state.getUserById?.data?.data?.companyId);
  // const previousProfile = useRef({});

  // useEffect(() => {
  //   if (profile && !isEqual(profile, previousProfile.current)) {
  //     analytics.identify(profile.user_id, {
  //       lastName: profile.lastName ?? '',
  //       firstName: profile.firstName ?? '',
  //       email: profile.email ?? '',
  //       companyName: profile.company_name ?? '',
  //       companyId: companyId ?? '',
  //     });
  //     previousProfile.current = profile;
  //   }
  // }, [profile]);

  axios.interceptors.response.use(
    (response: any) => {
      return response;
    },
    async (error) => {
      const { status } = error?.response || {};
      if (status === 401) {
        const axiosApiInstance = axios.create();
        const originalRequest = error.config;
        const auth = store.getState().auth;
        if (auth.refresh_token) {
          const response = await requestRefreshToken(auth?.refresh_token);
          if (response?.data?.data) {
            const auth = response.data.data;
            originalRequest._retry = true;
            originalRequest.headers.Authorization = `Bearer ${auth?.token}`;
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth?.token;
            return axiosApiInstance(originalRequest);
          }
        } else {
          dispatch(authActions.clear());
          if (location.pathname !== EMPLOYER_ROUTES.LOGIN) {
            navigate(EMPLOYER_ROUTES.LOGIN);
          }
        }
      }
      return Promise.reject(error);
    },
  );

  const location = useLocation();
  const didMount = useRef(false);

  useEffect(() => {
    dispatch(getOnboardActions.request());
  }, []);

  const authObj = useSelector((state: Store) => state.auth);
  const getOnboardStatus = useSelector((state: Store) => state.getOnboard);

  useEffect(() => {
    if (getOnboardStatus.pending === false) {
      dispatch(
        onboardActions.update({
          isOpen: !getOnboardStatus.data?.data,
        }),
      );
    }
  }, [getOnboardStatus]);

  useEffect(() => {
    didMount.current = false;
  }, [authObj.token]);

  useEffect(() => {
    if (didMount.current) {
      dispatch(
        onboardActions.update({
          isOpen: false,
        }),
      );
    }
    if (
      location.pathname !== '/employer/onboarding' &&
      getOnboardStatus.pending === false
    ) {
      didMount.current = true;
    }
  }, [location]);

  useEffect(() => {
    // for tracking services purpose

    //case auto access to the application
    const instance = sessionStorage.getItem('track_auto_login');
    if (!instance) {
      dispatch(autoTrackLoginAction.request({ action: TrackAutoType.auto }));
    }
    sessionStorage.setItem('track_auto_login', 'inSeason');

    //case when close tab browser
    window.addEventListener('beforeunload', () => {
      dispatch(autoTrackLoginAction.request({ action: TrackAutoType.close }));
    });

    // Check if the Page Visibility API is supported by the browser
    if (typeof document.hidden !== 'undefined') {
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Function to handle visibility change
  const handleVisibilityChange = () => {
    if (document.hidden) {
      // The tab is currently hidden (in the background)
      // case when switch to another tab on the browser
      dispatch(
        autoTrackLoginAction.request({
          action: TrackAutoType.swapTab,
          status: 'leave',
        }),
      );
    } else {
      // The tab is currently visible (in focus)
      // case when switch to another tab and get back application
      dispatch(
        autoTrackLoginAction.request({
          action: TrackAutoType.swapTab,
          status: 'enter',
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(getSharingActions.request());

    return () => dispatch(getSharingActions.clear());
  }, [authObj.token]);

  useEffect(() => {
    dispatch(getAllPlatformActions.request());

    return () => dispatch(getAllPlatformActions.clear());
  }, [authObj.token]);

  useEffect(() => {
    if (authObj.token) {
      addIdentity(authObj);
    } else if (redirect && !authObj.token) {
      navigate(redirect);
    }
  }, [authObj.token]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading></Loading>}>
        <Routes>
          {Object.values(ROUTES).map((r) => (
            <Route key={r.path} path={r.path} element={r.element} />
          ))}
          <Route path={COMPANY_ROUTES.VIEW_PAGE()} element={<LazyViewPage />} />
          <Route path="*" element={<Navigate to={MAIN_ROUTES.NOT_FOUND} />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default RoutesComponent;
