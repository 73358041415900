import axios from 'axios';

import { API } from 'consts';
import { CreateEmailTemplateMeta } from 'types/store/CreateEmailTemplate';
import { DeleteEmailTemplateMeta } from 'types/store/DeleteEmailTemplate';
import { GetContentMarketplaceMeta } from 'types/store/GetContentMarketplace';
import { GetEmailTemplatesMeta } from 'types/store/GetEmailTemplates';
import { MaskAsDefaultEmailTemplateMeta } from 'types/store/MaskAsDefaultEmailTemplate';
import { SendMailWithTemplateMeta } from 'types/store/SendMailWithTemplate';
import { UpdateContentMarketplaceMeta } from 'types/store/UpdateContentMarketplace';
import { UpdateEmailTemplateMeta } from 'types/store/UpdateEmailTemplate';
import { UpsertContentMarketplaceMeta } from 'types/store/UpsertContentMarketplace';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const EMAIL_TEMPLATE_URL = `${BASE_URL}/email-template`;

export const getEmailTemplates = (
  data: GetEmailTemplatesMeta,
  token: string
): any => {
  return axios({
    method: 'get',
    url: `${EMAIL_TEMPLATE_URL}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const createEmailTemplate = (
  data: CreateEmailTemplateMeta,
  token: string
): any => {
  return axios({
    method: 'post',
    url: `${EMAIL_TEMPLATE_URL}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const updateEmailTemplate = (
  data: UpdateEmailTemplateMeta,
  token: string
): any => {
  return axios({
    method: 'put',
    url: `${EMAIL_TEMPLATE_URL}/${data.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const maskAsDefaultEmailTemplate = (
  data: MaskAsDefaultEmailTemplateMeta,
  token: string
): any => {
  return axios({
    method: 'get',
    url: `${EMAIL_TEMPLATE_URL}/${data.id}/mask-as-default`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteEmailTemplate = (
  data: DeleteEmailTemplateMeta,
  token: string
): any => {
  return axios({
    method: 'delete',
    url: `${EMAIL_TEMPLATE_URL}/${data.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const sendMailWithTemplate = (data: SendMailWithTemplateMeta, token: string): any => {
  return axios({
    method: 'post',
    url: `${EMAIL_TEMPLATE_URL}/send`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  });
}
