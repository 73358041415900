import { AxiosError } from 'axios';
import {
  put,
  call,
  takeLatest,
  select,
  all,
  takeEvery,
} from 'redux-saga/effects';

import {
  updateSSO,
  embedEmail,
  generateGif,
  getListEmail,
  getSSOSetting,
  shareMagicLink,
  getListLibrary,
  getHTMLGmailCode,
  removeSSOSetting,
  getEmbedJSCodeLibrary,
  renewSSOVerificationCert,
} from 'services/shareLibrary';
import {
  generateGifRequest,
  GENERATE_GIF,
  getOktaSetting,
  GET_EMBED_JS,
  GET_HTML_GMAIL_CODE,
  GET_LIST_EMAIL,
  GET_LIST_MEDIA,
  GET_MAGIC_LINK,
  GET_OKTA_SETTING,
  OKTA_REMOVE_SSO,
  SEND_EMBED_EMAIL,
  UPLOAD_SSO,
  RENEW_SSO_CERT
} from 'store/actions/libraryShareContent.action';
import { openToast, TOAST } from 'store/actions/toast.action';
import { AsyncAction } from 'types/Action';
import { VideoContent } from 'types/store/ShareLibrary';
import { Store } from 'types/store/Store';
import { ErrorResponse } from 'utils/handleAxiosError';

function* watchGetGifVideos(action: AsyncAction) {
  const token: string | null = yield select((_: Store) => _.auth.token);
  const videos: VideoContent[] = yield select(
    (_: Store) => _.shareLibrary.videos,
  );
  const { id, type } = action.payload;
  if (token && videos.length) {
    try {
      const params = new URLSearchParams();
      params.append('id', id);
      params.append('type', type);
      const { data } = yield call(generateGif, params.toString(), token);
      const index = videos.findIndex((item) => item.id === id);
      videos[index] = data.data;
      yield put({
        type: GENERATE_GIF.GENERATE_GIF_SUCCESS,
        payload: videos,
      });
      return true;
    } catch (error) {
      yield put({
        type: GENERATE_GIF.GENERATE_GIF_ERROR,
        payload: videos,
      });
      return false;
    }
  }
}

function* watchRunAllGifService(action: AsyncAction) {
  const videos: VideoContent[] = yield select(
    (_: Store) => _.shareLibrary.videos,
  );
  try {
    yield all(
      videos
        .slice(action.payload, action.payload + 3)
        .map((item: VideoContent) => {
          if (!item.gif) {
            return put(
              generateGifRequest({
                id: item.id,
                type: item.type,
              }),
            );
          }
        }),
    );
  } catch (error) {
    console.log('eeeeee', error);
  }
}

function* watchGetListMediaRequest(action: any): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const params = new URLSearchParams();
      params.append('storyId', action.payload.storyId);
      params.append('isStory', action.payload.isStory);
      const { data } = yield call(
        getListLibrary,
        params.toString(),
        state.auth.token,
      );
      yield put({
        type: GET_LIST_MEDIA.GET_LIST_MEDIA_SUCCESS,
        payload: data.data,
      });
      if (data.status === 200 && data.data.videos.length) {
        yield all(
          data.data.videos
            .slice(0, 3)
            .map((item: VideoContent, index: number) => {
              if (!item.gif) {
                return put(
                  generateGifRequest({
                    id: item.id,
                    type: item.type,
                  }),
                );
              }
              return put({
                type: GENERATE_GIF.GENERATE_GIF_CLEAR,
              });
            }),
        );
      }
    } catch (error: any) {
      console.log('get list media error', error);
    }
  }
}

function* watchEmbedEmailRequest(action: any): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(embedEmail, action.payload, state.auth.token);
      yield put({
        type: TOAST.TOAST_OPEN,
        payload: { message: data.message, type: 'success' },
      });
      action.callback();
    } catch (error: any) {
      const errorMessage = error as AxiosError<ErrorResponse>;
      if (errorMessage.response?.data.status === 400) {
        const message = errorMessage.response?.data?.message;
        yield put({
          type: TOAST.TOAST_OPEN,
          payload: { message, type: 'error' },
        });
      }
    }
  }
}

function* watchEmbedJScode(action: any): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const params = new URLSearchParams();
      params.append('storyId', action.payload.storyId);
      action.payload.content &&
        params.append('content', action.payload.content);
      const { data } = yield call(
        getEmbedJSCodeLibrary,
        params.toString(),
        state.auth.token,
      );
      yield put({
        type: GET_EMBED_JS.GET_EMBED_JS_SUCCESS,
        payload: {
          jsCode: data.data.jsCode,
          iframeCode: data.data.iframeCode,
        },
      });
    } catch (error: any) {
      const errorMessage = error as AxiosError<ErrorResponse>;
      if (errorMessage.response?.data.status === 400) {
        const message = errorMessage.response?.data?.message;
        yield put({
          type: TOAST.TOAST_OPEN,
          payload: { message, type: 'error' },
        });
      }
    }
  }
}

function* watchGetListEmail(action: any): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getListEmail, state.auth.token);
      yield put({
        type: GET_LIST_EMAIL.GET_LIST_EMAIL_SUCCESS,
        payload: data.data.emails,
      });
    } catch (error: any) {
      const errorMessage = error as AxiosError<ErrorResponse>;
      if (errorMessage.response?.data.status === 400) {
        const message = errorMessage.response?.data?.message;
        yield put({
          type: TOAST.TOAST_OPEN,
          payload: { message, type: 'error' },
        });
      }
    }
  }
}

function* watchGetMagicLinkRequest(action: any): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const params = new URLSearchParams();
      action.payload.storyId &&
        params.append('storyId', action.payload.storyId);
      action.payload.content &&
        params.append('content', action.payload.content);
      action.payload.libraryId &&
        params.append('libraryId', action.payload.libraryId);
      action.payload.type && params.append('type', action.payload.type);
      action.payload.versionFileId &&
        params.append('versionFileId', action.payload.versionFileId);
      const { data } = yield call(
        shareMagicLink,
        params.toString(),
        state.auth.token,
      );
      if (data.status === 200) {
        yield put({
          type: GET_MAGIC_LINK.GET_MAGIC_LINK_SUCCESS,
          payload: data.data,
        });
        action.payload.callback(true);
      }
    } catch (error: any) {
      const errorMessage = error as AxiosError<ErrorResponse>;
      if (errorMessage.response?.data.status === 400) {
        const message = errorMessage.response?.data?.message;
        yield put({
          type: TOAST.TOAST_OPEN,
          payload: { message, type: 'error' },
        });
      }
    }
  }
}

function* watchGetOktaRequest(action: any): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getSSOSetting, state.auth.token);
      yield put({
        type: GET_OKTA_SETTING.GET_OKTA_SETTING_SUCCESS,
        payload: data.data,
      });
    } catch (error: any) {
      const errorMessage = error as AxiosError<ErrorResponse>;
      if (errorMessage.response?.data.status === 400) {
        const message = errorMessage.response?.data?.message;
        yield put({
          type: TOAST.TOAST_OPEN,
          payload: { message, type: 'error' },
        });
      } else {
        yield put({
          type: TOAST.TOAST_OPEN,
          payload: {
            message: 'Sever is busy, please try again later',
            type: 'error',
          },
        });
      }
      yield put({
        type: GET_OKTA_SETTING.GET_OKTA_SETTING_ERROR,
      });
    }
  }
}

function* watchUpdateSSORequest(action: any): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      yield call(updateSSO, action.payload, state.auth.token);
      yield put(getOktaSetting());
      yield put({
        type: TOAST.TOAST_OPEN,
        payload: { message: 'Your settings have been saved', type: 'success' },
      });
    } catch (error: any) {
      const errorMessage = error as AxiosError<ErrorResponse>;
      if (errorMessage.response?.data.status === 400) {
        const message = errorMessage.response?.data?.message;
        yield put({
          type: UPLOAD_SSO.UPLOAD_SSO_ERROR,
        });
        yield put({
          type: TOAST.TOAST_OPEN,
          payload: { message, type: 'error' },
        });
      }
    }
  }
}

function* watchRenewSSOVerificationCertRequest(action: any): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      yield call(renewSSOVerificationCert, state.auth.token)
      yield put(getOktaSetting())
      yield put({
        type: TOAST.TOAST_OPEN,
        payload: { message: "Verification certificate successfully renewed", type: 'success' }
      })
    } catch (error: any) {
      console.log('RenewSSOVerificationCert', error)
      yield put({
        type: RENEW_SSO_CERT.RENEW_SSO_CERT_ERROR,
      })
      yield put({
        type: TOAST.TOAST_OPEN,
        payload: { message: "Failed to verification certificate", type: 'error' }
      })
    }
  }
}

function* watchGetHtmlGmailCode(action: AsyncAction) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getHTMLGmailCode,
        action.payload,
        state.auth.token,
      );
      if (data.status === 200) {
        const type = 'text/html';
        const blob = new Blob([data.data.html], { type });
        const clipboard = [new window.ClipboardItem({ [type]: blob })];
        yield navigator.clipboard.write(clipboard);
        action.payload.callback(true);
      }
    } catch (err) {
      const errorMessage = err as AxiosError<ErrorResponse>;
      // Show toast message control by FE , only console log real error message
      console.log(errorMessage.response?.data.message);
      // yield put(openToast(errorMessage.response?.data.message, 'warning'));
      action.payload.callback(false);
    }
  }
}

function* watchRemoveSSO(action: AsyncAction) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(removeSSOSetting, state.auth.token);
      if (data.status === 200) {
        yield put({
          type: GET_OKTA_SETTING.GET_OKTA_SETTING_SUCCESS,
          payload: data.data,
        });
      }
    } catch (err) {
      console.log('uerr', err);
    }
  }
}

function* getListMediaWatcher(): any {
  yield takeLatest(
    GET_LIST_MEDIA.GET_LIST_MEDIA_REQUEST,
    watchGetListMediaRequest,
  );
  yield takeLatest(
    SEND_EMBED_EMAIL.SEND_EMBED_EMAIL_REQUEST,
    watchEmbedEmailRequest,
  );
  yield takeLatest(GET_EMBED_JS.GET_EMBED_JS_REQUEST, watchEmbedJScode);
  yield takeLatest(GET_LIST_EMAIL.GET_LIST_EMAIL_REQUEST, watchGetListEmail);
  yield takeLatest(GET_MAGIC_LINK.GET_MAGIC_LINK_REQUEST, watchGetMagicLinkRequest);
  yield takeLatest(GET_OKTA_SETTING.GET_OKTA_SETTING_REQUEST, watchGetOktaRequest);
  yield takeLatest(UPLOAD_SSO.UPLOAD_SSO_REQUEST, watchUpdateSSORequest);
  yield takeLatest(RENEW_SSO_CERT.RENEW_SSO_CERT_REQUEST, watchRenewSSOVerificationCertRequest);
  yield takeLatest(GET_HTML_GMAIL_CODE.GET_HTML_GMAIL_CODE_REQUEST, watchGetHtmlGmailCode);
  yield takeLatest(OKTA_REMOVE_SSO.OKTA_REMOVE_SSO_REQUEST, watchRemoveSSO);
  yield takeEvery(GENERATE_GIF.GENERATE_GIF_REQUEST, watchGetGifVideos);
  yield takeLatest(GENERATE_GIF.GENERATE_GIF_RUN_ALL, watchRunAllGifService);
}

export default getListMediaWatcher;
