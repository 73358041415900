import { createAsyncReducer } from 'utils/Redux';
import {
  GetStoryRepurposedEventLogMeta,
  GetStoryRepurposedEventLogPayload,
} from 'types/store/GetStoryRepurposedEventLog';

import { getStoryRepurposedEventLogActions } from '../actions';

export default createAsyncReducer<
  GetStoryRepurposedEventLogMeta,
  GetStoryRepurposedEventLogPayload
>(getStoryRepurposedEventLogActions);
