import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getDataShowcasePublicPageDetails } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import {
  GetDataShowcasePublicPageDetailsMeta,
  GetDataShowcasePublicPageDetailsPayload,
} from 'types/store/GetDataShowcasePublicPageDetailsState';
import { Store } from 'types/store/Store';

import { getDataShowcasePublicPageDetailsActions } from '../actions';

function* getDataShowcasePublicPageDetailsRequest(
  action: AsyncAction<GetDataShowcasePublicPageDetailsMeta, GetDataShowcasePublicPageDetailsPayload>
) {
  // const state: Store = yield select();
  // if (state.auth.token) {
  //   try {
  //     const { data } = yield call(
  //       getDataShowcasePublicPageDetails,
  //       action.meta,
  //       state.auth.token
  //     );
  //     yield put(getDataShowcasePublicPageDetailsActions.success(data));
  //   } catch (e: any) {
  //     yield put(getDataShowcasePublicPageDetailsActions.failed(e.message));
  //   }
  // } else {
  //   yield put(getDataShowcasePublicPageDetailsActions.failed('Token not found'));
  // }
  try {
    const { data } = yield call(
      getDataShowcasePublicPageDetails,
      action.meta
    );
    yield put(getDataShowcasePublicPageDetailsActions.success(data));
  } catch (e: any) {
    yield put(getDataShowcasePublicPageDetailsActions.failed(e.message));
  }
}

function* getDataShowcasePublicPageDetailsSuccess(
  action: AsyncAction<GetDataShowcasePublicPageDetailsMeta, GetDataShowcasePublicPageDetailsPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getDataShowcasePublicPageDetailsWatcher(): any {
  yield takeLatest(getDataShowcasePublicPageDetailsActions.REQUEST, getDataShowcasePublicPageDetailsRequest);
  yield takeLatest(getDataShowcasePublicPageDetailsActions.SUCCESS, getDataShowcasePublicPageDetailsSuccess);
}

export default getDataShowcasePublicPageDetailsWatcher;
