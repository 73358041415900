import Button from 'components/base/Button';
import Typography from 'components/base/Typography';
import styled from 'utils/styled';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 94px auto auto; */
`;

export const PageNotFound = styled(Typography)`
  font-family: Objective;
  font-size: 38px;
  font-weight: 700;
  line-height: 46px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #69ba92;
`;
export const PageNotFoundText = styled(Typography)`
  //styleName: XL/medium;
  font-family: Objective;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #060548;
  margin: 12px 30px 40px;
`;

export const Image = styled.img``;

export const GoHomeButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  background: #91dbb6;
  border-radius: 8px;
  border: 0;
  cursor: pointer;

  > svg {
    margin-right: 10px;
    color: #070550;
  }

  p {
    font-family: Objective;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #060548;
    margin-right: 8px;
  }
`;
