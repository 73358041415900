import { put, call, takeLatest, select } from 'redux-saga/effects';

import { createSmsTemplate } from 'services/sms-template';
import { AsyncAction } from 'types/Action';
import { CreateSmsTemplateMeta, CreateSmsTemplatePayload } from 'types/store/CreateSmsTemplate';
import { Store } from 'types/store/Store';

import { createSmsTemplateActions } from '../actions';

function* createSmsTemplateRequest(action: AsyncAction<CreateSmsTemplateMeta, CreateSmsTemplatePayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(createSmsTemplate, action.meta, state.auth.token);
      yield put(createSmsTemplateActions.success(data));
    } catch (e: any) {
      yield put(createSmsTemplateActions.failed(e.message));
    }
  }
}

// function* createSmsTemplateSuccess(action: AsyncAction<CreateSmsTemplateMeta, CreateSmsTemplatePayload>): any {
//   yield put()
// }

function* createSmsTemplateWatcher(): any {
  yield takeLatest(createSmsTemplateActions.REQUEST, createSmsTemplateRequest);
  // yield takeLatest(createSmsTemplateActions.SUCCESS, createSmsTemplateSuccess);
}

export default createSmsTemplateWatcher;
