import { put, call, takeLatest, select } from 'redux-saga/effects';

import { TrackingUrl } from 'routes/Employer/AnalyticsV2/ROIMetrics/WebsiteSection/components/Modal/ModalTracking.props';
import { InitialData } from 'routes/Employer/AnalyticsV2/ROIMetrics/WebsiteSection/components/TrackingLink/TrackingLink.props';
import {
  createUrlWebTrafficCrawler,
  fetchWebTraffic,
  getURLWebTrafficCrawler,
  updateUrlWebTrafficCrawler,
} from 'services/analytics';
import {
  analyticWebTrafficActions,
  analyticAddUrlCrawAction,
  openToast,
  analyticURLWebTrafficActions,
} from 'store/actions';
import { IAnalyticWebCrawTrafficMeta } from 'store/actions/analytics/ROI/createURLWebTraffic';
import { IAnalyticWebTrafficURLMeta } from 'store/actions/analytics/ROI/urlWebTraffic';
import { IAnalyticWebTrafficMeta } from 'store/actions/analytics/ROI/webTraffic';
import { AsyncAction } from 'types/Action';
import { GenericResponse } from 'types/GenericResponse';
import { Store } from 'types/store/Store';

function* getWebTraffic(
  action: AsyncAction<IAnalyticWebTrafficMeta, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        fetchWebTraffic,
        action.meta,
        state.auth.token,
      );
      // yield put(analyticURLWebTrafficActions.request({ isRoot: true }));
      yield put(analyticWebTrafficActions.success(data));
    } catch (e: any) {
      yield put(analyticWebTrafficActions.failed(e.message));
    }
  }
}

function* createUrlCrawler(
  action: AsyncAction<IAnalyticWebCrawTrafficMeta, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        action.meta.isEdit
          ? updateUrlWebTrafficCrawler
          : createUrlWebTrafficCrawler,
        action.meta.crawData,
        state.auth.token,
      );
      if (data.status === 200) {
        yield put(openToast(data.message, 'success'));
        yield put(
          analyticWebTrafficActions.request({
            time: '30',
            communities: [],
          }),
        );
      }
    } catch (e: any) {
      yield put(analyticAddUrlCrawAction.failed(e.message));
      yield put(openToast(e.message, 'error'));
    }
  }
}

function* getUrlCrawler(
  action: AsyncAction<IAnalyticWebTrafficURLMeta, GenericResponse>,
): any {
  const state: Store = yield select();
  const { payload, meta } = action;
  if (state.auth.token) {
    try {
      const { data: result } = yield call(
        getURLWebTrafficCrawler,
        Boolean(action.meta?.isRoot),
        state.auth.token,
      );
      let mappingData;
      if (meta && !meta.isRoot) {
        mappingData = result?.data?.filter(
          (item: TrackingUrl) => item.status === 'ACTIVE',
        );
      } else {
        const mapData = () => {
          return result.data.length
            ? result.data.map((item: TrackingUrl) => ({
                ...item,
                checked: item.status === 'ACTIVE' ? true : false,
              }))
            : InitialData.map((item, index) => ({
                link: result?.data[index]
                  ? result?.data[index]?.link
                  : item.link,
                checked: result?.data[index]?.status
                  ? result?.data[index]?.status === 'ACTIVE'
                    ? true
                    : false
                  : false,
                storyId: result?.data[index]?.storyId
                  ? result?.data[index]?.storyId
                  : '',
                utmSources: result?.data[index]?.utmSources
                  ? result?.data[index]?.utmSources
                  : {},
                shortLink: result?.data[index]?.shortLink
                  ? result?.data[index]?.shortLink
                  : '',
                status: result?.data[index]?.status
                  ? result?.data[index]?.status
                  : 'IN_ACTIVE',
                id: result?.data[index]?.id ? result?.data[index]?.id : '',
              }));
        };
        mappingData = mapData();
      }
      const data = mappingData.map((item: TrackingUrl, index: number) => ({
        ...item,
        checked: item.status === 'ACTIVE' ? true : false,
      }));
      const utmSource = data.find((item: TrackingUrl) => item.storyId === meta);

      let utm;
      let websiteUrl;
      let shortLink;
      if (utmSource) {
        utm = utmSource.utmSources;
        websiteUrl = {
          ...utmSource,
          label: utmSource.link,
          value: utmSource.link,
        };
        shortLink = utmSource.shortLink;
        if (!utm.utmContent) utm.utmContent = '';
      }
      yield put(
        analyticURLWebTrafficActions.success({
          data,
          meta: utm
            ? utm
            : {
                utmSource: '',
                utmMedium: '',
                utmCampaign: '',
                utmTerm: '',
                utmContent: '',
              },
          payload: websiteUrl ? websiteUrl : {},
          shortLink,
        }),
      );
    } catch (e: any) {
      console.log('e.message', e.message);
      yield put(analyticURLWebTrafficActions.failed(e.message));
      yield put(openToast(e.message, 'error'));
    }
  }
}

function* analyticWatcher(): any {
  yield takeLatest(analyticWebTrafficActions.REQUEST, getWebTraffic);
  yield takeLatest(analyticAddUrlCrawAction.REQUEST, createUrlCrawler);
  yield takeLatest(analyticURLWebTrafficActions.REQUEST, getUrlCrawler);
}

export default analyticWatcher;
