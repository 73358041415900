import { put, call, takeLatest, select } from 'redux-saga/effects';

import { shareToLinkedin } from 'services/linkedin';
import { AsyncAction } from 'types/Action';
import { ShareToLinkedinMeta, ShareToLinkedinPayload } from 'types/store/ShareToLinkedin';
import { Store } from 'types/store/Store';

import { shareToLinkedinActions, shareStoryActions } from '../actions';

function* shareToLinkedinRequest(
  action: AsyncAction<ShareToLinkedinMeta, ShareToLinkedinPayload>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const dataToSave = action.meta['dataToSave'];
      delete action.meta.dataToSave;
      const { data } = yield call(
        shareToLinkedin,
        action.meta,
        state.auth.token,
      );
      yield put(shareToLinkedinActions.success(data));
      const dataLinkInResp = data.data;
      const value = {
        ...dataToSave,
        shared_platform: {
          LinkedIn: {
            ...dataLinkInResp,
          },
        },
      };
      yield put(shareStoryActions.request(value));
    } catch (e: any) {
      yield put(shareToLinkedinActions.failed(e.message));
    }
  }
}

// function* shareToLinkedinSuccess(action: AsyncAction<ShareToLinkedinMeta, ShareToLinkedinPayload>): any {
//   yield put()
// }

function* shareToLinkedinWatcher(): any {
  yield takeLatest(shareToLinkedinActions.REQUEST, shareToLinkedinRequest);
  // yield takeLatest(shareToLinkedinActions.SUCCESS, shareToLinkedinSuccess);
}

export default shareToLinkedinWatcher;
