import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getLibraryStories } from 'services/story';
import { AsyncAction } from 'types/Action';
import { GetLibraryStoriesMeta, GetLibraryStoriesPayload } from 'types/store/GetLibraryStories';
import { Store } from 'types/store/Store';

import { getLibraryStoriesActions } from '../actions';

function* getLibraryStoriesRequest(action: AsyncAction<GetLibraryStoriesMeta, GetLibraryStoriesPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(getLibraryStories, action.meta, state.auth.token);
      yield put(getLibraryStoriesActions.success(data));
    } catch (e: any) {
      yield put(getLibraryStoriesActions.failed(e.message));
    }
  }
}

// function* getLibraryStoriesSuccess(action: AsyncAction<GetLibraryStoriesMeta, GetLibraryStoriesPayload>): any {
//   yield put()
// }

function* getLibraryStoriesWatcher(): any {
  yield takeLatest(getLibraryStoriesActions.REQUEST, getLibraryStoriesRequest);
  // yield takeLatest(getLibraryStoriesActions.SUCCESS, getLibraryStoriesSuccess);
}

export default getLibraryStoriesWatcher;
