import React from 'react';

import { SVGProps } from './SVG.props';

const Triangle = ({ width, height, fill }: SVGProps): React.JSX.Element => {
  return (
    <svg
      width={width || '10'}
      height={height || '11'}
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.81488 3.75004L3.17988 0.518374C2.87496 0.3423 2.52888 0.250063 2.17678 0.25103C1.82467 0.251997 1.4791 0.346134 1.17516 0.52388C0.87121 0.701626 0.619721 0.956649 0.446232 1.26304C0.272744 1.56944 0.183436 1.91629 0.187381 2.26837V8.75504C0.187381 9.28415 0.397568 9.79158 0.771703 10.1657C1.14584 10.5399 1.65327 10.75 2.18238 10.75C2.53264 10.7494 2.8766 10.6569 3.17988 10.4817L8.81488 7.25004C9.11766 7.0748 9.36904 6.82302 9.5438 6.51996C9.71856 6.2169 9.81056 5.87321 9.81056 5.52337C9.81056 5.17354 9.71856 4.82985 9.5438 4.52679C9.36904 4.22373 9.11766 3.97195 8.81488 3.79671V3.75004Z"
        fill={fill || 'white'}
      />
    </svg>
  );
};

export default Triangle;
