import { put, call, takeLatest, select } from 'redux-saga/effects';

import { upsertStoryApprovals } from 'services/story';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpsertStoryApprovalsMeta,
  UpsertStoryApprovalsPayload,
} from 'types/store/UpsertStoryApprovalsState';

import {
  upsertStoryApprovalsActions,
} from '../actions';

function* upsertStoryApprovalsRequest(
  action: AsyncAction<
    UpsertStoryApprovalsMeta,
    UpsertStoryApprovalsPayload
  >
) {
  const state: Store = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        upsertStoryApprovals,
        action.meta,
        state.auth.token
      );
      yield put(upsertStoryApprovalsActions.success(data));
    } catch (e: any) {
      yield put(upsertStoryApprovalsActions.failed(e.message));
    }
  } else {
    yield put(upsertStoryApprovalsActions.failed('Token not found'));
  }
}

function* upsertStoryApprovalsWatcher(): any {
  yield takeLatest(
    upsertStoryApprovalsActions.REQUEST,
    upsertStoryApprovalsRequest
  );
}

export default upsertStoryApprovalsWatcher;
