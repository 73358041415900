import * as React from 'react';

import { SVGProps } from './SVG.props';

const ExpandVertical = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="twoarrow-expand-vertical">
        <g id="line">
          <path
            d="M8.47075 2.19526C8.34573 2.07024 8.17616 2 7.99935 2C7.82254 2 7.65297 2.07024 7.52795 2.19526L4.86128 4.86193C4.60093 5.12228 4.60093 5.54439 4.86128 5.80474C5.12163 6.06509 5.54374 6.06509 5.80409 5.80474L7.99935 3.60948L10.1946 5.80474C10.455 6.06509 10.8771 6.06509 11.1374 5.80474C11.3978 5.54439 11.3978 5.12228 11.1374 4.86193L8.47075 2.19526Z"
            fill={fill || '#060548'}
          />
          <path
            d="M5.80409 10.1953C5.54374 9.93491 5.12163 9.93491 4.86128 10.1953C4.60093 10.4556 4.60093 10.8777 4.86128 11.1381L7.52794 13.8047C7.78829 14.0651 8.2104 14.0651 8.47075 13.8047L11.1374 11.1381C11.3978 10.8777 11.3978 10.4556 11.1374 10.1953C10.8771 9.93491 10.455 9.93491 10.1946 10.1953L7.99935 12.3905L5.80409 10.1953Z"
            fill={fill || '#060548'}
          />
        </g>
      </g>
    </svg>
  );
};

export default ExpandVertical;
