import { Reducer } from 'redux';
import _get from 'lodash/get';

import {
  CONTENT_REPURPOSING_ACTION,
  CREATE_REPURPOSE_STORY,
  GET_FILTER_ACTION,
  UPDATE_REPURPOSE_STORY,
} from 'store/actions/contentRepurposing/contentRepurposing.enum';
import { Action } from 'types/Action';
import {
  ContentRepurposingStore,
  GroupStory,
  StoryType,
} from 'types/store/RepurposeContent';

const initState: ContentRepurposingStore = {
  status: [],
  headline: [],
  tags: [],
  advocate: [],
  storyOwner: [],
  isLoading: false,
  stories: undefined,
  page: 1,
  isCreateSuccess: false,
  idCreateCP: '',
  totalPages: 0,
  isLoadingButton: false,
};

const ContentRepurposing: Reducer<ContentRepurposingStore, Action> = (
  state = initState,
  { type, payload },
) => {
  switch (type) {
    case CONTENT_REPURPOSING_ACTION.GET_LIST_CONTENT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CONTENT_REPURPOSING_ACTION.GET_LIST_CONTENT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        stories: state.stories?.length
          ? [...state.stories, ...payload.rows]
          : payload.rows,
        page: payload.page,
        totalPages: payload.totalPages,
      };
    }
    case CONTENT_REPURPOSING_ACTION.GET_LIST_CONTENT_CLEAR: {
      return {
        ...state,
        stories: undefined,
        page: 1,
        totalPages: 0,
      };
    }
    case GET_FILTER_ACTION.GET_FILTER_SUCCESS: {
      return {
        ...state,
        status: _get(payload, 'status', []),
        headline: _get(payload, 'headline', []),
        tags: _get(payload, 'tags', []),
        advocate: _get(payload, 'advocate', []),
        storyOwner: _get(payload, 'storyOwner', []),
      };
    }
    case CREATE_REPURPOSE_STORY.CREATE_REPURPOSE_STORY:
    case UPDATE_REPURPOSE_STORY.UPDATE_REPURPOSE_STORY: {
      return {
        ...state,
        isLoadingButton: true,
      };
    }
    case CREATE_REPURPOSE_STORY.CREATE_REPURPOSE_STORY_SUCCESS: {
      return {
        ...state,
        isCreateSuccess: true,
        idCreateCP: payload.story_id,
        isLoadingButton: false,
      };
    }
    case CREATE_REPURPOSE_STORY.CREATE_REPURPOSE_STORY_CLEAR: {
      return {
        ...state,
        isCreateSuccess: false,
        isCreateCP: '',
      };
    }
    case CREATE_REPURPOSE_STORY.CREATE_REPURPOSE_STORY_FAILURE: {
      return {
        ...state,
        isLoadingButton: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default ContentRepurposing;
