import { put, call, takeLatest, select } from 'redux-saga/effects';

import { MAIN_ROUTES } from 'consts';
import { getStoryById } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  GetStoryByIdMeta,
  GetStoryByIdPayload,
} from 'types/store/GetStoryByIdState';
import { Store } from 'types/store/Store';
import navigationService from 'utils/navigationService';

import { getStoryByIdActions } from '../actions';

function* getStoryByIdRequest(
  action: AsyncAction<GetStoryByIdMeta, GetStoryByIdPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getStoryById, action.meta, state.auth.token);
      yield put(getStoryByIdActions.success(data));
    } catch (e: any) {
      if (e.response.status === 404) {
        yield call(navigationService.goTo, MAIN_ROUTES.NOT_FOUND);
      } else {
        yield put(getStoryByIdActions.failed(e.message));
      }
    }
  } else {
    yield put(getStoryByIdActions.failed('Token not found'));
  }
}

// function* getStoryByIdSuccess(
//   action: AsyncAction<GetStoryByIdMeta, GetStoryByIdPayload>
// ) {
// }

function* getStoryByIdWatcher(): any {
  yield takeLatest(getStoryByIdActions.REQUEST, getStoryByIdRequest);
  // yield takeLatest(getStoryByIdActions.SUCCESS, getStoryByIdSuccess);
}

export default getStoryByIdWatcher;
