import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getNotificationsByUserId } from 'services/notifications';
import { AsyncAction } from 'types/Action';
import {
  GetNotificationsByUserIdMeta,
  GetNotificationsByUserIdPayload,
} from 'types/store/GetNotificationsByUserIdState';
import { Store } from 'types/store/Store';

import { getNotificationsByUserIdActions } from '../actions';

function* getNotificationsByUserIdRequest(
  action: AsyncAction<
    GetNotificationsByUserIdMeta,
    GetNotificationsByUserIdPayload
  >
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getNotificationsByUserId,
        action.meta,
        state.auth.token
      );
      yield put(getNotificationsByUserIdActions.success(data));
    } catch (e: any) {
      yield put(getNotificationsByUserIdActions.failed(e.message));
    }
  } else {
    yield put(getNotificationsByUserIdActions.failed('Token not found'));
  }
}

//   function* forgotPasswordSuccess(action: AsyncAction<ForgotPasswordMeta, ForgotPasswordPayload>) {
//     yield put(push(EMPLOYER_ROUTES.LOGIN));
//   }

function* getNotificationsByUserIdWatcher(): any {
  yield takeLatest(
    getNotificationsByUserIdActions.REQUEST,
    getNotificationsByUserIdRequest
  );
  // yield takeLatest(forgotPasswordActions.SUCCESS, forgotPasswordSuccess);
}

export default getNotificationsByUserIdWatcher;
