// src/hooks/useNavigationSetup.ts
import { useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import navigationService from 'utils/navigationService';

const useNavigationSetup = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigationService.setNavigate(navigate);
    navigationService.setLocation(location);
  }, [navigate, location]);
};

export default useNavigationSetup;
