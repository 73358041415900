import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getCompanyCalendlyLink } from 'services/appointment';
import { AsyncAction } from 'types/Action';
import { GetCompanyCalendlyLinkMeta, GetCompanyCalendlyLinkPayload } from 'types/store/GetCompanyCalendlyLink';
import { Store } from 'types/store/Store';

import { getCompanyCalendlyLinkActions } from '../actions';

function* getCompanyCalendlyLinkRequest(action: AsyncAction<GetCompanyCalendlyLinkMeta, GetCompanyCalendlyLinkPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(getCompanyCalendlyLink, state.auth.token);
      yield put(getCompanyCalendlyLinkActions.success(data));
    } catch (e: any) {
      yield put(getCompanyCalendlyLinkActions.failed(e.message));
    }
  }
}

// function* getCompanyCalendlyLinkSuccess(action: AsyncAction<GetCompanyCalendlyLinkMeta, GetCompanyCalendlyLinkPayload>): any {
//   yield put()
// }

function* getCompanyCalendlyLinkWatcher(): any {
  yield takeLatest(getCompanyCalendlyLinkActions.REQUEST, getCompanyCalendlyLinkRequest);
  // yield takeLatest(getCompanyCalendlyLinkActions.SUCCESS, getCompanyCalendlyLinkSuccess);
}

export default getCompanyCalendlyLinkWatcher;
