import { DraftAI } from 'types/store/StoryEditorV2';

export interface QuestionBlockProps {
  storyId: string;
  idx?: number;
  blockContent: {
    uid?: string;
    body?: string | any | any[];
    hidden?: boolean;
    title?: string;
    images?: string[];
    paraId?: string;
    data?: string;
    createAt?: string;
    total_count?: number;
    numberCurrentParagraphVersion?: number;
  };
  haveAddBtn?: boolean;
  listBlocks?: any[];
  handleAddNewSection?: (index: number) => void;
  handleRemoveSection?: (index: number, answer: string) => void;
  isHiddenRemove?: boolean;
  isSection?: boolean;
}

export interface IAdvocate {
  title: string;
}

export enum ActionParagraph {
  BACK = 'back',
  NEXT = 'next',
}

export enum StoryAction {
  CREATE = 'CREATE',
  OVERWRITE = 'OVERWRITE',
}
