import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getAdvocateInvites } from 'services/advocate';
import { AsyncAction } from 'types/Action';
import {
  GetAdvocateInvitesMeta,
  GetAdvocateInvitesPayload,
} from 'types/store/GetAdvocateInvites';
import { Store } from 'types/store/Store';

import { getAdvocateInvitesAction } from '../actions';

function* getAdvocateInvitesRequest(
  action: AsyncAction<GetAdvocateInvitesMeta, GetAdvocateInvitesPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getAdvocateInvites, action.meta, state.auth.token);
      yield put(getAdvocateInvitesAction.success(data));
    } catch (e: any) {
      yield put(getAdvocateInvitesAction.failed(e.message));
    }
  } else {
    yield put(getAdvocateInvitesAction.failed('Token not found'));
  }
}

function* getAdvocateInvitesSuccess(
  action: AsyncAction<GetAdvocateInvitesMeta, GetAdvocateInvitesPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getAdvocateInvitesWatcher(): any {
  yield takeLatest(
    getAdvocateInvitesAction.REQUEST,
    getAdvocateInvitesRequest
  );
  yield takeLatest(
    getAdvocateInvitesAction.SUCCESS,
    getAdvocateInvitesSuccess
  );
}

export default getAdvocateInvitesWatcher;
