import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getAllAdvocatesByCompany } from 'services/company';
import { AsyncAction } from 'types/Action';
import {
  GetAllAdvocatesMeta,
  GetAllAdvocatesPayload,
} from 'types/store/Company';
import { Store } from 'types/store/Store';

import { getAllAdvocateByCompanyActions } from '../actions';

function* getAllAdvocateByCompanyRequest(
  action: AsyncAction<GetAllAdvocatesMeta>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      console.log('action.meta', action.meta);

      const { data } = yield call(
        getAllAdvocatesByCompany,
        action.meta,
        state.auth.token,
      );
      yield put(getAllAdvocateByCompanyActions.success(data.data));
    } catch (e: any) {
      yield put(getAllAdvocateByCompanyActions.failed(e.message));
    }
  }
}

// function* companySuccess(action: AsyncAction<CompanyMeta, CompanyPayload>): any {
//   yield put()
// }

function* companyWatcher(): any {
  yield takeLatest(
    getAllAdvocateByCompanyActions.REQUEST,
    getAllAdvocateByCompanyRequest,
  );
  // yield takeLatest(companyActions.SUCCESS, companySuccess);
}

export default companyWatcher;
