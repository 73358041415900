import axios from 'axios';

import { API } from 'consts';
import { GetContentPackageDiscussionByIdAndEmailMeta } from 'types/store/GetContentPackageDiscussionByIdAndEmail';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const CONTENT_PACKAGE_DISCUSSION_URL = `${BASE_URL}/content-package-discussion`;

export const getContentPackageDiscussion = (data: GetContentPackageDiscussionByIdAndEmailMeta): any => {
    return axios({
        method: 'get',
        url: `${CONTENT_PACKAGE_DISCUSSION_URL}/by-content-package-and-email?contentPackageId=${data.contentPackageId}&email=${data.email}`,
    })
}