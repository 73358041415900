import { put, call, takeLatest, select } from 'redux-saga/effects';

import { ebGetStoriesEditor } from 'services/story-editor';
import { AsyncAction } from 'types/Action';
import { EbGetStoriesEditorMeta, EbGetStoriesEditorPayload } from 'types/store/EbGetStoriesEditor';
import { Store } from 'types/store/Store';

import { ebGetStoriesEditorActions } from '../actions';

function* ebGetStoriesEditorRequest(action: AsyncAction<EbGetStoriesEditorMeta, EbGetStoriesEditorPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(ebGetStoriesEditor, action.meta, state.auth.token);
      yield put(ebGetStoriesEditorActions.success(data));
    } catch (e: any) {
      yield put(ebGetStoriesEditorActions.failed(e.message));
    }
  }
}

// function* ebGetStoriesEditorSuccess(action: AsyncAction<EbGetStoriesEditorMeta, EbGetStoriesEditorPayload>): any {
//   yield put()
// }

function* ebGetStoriesEditorWatcher(): any {
  yield takeLatest(ebGetStoriesEditorActions.REQUEST, ebGetStoriesEditorRequest);
  // yield takeLatest(ebGetStoriesEditorActions.SUCCESS, ebGetStoriesEditorSuccess);
}

export default ebGetStoriesEditorWatcher;
