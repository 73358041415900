import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getReviewedStories } from 'services/story';
import { ebGetStoriesEditor } from 'services/story-editor';
import { AsyncAction } from 'types/Action';
import {
  GetReviewedStoriesMeta,
  GetReviewedStoriesPayload,
} from 'types/store/GetReviewedStoriesState';
import { Store } from 'types/store/Store';

import { getReviewedStoriesActions } from '../actions';

function* getReviewedStoriesRequest(
  action: AsyncAction<GetReviewedStoriesMeta, GetReviewedStoriesPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(
        ebGetStoriesEditor,
        action.meta,
        state.auth.token
      );
      yield put(getReviewedStoriesActions.success(data));
    } catch (e: any) {
      yield put(getReviewedStoriesActions.failed(e.message));
    }
  } else {
    yield put(getReviewedStoriesActions.failed('Token not found'));
  }
}

function* getReviewedStoriesSuccess(
  action: AsyncAction<GetReviewedStoriesMeta, GetReviewedStoriesPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getReviewedStoriesWatcher(): any {
  yield takeLatest(
    getReviewedStoriesActions.REQUEST,
    getReviewedStoriesRequest
  );
  yield takeLatest(
    getReviewedStoriesActions.SUCCESS,
    getReviewedStoriesSuccess
  );
}

export default getReviewedStoriesWatcher;
