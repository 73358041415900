import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getRecipeDiscussionByEmail } from 'services/recipe-detail-discussion';
import { AsyncAction } from 'types/Action';
import { GetRecipeDiscussionByEmailMeta, GetRecipeDiscussionByEmailPayload } from 'types/store/GetRecipeDiscussionByEmail';
import { Store } from 'types/store/Store';

import { getRecipeDiscussionByEmailActions } from '../actions';

function* getRecipeDiscussionByEmailRequest(action: AsyncAction<GetRecipeDiscussionByEmailMeta, GetRecipeDiscussionByEmailPayload>): any {
  const state: Store = yield select();
  try {
    const { data } = yield call(getRecipeDiscussionByEmail, action.meta);
    yield put(getRecipeDiscussionByEmailActions.success(data));
  } catch (e: any) {
    yield put(getRecipeDiscussionByEmailActions.failed(e.message));
  }
}

// function* getRecipeDiscussionByEmailSuccess(action: AsyncAction<GetRecipeDiscussionByEmailMeta, GetRecipeDiscussionByEmailPayload>): any {
//   yield put()
// }

function* getRecipeDiscussionByEmailWatcher(): any {
  yield takeLatest(getRecipeDiscussionByEmailActions.REQUEST, getRecipeDiscussionByEmailRequest);
  // yield takeLatest(getRecipeDiscussionByEmailActions.SUCCESS, getRecipeDiscussionByEmailSuccess);
}

export default getRecipeDiscussionByEmailWatcher;
