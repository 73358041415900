import * as React from 'react';

import { useSelector } from 'react-redux';

import { Store } from 'types/store/Store';

interface ISSOAuthProps {}

/**
 * Get the auth object, and then we're using the
 * postMessage API to send the auth object to the parent window
 * @param props - ISSOAuthProps
 * @returns An empty component
 */
const SSOAuth: React.FunctionComponent<ISSOAuthProps> = (props) => {
  const authObj = useSelector((state: Store) => state.auth);
  React.useEffect(() => {
    /* It's sending the auth object to the parent window. Should allow for whitelist domains only */
    parent.postMessage(
      { source: process.env.REACT_APP_PUBLISH_URL, ...authObj },
      process.env.REACT_APP_STUDIO_DOMAIN || '',
    );
  }, [authObj]);

  return <></>;
};

export default SSOAuth;
