import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getEmployeProfileByAuth } from 'services/employee';
import { AsyncAction } from 'types/Action';
import {
  GetEmployeeProfileByAuthMeta,
  GetEmployeeProfileByAuthPayload,
} from 'types/store/GetEmployeeProfileByAuth';
import { Store } from 'types/store/Store';

import { getEmployeeProfileByAuthActions } from '../actions';

function* getEmployeeProfileByAuthRequest(
  action: AsyncAction<
    GetEmployeeProfileByAuthMeta,
    GetEmployeeProfileByAuthPayload
  >
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(
        getEmployeProfileByAuth,
        action.meta,
        state.auth.token
      );
      yield put(getEmployeeProfileByAuthActions.success(data));
    } catch (e: any) {
      yield put(getEmployeeProfileByAuthActions.failed(e.message));
    }
  } else {
    yield put(getEmployeeProfileByAuthActions.failed('Token not found'));
  }
}

function* getEmployeeProfileByAuthSuccess(
  action: AsyncAction<
    GetEmployeeProfileByAuthMeta,
    GetEmployeeProfileByAuthPayload
  >
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getEmployeeProfileByAuthWatcher(): any {
  yield takeLatest(
    getEmployeeProfileByAuthActions.REQUEST,
    getEmployeeProfileByAuthRequest
  );
  yield takeLatest(
    getEmployeeProfileByAuthActions.SUCCESS,
    getEmployeeProfileByAuthSuccess
  );
}

export default getEmployeeProfileByAuthWatcher;
