import React from 'react';

import { SVGProps } from './SVG.props';

const SortDataset = (props: SVGProps): React.JSX.Element => {
  const { width = 16, height = 16, fill = '#060548' } = props;
  const viewBox = `0 0 ${width} ${height}`;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.195262 0.528514C0.455612 0.268165 0.877722 0.268165 1.13807 0.528514L6 5.39044L10.8619 0.528514C11.1223 0.268165 11.5444 0.268165 11.8047 0.528514C12.0651 0.788864 12.0651 1.21097 11.8047 1.47132L6.4714 6.80466C6.21106 7.06501 5.78895 7.06501 5.5286 6.80466L0.195262 1.47132C-0.0650874 1.21097 -0.0650874 0.788864 0.195262 0.528514Z"
        fill="#0D729D"
      />
    </svg>
  );
};

export default SortDataset;
