import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getLinkedinConnectionStatus } from 'services/linkedin';
import { AsyncAction } from 'types/Action';
import {
  GetLinkedinConnectionStatusMeta,
  GetLinkedinConnectionStatusPayload,
} from 'types/store/GetLinkedinConnectionStatus';
import { Store } from 'types/store/Store';

import { getLinkedinConnectionStatusActions } from '../actions';

function* getLinkedinConnectionStatusRequest(
  action: AsyncAction<
    GetLinkedinConnectionStatusMeta,
    GetLinkedinConnectionStatusPayload
  >,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getLinkedinConnectionStatus,
        action.meta,
        state.auth.token,
      );
      yield put(getLinkedinConnectionStatusActions.success(data));
    } catch (e: any) {
      const dataFailed = {
        data: {
          status: 'DISCONNECTED',
        },
        status: 400,
        message: e.message,
      };
      yield put(getLinkedinConnectionStatusActions.success(dataFailed));
      // yield put(getLinkedinConnectionStatusActions.failed(e.message));
    }
  }
}

// function* getLinkedinConnectionStatusSuccess(action: AsyncAction<GetLinkedinConnectionStatusMeta, GetLinkedinConnectionStatusPayload>): any {
//   yield put()
// }

function* getLinkedinConnectionStatusWatcher(): any {
  yield takeLatest(
    getLinkedinConnectionStatusActions.REQUEST,
    getLinkedinConnectionStatusRequest,
  );
  // yield takeLatest(getLinkedinConnectionStatusActions.SUCCESS, getLinkedinConnectionStatusSuccess);
}

export default getLinkedinConnectionStatusWatcher;
