import { put, call, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { deleteLibrary } from 'services/library';
import { AsyncAction } from 'types/Action';
import {
  DeleteLibraryMeta,
  DeleteLibraryPayload,
} from 'types/store/DeleteLibraryState';
import { Store } from 'types/store/Store';

import { deleteLibraryActions } from '../actions';

function* deleteLibraryRequest(
  action: AsyncAction<DeleteLibraryMeta, DeleteLibraryPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const action_sucess = action.meta?.action_sucess;
      delete action.meta?.action_sucess;
      const { data } = yield call(deleteLibrary, action.meta, state.auth.token);
      action_sucess?.();
      yield put(deleteLibraryActions.success(data));
      toast.success(data.message || 'Library successfully deleted', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch (e: unknown) {
      const errorMessage =
        (e as AxiosError).response?.data.message || 'An error occurred';
      toast.error(
        errorMessage || 'Something went wrong, please try again later.',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        },
      );
      yield put(deleteLibraryActions.failed(errorMessage));
    }
  }
}

function* deleteLibrarySuccess(
  action: AsyncAction<DeleteLibraryMeta, DeleteLibraryPayload>,
) {
  // yield put(deleteLibraryActions.clear());
}

function* deleteLibraryWatcher(): any {
  yield takeLatest(deleteLibraryActions.REQUEST, deleteLibraryRequest);
  yield takeLatest(deleteLibraryActions.SUCCESS, deleteLibrarySuccess);
}

export default deleteLibraryWatcher;
