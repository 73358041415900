import { put, call, takeLatest, select } from 'redux-saga/effects';

import { MAIN_ROUTES } from 'consts';
import { updateRevisionApproval } from 'services/approvers';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateRevisionApprovalMeta,
  UpdateRevisionApprovalPayload,
} from 'types/store/UpdateRevisionApprovalState';
import navigationService from 'utils/navigationService';

import { updateRevisionApprovalActions } from '../actions';

function* updateRevisionApprovalRequest(
  action: AsyncAction<
    UpdateRevisionApprovalMeta,
    UpdateRevisionApprovalPayload
  >,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      console.log('meta', action.meta);
      const { data } = yield call(
        updateRevisionApproval,
        action.meta,
        state.auth.token,
      );
      yield put(updateRevisionApprovalActions.success(data));
    } catch (e: any) {
      if (e.response.status === 404) {
        yield call(navigationService.goTo, MAIN_ROUTES.NOT_FOUND);
      } else {
        yield put(updateRevisionApprovalActions.failed(e.message));
      }
    }
  }
}

// function* updateRevisionApprovalSuccess(
//   action: AsyncAction<UpdateRevisionApprovalMeta, UpdateRevisionApprovalPayload>
// ) {
//   //   yield put(push(EMPLOYER_ROUTES.STORY_EDITOR));
//   //   window.location.reload();
// }

function* updateRevisionApprovalWatcher(): any {
  yield takeLatest(
    updateRevisionApprovalActions.REQUEST,
    updateRevisionApprovalRequest,
  );
  // yield takeLatest(updateRevisionApprovalActions.SUCCESS, updateRevisionApprovalSuccess);
}

export default updateRevisionApprovalWatcher;
