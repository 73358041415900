import React from 'react';

import { SVGProps } from './SVG.props';

const Play = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill, opacity } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill={fill || 'none'}
      {...props}
    >
      <path
        d="M18.54 9L8.88 3.46a3.42 3.42 0 00-5.13 3v11.12a3.42 3.42 0 005.13 2.96L18.54 15a3.42 3.42 0 000-5.92V9z"
        fill={fill || '#fff'}
        fillOpacity={opacity || 1}
      />
    </svg>
  );
};

export default Play;
