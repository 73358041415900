import axios from 'axios';

import { API } from 'consts';
import { GetTopicsMeta } from 'types/store/GetTopics';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const INTEREST_URL = `${BASE_URL}/interest`;

//company_id is already embeded in the token
export const getTopics = (data: GetTopicsMeta, token: string): any => {
  return axios({
    method: 'get',
    url: `${INTEREST_URL}/getAdvocateInterests`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};
