import React from 'react';

import { SVGProps } from './SVG.props';

const TrashCan = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66667 0.667969C6.29848 0.667969 6 0.966446 6 1.33464V2.66797H2.66667C2.29848 2.66797 2 2.96645 2 3.33464C2 3.70283 2.29848 4.0013 2.66667 4.0013H13.3333C13.7015 4.0013 14 3.70283 14 3.33464C14 2.96645 13.7015 2.66797 13.3333 2.66797H10V1.33464C10 0.966446 9.70152 0.667969 9.33333 0.667969H6.66667ZM8.66667 2.66797H7.33333V2.0013H8.66667V2.66797Z"
        fill={fill || '#9D0D11'}
      />
      <path
        d="M6.66667 7.33464C7.03486 7.33464 7.33333 7.63311 7.33333 8.0013V12.0013C7.33333 12.3695 7.03486 12.668 6.66667 12.668C6.29848 12.668 6 12.3695 6 12.0013V8.0013C6 7.63311 6.29848 7.33464 6.66667 7.33464Z"
        fill={fill || '#9D0D11'}
      />
      <path
        d="M10 8.0013C10 7.63311 9.70152 7.33464 9.33333 7.33464C8.96514 7.33464 8.66667 7.63311 8.66667 8.0013V12.0013C8.66667 12.3695 8.96514 12.668 9.33333 12.668C9.70152 12.668 10 12.3695 10 12.0013V8.0013Z"
        fill={fill || '#9D0D11'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33333 4.66797C2.96514 4.66797 2.66667 4.96645 2.66667 5.33464V13.3346C2.66667 14.4392 3.5621 15.3346 4.66667 15.3346H11.3333C12.4379 15.3346 13.3333 14.4392 13.3333 13.3346V5.33464C13.3333 4.96645 13.0349 4.66797 12.6667 4.66797H3.33333ZM4 13.3346V6.0013H12V13.3346C12 13.7028 11.7015 14.0013 11.3333 14.0013H4.66667C4.29848 14.0013 4 13.7028 4 13.3346Z"
        fill={fill || '#9D0D11'}
      />
    </svg>
  );
};

export default TrashCan;
