import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getShowcaseCompany } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import {
  GetShowcaseCompanyMeta,
  GetShowcaseCompanyPayload,
} from 'types/store/GetShowcaseCompanyState';
import { Store } from 'types/store/Store';

import { getShowcaseCompanyActions } from '../actions';

function* getShowcaseCompanyRequest(
  action: AsyncAction<GetShowcaseCompanyMeta, GetShowcaseCompanyPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(
        getShowcaseCompany,
        action.meta,
        state.auth.token
      );
      yield put(getShowcaseCompanyActions.success(data));
    } catch (e: any) {
      yield put(getShowcaseCompanyActions.failed(e.message));
    }
  } else {
    yield put(getShowcaseCompanyActions.failed('Token not found'));
  }
}

function* getShowcaseCompanySuccess(
  action: AsyncAction<GetShowcaseCompanyMeta, GetShowcaseCompanyPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getShowcaseCompanyWatcher(): any {
  yield takeLatest(
    getShowcaseCompanyActions.REQUEST,
    getShowcaseCompanyRequest
  );
  yield takeLatest(
    getShowcaseCompanyActions.SUCCESS,
    getShowcaseCompanySuccess
  );
}

export default getShowcaseCompanyWatcher;
