import {
  DeleteRecipeDetailNoteMeta,
  DeleteRecipeDetailNotePayload,
} from 'types/store/ContentPlanner';
import { createAsyncAction } from 'utils/Redux';

const ns = 'DELETE_REPICE_DETAIL_NOTE';
const asyncAction = createAsyncAction<
  DeleteRecipeDetailNoteMeta,
  DeleteRecipeDetailNotePayload
>(ns);

const deleteRecipeDetailNoteActions = {
  ...asyncAction,
};

export default deleteRecipeDetailNoteActions;
