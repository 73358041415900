import React from 'react';

import { TouchableProps } from './Touchable.props';
import { TouchableBase } from './Touchable.style';

const Touchable = (props: TouchableProps): React.JSX.Element => {
  const { children, width, height, circle, dark } = props;
  return (
    <TouchableBase
      type="button"
      width={width}
      height={height}
      circle={circle}
      dark={dark}
      {...props}
    >
      {children}
    </TouchableBase>
  );
};

export default React.memo(Touchable);
