import { Action } from 'types/Action';
import {
  RepurposeContentParams,
  RepurposeData,
  UpdateRepurpose,
} from 'types/store/RepurposeContent';

import {
  CONTENT_REPURPOSING_ACTION,
  CREATE_REPURPOSE_STORY,
  GET_FILTER_ACTION,
  UPDATE_REPURPOSE_STORY,
} from './contentRepurposing.enum';

export const getListRepurposeStory = (
  payload: RepurposeContentParams,
): Action => ({
  type: CONTENT_REPURPOSING_ACTION.GET_LIST_CONTENT,
  payload,
});

export const getFilterAction = (): Action => ({
  type: GET_FILTER_ACTION.GET_FILTER,
  payload: {},
});

export const clearListRepurposeStory = (): Action => ({
  type: CONTENT_REPURPOSING_ACTION.GET_LIST_CONTENT_CLEAR,
  payload: {},
});

export const createRepurposeStory = (payload: RepurposeData): Action => ({
  type: CREATE_REPURPOSE_STORY.CREATE_REPURPOSE_STORY,
  payload,
});

export const clearCreateRepurposeStory = (): Action => ({
  type: CREATE_REPURPOSE_STORY.CREATE_REPURPOSE_STORY_CLEAR,
  payload: {},
});

export const updateRepurposeStory = (payload: UpdateRepurpose): Action => ({
  type: UPDATE_REPURPOSE_STORY.UPDATE_REPURPOSE_STORY,
  payload,
});
