import Axios from 'axios';

import { API } from 'consts';
const BASE_URL = `${API.URL}/${API.VERSION}`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
async function addUserProperties(authObj: any): Promise<any> {
  const getUserById = await Axios.get(`${BASE_URL}/advocate/getUserById`, {
    headers: { Authorization: `Bearer ${authObj.token}` },
  });
  const profile = getUserById?.data?.data?.profile;
  const session =
    authObj?.platform === 'EMPLOYER'
      ? 'EB'
      : authObj?.platform === 'RECRUITER'
      ? 'RE'
      : 'BA';

  const userInfo = {
    Email: profile?.email?.toLowerCase(),
    Name: `${profile?.firstName} ${profile?.lastName}`,
    Session: session,
    Company: profile?.company_name,
    Id: profile?.user_id?.split('-')[0],
    CompanyId: getUserById?.data?.data?.companyId,
  };

  if ((window as any).analytics) {
    (window as any).analytics.identify(userInfo.Id, {
      name: userInfo.Name,
      email: userInfo.Email,
      session: userInfo.Session,
      company: userInfo.Company,
    });

    if (userInfo.CompanyId) {
      (window as any).analytics.group(userInfo.CompanyId, {
        name: userInfo.Company,
        // Add any other company traits you want to track
      });
    }
  }
  return;
}

export default addUserProperties;
