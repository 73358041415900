import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';

import { Form, Formik } from 'formik';
import { ChromePicker } from 'react-color';
import { useSelector } from 'react-redux';

import Button from 'components/base/Button';
import DropDown from 'components/base/DropDown';
import { Questions, ExclamationFilled, ChevronLeft } from 'components/base/SVG';
import Close from 'components/base/SVG/Close';
import File from 'components/base/SVG/File';
import AddFile from 'components/module/AddFile';
import AddImage from 'components/module/AddImage';
import ConfirmDialogV2 from 'components/module/ConfirmDialogV2';
import RadioGroup from 'components/module/RadioGroup';
import { Store } from 'types/store/Store';
import { theme } from 'utils/Theme';

import styledDropdown from '../styledDropdown';
import {
  Container,
  Title,
  FormContainer,
  FieldContainer,
  TextField,
  Label,
  Textarea,
  DropdownContainer,
  AttachFileContainer,
  AllowFileText,
  SelectedFileContainer,
  SelectedFileName,
  Description,
  ActionButton,
  ButtonContainer,
  CloseButton,
  ErrorMessage,
  TooltipText,
  BorderedContainer,
  Tag,
  TagText,
} from '../WrittenForm/WrittenForm.style';
import MultiTextField from './MultiTextField';
import { VideoFormProps } from './VideoForm.props';
import {
  AddImageContainer,
  AcceptImageContainer,
  AcceptImageTitle,
  ColorPickerContainer,
  ColorBox,
  ColorName,
  Backdrop,
  ColorSelector,
  LabelContainer,
  SVGContainer,
  WideScreen,
  Square,
} from './VideoForm.style';
import { validate, validateSchema } from './VideoForm.validation';

const VideoLengthOptions = [
  { id: 0, label: '30-60 seconds', value: '30-60' },
  { id: 1, label: 'Up to 30 seconds', value: '<30' },
];

const VideoAspectOptions = [
  { value: '16:9', label: '16:9 widescreen' },
  { value: '1:1', label: '1:1 square' },
];

const BorderOptions = [
  { value: 0, label: 'No Border' },
  { value: 1, label: 'Border' },
];

const VideoForm = (props: VideoFormProps): React.JSX.Element => {
  const {
    onClose,
    storyId,
    handleUpsertContentMarketplace,
    contentMarketplace,
    pending,
    activeStoryDetails,
    advocatesInfo,
    answeredVideos,
  } = props;

  const storyEditorState = useSelector((state: Store) => state.storyEditor);
  const storyDetail = useSelector(
    (state: Store) => state.getStoryForEditor.data?.data?.story,
  );
  // const { activeStory } = storyEditorState;
  const storyTitle =
    storyDetail?.audience_research?.headline ??
    activeStoryDetails?.audience_research?.headline ??
    '';
  const typeOfStory = `${storyDetail?.type} ${storyDetail?.sub_type}`;
  const categoryName = activeStoryDetails?.category_name || '';

  const [file, setFile] = useState<File | string | null>('');
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [brandGuidelineAttachments, setBrandGuidelineAttachments] = useState<
    any[] | []
  >([]);
  const [logoAttachments, setLogoAttachments] = useState<any[] | []>([]);
  const [deleteMarketplaceAttachmentIds, setDeleteMarketplaceAttachmentIds] =
    useState<string[] | []>([]);
  const [deleteIntroSlideIds, setDeleteIntroSlideIds] = useState<string[] | []>(
    [],
  );
  const [isDisabled, setIsDisabled] = useState(false);
  const [direction, setDirection] = useState({
    horizontal: 'left',
    vertical: 'bottom',
  });
  const [openDialog, setOpenDialog] = useState(false);

  const childRef: React.RefObject<any> = useRef(null);
  const formRef = useRef<any>(null);

  const checkPosition = (coordinates: any) => {
    if (coordinates) {
      const { width, height, bottom, right } = coordinates;
      const passMiddleVerticalScreen =
        bottom - height / 2 > window.innerHeight / 2;
      const passMiddleHorizontalScreen =
        right - width / 2 > window.innerWidth / 2;
      const direction = {
        horizontal: passMiddleHorizontalScreen ? 'right' : 'left',
        vertical: passMiddleVerticalScreen ? 'top' : 'bottom',
      };
      setDirection(direction);
    }
  };

  const showTip = () => {
    if (childRef.current && childRef.current?.getBoundingClientRect) {
      checkPosition(childRef.current?.getBoundingClientRect());
    }
  };

  const handleDeleteIntroSlide = (id: string) => {
    setDeleteIntroSlideIds([...deleteIntroSlideIds, id]);
  };

  const formikProps = {
    initialValues: {
      id: '',
      topic: storyTitle,
      video_length: VideoLengthOptions[0],
      video_aspect: '16:9',
      border: true,
      border_color: '#000000',
      logo_url: '',
      brand_guideline: '',
      fonts: '',
      music: '',
      // intro_slide_question: [{ content: '', type: 'QUESTION' }],
      // intro_slide_name: [{ content: '', type: 'NAME' }],
      // intro_slide_job_title: [{ content: '', type: 'JOB_TITLE' }],
      video_questions: answeredVideos,
      additional_instructions: '',
    },
    validate: validate,
    validationSchema: validateSchema,
    onSubmit: (values: any) => {
      const videoQuestions = values.video_questions
        .filter((video: any, _: any) => video.isSelected)
        ?.map((video: any, _: any) => {
          return {
            question: video.questionTitle,
            videoLink: video.video_link,
            username: video.username,
            userrole: video.userrole,
          };
        });
      const marketplace_attachments: any[] = [
        ...brandGuidelineAttachments,
        ...logoAttachments,
      ];
      const payload = {
        storyId,
        marketplace: {
          id: values.id,
          topic: values.topic,
          video_form: {
            ...values,
            id: contentMarketplace?.video_form.id,
            video_length: values.video_length?.label || values.video_length,
            // intro_slides: [
            //   ...values.intro_slide_question,
            //   ...values.intro_slide_name,
            //   ...values.intro_slide_job_title
            // ],
            video_questions: values.video_questions.filter(
              (video: any, _: any) => video.isSelected,
            ),
          },
          marketplace_attachments,
          mail_items: [
            {
              isAdditional: false,
              title: 'Topic',
              value: values.topic,
            },
            {
              isAdditional: false,
              title: 'Video length',
              value: `${values.video_length?.label ?? values.video_length}`,
            },
            {
              isAdditional: false,
              title: 'Video Aspect',
              value:
                values.video_aspect === '16:9'
                  ? '16:9 widescreen'
                  : '1:1 square',
            },
            {
              isAdditional: false,
              title: 'Border',
              value: values.border ? 'Border' : 'No Border',
            },
            {
              isAdditional: true,
              isBordered: values.border,
              title: 'Color border',
              value: values.border ? values.border_color : '',
            },
            {
              isAdditional: false,
              isLogo: true,
              title: 'LOGOS',
              fileNames: logoAttachments,
            },
            {
              isAdditional: brandGuidelineAttachments.length > 0 ? false : true,
              title: 'Brand Guidelines',
              fileNames: brandGuidelineAttachments,
            },
            {
              isAdditional: false,
              title: 'FONTS',
              value: values.fonts,
            },
            {
              isAdditional: false,
              title: 'Music',
              value: values.music,
            },
            {
              isAdditional: false,
              isVideo: true,
              title: 'Videos',
              videoQuestions: [...videoQuestions],
            },
            {
              isAdditional: true,
              title: 'Additional instructions',
              value: values.additional_instructions,
            },
          ],
        },
        ...(values.id
          ? {
            deleteIntroSlideIds: deleteIntroSlideIds,
            deleteMarketplaceAttachmentIds: deleteMarketplaceAttachmentIds,
          }
          : {}),
      };
      handleUpsertContentMarketplace(payload);
    },
  };

  const handleRemovefile = (
    index: number,
    array: any[],
    callback: Dispatch<SetStateAction<[] | File[]>>,
  ) => {
    const newState = [...array];
    if (newState[index].id)
      setDeleteMarketplaceAttachmentIds([
        ...deleteMarketplaceAttachmentIds,
        newState[index].id,
      ]);
    newState.splice(index, 1);
    callback(newState);
    setIsDisabled(false);
  };

  useEffect(() => {
    setIsDisabled(true);
    return () => {
      if (VideoLengthOptions.length > 2) {
        VideoLengthOptions.shift();
      }
    };
  }, []);

  useEffect(() => {
    // const { activeStory } = storyEditorState;
    const storyTitle =
      storyDetail?.audience_research?.headline ??
      activeStoryDetails?.audience_research?.headline ??
      '';
    formRef.current.setFieldValue('topic', storyTitle);
    setTimeout(() => {
      setIsDisabled(true);
    }, 500);
  }, []);

  useEffect(() => {
    if (answeredVideos) {
      formRef.current.setFieldValue('video_questions', answeredVideos);
    }
  }, [answeredVideos]);

  useEffect(() => {
    if (contentMarketplace) {
      const values = {
        id: contentMarketplace?.id,
        topic: formRef?.current?.values?.topic ?? storyTitle,
        video_length: contentMarketplace?.video_form?.video_length,
        video_aspect: contentMarketplace?.video_form?.video_aspect,
        border: contentMarketplace?.video_form?.border,
        border_color: contentMarketplace?.video_form?.border_color,
        logo_url: contentMarketplace?.video_form?.logo_url,
        brand_guideline: contentMarketplace.video_form?.brand_guideline,
        fonts: contentMarketplace?.video_form?.fonts,
        music: contentMarketplace?.video_form?.music,
        video_questions: answeredVideos,
      };
      formRef.current.setValues(values);
      if (
        !VideoLengthOptions.find((_) =>
          _.label.includes(contentMarketplace.video_form.video_length),
        )
      ) {
        VideoLengthOptions.unshift({
          id: 0,
          label: contentMarketplace.video_form?.video_length,
          value: contentMarketplace.video_form?.video_length,
        });
      }
      setLogoAttachments(
        contentMarketplace.marketplace_attachments.filter(
          (_) => _.type === 'LOGO',
        ),
      );
      setBrandGuidelineAttachments(
        contentMarketplace?.marketplace_attachments?.filter(
          (_: any) => _.type === 'BRAND_GUIDELINES',
        ),
      );
      setTimeout(() => {
        setIsDisabled(true);
      }, 500);
    }
  }, [contentMarketplace]);

  const setShowMoreInfo = (currentItemIdx: number) => {
    const videoArr = formRef.current?.values?.video_questions?.map(
      (video: any, idx: number) => {
        if (idx === currentItemIdx) {
          return { ...video, isShowHide: !video.isShowHide };
        }
        return video;
      },
    );
    formRef.current?.setFieldValue('video_questions', videoArr);
  };

  const setSelectedVideo = (currentItemIdx: number) => {
    const videoArr = formRef.current?.values?.video_questions?.map(
      (video: any, idx: number) => {
        if (idx === currentItemIdx) {
          return { ...video, isSelected: !video.isSelected };
        }
        return video;
      },
    );
    formRef.current?.setFieldValue('video_questions', videoArr);
    setTimeout(() => {
      if (videoArr && videoArr.length > 0) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }, 500);
  };

  return (
    <Container>
      <Tag>
        <ChevronLeft fill={theme.brand.primary} width={8} height={7} />
        <TagText altVariant="overlineSmall">
          {categoryName}, {typeOfStory}
        </TagText>
      </Tag>
      <Title variant="title5" color="primary">
        {`"${formRef?.current?.values?.topic ?? storyTitle}"`}
      </Title>
      <Title variant="title5" color="shade7">
        Brief
      </Title>
      <FormContainer>
        <Formik {...formikProps} innerRef={formRef}>
          {(formikProperties: any) => {
            const { values } = formikProperties;
            useEffect(() => {
              if (isDisabled) setIsDisabled(false);
            }, [values]);
            return (
              <Form>
                <FieldContainer>
                  <Label variant="caption" color="primary">
                    TOPIC
                  </Label>
                  <Textarea
                    name="topic"
                    rows={2}
                    placeholder="How an employee can uphold their mental wellbeing during remote working"
                  />
                </FieldContainer>
                <FieldContainer>
                  <Label variant="caption" color="primary">
                    Video Length
                  </Label>
                  <DropdownContainer>
                    <DropDown
                      value={
                        VideoLengthOptions.find((_) =>
                          _.label.includes(
                            formikProperties.values?.video_length,
                          ),
                        ) || formikProperties.values?.video_length
                      }
                      styles={styledDropdown}
                      isCreatable
                      options={VideoLengthOptions}
                      onChange={(option) =>
                        formikProperties.setFieldValue('video_length', option)
                      }
                    />
                  </DropdownContainer>
                </FieldContainer>
                {/*  */}
                <FieldContainer>
                  <LabelContainer>
                    <Label variant="caption" color="primary">
                      Video Aspect
                    </Label>
                    <SVGContainer
                      horizontalPos={direction.horizontal}
                      verticalPos={direction.vertical}
                      onMouseEnter={showTip}
                      ref={childRef}
                    >
                      <Questions width={13.33} height={13.33} fill="#070550" />
                      <div className="popup">
                        <WideScreen />
                        <Square />
                      </div>
                    </SVGContainer>
                  </LabelContainer>
                  <RadioGroup
                    value={formikProperties.values?.video_aspect}
                    items={VideoAspectOptions}
                    onSelect={(value: any) =>
                      formikProperties.setFieldValue('video_aspect', value)
                    }
                    direction={'row'}
                  />
                </FieldContainer>
                {/*  */}
                <FieldContainer>
                  <Label variant="caption" color="primary">
                    Border
                  </Label>
                  <RadioGroup
                    value={formikProperties.values?.border ? 1 : 0}
                    items={BorderOptions}
                    onSelect={(value: any) =>
                      formikProperties.setFieldValue('border', value)
                    }
                    direction={'row'}
                  />
                </FieldContainer>
                {formikProperties.values?.border ? (
                  <FieldContainer>
                    <Label variant="caption" color="shade6">
                      Color border
                    </Label>
                    <ColorPickerContainer>
                      <ColorBox
                        bgColor={formikProperties.values?.border_color}
                        onClick={() => setOpenColorPicker(true)}
                      />
                      <ColorName>
                        {formikProperties.values?.border_color}
                      </ColorName>
                      {openColorPicker && (
                        <ColorSelector>
                          <Backdrop
                            isOpen={openColorPicker}
                            onClick={() => setOpenColorPicker(false)}
                          />
                          <ChromePicker
                            color={formikProperties.values?.border_color}
                            onChange={(color: any) =>
                              formikProperties.setFieldValue(
                                'border_color',
                                color?.hex,
                              )
                            }
                          />
                        </ColorSelector>
                      )}
                    </ColorPickerContainer>
                  </FieldContainer>
                ) : null}
                {/*  */}
                <FieldContainer>
                  <Label variant="caption" color="primary">
                    Logo
                  </Label>
                  <AddFile
                    file={null}
                    onSelectFile={(f) => {
                      if (f) {
                        setLogoAttachments((prev) => [
                          ...prev,
                          { name: f.name, url: f, type: 'LOGO' },
                        ]);
                        setIsDisabled(false);
                      }
                    }}
                    onRemoveFile={() => {
                      return;
                    }}
                    height={'40px'}
                    width="calc(100% - 48px)"
                  />
                  <Description>
                    Multiple image files attachment (SVG or PNG)
                  </Description>
                  {logoAttachments?.map((_, index) => (
                    <SelectedFileContainer key={index}>
                      <File />
                      <SelectedFileName>{_.name}</SelectedFileName>
                      <CloseButton
                        onClick={() =>
                          handleRemovefile(
                            index,
                            logoAttachments,
                            setLogoAttachments,
                          )
                        }
                      >
                        <Close width={5.34} height={5.34} fill="#070550" />
                      </CloseButton>
                    </SelectedFileContainer>
                  ))}
                  {!isDisabled && logoAttachments.length === 0 ? (
                    <ErrorMessage>*Required</ErrorMessage>
                  ) : null}
                </FieldContainer>
                {/*  */}
                <FieldContainer>
                  <Label variant="caption" color="primary">
                    Brand Guidelines
                  </Label>
                  <AddFile
                    file={null}
                    onSelectFile={(f) => {
                      if (f) {
                        setBrandGuidelineAttachments((prev) => [
                          ...prev,
                          { name: f.name, url: f, type: 'BRAND_GUIDELINES' },
                        ]);
                        setIsDisabled(false);
                      }
                    }}
                    onRemoveFile={() => console.log('removeFile')}
                    height={'40px'}
                    width="calc(100% - 48px)"
                    fileTypes=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  />
                  <Description>
                    (Client to send brand guideline documents including
                    information on how to use logo, brand colours and brand
                    fonts)
                  </Description>
                  {brandGuidelineAttachments?.map((_, index) => (
                    <SelectedFileContainer key={index}>
                      <File />
                      <SelectedFileName>{_.name}</SelectedFileName>
                      <CloseButton
                        onClick={() =>
                          handleRemovefile(
                            index,
                            brandGuidelineAttachments,
                            setBrandGuidelineAttachments,
                          )
                        }
                      >
                        <Close width={5.34} height={5.34} fill="#070550" />
                      </CloseButton>
                    </SelectedFileContainer>
                  ))}
                </FieldContainer>
                {/*  */}
                <FieldContainer>
                  <Label variant="caption" color="primary">
                    Fonts
                  </Label>
                  <TextField
                    name="fonts"
                    placeholder="Font file or download link"
                  />
                  <Description>
                    (Client to provide name of fonts - if using Google fonts for
                    example) or download link for font package -if hosted on
                    brand asset platform for example)
                  </Description>
                </FieldContainer>
                {/*  */}
                <FieldContainer>
                  <Label variant="caption" color="primary">
                    Music
                  </Label>
                  <TextField
                    name="music"
                    placeholder="(e.g. https://app.soundstripe.com)"
                  />
                </FieldContainer>
                {/*  */}
                {/* <FieldContainer>
                  <MultiTextField
                    title='Intro slide - Name'
                    data={formikProperties.values.intro_slide_name}
                    name='intro_slide_name'
                    type='NAME'
                    handleDeleteIntroSlide={handleDeleteIntroSlide}
                  />
                </FieldContainer> */}
                {/*  */}
                {/* <FieldContainer>
                  <MultiTextField
                    title='Intro slide - Job Title'
                    data={formikProperties.values.intro_slide_job_title}
                    name='intro_slide_job_title'
                    type='JOB_TITLE'
                    handleDeleteIntroSlide={handleDeleteIntroSlide}
                  />
                </FieldContainer> */}
                {/*  */}
                {/* <FieldContainer>
                  <MultiTextField
                    title='Intro slide - Question'
                    data={formikProperties.values.intro_slide_question}
                    name='intro_slide_question'
                    type='QUESTION'
                    handleDeleteIntroSlide={handleDeleteIntroSlide}
                  />
                </FieldContainer> */}
                {/*  */}
                <FieldContainer>
                  <LabelContainer>
                    <Label variant="caption" color="primary">
                      VIDEOS
                    </Label>
                    <SVGContainer
                      horizontalPos={direction.horizontal}
                      verticalPos={direction.vertical}
                      onMouseEnter={showTip}
                      ref={childRef}
                    >
                      <ExclamationFilled
                        width={13.33}
                        height={13.33}
                        fill="#070550"
                      />
                      <div className="popup" style={{ width: '295px' }}>
                        <TooltipText altVariant="label">
                          These fields can be used for intro slide
                        </TooltipText>
                      </div>
                    </SVGContainer>
                  </LabelContainer>
                  {formikProperties.values.video_questions &&
                    formikProperties.values.video_questions.length > 0 && (
                      <BorderedContainer>
                        <MultiTextField
                          title=""
                          data={formikProperties.values.video_questions}
                          name="video_questions"
                          type="NAME"
                          handleDeleteIntroSlide={handleDeleteIntroSlide}
                          setSelected={setSelectedVideo}
                          setShowInfo={setShowMoreInfo}
                        />
                      </BorderedContainer>
                    )}
                  {!isDisabled &&
                    formikProperties.values?.video_questions?.filter(
                      (item: any, _: number) => item.isSelected,
                    ).length === 0 ? (
                    <ErrorMessage>*Required</ErrorMessage>
                  ) : null}
                </FieldContainer>
                <FieldContainer optional>
                  <Label variant="caption" color="primary">
                    Additional instructions
                  </Label>
                  <TextField
                    name="additional_instructions"
                    placeholder="Anything else we should know?"
                  />
                </FieldContainer>
                {/*  */}
                <ActionButton>
                  <ButtonContainer>
                    <Button
                      size="lg"
                      variant="outline"
                      type="button"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                  </ButtonContainer>
                  <ButtonContainer>
                    <Button
                      size="lg"
                      variant="primary"
                      type="button"
                      disabled={
                        isDisabled ||
                        !!pending ||
                        formikProperties.values?.video_questions?.filter(
                          (item: any, _: number) => item.isSelected,
                        ).length === 0
                      }
                      loading={!!pending}
                      onClick={() => setOpenDialog(true)}
                    >
                      Submit Now
                    </Button>
                  </ButtonContainer>
                </ActionButton>
              </Form>
            );
          }}
        </Formik>
      </FormContainer>
      <ConfirmDialogV2
        isOpen={openDialog}
        onClose={() => setOpenDialog(false)}
        onSubmit={() => {
          setOpenDialog(false);
          formRef.current?.submitForm();
        }}
        title="Confirmation"
        message="Are you sure you want to submit?"
      />
    </Container>
  );
};

export default React.memo(VideoForm);
