import { put, call, takeLatest, select } from 'redux-saga/effects';

import { addQuestionsForAdvocate } from 'services/story-editor';
import { AsyncAction } from 'types/Action';
import { AddQuestionsForAdvocateMeta, AddQuestionsForAdvocatePayload } from 'types/store/AddQuestionsForAdvocate';
import { Store } from 'types/store/Store';

import { addQuestionsForAdvocateActions } from '../actions';

function* addQuestionsForAdvocateRequest(action: AsyncAction<AddQuestionsForAdvocateMeta, AddQuestionsForAdvocatePayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(addQuestionsForAdvocate, action.meta, state.auth.token);
      yield put(addQuestionsForAdvocateActions.success(data));
    } catch (e: any) {
      yield put(addQuestionsForAdvocateActions.failed(e.message));
    }
  }
}

// function* addQuestionsForAdvocateSuccess(action: AsyncAction<AddQuestionsForAdvocateMeta, AddQuestionsForAdvocatePayload>): any {
//   yield put()
// }

function* addQuestionsForAdvocateWatcher(): any {
  yield takeLatest(addQuestionsForAdvocateActions.REQUEST, addQuestionsForAdvocateRequest);
  // yield takeLatest(addQuestionsForAdvocateActions.SUCCESS, addQuestionsForAdvocateSuccess);
}

export default addQuestionsForAdvocateWatcher;
