import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getAppbarInfo } from 'services/employee';
import { AsyncAction } from 'types/Action';
import {
  GetAppbarInfoMeta,
  GetAppbarInfoPayload,
} from 'types/store/GetAppbarInfoState';
import { Store } from 'types/store/Store';

import { getAppbarInfoActions } from '../actions';

function* getAppbarInfoRequest(
  action: AsyncAction<GetAppbarInfoMeta, GetAppbarInfoPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(getAppbarInfo, action.meta, state.auth.token);
      yield put(getAppbarInfoActions.success(data));
    } catch (e: any) {
      yield put(getAppbarInfoActions.failed(e.message));
    }
  } else {
    yield put(getAppbarInfoActions.failed('Token not found'));
  }
}

function* getAppbarInfoSuccess(
  action: AsyncAction<GetAppbarInfoMeta, GetAppbarInfoPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getAppbarInfoWatcher(): any {
  yield takeLatest(getAppbarInfoActions.REQUEST, getAppbarInfoRequest);
  yield takeLatest(getAppbarInfoActions.SUCCESS, getAppbarInfoSuccess);
}

export default getAppbarInfoWatcher;
