import { takeLatest, select, call, put } from 'redux-saga/effects';

import { getListLanguage, translateLanguage } from 'services/settings';
import { LANGUAGE, TRANSLATE } from 'store/actions/getListLanguage';
import storyEditorActions from 'store/actions/storyHub.action';
import { Action } from 'types/Action';
import { Store } from 'types/store/Store';

function* watchLanguageRequest(action: Action) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getListLanguage, state.auth.token);
      yield put({
        type: LANGUAGE.GET_LANGUAGE_SUCCESS,
        payload: data.data
      })
    } catch (e: any) {
      console.error(e);
    }
  }
}

function* watchLanguageTranslate(action: Action) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(translateLanguage, action.payload, state.auth.token);
      yield put(storyEditorActions.success(data.data.TranslatedText))
    } catch (e: any) {
      console.error(e);
    }
  }
}

function* watcherLanguage(): any {
  yield takeLatest(LANGUAGE.GET_LANGUAGE_ACTION, watchLanguageRequest);
  yield takeLatest(TRANSLATE.TRANSLATE_LANGUAGE_REQUEST, watchLanguageTranslate)
}

export default watcherLanguage;
