import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getShowcaseFeedbacksByUrl } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import {
  GetShowcaseFeedbacksByUrlMeta,
  GetShowcaseFeedbacksByUrlPayload,
} from 'types/store/GetShowcaseFeedbacksByUrlState';
import { Store } from 'types/store/Store';

import { getShowcaseFeedbacksByUrlActions } from '../actions';

function* getShowcaseFeedbacksByUrlRequest(
  action: AsyncAction<
    GetShowcaseFeedbacksByUrlMeta,
    GetShowcaseFeedbacksByUrlPayload
  >
) {
  const state: Store = yield select();
  try {
    const { data } = yield call(getShowcaseFeedbacksByUrl, action.meta);
    yield put(getShowcaseFeedbacksByUrlActions.success(data));
  } catch (e: any) {
    yield put(getShowcaseFeedbacksByUrlActions.failed(e.message));
  }
}

function* getShowcaseFeedbacksByUrlSuccess(
  action: AsyncAction<
    GetShowcaseFeedbacksByUrlMeta,
    GetShowcaseFeedbacksByUrlPayload
  >
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getShowcaseFeedbacksByUrlWatcher(): any {
  yield takeLatest(
    getShowcaseFeedbacksByUrlActions.REQUEST,
    getShowcaseFeedbacksByUrlRequest
  );
  yield takeLatest(
    getShowcaseFeedbacksByUrlActions.SUCCESS,
    getShowcaseFeedbacksByUrlSuccess
  );
}

export default getShowcaseFeedbacksByUrlWatcher;
