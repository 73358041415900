import React from 'react';

import { SVGProps } from './SVG.props';

const Edit = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || 14}
      height={height || 14}
      viewBox={'0 0  14  14'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.33203 10.9996H5.1587C5.24644 11.0001 5.33341 10.9833 5.41464 10.9501C5.49586 10.917 5.56974 10.8681 5.63203 10.8063L10.2454 6.1863L12.1387 4.33297C12.2012 4.27099 12.2508 4.19726 12.2846 4.11602C12.3185 4.03478 12.3359 3.94764 12.3359 3.85963C12.3359 3.77163 12.3185 3.68449 12.2846 3.60325C12.2508 3.52201 12.2012 3.44828 12.1387 3.3863L9.31203 0.5263C9.25006 0.463815 9.17632 0.414219 9.09508 0.380373C9.01384 0.346527 8.92671 0.329102 8.8387 0.329102C8.75069 0.329102 8.66355 0.346527 8.58231 0.380373C8.50107 0.414219 8.42734 0.463815 8.36537 0.5263L6.48536 2.41297L1.8587 7.03297C1.79691 7.09526 1.74803 7.16914 1.71485 7.25036C1.68167 7.33159 1.66486 7.41856 1.66536 7.5063V10.333C1.66536 10.5098 1.7356 10.6793 1.86063 10.8044C1.98565 10.9294 2.15522 10.9996 2.33203 10.9996ZM8.8387 1.93963L10.7254 3.8263L9.7787 4.77297L7.89203 2.8863L8.8387 1.93963ZM2.9987 7.77963L6.95203 3.8263L8.8387 5.71297L4.88536 9.6663H2.9987V7.77963ZM12.9987 12.333H0.998698C0.821887 12.333 0.652318 12.4032 0.527293 12.5282C0.402269 12.6533 0.332031 12.8228 0.332031 12.9996C0.332031 13.1764 0.402269 13.346 0.527293 13.471C0.652318 13.5961 0.821887 13.6663 0.998698 13.6663H12.9987C13.1755 13.6663 13.3451 13.5961 13.4701 13.471C13.5951 13.346 13.6654 13.1764 13.6654 12.9996C13.6654 12.8228 13.5951 12.6533 13.4701 12.5282C13.3451 12.4032 13.1755 12.333 12.9987 12.333Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Edit;
