export const APP_URL = {
  GOOGLE_PLAY:
    process.env.REACT_APP_GOOGLE_PLAY_URL || 'https://play.google.com',
  APP_STORE: process.env.REACT_APP_APP_STORE_URL || 'https://www.apple.com',
};

export const API_GOOGLE_FONTS_URL =
  process.env.REACT_APP_API_GOOGLE_FONTS_URL ||
  'https://www.googleapis.com/css2?family=';

export {
  MAIN_ROUTES,
  EMPLOYER_ROUTES,
  ADVOCATE_ROUTES,
  COMPANY_ROUTES,
} from './routes';

export const isProd = process.env.REACT_APP_ENV === 'production';

export const API = {
  URL: process.env.REACT_APP_API_URL || 'http://localhost:3000',
  VERSION: process.env.REACT_APP_API_VERSION || 'v1',
};

export const PUBLISH_LINK_URL =
  process.env.REACT_APP_PUBLISH_URL || 'http://localhost:3000';

//Open app
export const DEEP_LINK_URL =
  process.env.DEEP_LINK_URL || 'themartec://localhost:3000';

// Integration
export const MS_CLOUD_INSTANCE = 'https://login.microsoftonline.com/';
