import React, { useEffect, useState } from 'react';

import ReactDOM from 'react-dom';

import {
  CheckFilled,
  Close,
  Info,
  QuestionFilled,
  ExclamationFilled,
  CloseFilled,
} from 'components/base/SVG';
import { useTheme } from 'utils/Theme';

import { SnackbarProps } from './Snackbar.props';
import {
  Container,
  Content,
  BorderLeftContainer,
  IconContainer,
  MessageContainer,
  Message,
  CloseContainer,
  DetailMessage,
} from './Snackbar.style';

const bodyTarget = document.querySelector('body') as HTMLBodyElement;

const Snackbar = (props: SnackbarProps) => {
  const {
    open,
    variant,
    message,
    onClose,
    autoHideDuration = 10000,
    detailMessage,
    style,
  } = props;
  const theme = useTheme();
  const [closeTimeout, setCloseTimeout] = useState<any>();

  useEffect(() => {
    if (open) {
      closeTimeout && clearTimeout(closeTimeout);
      const handleClose = setTimeout(() => {
        onClose && onClose();
      }, autoHideDuration);
      setCloseTimeout(handleClose);
    }
    return () => {
      closeTimeout && clearTimeout(closeTimeout);
      setCloseTimeout(undefined);
    };
  }, [open]);

  if (!open) {
    return null;
  }

  return ReactDOM.createPortal(
    <Container style={style}>
      <Content variant={variant}>
        <BorderLeftContainer variant={variant} />
        <IconContainer>
          {variant === 'error' && (
            <CloseFilled
              width={20}
              height={20}
              fill={theme.product[variant].primary}
            />
          )}
          {variant === 'success' && (
            <CheckFilled
              width={20}
              height={20}
              fill={theme.product[variant].primary}
            />
          )}
          {variant === 'warning' && (
            <ExclamationFilled
              width={20}
              height={20}
              fill={theme.product[variant].primary}
            />
          )}
          {variant === 'alert' && (
            <QuestionFilled
              width={20}
              height={20}
              fill={theme.product[variant].primary}
            />
          )}
          {variant === 'information' && (
            <ExclamationFilled
              width={20}
              height={20}
              fill={theme.product[variant].primary}
            />
          )}
        </IconContainer>
        <MessageContainer>
          <Message>{message || variant}</Message>
          {detailMessage && (
            <DetailMessage variant="caption" color="shade7">
              {detailMessage}
            </DetailMessage>
          )}
        </MessageContainer>
        <CloseContainer onClick={onClose}>
          <Close width={10} height={10} />
        </CloseContainer>
      </Content>
    </Container>,
    bodyTarget,
  );
};

export default React.memo(Snackbar);
