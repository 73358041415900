import { put, call, takeLatest, select } from 'redux-saga/effects';

import { inviteToShareStory } from 'services/story';
import { AsyncAction } from 'types/Action';
import { InviteToShareStoryMeta, InviteToShareStoryPayload } from 'types/store/InviteToShareStory';
import { Store } from 'types/store/Store';

import { inviteToShareStoryActions } from '../actions';

function* inviteToShareStoryRequest(action: AsyncAction<InviteToShareStoryMeta, InviteToShareStoryPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(inviteToShareStory, action.meta, state.auth.token);
      yield put(inviteToShareStoryActions.success(data));
      action.meta.callback && action.meta.callback();
    } catch (e: any) {
      yield put(inviteToShareStoryActions.failed(e.message));
    }
  }
}

// function* inviteToShareStorySuccess(action: AsyncAction<InviteToShareStoryMeta, InviteToShareStoryPayload>): any {
//   yield put()
// }

function* inviteToShareStoryWatcher(): any {
  yield takeLatest(inviteToShareStoryActions.REQUEST, inviteToShareStoryRequest);
  // yield takeLatest(inviteToShareStoryActions.SUCCESS, inviteToShareStorySuccess);
}

export default inviteToShareStoryWatcher;
