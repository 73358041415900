import React from 'react';

import { SVGProps } from './SVG.props';

const Forward = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || '8'}
      height={height || '9'}
      viewBox="0 0 8 9"
      fill="none"
    >
      <g clipPath="url(#clip0_25134_48633)">
        <path
          d="M3.6465 2.66945V1.31641L2.17497 2.78794L0.995117 3.96779L2.19592 4.96851L3.6465 6.17744V4.85347C6.19316 4.66151 7.0854 6.13523 7.0854 6.13523C7.0854 5.21705 7.00974 4.26416 6.28789 3.54262C5.4488 2.70322 4.23362 2.64382 3.6465 2.66945Z"
          fill={fill || '#B5ADB0'}
        />
      </g>
      <defs>
        <clipPath id="clip0_25134_48633">
          <rect
            width="7.50304"
            height="7.50304"
            fill="white"
            transform="translate(0.208008 0.507812)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Forward;
