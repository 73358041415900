import { put, call, takeLatest, select } from 'redux-saga/effects';

import { createCustomQuestion } from 'services/question';
import { CREATE_ADD_CUSTOME_QUESTION } from 'store/actions/storyHub.action';
import { AsyncAction } from 'types/Action';
import {
  CreateCustomQuestionMeta,
  CreateCustomQuestionPayload,
} from 'types/store/CreateCustomQuestionState';
import { Store } from 'types/store/Store';

import { createCustomQuestionActions } from '../actions';

function* createCustomQuestionRequest(
  action: AsyncAction<CreateCustomQuestionMeta, CreateCustomQuestionPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const dataMeta = {
        question: action.meta.question.value,
        audience_research_id: action.meta.audience_research_id
      }
      const { data } = yield call(
        createCustomQuestion,
        dataMeta,
        state.auth.token
      );
      yield put(createCustomQuestionActions.success(data));
      if (action.meta.question.isMoreQuestion) {
        yield put({
          type: CREATE_ADD_CUSTOME_QUESTION.CREATE_ADD_CUSTOME_QUESTION_SUCCESS,
          payload: data
        })
      }
    } catch (e: any) {
      yield put(createCustomQuestionActions.failed(e.message));
    }
  }
}

function* createCustomQuestionSuccess(
  action: AsyncAction<CreateCustomQuestionMeta, CreateCustomQuestionPayload>
) {
  // yield put(createCustomQuestionActions.clear());
}

function* createCustomQuestionWatcher(): any {
  yield takeLatest(
    createCustomQuestionActions.REQUEST,
    createCustomQuestionRequest
  );
  yield takeLatest(
    createCustomQuestionActions.SUCCESS,
    createCustomQuestionSuccess
  );
}

export default createCustomQuestionWatcher;
