/* eslint-disable react/no-unknown-property */
import React from 'react';

import { SVGProps } from './SVG.props';

const IconCheckedFill = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill = '#0D729D' } = props;

  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.8047 5.5286C12.0651 5.78895 12.0651 6.21106 11.8047 6.4714L7.13807 11.1381C6.87772 11.3984 6.45561 11.3984 6.19526 11.1381L3.5286 8.47141C3.26825 8.21106 3.26825 7.78895 3.5286 7.5286C3.78894 7.26825 4.21106 7.26825 4.4714 7.5286L6.66667 9.72386L10.8619 5.5286C11.1223 5.26825 11.5444 5.26825 11.8047 5.5286Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconCheckedFill;
