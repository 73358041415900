import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getDataShowcasePageDetails } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import {
  GetDataShowcasePageDetailsMeta,
  GetDataShowcasePageDetailsPayload,
} from 'types/store/GetDataShowcasePageDetailsState';
import { Store } from 'types/store/Store';

import { getDataShowcasePageDetailsActions } from '../actions';

function* getDataShowcasePageDetailsRequest(
  action: AsyncAction<GetDataShowcasePageDetailsMeta, GetDataShowcasePageDetailsPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getDataShowcasePageDetails,
        action.meta,
        state.auth.token
      );
      yield put(getDataShowcasePageDetailsActions.success(data));
    } catch (e: any) {
      yield put(getDataShowcasePageDetailsActions.failed(e.message));
    }
  } else {
    yield put(getDataShowcasePageDetailsActions.failed('Token not found'));
  }
}

function* getDataShowcasePageDetailsSuccess(
  action: AsyncAction<GetDataShowcasePageDetailsMeta, GetDataShowcasePageDetailsPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getDataShowcasePageDetailsWatcher(): any {
  yield takeLatest(getDataShowcasePageDetailsActions.REQUEST, getDataShowcasePageDetailsRequest);
  yield takeLatest(getDataShowcasePageDetailsActions.SUCCESS, getDataShowcasePageDetailsSuccess);
}

export default getDataShowcasePageDetailsWatcher;
