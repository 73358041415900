import React from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ArrowRight } from 'components/base/SVG';
import Typography from 'components/base/Typography';
import { EMPLOYER_ROUTES, ADVOCATE_ROUTES } from 'consts';
import NotFound from 'res/img/NotFound.png';
import { Store } from 'types/store/Store';
import { PlatForm } from 'types/store/LoginState';
import { translations } from 'locales/i18n';

import { NotFoundGeneratedProps } from './NotFound.props';
import {
  Container,
  PageNotFound,
  Image,
  Content,
  GoHomeButton,
  PageNotFoundText,
} from './NotFound.style';
const NotFoundView = (props: NotFoundGeneratedProps): React.JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getPlatform = useSelector((state: Store) => state.auth.platform);

  const allPlatforms = useSelector(
    (state: Store) => state.getAllPlatform.data?.data,
  );

  const handleGoHomeButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    let targetUrl = '';
  
    if (getPlatform === PlatForm.RECRUITER) {
      targetUrl = allPlatforms?.recruiterJD
        ? EMPLOYER_ROUTES.HOMEPAGE_JD
        : EMPLOYER_ROUTES.HOMEPAGE;
    } else if (getPlatform === PlatForm.EMPLOYER) {
      targetUrl = allPlatforms?.renderHomePage
        ? EMPLOYER_ROUTES.HOMEPAGE
        : EMPLOYER_ROUTES.CONTENT_CALENDAR;
    } else if (getPlatform === PlatForm.ADVOCATE) {
      targetUrl = ADVOCATE_ROUTES.STORY_MANAGEMENT;
    } else {
      targetUrl = '/employer/login';
    }
  
    // Navigate and force a full page reload:
    window.location.href = targetUrl;
  };
  

  return (
    <Container>
      <Content>
        <Image src={NotFound} alt="brand image" loading="lazy" />
        <PageNotFound>{t(translations.WeLostThisPage)}</PageNotFound>
        <PageNotFoundText>
          {t(translations.PageNotFoundOrError)}
        </PageNotFoundText>
        <GoHomeButton onClick={handleGoHomeButtonClick}>
          <Typography>{t(translations.BackToHomepage)}</Typography>
          <ArrowRight fill="#060548" />
        </GoHomeButton>
      </Content>
    </Container>
  );
};

export default NotFoundView;
