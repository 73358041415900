import React from 'react';

import { useField } from 'formik';

import TextField from 'components/base/TextField';

import { FormikTextFieldProps } from './FormikTextField.props';

const FormikTextField = (props: FormikTextFieldProps): React.JSX.Element => {
  const {
    name,
    onFocus = () => null,
    errorString,
    noBorder,
    noRightBorder,
    RightComponentWidth,
    ...textFieldProps
  } = props;
  const [field, meta] = useField<string>(name);

  const onChange = (event: React.ChangeEvent<any>) => {
    if (!props.readOnly) {
      field.onChange(event);
      if (props.onChange) {
        props.onChange(event);
      }
    }
  };

  return (
    <TextField
      {...field}
      {...textFieldProps}
      onChange={onChange}
      onFocus={onFocus}
      id={name}
      error={errorString ?? (meta.touched ? meta.error : undefined)}
      noLeftCompBorder={noBorder}
      noRightCompBorder={noRightBorder}
      RightComponentWidth={RightComponentWidth}
      highlightError={props.highlightError}
      height={props.inputHeight}
      customRadius="6px"
    />
  );
};

export default React.memo(FormikTextField);
