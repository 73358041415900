import { put, call, takeLatest, select } from 'redux-saga/effects';

import { updateShowcaseUrl } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateShowcaseUrlMeta,
  UpdateShowcaseUrlPayload,
} from 'types/store/UpdateShowcaseUrlState';

import { updateShowcaseUrlActions } from '../actions';

function* updateShowcaseUrlRequest(
  action: AsyncAction<UpdateShowcaseUrlMeta, UpdateShowcaseUrlPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateShowcaseUrl,
        action.meta,
        state.auth.token
      );
      yield put(updateShowcaseUrlActions.success(data));
    } catch (e: any) {
      yield put(updateShowcaseUrlActions.failed(e.message));
    }
  }
}

function* updateShowcaseUrlSuccess(
  action: AsyncAction<UpdateShowcaseUrlMeta, UpdateShowcaseUrlPayload>
) {
  //
}

function* updateShowcaseUrlWatcher(): any {
  yield takeLatest(updateShowcaseUrlActions.REQUEST, updateShowcaseUrlRequest);
  yield takeLatest(updateShowcaseUrlActions.SUCCESS, updateShowcaseUrlSuccess);
}

export default updateShowcaseUrlWatcher;
