import { put, call, takeLatest } from 'redux-saga/effects';

import { generatePage } from 'services/page';
import { AsyncAction } from 'types/Action';
import {
  GeneratePageMeta,
  GeneratePagePayload,
} from 'types/store/GeneratePage';

import { generatePageActions } from '../actions';

function* generatePageRequest(
  action: AsyncAction<GeneratePageMeta, GeneratePagePayload>
) {
  try {
    const { data } = yield call(generatePage, {
      url: action.meta.url,
    });
    yield put(generatePageActions.success(data));
  } catch (e: any) {
    // yield put(forgotPasswordActions.failed(e.message));
  }
}

//   function* forgotPasswordSuccess(action: AsyncAction<ForgotPasswordMeta, ForgotPasswordPayload>) {
//     yield put(push(EMPLOYER_ROUTES.LOGIN));
//   }

function* generatePageWatcher(): any {
  yield takeLatest(generatePageActions.REQUEST, generatePageRequest);
  // yield takeLatest(forgotPasswordActions.SUCCESS, forgotPasswordSuccess);
}

export default generatePageWatcher;
