import axios, { AxiosPromise } from 'axios';

import { GenericResponse } from 'types/GenericResponse';
import { API } from 'consts';

const BASE_URL = `${API.URL}/${API.VERSION}`;

export const getWorkspaces = (
  widgetId: string,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/workspace`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      widgetId,
    },
  });
};

export const updateWorkspace = (
  data: { [field: string]: string },
  company_id: string,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'PUT',
    url: `${BASE_URL}/workspace/${company_id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const getWorkspaceTag = (token: string): Promise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/workspace/getTags`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.data);
};

export const createWorkspaceTag = (
  data: { name: string }[],
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/workspace/createTags`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      tags: data,
    },
  });
};
