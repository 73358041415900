import { put, call, takeLatest, select } from 'redux-saga/effects';

import { uploadVideoNewFlow } from 'services/question';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UploadAnswerVideoMeta,
  UploadAnswerVideoPayload,
} from 'types/store/UploadAnswerVideo';

import { uploadAnswerVideoActions } from '../../actions';

function* uploadRevisedVideoRequest(
  action: AsyncAction<UploadAnswerVideoMeta, UploadAnswerVideoPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        uploadVideoNewFlow,
        action.meta,
        state.auth.token,
      );
      yield put(uploadAnswerVideoActions.success(data));
    } catch (e: any) {
      yield put(uploadAnswerVideoActions.failed(e.message));
    }
  }
}

function* uploadAnswerVideoWatcher(): any {
  yield takeLatest(uploadAnswerVideoActions.REQUEST, uploadRevisedVideoRequest);
}

export default uploadAnswerVideoWatcher;
