import { put, call, takeLatest, select, all } from 'redux-saga/effects';

import { Action } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  CONTENT_REPURPOSING_ACTION,
  CREATE_REPURPOSE_STORY,
  GET_FILTER_ACTION,
  UPDATE_REPURPOSE_STORY,
} from 'store/actions/contentRepurposing/contentRepurposing.enum';
import {
  createRepurpose,
  getListHeadline,
  getListQuestionGroup,
  getListRepurpose,
  getListStoryStatus,
  getListTags,
  updateRepurpose,
} from 'services/contentRepurposing';
import {
  Advocate,
  HeadlineDynamicFilter,
  RepurposeContentParams,
  RepurposeData,
  TagsDynamicFilter,
  UpdateRepurpose,
} from 'types/store/RepurposeContent';
import { getAdvocatesByCompanyId } from 'services/advocate';
import { AdvocateStatus } from 'types/Advocate';
import { storyOwner } from 'services/story-builder';
import { getStoryForEditorActions, openToast } from 'store/actions';
import { handleAxiosError } from 'utils/handleAxiosError';
import { getStoryForEditor } from 'services/story-editor';

function ignoreStory(storyQuestionData: any) {
  const ignoreStoryVideoIds: string[] = [];
  const ignoreUserAnswerIds: string[] = [];
  for (const i in storyQuestionData?.story_questions) {
    for (const j in storyQuestionData?.story_questions[i]
      .user_story_question_answers) {
      const resolveVideo = new Promise((resolve, reject) => {
        for (const k in storyQuestionData?.story_questions[i]
          ?.user_story_question_answers[j]?.story_video?.story_video_mappings) {
          const parent_id =
            storyQuestionData?.story_questions[i].user_story_question_answers[j]
              ?.story_video?.story_video_mappings[k].parent_id;

          ignoreStoryVideoIds.push(parent_id);
        }
        resolve('done');
      });

      const resolveWritten = new Promise((resolve, reject) => {
        for (const k in storyQuestionData?.story_questions[i]
          ?.user_story_question_answers[j]?.user_answer_mappings) {
          const parent_id =
            storyQuestionData?.story_questions[i]?.user_story_question_answers[
              j
            ]?.user_answer_mappings[k]?.parent_id;
          ignoreUserAnswerIds.push(parent_id);
        }
        resolve('done');
      });
      Promise.all([resolveVideo, resolveWritten]);
    }
  }
  return { ignoreStoryVideoIds, ignoreUserAnswerIds };
}

function* getListRepurposeRequest(action: Action<RepurposeContentParams>) {
  const state: Store = yield select();
  if (state.getStoryForEditor.data?.data.story) {
    const { ignoreStoryVideoIds, ignoreUserAnswerIds } = yield call(
      ignoreStory,
      state.getStoryForEditor.data?.data.story,
    );
    action.payload.ignoreStoryVideoIds = ignoreStoryVideoIds;
    action.payload.ignoreUserAnswerIds = ignoreUserAnswerIds;
  } else {
    const { data } = yield call(
      getStoryForEditor,
      { storyId: action.payload.storyId || '' },
      state.auth.token || '',
    );
    const { ignoreStoryVideoIds, ignoreUserAnswerIds } = yield call(
      ignoreStory,
      data.data.story,
    );
    action.payload.ignoreStoryVideoIds = ignoreStoryVideoIds;
    action.payload.ignoreUserAnswerIds = ignoreUserAnswerIds;
    yield put(getStoryForEditorActions.success(data));
  }

  if (state.auth.token) {
    try {
      const groupQuestion = action.payload?.groupQuestion;
      delete action.payload?.groupQuestion;
      delete action.payload.isEdit;
      delete action.payload.storyId;
      if (groupQuestion) {
        const { data } = yield call(
          getListQuestionGroup,
          action.payload,
          state.auth.token,
        );
        yield put({
          type: CONTENT_REPURPOSING_ACTION.GET_LIST_CONTENT_SUCCESS,
          payload: {
            rows: data.data.rows,
            totalPages: data.data.totalPages,
            page: action.payload.page,
          },
        });
      } else {
        const { data } = yield call(
          getListRepurpose,
          action.payload,
          state.auth.token,
        );
        yield put({
          type: CONTENT_REPURPOSING_ACTION.GET_LIST_CONTENT_SUCCESS,
          payload: {
            rows: data.data.rows,
            totalPages: data.data.totalPages,
            page: action.payload.page,
          },
        });
      }
    } catch (e: unknown) {
      yield put({
        type: CONTENT_REPURPOSING_ACTION.GET_LIST_CONTENT_FAILURE,
      });
    }
  }
}

function* getListStatusFilter(token: string): any {
  try {
    const { data } = yield call(getListStoryStatus, token);
    return data.data.map((item: string) => ({
      label: item,
      value: item,
    }));
  } catch (e: unknown) {
    yield put({
      type: CONTENT_REPURPOSING_ACTION.GET_LIST_CONTENT_FAILURE,
    });
  }
}

function* getListHeadlineFilter(token: string) {
  try {
    const { data } = yield call(getListHeadline, token);
    return data.data.map((item: HeadlineDynamicFilter) => ({
      label: item.headline,
      value: item.id,
    }));
  } catch (e: unknown) {
    yield put({
      type: CONTENT_REPURPOSING_ACTION.GET_LIST_CONTENT_FAILURE,
    });
  }
}

function* getListTagsFilter(token: string) {
  try {
    const { data } = yield call(getListTags, token);
    return data.data.map((item: TagsDynamicFilter) => ({
      value: item.id,
      label: item.name,
    }));
  } catch (e: unknown) {
    yield put({
      type: CONTENT_REPURPOSING_ACTION.GET_LIST_CONTENT_FAILURE,
    });
  }
}

function* getStoryOwnerRequest(token: string) {
  try {
    const { data } = yield call(storyOwner, token);
    return data.data.map((item: { name: string; id: string }) => ({
      label: item.name,
      value: item.id,
    }));
  } catch (e: unknown) {
    yield put({
      type: CONTENT_REPURPOSING_ACTION.GET_LIST_CONTENT_FAILURE,
    });
  }
}

function* getFilterDynamicRequest(action: Action): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const payload = yield all({
        status: call(getListStatusFilter, state.auth.token),
        headline: call(getListHeadlineFilter, state.auth.token),
        tags: call(getListTagsFilter, state.auth.token),
        storyOwner: call(getStoryOwnerRequest, state.auth.token),
      });
      yield put({
        type: GET_FILTER_ACTION.GET_FILTER_SUCCESS,
        payload,
      });
    } catch (e: unknown) {
      yield put({
        type: CONTENT_REPURPOSING_ACTION.GET_LIST_CONTENT_FAILURE,
      });
    }
  }
}

function* createRepurposeRequest(action: Action<RepurposeData>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        createRepurpose,
        action.payload,
        state.auth.token,
      );
      yield put({
        type: CREATE_REPURPOSE_STORY.CREATE_REPURPOSE_STORY_SUCCESS,
        payload: data.data,
      });
    } catch (e: unknown) {
      // const errorMessage = e as AxiosError;
      const errorMessage = handleAxiosError(e);
      yield put(
        openToast(
          errorMessage ??
            `There’s something wrong when creating new story. Please select other responses or try again.`,
          'error',
          'Failed to create new story',
        ),
      );
      yield put({
        type: CREATE_REPURPOSE_STORY.CREATE_REPURPOSE_STORY_FAILURE,
      });
    }
  }
}

function* updateRepurposeRequest(action: Action<UpdateRepurpose>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateRepurpose,
        action.payload,
        state.auth.token,
      );
      yield put({
        type: CREATE_REPURPOSE_STORY.CREATE_REPURPOSE_STORY_SUCCESS,
        payload: data.data,
      });
    } catch (e: unknown) {
      yield put({
        type: UPDATE_REPURPOSE_STORY.UPDATE_REPURPOSE_STORY_FAILURE,
      });
      const errorMessage = handleAxiosError(e);
      yield put(
        openToast(
          errorMessage ??
            `There’s something wrong when creating new story. Please select other responses or try again.`,
          'error',
          'Failed to add new story',
        ),
      );
      yield put({
        type: CREATE_REPURPOSE_STORY.CREATE_REPURPOSE_STORY_FAILURE,
      });
    }
  }
}

function* contentRepurposingWatcher(): any {
  yield takeLatest(
    CONTENT_REPURPOSING_ACTION.GET_LIST_CONTENT,
    getListRepurposeRequest,
  );
  yield takeLatest(GET_FILTER_ACTION.GET_FILTER, getFilterDynamicRequest);
  yield takeLatest(
    CREATE_REPURPOSE_STORY.CREATE_REPURPOSE_STORY,
    createRepurposeRequest,
  );
  yield takeLatest(
    UPDATE_REPURPOSE_STORY.UPDATE_REPURPOSE_STORY,
    updateRepurposeRequest,
  );
}

export default contentRepurposingWatcher;
