import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import {
  getStoryByIdActions,
  storyEditorActions,
  getStoryForEditorActions,
} from 'store/actions';
import { Item } from 'types/store/GetAllItems';
import { Store } from 'types/store/Store';

import { StoryEditorUpsellingProps } from './StoryEditorUpselling.props';
import StoryEditorUpsellingView from './StoryEditorUpselling.view';

const StoryEditorUpselling = (): React.JSX.Element => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const storyId = query.get('storyId');
  const navigate = useNavigate();

  const storyEditorState = useSelector((state: Store) => state.storyEditor);
  const activeStoryDetails = useSelector(
    (state: Store) => state.getStoryById.data?.data.story,
  );
  const storyDetail = useSelector(
    (state: Store) => state.getStoryForEditor.data?.data?.story,
  );
  const itemList = useSelector(
    (state: Store) => state.getAllItems.data?.data.items || undefined,
  );
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (!activeStoryDetails && storyId) {
      dispatch(
        getStoryByIdActions.request({
          story_id: storyId,
          only_status: [
            'ASSIGNED',
            'ACCEPTED',
            'REVIEW',
            'IN_PROGRESS',
            'IN_EDIT',
            'IN_APPROVAL',
            'READY_TO_PUBLISH',
            'PUBLISHED',
          ],
        }),
      );
    }
  }, [activeStoryDetails]);

  useEffect(() => {
    if (!storyDetail && storyId) {
      dispatch(getStoryForEditorActions.request({ storyId: storyId }));
    }
  }, [storyDetail]);

  useEffect(() => {
    return () => {
      dispatch(getStoryByIdActions.clear());
    };
  }, []);

  const { activeStory } = storyEditorState;
  const storyType = storyDetail?.sub_type;
  const typeOfStory = `${storyDetail?.type} ${storyDetail?.sub_type}`;
  const storyTitle = storyDetail?.headline || '';
  // const categoryName = '';
  let itemPrice = 0;

  if (itemList !== undefined) {
    if (storyType === 'WRITTEN' && storyDetail?.type === 'COMPANY') {
      itemPrice = itemList
        .filter((value: Item) => value.description === 'Company Articles')
        .map((value: Item) => value.price)[0];
    } else if (storyType === 'WRITTEN' && storyDetail?.type === 'INDIVIDUAL') {
      itemPrice = itemList
        .filter((value: Item) => value.description === 'Individual Articles')
        .map((value: Item) => value.price)[0];
    } else if (storyType === 'VIDEO') {
      itemPrice = itemList
        .filter((value: Item) => value.description === 'Multi-Question Videos')
        .map((value: Item) => value.price)[0];
    }
  }

  const updatePaidStatus = (value: boolean) => {
    const updatedStoryEditorState = { ...storyEditorState };
    if (updatedStoryEditorState.activeStory !== undefined) {
      updatedStoryEditorState.activeStory.paid = true;
    }
    updatedStoryEditorState.showUpselling = false; // close upselling
    dispatch(storyEditorActions.update(updatedStoryEditorState));
  };

  const gerenatedProps: StoryEditorUpsellingProps = {
    storyId: storyId ?? '',
    storyTitle,
    storyType: storyType,
    updatePaidStatus: updatePaidStatus,
    typeOfStory: typeOfStory,
    // categoryName: categoryName,
    categoryPrice: itemPrice,
    marketplaceId: storyDetail?.marketplace_id,
    activeStoryDetails,
    setView: goBack,
  };

  return <StoryEditorUpsellingView {...gerenatedProps} />;
};

export default StoryEditorUpselling;
