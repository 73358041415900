import React from 'react';

import { SVGProps } from './SVG.props';

const BarsFilter = (props: SVGProps): React.JSX.Element => {
  const { height, width, fill } = props;
  return (
    <svg
      width={24 || width}
      height={24 || height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 12C6 11.4477 6.44772 11 7 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H7C6.44772 13 6 12.5523 6 12Z"
        fill={fill || '#060548'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7C3 6.44772 3.44772 6 4 6H20C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8H4C3.44772 8 3 7.55228 3 7Z"
        fill={fill || '#060548'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 17C9 16.4477 9.44772 16 10 16H14C14.5523 16 15 16.4477 15 17C15 17.5523 14.5523 18 14 18H10C9.44772 18 9 17.5523 9 17Z"
        fill={fill || '#060548'}
      />
    </svg>
  );
};

export default BarsFilter;
