import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

export interface IAnalyticContentMetricsMeta {
  time: string;
  provider: string;
  communities: string[];
}

const sessionToken = 'SESSION_TOKEN';

const getSessionToken = createAsyncAction<
  null,
  GenericResponse
>(sessionToken);

export default getSessionToken;
