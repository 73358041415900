import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getCompanyCredits } from 'services/company';
import { AsyncAction } from 'types/Action';
import {
  GetCompanyCreditsMeta,
  GetCompanyCreditsPayload,
} from 'types/store/GetCompanyCredits';
import { Store } from 'types/store/Store';

import { getCompanyCreditsActions } from '../actions';

function* getCompanyCreditsRequest(
  action: AsyncAction<GetCompanyCreditsMeta, GetCompanyCreditsPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getCompanyCredits,
        action.meta,
        state.auth.token
      );
      yield put(getCompanyCreditsActions.success(data));
    } catch (e: any) {
      yield put(getCompanyCreditsActions.failed(e.message));
    }
  } else {
    yield put(getCompanyCreditsActions.failed('Token not found'));
  }
}

function* getCompanyCreditsSuccess(
  action: AsyncAction<GetCompanyCreditsMeta, GetCompanyCreditsPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getCompanyCreditsWatcher(): any {
  yield takeLatest(getCompanyCreditsActions.REQUEST, getCompanyCreditsRequest);
  yield takeLatest(getCompanyCreditsActions.SUCCESS, getCompanyCreditsSuccess);
}

export default getCompanyCreditsWatcher;
