export enum StoryStatus {
  IDEATION = 'IDEATION',
  DRAFT = 'DRAFT',
  ASSIGNED = 'ASSIGNED',
  ACCEPTED = 'ACCEPTED',
  IN_PROGRESS = 'IN_PROGRESS',
  REVIEW = 'REVIEW',
  IN_EDIT = 'IN_EDIT',
  IN_APPROVAL = 'IN_APPROVAL',
  TO_PUBLISH = 'TO_PUBLISH',
  READY_TO_PUBLISH = 'READY_TO_PUBLISH',
  PUBLISHED = 'PUBLISHED',
  DELETED = 'DELETED',
  DECLINED = 'DECLINED',
  ARCHIVED = 'ARCHIVED',
  APPROVED_WITH_CHANGES = 'APPROVED_WITH_CHANGES',
}

export enum ApprovalStatus {
  APPROVED = 'APPROVED',
}

export enum StoryApprovalVersionDetailStatus {
  WORKING = 'WORKING',
  DECLINED = 'DECLINED',
  APPROVED = 'APPROVED',
}

export enum StorySubType {
  IMAGE = 'IMAGE',
  WRITTEN = 'WRITTEN',
  VIDEO = 'VIDEO',
  ADVERT = 'ADVERT',
}

export enum FinalPieceStatus {
  pending = 'pending',
  skipped = 'skipped',
  working = 'working',
  declined = 'declined',
  approved = 'approved',
}

export enum AdvocateStoryClassifyArray {
  storiesToComplete = 0,
  submittedStories = 1,
  storiesToApprovers = 2,
  approvedStories = 3,
  storiesToPublish = 4,
  publishedStories = 5,
  videoStoriesToComplete = 6,
}

export type StoryFeedbackOption = {
  label: string;
  value: string;
};

export type StoryFeedback = {
  id: string;
  type: string;
  question: string;
  score?: string;
  comments?: string;
  startText?: string;
  endText?: string;
  options?: StoryFeedbackOption[];
  score_type?: 'FEEDBACK_SCORE' | 'CONTENT_SCORE';
};

export enum StoryBuilderType {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
}

export enum StoryCPType {
  REPURPOSED = 'REPURPOSED',
  ORIGINAL = 'ORIGINAL',
}
