import React from 'react';

import Select, {
  // eslint-disable-next-line import/named
  Props as SelectProps,
  components as defaultComponents,
} from 'react-select';
import { OptionProps } from 'react-select/dist/declarations/src/components/Option';
import CreatableSelect from 'react-select/creatable';

import styled from 'utils/styled';
// These SVG imports are just placeholders. Adjust to match your own imports.
import {
  DropdownArrow,
  Info,
  PlusCircle,
  CloseFilled,
} from 'components/base/SVG';
import ToolTip from 'components/base/ToolTip';
import Typography from 'components/base/Typography';
import theme from 'utils/Theme';

import {
  Container,
  LabelContainer,
  SelectWrapper,
  TipIcon,
  CreateLabel,
} from './DropDown.style';

// --------------------------------------------
// PROPS
// --------------------------------------------
export interface MenuList {
  value: string | number;
  label: string;
  shown?: boolean;
}

export interface DropDownProps extends SelectProps<any, boolean> {
  label?: string;
  showLabel?: boolean;
  tooltip?: string;
  isCreatable?: boolean;
  isFullWidth?: boolean;
  customMargin?: string;
  customLabel?: React.ReactNode;
  useCustomStyles?: boolean;
  placeholderCreatable?: string;
  createOptionPosition?: 'first' | 'last';
  isColorBlack?: boolean;
  preventCreateDuplicateValue?: boolean;
  marginLabel?: string;
}

// --------------------------------------------
// CUSTOM COMPONENTS
// --------------------------------------------
const DropdownIndicator = (props: any) => {
  return (
    <defaultComponents.DropdownIndicator {...props}>
      <DropdownArrow fill="#070550" />
    </defaultComponents.DropdownIndicator>
  );
};

const ClearIndicator = (props: any) => {
  return (
    <defaultComponents.ClearIndicator {...props}>
      <CloseFilled width={16.67} height={16.67} fill="#B5ADB0" />
    </defaultComponents.ClearIndicator>
  );
};

const Option = (props: OptionProps<any, false>) => {
  const styleOverride =
    props.data.select_disabled || props.data.isDisabled ? { opacity: 0.4 } : {};
  return (
    <defaultComponents.Option {...props}>
      <div style={styleOverride}>{props.data.label}</div>
    </defaultComponents.Option>
  );
};

// --------------------------------------------
// MAIN COMPONENT
// --------------------------------------------
const DropDown = (props: DropDownProps): React.JSX.Element => {
  const {
    label,
    showLabel = true,
    isCreatable,
    tooltip,
    isFullWidth,
    customMargin,
    customLabel,
    useCustomStyles = false,
    placeholderCreatable = 'Create',
    createOptionPosition = 'first',
    isColorBlack,
    preventCreateDuplicateValue = false,
    menuPlacement = 'auto',
    marginLabel = '4px',
    ...selectProps
  } = props;

  // A helper function if you want to check for duplicates
  const isDuplicateWithOptions = (value: string) => {
    return props.options?.some((option) => option.value === value) || !value;
  };

  // Pull out any props we do NOT want to spread again
  const {
    components: selectPropsComponents,
    placeholder: selectPropsPlaceholder,
    onChange: selectPropsOnChange,
    styles: selectPropsStyles,
    ...restSelectProps
  } = selectProps;

  return (
    <Container margin={customMargin}>
      {label && showLabel && (
        <LabelContainer marginLabel={marginLabel}>
          <Typography
            variant="overline"
            color={isColorBlack ? 'black' : 'shade6'}
          >
            {label}
          </Typography>
        </LabelContainer>
      )}

      {customLabel && customLabel}

      <SelectWrapper isFullWidth={isFullWidth}>
        {isCreatable ? (
          <CreatableSelect
            {...restSelectProps}
            menuPlacement={menuPlacement}
            // Merge your custom components with user-supplied components
            components={{
              ...selectPropsComponents,
              DropdownIndicator,
              ClearIndicator,
            }}
            // Fallback to your default placeholder if user didn’t supply one
            placeholder={selectPropsPlaceholder ?? 'Select or create'}
            onChange={(newValue, action) => {
              // If you need to intercept or transform the values, do it here
              if (selectPropsOnChange) {
                selectPropsOnChange(newValue, action);
              }
            }}
            styles={
              !useCustomStyles
                ? {
                    ...selectPropsStyles,
                    option: (provided: any, state) => ({
                      ...provided,
                      cursor: 'pointer',
                      color: theme.brand.primary,
                      padding: '10px 20px',
                      backgroundColor: state.isSelected
                        ? theme.brand.secondary
                        : state.isFocused
                        ? theme.grey.shade2
                        : theme.grey.noshade,
                    }),
                    menuList: (provided: any) => ({
                      ...provided,
                      marginBottom: 12,
                      borderBottom: '1px solid #F0ECEE',
                    }),
                  }
                : selectPropsStyles
            }
            createOptionPosition={createOptionPosition}
            formatCreateLabel={(inputValue) => {
              const inputValWithColon = inputValue ? `: ${inputValue}` : '';
              return !preventCreateDuplicateValue ||
                !isDuplicateWithOptions(inputValue) ? (
                <CreateLabel
                  active={inputValue !== ''}
                  onClick={(e: React.MouseEvent) => e.preventDefault()}
                >
                  <PlusCircle
                    width={16.67}
                    height={16.67}
                    fill={
                      inputValue !== ''
                        ? theme.brand.secondary
                        : theme.brand.primary
                    }
                  />
                  <Typography>
                    {placeholderCreatable}
                    {inputValWithColon}
                  </Typography>
                </CreateLabel>
              ) : (
                <> </>
              );
            }}
            onCreateOption={(inputValue) => {
              if (inputValue && selectPropsOnChange) {
                // Construct your new item
                const newOption = { value: null, label: inputValue };

                if (props.isMulti) {
                  // Multi-select scenario
                  const currentValueArray =
                    Array.isArray(props.value) && props.value.length > 0
                      ? [...props.value]
                      : [];
                  const updatedValue = [...currentValueArray, newOption];

                  selectPropsOnChange(updatedValue, {
                    action: 'create-option',
                    option: newOption,
                  });
                } else {
                  // Single-select scenario
                  selectPropsOnChange(newOption, {
                    action: 'create-option',
                    option: newOption,
                  });
                }
              }
            }}
          />
        ) : (
          <Select
            {...restSelectProps}
            menuPlacement={menuPlacement}
            components={{
              ...selectPropsComponents,
              DropdownIndicator,
              ClearIndicator,
              Option,
            }}
            placeholder={selectPropsPlaceholder ?? 'Select'}
            onChange={(newValue, action) => {
              // If you need to intercept or transform the values, do it here
              if (selectPropsOnChange) {
                selectPropsOnChange(newValue, action);
              }
            }}
            styles={
              !useCustomStyles
                ? {
                    ...selectPropsStyles,
                    option: (provided: any, state) => ({
                      ...provided,
                      cursor: 'pointer',
                      color: theme.brand.primary,
                      padding: '10px 20px',
                      backgroundColor: state.isSelected
                        ? theme.brand.secondary
                        : state.isFocused
                        ? theme.grey.shade2
                        : theme.grey.noshade,
                    }),
                    menuList: (provided: any) => ({
                      ...provided,
                      marginBottom: 12,
                      borderBottom: '1px solid #F0ECEE',
                    }),
                  }
                : selectPropsStyles
            }
          />
        )}

        {tooltip && (
          <TipIcon>
            <ToolTip arrow content={tooltip}>
              <Info width={24} height={24} />
            </ToolTip>
          </TipIcon>
        )}
      </SelectWrapper>
    </Container>
  );
};

export default React.memo(DropDown);
