import Button from 'components/base/Button';
import Play from 'components/base/SVG/Play';
import styled from 'utils/styled';

export const Container = styled.div``;

export const VideoContainer = styled.div<{
  widthCustom?: number;
  heightCustom?: number;
}>`
  position: relative;
  cursor: pointer;

  width: ${(props) => props.widthCustom || 160}px;
  height: ${(props) => props.heightCustom || 265}px;

  margin-right: 16px;

  &:hover .action_btn {
    opacity: 1;
  }

  .background-blur {
    z-index: 1;
    opacity: 0.8;
    background: #070632;
  }

  .zIndex-2 {
    z-index: 2;
    height: 99px;
  }
`;

export const VideoPlayer = styled.video`
  object-fit: cover;
  border-radius: 12px;
  height: inherit;
`;

export const PlayIcon = styled(Play)<{ playIconSize?: number }>`
  padding: ${(props) => props.playIconSize || 30}px;
  background-color: #070550;
  border-radius: 50%;

  position: absolute;
  // left: 50%;
  // top: 50%;
  // margin-top: -50px;
  // margin-left: -38px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const UploadFinalAction = styled(Button)`
  position: absolute;
  top: 6px;
  right: 10px;
  height: 24px;
  width: 24px;
  background: #ffffff;
  opacity: 0.85;
  border-radius: 4px;
  padding: 2px;
`;

export const SendBackAction = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
  height: 24px;
  width: 24px;
  background: #ffffff;
  opacity: 1;
  border-radius: 4px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  &:hover {
    cursor: pointer;
  }
`;

export const DownloadAction = styled(Button)`
  position: absolute;
  top: 38px;
  right: 10px;
  height: 24px;
  width: 24px;
  background: #ffffff;
  opacity: 0.85;
  border-radius: 4px;
  padding: 2px;
`;

export const ActionList = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 45px;
  opacity: 0;
`;

export const ActionListLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 45px;
  opacity: 0;
`;

export const EditAction = styled.div`
  position: absolute;
  top: 6px;
  left: 10px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  opacity: 0.85;
  border-radius: 4px;
  padding: 2px;
`;

export const ContainerAction = styled.div<{ top?: number; left?: number }>`
  position: absolute;
  top: ${({ top }) => top || 0}px;
  left: ${({ left }) => left || 0}px;
  min-width: 158px;
  width: 185px;
  padding: 8px 0;
  box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
  border-radius: 12px;
  background-color: #ffffff;
  justify-content: center;
  z-index: 9999;
`;

export const Action = styled.div`
  display: flex;
  margin-left: 25px;
`;

export const TextActionItem = styled.div`
  margin-top: 4px;
  margin-left: 8px;
`;

export const ActionItem = styled.div`
  color: #070632;
  font-size: 14px;
  width: 100%;
  text-align: center;
  padding: 6px 0px;
  color: #070632;
  font-weight: 400;
  &:hover {
    background-color: #faf7f8;
    cursor: pointer;
  }
`;
