import axios, { AxiosPromise } from 'axios';

import { API, API_GOOGLE_FONTS_URL } from 'consts';
import { GenericResponse } from 'types/GenericResponse';
import { DeleteShowcaseArticleMeta } from 'types/store/DeleteShowcaseArticleState';
import { DeleteShowcaseFeedbackMeta } from 'types/store/DeleteShowcaseFeedbackState';
import { DeleteShowcasePageMeta } from 'types/store/DeleteShowcasePageState';
import { GetOwnPageDetailsMeta } from 'types/store/getOwnPageDetailsState';
import { GetShowcaseArticlesByUrlMeta } from 'types/store/GetShowcaseArticlesByUrlState';
import { GetShowcaseArticlesMeta } from 'types/store/GetShowcaseArticlesState';
import { GetShowcaseCompanyByUrlMeta } from 'types/store/GetShowcaseCompanyByUrlState';
import { GetShowcaseCompanyMeta } from 'types/store/GetShowcaseCompanyState';
import { GetShowcaseFeedbacksByUrlMeta } from 'types/store/GetShowcaseFeedbacksByUrlState';
import { GetShowcaseFeedbacksMeta } from 'types/store/GetShowcaseFeedbacksState';
import { UpdateArticlePositionMeta } from 'types/store/UpdateArticlePosition';
import { UpdateArticlePositionStoryPageMeta } from 'types/store/UpdateArticlePositionStoryPage';
import { UpdateFeedbackPositionMeta } from 'types/store/UpdateFeedbackPosition';
import { UpdateShowcaseUrlMeta } from 'types/store/UpdateShowcaseUrlState';
import { UpsertShowcaseArticleImageMeta } from 'types/store/UpsertShowcaseArticleImageState';
import { UpsertShowcaseArticleMeta } from 'types/store/UpsertShowcaseArticleState';
import { UpsertShowcaseFeedbackImageMeta } from 'types/store/UpsertShowcaseFeedbackImageState';
import { UpsertShowcaseFeedbackMeta } from 'types/store/UpsertShowcaseFeedbackState';
import {
  UpsertShowcasePageMeta,
  UpsertShowcasePagePayload,
} from 'types/store/UpsertShowcasePageState';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const SHOWCASE_URL = `${BASE_URL}/showcase`;

export const upsertShowcaseArticle = (
  data: UpsertShowcaseArticleMeta,
  token: string
): any => {
  return axios({
    method: 'post',
    url: `${SHOWCASE_URL}/article`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const uploadShowcaseArticlePhoto = (
  data: UpsertShowcaseArticleImageMeta,
  token: string
): any => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };

  const fd = new FormData();
  fd.append('photo', data.image);
  fd.append('data', JSON.stringify(data));
  return axios.post(`${SHOWCASE_URL}/article_image`, fd, config);
};

export const getShowcaseArticles = (
  data: GetShowcaseArticlesMeta,
  token: string
): any => {
  return axios({
    method: 'get',
    url: `${SHOWCASE_URL}/getArticlesByCompanyId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getShowcaseArticlesByUrl = (
  data: GetShowcaseArticlesByUrlMeta
): any => {
  return axios({
    method: 'get',
    url: `${SHOWCASE_URL}/getArticlesByUrl/${data.url}`,
    data: data,
  });
};

export const getShowcaseFeedbacks = (
  data: GetShowcaseFeedbacksMeta,
  token: string
): any => {
  return axios({
    method: 'get',
    url: `${SHOWCASE_URL}/getFeedbacksByCompanyId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getShowcaseFeedbacksByUrl = (
  data: GetShowcaseFeedbacksByUrlMeta
): any => {
  return axios({
    method: 'get',
    url: `${SHOWCASE_URL}/getFeedbacksByUrl/${data.url}`,
    data: data,
  });
};

export const deleteShowcaseArticle = (
  data: DeleteShowcaseArticleMeta,
  token: string
): any => {
  return axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'delete',
    url: `${SHOWCASE_URL}/article`,
    data: data,
  });
};

export const deleteShowcaseFeedback = (
  data: DeleteShowcaseFeedbackMeta,
  token: string
): any => {
  return axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'delete',
    url: `${SHOWCASE_URL}/feedback`,
    data: data,
  });
};

export const upsertShowcaseFeedback = (
  data: UpsertShowcaseFeedbackMeta,
  token: string
): any => {
  return axios({
    method: 'post',
    url: `${SHOWCASE_URL}/feedback`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const uploadShowcaseFeedbackPhoto = (
  data: UpsertShowcaseFeedbackImageMeta,
  token: string
): any => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };

  const fd = new FormData();
  fd.append('photo', data.image);
  fd.append('data', JSON.stringify(data));
  return axios.post(`${SHOWCASE_URL}/feedback_image`, fd, config);
};

export const getShowcaseCompany = (
  data: GetShowcaseCompanyMeta,
  token: string
): any => {
  return axios({
    method: 'get',
    url: `${SHOWCASE_URL}/getPagesByShowcaseId/${data.id}?`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getOwnPageDetails = (
  data: GetOwnPageDetailsMeta,
  token: string
): any => {
  return axios({
    method: 'get',
    url: `${SHOWCASE_URL}/page/${data.page_id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const deleteShowcasePage = (
  data: DeleteShowcasePageMeta,
  token: string
): any => {
  return axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'delete',
    url: `${SHOWCASE_URL}/page`,
    data: data,
  });
};

///getShowcaseCompanyDetailsByUrl:/url
export const getShowcaseCompanyByUrl = (
  data: GetShowcaseCompanyByUrlMeta
): any => {
  return axios({
    method: 'get',
    url: `${SHOWCASE_URL}/getShowcaseCompanyDetailsByUrl/${data.url}`,
    data: data,
  });
};

export const updateShowcaseUrl = (
  data: UpdateShowcaseUrlMeta,
  token: string
): any => {
  return axios({
    method: 'post',
    url: `${SHOWCASE_URL}/updateUrl`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const updateFeedbackPosition = (
  data: UpdateFeedbackPositionMeta,
  token: string
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${SHOWCASE_URL}/updateFeedbackPosition`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const updateArticlePosition = (
  data: UpdateArticlePositionMeta,
  token: string
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${SHOWCASE_URL}/updateArticlePosition`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const updateStoryArticlesPosition = (
  data: UpdateArticlePositionStoryPageMeta,
  token: string
): any => {
  return axios({
    method: 'post',
    url: `${SHOWCASE_URL}/updateStoryArticlesPosition`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
}

export const upsertShowcasePage = (
  data: UpsertShowcasePageMeta,
  token: string
): any => {
  return axios({
    method: 'post',
    url: `${SHOWCASE_URL}/page`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const updatePagePosition = (data: any | null, token: string): any => {
  return axios({
    method: 'post',
    url: `${SHOWCASE_URL}/updatePagePosition`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const uploadCompanyLogo = (file: File, token: string): any => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };

  const fd = new FormData();
  fd.append('photo', file);
  return axios.post(
    `${SHOWCASE_URL}/page/showcasePageCompany_logo`,
    fd,
    config
  );
};

export const getShowcasePageDetails = (
  data: any | null,
  token: string
): any => {
  return axios({
    method: 'get',
    url: `${SHOWCASE_URL}/getPageItems/${data.page_id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getDataShowcasePublicHomepage = (data: any | null): any => {
  return axios({
    method: 'get',
    url: `${SHOWCASE_URL}/page/public/${data.companyUrl}`,
  });
};

export const getDataShowcasePublicPageDetails = (data: any | null): any => {
  return axios({
    method: 'get',
    url: `${SHOWCASE_URL}/page/public/${data.companyUrl}/${data.pageUrl}`,
  });
};

export const getDataShowcasePageDetails = (
  data: any | null,
  token: string
): any => {
  return axios({
    method: 'get',
    url: `${SHOWCASE_URL}/getArticlesByPageId/${data.page_id}`, // TODO: CHANGE THIS
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const upsertDataShowcasePageDetails = (
  data: any | null,
  token: string
): any => {
  return axios({
    method: 'post',
    url: `${SHOWCASE_URL}/page/public/${data.companyUrl}/${data.pageUrl}`, // TODO: CHANGE THIS
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const updateShowcaseArticleHighlight = (
  data: any | null,
  token: string
): any => {
  return axios({
    method: 'post',
    url: `${SHOWCASE_URL}/articles/update`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const updateShowcaseFeedbackHighlight = (
  data: any | null,
  token: string
): any => {
  return axios({
    method: 'post',
    url: `${SHOWCASE_URL}/feedbacks/update`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const updateShowcaseArticlePosition = (
  data: any | null,
  token: string
): any => {
  return axios({
    method: 'post',
    url: `${SHOWCASE_URL}/updateArticlePosition`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const updateShowcaseFeedbackPosition = (
  data: any | null,
  token: string
): any => {
  return axios({
    method: 'post',
    url: `${SHOWCASE_URL}/updateFeedbackPosition`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const checkUrl = (data: any | null, token: string): any => {
  return axios({
    method: 'post',
    url: `${SHOWCASE_URL}/checkUrl`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getListGoogleFonts = (data: any | null): any => {
  return axios({
    method: 'get',
    url: API_GOOGLE_FONTS_URL,
  });
};

// SHOWCASE V2 APIs REQUEST

export const getShowcasePagesByCompanyId = (token: string): any => {
  return axios({
    method: 'get',
    url: `${SHOWCASE_URL}/getShowcasesBycompanyId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createShowcase = (data: any | null, token: string): any => {
  return axios({
    method: 'post',
    url: `${SHOWCASE_URL}/upsertShowcase`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const publishShowcase = (data: any | null, token: string): any => {
  return axios({
    method: 'post',
    url: `${SHOWCASE_URL}/publishShowcase`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const deleteShowcase = (data: any | null, token: string): any => {
  return axios({
    method: 'delete',
    url: `${SHOWCASE_URL}/deleteShowcase`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};
