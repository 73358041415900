import axios, { AxiosPromise } from 'axios';

import { API } from 'consts';
import { IAnalyticAverageTimeMeta } from 'store/actions/analytics/averageTime';
import { IAnalyticBrandAdvocatesMeta } from 'store/actions/analytics/brandAdvocates';
import { IAnalyticContentMetricsMeta } from 'store/actions/analytics/contentMetrics';
import { IAnalyticContentPipelineMeta } from 'store/actions/analytics/contentPipeline';
import { IAnalyticContentCostMeta } from 'store/actions/analytics/ROI/contentCost';
import { IAnalyticEmployeeAdvocacyMeta } from 'store/actions/analytics/ROI/employeeAdvocacy';
import { IAnalyticSocialReachMeta } from 'store/actions/analytics/ROI/socialReach';
import { IAnalyticWebTrafficMeta } from 'store/actions/analytics/ROI/webTraffic';
import { GenericResponse } from 'types/GenericResponse';
import { GetAdvocateAnalyticsMeta } from 'types/store/GetAdvocateAnalytics';
import { GetStoriesAnalyticsMeta } from 'types/store/GetStoriesAnalytics';
import { UpdateTraffic } from 'types/store/GetWebTraffic';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const ANALYTICS_URL = `${BASE_URL}/analytics`;

export const getStoriesAnalytics = (
  data: GetStoriesAnalyticsMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${ANALYTICS_URL}/getStoriesAnalytics`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};
export const getAdvocateAnalytics = (
  data: GetAdvocateAnalyticsMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${ANALYTICS_URL}/getAdvocateAnalytics`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const fetchAverageTime = (
  data: IAnalyticAverageTimeMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${ANALYTICS_URL}/average-time`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const fetchContentPipeline = (
  data: IAnalyticContentPipelineMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${ANALYTICS_URL}/content-pipeline`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const fetchContentMetrics = (
  data: IAnalyticContentMetricsMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${ANALYTICS_URL}/content-metrics`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const fetchBrandAdvocates = (
  data: IAnalyticBrandAdvocatesMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${ANALYTICS_URL}/brand-advocates`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const fetchContentCost = (
  data: IAnalyticContentCostMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${ANALYTICS_URL}/roi-content-cost`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const fetchEmployeeAdvocacy = (
  data: IAnalyticEmployeeAdvocacyMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${ANALYTICS_URL}/roi-employee-advocacy`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const fetchSocialReach = (
  data: IAnalyticSocialReachMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${ANALYTICS_URL}/roi-social-reach`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const fetchWebTraffic = (
  data: IAnalyticWebTrafficMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${ANALYTICS_URL}/roi-website-traffic`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const exportCsv = (
  data: { time: string; communities: string[] },
  token: string,
): AxiosPromise<any> => {
  return axios({
    method: 'get',
    url: `${ANALYTICS_URL}/download-csv`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const createUrlWebTrafficCrawler = (
  data: string[],
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'POST',
    url: `${ANALYTICS_URL}/add-website-traffic-link`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const updateUrlWebTrafficCrawler = (
  data: UpdateTraffic[],
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'POST',
    url: `${ANALYTICS_URL}/edit-website-link`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getURLWebTrafficCrawler = (
  isUrlRoot: boolean,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${ANALYTICS_URL}/get-website-traffic-link`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: isUrlRoot && { isUrlRoot },
  });
};

export const deleteUrlWebTrafficCrawler = (
  data: string[],
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'POST',
    url: `${ANALYTICS_URL}/delete-website-link`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
