import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getAdvocatesByUserIds } from 'services/advocate';
import { AsyncAction } from 'types/Action';
import { GetAdvocatesByUserIdMeta, GetAdvocatesByUserIdPayload } from 'types/store/GetAdvocatesByUserId';
import { Store } from 'types/store/Store';

import { getAdvocatesByUserIdActions } from '../actions';

function* getAdvocatesByUserIdRequest(action: AsyncAction<GetAdvocatesByUserIdMeta, GetAdvocatesByUserIdPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(getAdvocatesByUserIds, action.meta, state.auth.token);
      yield put(getAdvocatesByUserIdActions.success(data));
    } catch (e: any) {
      yield put(getAdvocatesByUserIdActions.failed(e.message));
    }
  }
}

// function* getAdvocatesByUserIdSuccess(action: AsyncAction<GetAdvocatesByUserIdMeta, GetAdvocatesByUserIdPayload>): any {
//   yield put()
// }

function* getAdvocatesByUserIdWatcher(): any {
  yield takeLatest(getAdvocatesByUserIdActions.REQUEST, getAdvocatesByUserIdRequest);
  // yield takeLatest(getAdvocatesByUserIdActions.SUCCESS, getAdvocatesByUserIdSuccess);
}

export default getAdvocatesByUserIdWatcher;
