import axios from 'axios';

import { API } from 'consts';
import { GetContentMarketplaceMeta } from 'types/store/GetContentMarketplace';
import { UpdateContentMarketplaceMeta } from 'types/store/UpdateContentMarketplace';
import { UpsertContentMarketplaceMeta } from 'types/store/UpsertContentMarketplace';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const MARKETPLACE_URL = `${BASE_URL}/marketplace`;

export const getContentMarketplace = (
    data: GetContentMarketplaceMeta,
    token: string
): any => {
    return axios({
        method: 'get',
        url: `${MARKETPLACE_URL}/${data.marketplaceId}`,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}

export const upsertContentMarketplace = (
    data: UpsertContentMarketplaceMeta,
    token: string
): any => {
    return axios({
        method: 'post',
        url: `${MARKETPLACE_URL}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: data
    })
}

export const updateContentMarketplace = (
    data: UpdateContentMarketplaceMeta,
    token: string
): any => {
    return axios({
        method: 'put',
        url: `${MARKETPLACE_URL}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: data
    })
}