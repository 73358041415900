import React from 'react';

import { SVGProps } from './SVG.props';

const ChevronRight = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '4'}
      height={height || '7'}
      viewBox="0 0 6 8"
      fill={fill || 'none'}
    >
      <path
        d="M2.06012 7.33334L4.88679 4.47334C4.94927 4.41136 4.99887 4.33763 5.03271 4.25639C5.06656 4.17515 5.08398 4.08801 5.08398 4.00001C5.08398 3.912 5.06656 3.82486 5.03271 3.74362C4.99887 3.66238 4.94927 3.58865 4.88679 3.52667L2.06012 0.700006C1.99814 0.637521 1.92441 0.587924 1.84317 0.554079C1.76193 0.520233 1.67479 0.502807 1.58679 0.502807C1.49878 0.502807 1.41164 0.520233 1.3304 0.554079C1.24916 0.587924 1.17543 0.637521 1.11345 0.700006C0.989285 0.824915 0.91959 0.993882 0.91959 1.17001C0.91959 1.34613 0.989285 1.5151 1.11345 1.64001L3.47345 4.00001L1.11345 6.36001C0.990289 6.48418 0.920855 6.65178 0.920119 6.82667C0.919611 6.91441 0.936428 7.00139 0.969604 7.08261C1.00278 7.16384 1.05166 7.23771 1.11345 7.30001C1.1732 7.36472 1.24517 7.41696 1.32522 7.4537C1.40528 7.49043 1.49181 7.51095 1.57984 7.51405C1.66786 7.51715 1.75562 7.50277 1.83806 7.47175C1.9205 7.44074 1.99597 7.39369 2.06012 7.33334Z"
        fill={fill || '#E35D32'}
      />
    </svg>
  );
};

export default ChevronRight;
