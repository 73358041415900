import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getSuggestedQuestions } from 'services/question';
import { AsyncAction } from 'types/Action';
import {
  GetSuggestedQuestionsMeta,
  GetSuggestedQuestionsPayload,
} from 'types/store/GetSuggestedQuestions';
import { Store } from 'types/store/Store';

import { getSuggestedQuestionsActions } from '../actions';

function* getSuggestedQuestionsRequest(
  action: AsyncAction<GetSuggestedQuestionsMeta, GetSuggestedQuestionsPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getSuggestedQuestions,
        action.meta,
        state.auth.token
      );
      yield put(getSuggestedQuestionsActions.success(data));
    } catch (e: any) {
      yield put(getSuggestedQuestionsActions.failed(e.message));
    }
  } else {
    yield put(getSuggestedQuestionsActions.failed('Token not found'));
  }
}

function* getSuggestedQuestionsSuccess(
  action: AsyncAction<GetSuggestedQuestionsMeta, GetSuggestedQuestionsPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getSuggestedQuestionsWatcher(): any {
  yield takeLatest(
    getSuggestedQuestionsActions.REQUEST,
    getSuggestedQuestionsRequest
  );
  yield takeLatest(
    getSuggestedQuestionsActions.SUCCESS,
    getSuggestedQuestionsSuccess
  );
}

export default getSuggestedQuestionsWatcher;
