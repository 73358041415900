import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getStoryForBuilder } from 'services/story';
import { AsyncAction } from 'types/Action';
import { GetStoryForBuilderMeta, GetStoryForBuilderPayload } from 'types/store/GetStoryForBuilder';
import { Store } from 'types/store/Store';

import { getStoryForBuilderActions } from '../actions';

function* getStoryForBuilderRequest(action: AsyncAction<GetStoryForBuilderMeta, GetStoryForBuilderPayload>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getStoryForBuilder, action.meta, state.auth.token);
      yield put(getStoryForBuilderActions.success(data));
    } catch (e: any) {
      yield put(getStoryForBuilderActions.failed(e.message));
    }
  }
}

function* getStoryForBuilderSuccess(action: AsyncAction<GetStoryForBuilderMeta, GetStoryForBuilderPayload>) {
  // yield put()
}

function* getStoryForBuilderWatcher(): any {
  yield takeLatest(getStoryForBuilderActions.REQUEST, getStoryForBuilderRequest);
  yield takeLatest(getStoryForBuilderActions.SUCCESS, getStoryForBuilderSuccess);
}

export default getStoryForBuilderWatcher;
