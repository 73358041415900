import React from 'react';

import { SVGProps } from './SVG.props';

const Mic = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '22'}
      height={height || '30'}
      viewBox="0 0 22 30"
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.999 19a5.333 5.333 0 005.333-5.333v-8a5.333 5.333 0 10-10.667 0v8A5.333 5.333 0 0011 19zM8.332 5.667a2.667 2.667 0 015.333 0v8a2.667 2.667 0 01-5.333 0v-8zm13.333 8a1.333 1.333 0 00-2.666 0 8 8 0 01-16 0 1.333 1.333 0 00-2.667 0A10.667 10.667 0 009.665 24.24V27H7a1.333 1.333 0 100 2.667h8a1.333 1.333 0 100-2.667h-2.667v-2.76a10.667 10.667 0 009.333-10.573z"
        fill="#070550"
      />
    </svg>
  );
};

export default Mic;
