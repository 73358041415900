import { put, call, takeLatest, select } from 'redux-saga/effects';

import { publishShowcase } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import {
  PublishShowcaseMeta,
  PublishShowcasePayload
} from 'types/store/PublishShowcaseState';
import { Store } from 'types/store/Store';

import {
  publishShowcaseAction
} from '../actions';

function* publishShowcaseRequest(
  action: AsyncAction<PublishShowcaseMeta, PublishShowcasePayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(publishShowcase, action.meta, state.auth.token);
      yield put(publishShowcaseAction.success(data));
    } catch (e: any) {
      yield put(publishShowcaseAction.failed(e.message));
    }
  }
}

function* publishShowcaseSuccess(
  action: AsyncAction<PublishShowcaseMeta, PublishShowcasePayload>
) {
  // 
}

function* publishShowcaseWatcher(): any {
  yield takeLatest(publishShowcaseAction.REQUEST, publishShowcaseRequest);
  yield takeLatest(publishShowcaseAction.SUCCESS, publishShowcaseSuccess);
}

export default publishShowcaseWatcher;
