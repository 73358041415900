import { Reducer } from 'redux';

import { defaultSettingForm } from 'routes/Employer/Widget/Setting/Setting.props';
import { getWorkspacesAction } from 'store/actions';
import { GET_WORKSPACES_TAG } from 'store/actions/settings/workspacesReferences';
import { Action, AsyncAction } from 'types/Action';
import { WorkspacesState } from 'types/store/Workspaces';

const initState: WorkspacesState = {
  isLoading: false,
  workspaces: [],
  tags: [],
};

const workspacesReducer: Reducer<WorkspacesState, AsyncAction> = (
  state = initState,
  { type, payload, meta },
) => {
  switch (type) {
    case getWorkspacesAction.REQUEST: {
      return {
        ...state,
        isLoading: true,
        workspaces: [],
      };
    }
    case getWorkspacesAction.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        workspaces: payload,
      };
    }
    case GET_WORKSPACES_TAG.REQUEST: {
      return {
        ...state,
        isLoading: true,
        tags: [],
      };
    }
    case GET_WORKSPACES_TAG.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        tags: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default workspacesReducer;
