import React from 'react';

import { SVGProps } from './SVG.props';

const SELinkCopy = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="link">
        <g id="line">
          <path
            d="M10.8619 9.1387C11.1223 9.39905 11.5444 9.39905 11.8048 9.1387L13.1381 7.80536C14.503 6.44044 14.503 4.22747 13.1381 2.86256C11.7732 1.49764 9.5602 1.49764 8.19528 2.86256L6.86195 4.19589C6.6016 4.45624 6.6016 4.87835 6.86195 5.1387C7.1223 5.39905 7.54441 5.39905 7.80476 5.1387L9.13809 3.80536C9.98231 2.96115 11.3511 2.96115 12.1953 3.80537C13.0395 4.64959 13.0395 6.01834 12.1953 6.86255L10.8619 8.19589C10.6016 8.45624 10.6016 8.87835 10.8619 9.1387Z"
            fill={fill || '#060548'}
          />
          <path
            d="M2.86195 13.1387C4.22686 14.5036 6.43983 14.5036 7.80475 13.1387L9.13809 11.8054C9.39844 11.545 9.39844 11.1229 9.13809 10.8626C8.87774 10.6022 8.45563 10.6022 8.19528 10.8626L6.86194 12.1959C6.01773 13.0401 4.64898 13.0401 3.80476 12.1959C2.96054 11.3517 2.96054 9.98294 3.80475 9.13872L5.13809 7.80539C5.39844 7.54504 5.39844 7.12293 5.13809 6.86258C4.87774 6.60223 4.45563 6.60223 4.19528 6.86258L2.86195 8.19591C1.49703 9.56083 1.49703 11.7738 2.86195 13.1387Z"
            fill={fill || '#060548'}
          />
          <path
            d="M6.47143 10.472C6.21108 10.7324 5.78897 10.7324 5.52862 10.472C5.26827 10.2117 5.26827 9.78957 5.52862 9.52922L9.52862 5.52922C9.78897 5.26887 10.2111 5.26887 10.4714 5.52922C10.7318 5.78957 10.7318 6.21168 10.4714 6.47203L6.47143 10.472Z"
            fill={fill || '#060548'}
          />
        </g>
      </g>
    </svg>
  );
};

export default SELinkCopy;
