import React from 'react';

import Radio from 'components/base/Radio';

import { RadioGroupProps } from './RadioGroup.props';
import { Container, RadioContainer } from './RadioGroup.style';

const RadioGroup = (props: RadioGroupProps): React.JSX.Element => {
  const { value, items, onSelect, disabledIndex, direction } = props;
  return (
    <Container direction={direction}>
      {items.map((item) => (
        <RadioContainer key={item.label} direction={direction}>
          <Radio
            checked={item.value === value}
            onClick={() => {
              if (
                disabledIndex &&
                disabledIndex.indexOf(':' + item.value + ':') > -1
              )
                return;
              props.onSelectCallback && props.onSelectCallback(item.value);
              onSelect(item.value);
            }}
            color="#91DBB6"
            label={item.label}
            disabled={
              disabledIndex &&
              disabledIndex.indexOf(':' + item.value + ':') > -1
                ? true
                : false
            }
          />
        </RadioContainer>
      ))}
    </Container>
  );
};

export default React.memo(RadioGroup);
