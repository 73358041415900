import axios, { AxiosPromise } from 'axios';

import { API } from 'consts';
import { GenericResponse } from 'types/GenericResponse';
import {
  EditPlanMeta,
  GetContentPlannerRecipeMeta,
  CreateRecipeMeta,
  CreateRecipeDetailNoteMeta,
  DeleteRecipeDetailNoteMeta,
  UpdateRecipeDetailNoteMeta,
} from 'types/store/ContentPlanner';
import { GetDataRecipeDetailMeta } from 'types/store/GetDataRecipeDetail';
import { GetDataRecipeExampleDetailMeta } from 'types/store/GetDataRecipeExampleDetail';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const RECIPE_URL = `${BASE_URL}/recipe`;
const RECIPE_NOTE_URL = `${BASE_URL}/recipe-note`;
const PUBLIC_RECIPE_URL = `${BASE_URL}/public/recipe`;
const RECIPE_DETAIL_URL = `${BASE_URL}/recipe-detail`;

export const getRecipe = (
  data: GetContentPlannerRecipeMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${RECIPE_URL}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getAllRecipeDetail = (
  data: GetContentPlannerRecipeMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${RECIPE_DETAIL_URL}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const createRecipeDetail = (data: EditPlanMeta, token: string): any => {
  return axios({
    method: 'post',
    url: `${RECIPE_DETAIL_URL}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const updateRecipeDetail = (
  data: EditPlanMeta,
  customDataId: string,
  token: string,
): any => {
  return axios({
    method: 'put',
    url: `${RECIPE_DETAIL_URL}/${customDataId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const getDataRecipeDetail = (
  data: GetDataRecipeDetailMeta,
  token: string | null,
): any => {
  return axios({
    method: 'get',
    url: `${data.isPublic ? PUBLIC_RECIPE_URL : RECIPE_URL}/${data.id}`,
    params: {
      contentPackageId: data.contentPackageId,
      companyId: data.companyId,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createRecipe = (data: CreateRecipeMeta, token: string): any => {
  return axios({
    method: 'post',
    url: `${RECIPE_URL}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const getRecipeExamples = (
  data: GetDataRecipeExampleDetailMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/recipe-examples/my-examples/${data.recipeId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createRecipeDetailNote = (
  data: CreateRecipeDetailNoteMeta,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${RECIPE_NOTE_URL}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const updateRecipeDetailNote = (
  data: UpdateRecipeDetailNoteMeta,
  token: string,
): any => {
  const { recipe_note_id, content } = data;
  return axios({
    method: 'put',
    url: `${RECIPE_NOTE_URL}/${recipe_note_id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { content },
  });
};

export const deleteRecipeDetailNote = (
  data: DeleteRecipeDetailNoteMeta,
  token: string,
): any => {
  const { recipe_note_id } = data;
  return axios({
    method: 'delete',
    url: `${RECIPE_NOTE_URL}/${recipe_note_id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
