import React from 'react';

import { SVGProps } from './SVG.props';

const Portugal = (props: SVGProps): React.JSX.Element => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40 30.4444C40 32.8989 38.01 34.8889 35.5556 34.8889H4.44444C1.99 34.8889 0 32.8989 0 30.4444V10.4444C0 7.99 1.99 6 4.44444 6H35.5556C38.01 6 40 7.99 40 10.4444V30.4444Z"
        fill="#006600"
      />
      <path
        d="M35.5554 6H16.6665V34.8889H35.5554C38.0098 34.8889 39.9998 32.8989 39.9998 30.4444V10.4444C39.9998 7.99 38.0098 6 35.5554 6Z"
        fill="#D52B1E"
      />
      <path
        d="M16.6667 11.5557C11.7578 11.5557 7.77783 15.5357 7.77783 20.4446C7.77783 25.3534 11.7578 29.3334 16.6667 29.3334C21.5756 29.3334 25.5556 25.3534 25.5556 20.4446C25.5556 15.5357 21.5756 11.5557 16.6667 11.5557ZM21.1045 18.1534L23.4256 16.6057C23.9712 17.5646 24.3178 18.649 24.4123 19.8068L21.1045 18.1534ZM17.7778 27.1112H20.6578C19.7923 27.6301 18.8189 27.9846 17.7778 28.1334V27.1112ZM8.92117 19.8068C9.01339 18.6712 9.34672 17.6046 9.8745 16.6601L11.6545 18.4401L8.92117 19.8068ZM8.92117 21.0812L13.0845 23.1634L13.5812 22.169L10.1312 20.4446L12.4823 19.269L14.0512 20.8379L14.8367 20.0523L13.5301 18.7457L13.5812 18.7201L13.0845 17.7257L12.7023 17.9168L11.6189 16.8334L13.2423 17.1046L13.4245 16.009L10.6312 15.5434C11.8356 14.0623 13.5756 13.0401 15.5556 12.7568V26.0001H12.1123L14.7523 24.2401L14.1356 23.3157L10.8023 25.5379L10.8178 25.5612C9.75228 24.3423 9.06005 22.7923 8.92117 21.0812ZM15.5556 28.1334C14.5145 27.9834 13.5412 27.6301 12.6756 27.1112H15.5556V28.1334ZM22.5412 25.5334L19.2823 22.2746L18.4967 23.0601L19.0201 23.5834L18.5812 24.2412L21.2212 26.0001H17.7778V12.7557C19.2456 12.9657 20.5823 13.579 21.6656 14.4901L18.7134 15.4746L19.0645 16.5279L22.3978 15.4168L22.2834 15.0746C22.4689 15.2679 22.6423 15.4712 22.8067 15.6834L19.6912 17.7601L20.0001 18.2223L19.7512 18.719L23.2023 20.4446L19.7523 22.1701L20.2489 23.1646L24.4123 21.0823C24.2745 22.779 23.5934 24.319 22.5412 25.5334Z"
        fill="#FFCC4D"
      />
      <path
        d="M12.2222 14.8887V22.6665C12.2222 25.1209 14.2122 27.1109 16.6666 27.1109C19.1211 27.1109 21.1111 25.1209 21.1111 22.6665V14.8887H12.2222Z"
        fill="#D52B1E"
      />
      <path
        d="M13.3335 16V22.6667C13.3335 24.5067 14.8257 26 16.6668 26C18.5079 26 20.0002 24.5067 20.0002 22.6667V16H13.3335Z"
        fill="white"
      />
      <path
        d="M14.4443 19.3335H18.8888V21.5557H14.4443V19.3335Z"
        fill="#829ACD"
      />
      <path
        d="M15.5557 18.2222H17.7779V22.6666H15.5557V18.2222Z"
        fill="#829ACD"
      />
      <path
        d="M13.3335 19.3333H14.4446V21.5556H13.3335V19.3333ZM15.5557 19.3333H17.7779V21.5556H15.5557V19.3333ZM18.8891 19.3333H20.0002V21.5556H18.8891V19.3333ZM15.5557 22.6667H17.7779V24.8889H15.5557V22.6667ZM15.5557 16H17.7779V18.2222H15.5557V16Z"
        fill="#003399"
      />
    </svg>
  );
};

export default Portugal;
