import { delay, put, select, takeLatest } from 'redux-saga/effects';

import { FLOAT_MESSAGE } from 'store/actions';
import { Action } from 'types/Action';


function* watchOpenFloatAlertRequest(
  action: Action<{ message?: string, delay?: number }>,
) {
  yield delay(action.payload.delay ? action.payload.delay : 2000)
  yield put({
    type: FLOAT_MESSAGE.FLOAT_MESSAGE_CLOSE
  })
}

function* watchFloatMessage(): any {
  yield takeLatest(FLOAT_MESSAGE.FLOAT_MESSAGE_OPEN, watchOpenFloatAlertRequest)
}

export default watchFloatMessage;
