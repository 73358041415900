import React from 'react';

import { SVGProps } from './SVG.props';

const User = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      // xmlns="http://www.w3.org/2000/svg"
      width={width || 28}
      height={height || 28}
      viewBox="0 0 28 28"
      fill="none"
      {...props}
    >
      <path
        fill={fill || '#fff'}
        d="M18.1342 14.8708C19.2385 14.002 20.0446 12.8105 20.4403 11.4622C20.8359 10.1138 20.8015 8.67571 20.3418 7.34786C19.882 6.02001 19.0199 4.86847 17.8752 4.05345C16.7305 3.23842 15.3603 2.80045 13.9551 2.80045C12.5499 2.80045 11.1796 3.23842 10.035 4.05345C8.8903 4.86847 8.02812 6.02001 7.56838 7.34786C7.10864 8.67571 7.07421 10.1138 7.46987 11.4622C7.86553 12.8105 8.67161 14.002 9.77597 14.8708C7.88363 15.629 6.2325 16.8864 4.9986 18.5092C3.7647 20.1319 2.99429 22.059 2.76949 24.0852C2.75322 24.2331 2.76624 24.3828 2.80782 24.5257C2.84939 24.6686 2.91871 24.8019 3.0118 24.918C3.19981 25.1524 3.47327 25.3026 3.77202 25.3355C4.07078 25.3684 4.37035 25.2812 4.60483 25.0932C4.83932 24.9052 4.98951 24.6317 5.02238 24.333C5.26973 22.131 6.31969 20.0973 7.97165 18.6205C9.62362 17.1438 11.7618 16.3274 13.9776 16.3274C16.1934 16.3274 18.3316 17.1438 19.9836 18.6205C21.6355 20.0973 22.6855 22.131 22.9328 24.333C22.9635 24.6098 23.0955 24.8654 23.3035 25.0505C23.5116 25.2357 23.7808 25.3372 24.0593 25.3355H24.1832C24.4785 25.3015 24.7483 25.1522 24.934 24.9201C25.1197 24.688 25.2061 24.392 25.1744 24.0964C24.9486 22.0645 24.174 20.1324 22.9338 18.5072C21.6935 16.882 20.0344 15.625 18.1342 14.8708ZM13.9551 14.0711C13.0639 14.0711 12.1928 13.8068 11.4518 13.3117C10.7108 12.8166 10.1333 12.1129 9.79228 11.2896C9.45125 10.4663 9.36202 9.5603 9.53588 8.68626C9.70973 7.81223 10.1389 7.00938 10.769 6.37923C11.3992 5.74909 12.202 5.31996 13.076 5.1461C13.9501 4.97224 14.856 5.06147 15.6794 5.4025C16.5027 5.74354 17.2064 6.32105 17.7015 7.06202C18.1966 7.80299 18.4608 8.67414 18.4608 9.5653C18.4608 10.7603 17.9861 11.9064 17.1411 12.7514C16.2961 13.5964 15.1501 14.0711 13.9551 14.0711Z"
      />
    </svg>
  );
};

export default User;
