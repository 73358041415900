import { put, call, takeLatest, select } from 'redux-saga/effects';

import { updateUserSetting } from 'services/settings';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateUserSettingMeta,
  UpdateUserSettingPayload,
} from 'types/store/UpdateUserSettingState';

import { updateUserSettingActions } from '../actions';

function* updateUserSettingRequest(
  action: AsyncAction<UpdateUserSettingMeta, UpdateUserSettingPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateUserSetting,
        action.meta,
        state.auth.token
      );
      yield put(updateUserSettingActions.success(data));
    } catch (e: any) {
      yield put(updateUserSettingActions.failed(e.message));
    }
  }
}

function* updateUserSettingSuccess(
  action: AsyncAction<UpdateUserSettingMeta, UpdateUserSettingPayload>
) {
  //
}

function* updateUserSettingWatcher(): any {
  yield takeLatest(updateUserSettingActions.REQUEST, updateUserSettingRequest);
  yield takeLatest(updateUserSettingActions.SUCCESS, updateUserSettingSuccess);
}

export default updateUserSettingWatcher;
