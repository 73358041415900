import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getSubtitles } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  GetSubtitlesMeta,
  GetSubtitlesPayload,
} from 'types/store/GetSubtitles';
import { Store } from 'types/store/Store';

import { getSubtitlesActions } from '../actions';

function* getSubtitlesRequest(
  action: AsyncAction<GetSubtitlesMeta, GetSubtitlesPayload>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getSubtitles, action.meta, state.auth.token);
      yield put(getSubtitlesActions.success(data));
    } catch (e: any) {
      yield put(getSubtitlesActions.failed(e.message));
    }
  } else {
    yield put(getSubtitlesActions.failed('Token not found'));
  }
}

// function* getSubtitlesSuccess(
//   action: AsyncAction<GetSubtitlesMeta, GetSubtitlesPayload>,
// ) {
//   // yield put(push(EMPLOYER_ROUTES.LOGIN));
// }

function* getSubtitlesWatcher(): any {
  yield takeLatest(getSubtitlesActions.REQUEST, getSubtitlesRequest);
  // yield takeLatest(getSubtitlesActions.SUCCESS, getSubtitlesSuccess);
}

export default getSubtitlesWatcher;
