import Typography from 'components/base/Typography';
import styled from 'utils/styled';
import theme from 'utils/Theme';

export const Container = styled.div`
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: ${({ theme }) => theme.zIndex.tooltip};

  @media screen and (max-width: ${theme.breakpoints.desktop}px) {
    top: 16px;
    right: 0px;
  }
`;

export const Content = styled.div<{
  variant: 'error' | 'success' | 'information' | 'alert' | 'warning';
}>`
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  padding: 16px 24px 16px 18px;
  border-radius: 12px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.neutral.white};
  width: 470px;
  filter: drop-shadow(0px 4px 12px rgba(41, 43, 50, 0.08));

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 340px;
  }
`;

export const BorderLeftContainer = styled.div<{
  variant: 'error' | 'success' | 'information' | 'alert' | 'warning';
}>`
  position: absolute;
  width: 4px;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme, variant }) => theme.product[variant].primary};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const MessageContainer = styled.div`
  margin-left: 14px;
  margin-right: 14px;
  width: 90%;
`;

export const Message = styled.p`
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  font-weight: bold;
  font-family: 'Objective';
  color: ${({ theme }) => theme.altGrey.shade9};

  &:first-letter {
    text-transform:capitalize;
  }
`;

export const DetailMessage = styled(Typography)``;

export const CloseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
