import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getFinalWrittenStory } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  GetFinalWrittenStoryMeta,
  GetFinalWrittenStoryPayload,
} from 'types/store/GetFinalWrittenStoryState';
import { Store } from 'types/store/Store';

import { getFinalWrittenStoryActions } from '../actions';

function* getFinalWrittenStoryRequest(
  action: AsyncAction<GetFinalWrittenStoryMeta, GetFinalWrittenStoryPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(
        getFinalWrittenStory,
        action.meta,
        state.auth.token
      );
      yield put(getFinalWrittenStoryActions.success(data));
    } catch (e: any) {
      yield put(getFinalWrittenStoryActions.failed(e.message));
    }
  } else {
    yield put(getFinalWrittenStoryActions.failed('Token not found'));
  }
}

function* getFinalWrittenStorySuccess(
  action: AsyncAction<GetFinalWrittenStoryMeta, GetFinalWrittenStoryPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getFinalWrittenStoryWatcher(): any {
  yield takeLatest(
    getFinalWrittenStoryActions.REQUEST,
    getFinalWrittenStoryRequest
  );
  yield takeLatest(
    getFinalWrittenStoryActions.SUCCESS,
    getFinalWrittenStorySuccess
  );
}

export default getFinalWrittenStoryWatcher;
