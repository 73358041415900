import pathOr from 'ramda/es/pathOr';

import { Action } from 'types/Action';
import {
  ShowcaseArticleState,
  ShowcaseArticlePayload,
} from 'types/store/ShowcaseArticleState';

import { showcaseArticleActions } from '../actions';

const DEFAULT_STATE: ShowcaseArticleState = {
  updateMode: false,
  formOpen: false,
  formSelection: 'Add a Story',
  articleInFocus: {
    sort: 0,
    author: '',
    id: '',
    image: '',
    preview: '',
    link: '',
    is_featured: false,
  },
  feedbackInFocus: {
    sort: 0,
    id: '',
    image: '',
    feedback: '',
    name: '',
  },
};

const DEFAULT_ACTION: Action<ShowcaseArticlePayload> = {
  type: '',
  payload: {
    storyId: '',
  },
};

export default (state = DEFAULT_STATE, action = DEFAULT_ACTION): any => {
  const localReducer = (): ShowcaseArticlePayload => {
    return {
      ...state,
      ...action.payload,
    };
  };
  return pathOr(state, [action.type], {
    [showcaseArticleActions.UPDATE]: localReducer(),
    [showcaseArticleActions.CLEAR]: DEFAULT_STATE,
  });
};
