import { Reducer } from 'redux';
import _get from 'lodash/get';

import { Action } from 'types/Action';
import { AssetLibrary } from 'types/store/AssetLibrary';
import {
  LOADING,
  LOADING_INFO_DETAIL,
  OPEN_VIEW_INFO_DETAIL,
  SELECT_FILE,
  SELECT_STEP,
  GET_ASSET_LIBRARY,
  GET_ASSET_LIBRARY_FOLDER,
  GET_ASSET_INFO_DETAIL,
  SELECT_ASSET_FILE,
  SEARCH_ASSET_LIBRARY,
  GET_TABS_SMART_UPLOADS,
  SELECT_SMART_UPLOAD,
  SELECT_INNER_SMART_UPLOAD,
  SEARCH_VALUE,
  GET_IMAGES_MY_STORIES,
  EDIT_IMAGES_MY_STORIES,
  SELECTED_ADVOCATE,
  SET_LIMIT_DURATION,
} from 'store/actions/assetLibrary/assetLibrary.enum';

const initState: AssetLibrary = {
  isLoading: false,
  isLoadingInfoDetail: false,
  isOpenViewInfoDetail: false,
  step: [],
  listMasterAssetLibrary: undefined,
  listAssetLibraryFolder: undefined,
  assetInfoDetail: undefined, // folder info detail and asset info detail
  isPreviewAsset: false,
  assetSelected: undefined,
  searchResultAssetLibrary: undefined,
  tabsSmartUploads: undefined,
  isSelectSmartUploads: false,
  searchValue: '',
  listTypeAssets: undefined,
  listImagesMyStories: [] || undefined,
  listImagesMyStoriesEdited: [] || undefined,
  advocateSelected: undefined,
  listImagesByAdvocate: [] || undefined,
  limitDuration: 0,
};

const assetLibrary: Reducer<AssetLibrary, Action> = (
  state = initState,
  { type, payload },
) => {
  switch (type) {
    case LOADING.LOADING_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case LOADING.LOADING_CLEAR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case LOADING_INFO_DETAIL.LOADING_REQUEST: {
      return {
        ...state,
        isLoadingInfoDetail: true,
      };
    }
    case LOADING_INFO_DETAIL.LOADING_CLEAR: {
      return {
        ...state,
        isLoadingInfoDetail: false,
      };
    }
    case OPEN_VIEW_INFO_DETAIL.OPEN_VIEW_INFO_DETAIL_REQUEST: {
      return {
        ...state,
        isOpenViewInfoDetail: true,
      };
    }
    case OPEN_VIEW_INFO_DETAIL.OPEN_VIEW_INFO_DETAIL_CLOSE: {
      return {
        ...state,
        isOpenViewInfoDetail: false,
      };
    }
    case SELECT_STEP.SELECT_STEP_REQUEST: {
      return {
        ...state,
        step: payload?.sort((first: any, second: any) =>
          first.step - second.step ? -1 : 1,
        ),
      };
    }
    case SELECT_STEP.BACK_STEP_REQUEST: {
      return {
        ...state,
        step: state.step?.filter((x) => x.step <= payload),
      };
    }
    case SELECT_STEP.SELECT_STEP_CLEAR: {
      return {
        ...state,
        step: [],
      };
    }
    case GET_ASSET_LIBRARY.GET_ASSET_LIBRARY_SUCCESS: {
      return {
        ...state,
        listMasterAssetLibrary: payload,
        isLoading: false,
      };
    }
    case GET_ASSET_LIBRARY.GET_LIST_TYPES_ASSET_LIBRARY_REQUEST: {
      return {
        ...state,
        listTypeAssets: payload,
      };
    }
    case GET_ASSET_LIBRARY_FOLDER.GET_ASSET_LIBRARY_FOLDER_SUCCESS: {
      return {
        ...state,
        listAssetLibraryFolder: payload,
        isLoading: false,
      };
    }
    case GET_ASSET_LIBRARY_FOLDER.GET_ASSET_LIBRARY_FOLDER_CLEAR: {
      return {
        ...state,
        listAssetLibraryFolder: undefined,
      };
    }
    case GET_ASSET_INFO_DETAIL.GET_ASSET_INFO_DETAIL_SUCCESS: {
      return {
        ...state,
        assetInfoDetail: payload,
        isLoadingInfoDetail: false,
      };
    }
    case GET_ASSET_INFO_DETAIL.GET_ASSET_INFO_DETAIL_CLEAR: {
      return {
        ...state,
        assetInfoDetail: undefined,
      };
    }
    case SELECT_ASSET_FILE.SELECT_ASSET_FILE_REQUEST: {
      return {
        ...state,
        isPreviewAsset: payload.isSelected,
        assetSelected: payload.assetSelected,
      };
    }
    case SELECT_ASSET_FILE.SELECT_ASSET_FILE_CLEAR: {
      return {
        ...state,
        isPreviewAsset: false,
        assetSelected: undefined,
      };
    }
    case SELECT_ASSET_FILE.SELECT_ASSET_FILE_DETAIL_REQUEST: {
      return {
        ...state,
        assetSelected: payload,
      };
    }
    case SELECT_ASSET_FILE.SELECT_ASSET_FILE_OPEN_PREVIEW_REQUEST: {
      return {
        ...state,
        isPreviewAsset: true,
      };
    }
    case SEARCH_ASSET_LIBRARY.SEARCH_ASSET_LIBRARY_SUCCESS: {
      return {
        ...state,
        searchResultAssetLibrary: payload,
        isLoading: false,
      };
    }
    case SEARCH_ASSET_LIBRARY.SEARCH_ASSET_LIBRARY_CLEAR: {
      return {
        ...state,
        searchResultAssetLibrary: undefined,
      };
    }
    case GET_TABS_SMART_UPLOADS.GET_TABS_SMART_UPLOADS_SUCCESS: {
      return {
        ...state,
        tabsSmartUploads: payload,
        isLoading: false,
      };
    }
    case GET_TABS_SMART_UPLOADS.GET_TABS_SMART_UPLOADS_CLEAR: {
      return {
        ...state,
        tabsSmartUploads: undefined,
      };
    }
    case SELECT_SMART_UPLOAD.SELECT_SMART_UPLOAD_REQUEST: {
      return {
        ...state,
        isSelectSmartUploads: true,
      };
    }
    case SELECT_SMART_UPLOAD.SELECT_SMART_UPLOAD_CLEAR: {
      return {
        ...state,
        isSelectSmartUploads: false,
      };
    }
    case SELECT_INNER_SMART_UPLOAD.SELECT_INNER_SMART_UPLOAD_REQUEST: {
      return {
        ...state,
        isSelectInnerSmartUploads: true,
      };
    }
    case SELECT_INNER_SMART_UPLOAD.SELECT_INNER_SMART_UPLOAD_CLEAR: {
      return {
        ...state,
        isSelectInnerSmartUploads: false,
      };
    }
    case SEARCH_VALUE.SEARCH_VALUE_REQUEST: {
      return {
        ...state,
        searchValue: payload,
      };
    }
    case SEARCH_VALUE.SEARCH_VALUE_CLEAR: {
      return {
        ...state,
        searchValue: '',
      };
    }
    case GET_IMAGES_MY_STORIES.GET_IMAGES_MY_STORIES_SUCCESS: {
      return {
        ...state,
        listImagesMyStories: payload,
        listImagesMyStoriesEdited: payload,
      };
    }
    case EDIT_IMAGES_MY_STORIES.EDIT_IMAGES_MY_STORIES_REQUEST: {
      return {
        ...state,
        listImagesMyStoriesEdited: payload,
      };
    }
    case EDIT_IMAGES_MY_STORIES.EDIT_IMAGES_MY_STORIES_CLEAR: {
      return {
        ...state,
        listImagesMyStoriesEdited: [],
      };
    }
    case SELECTED_ADVOCATE.SELECTED_ADVOCATE_REQUEST: {
      return {
        ...state,
        advocateSelected: payload,
      };
    }
    case SELECTED_ADVOCATE.SELECTED_ADVOCATE_CLEAR: {
      return {
        ...state,
        advocateSelected: undefined,
      };
    }
    case GET_IMAGES_MY_STORIES.GET_IMAGES_BY_ADVOCATE_SUCCESS: {
      return {
        ...state,
        listImagesByAdvocate: payload,
        isLoading: false,
      };
    }
    case SET_LIMIT_DURATION.SET_LIMIT_DURATION_REQUEST: {
      return {
        ...state,
        limitDuration: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default assetLibrary;
