import { put, call, takeLatest, select } from 'redux-saga/effects';

import { guestCommentOnRecipe } from 'services/recipe-detail';
import { AsyncAction } from 'types/Action';
import { GuestCommentOnRecipeMeta, GuestCommentOnRecipePayload } from 'types/store/GuestCommentOnRecipe';
import { Store } from 'types/store/Store';

import { guestCommentOnRecipeActions } from '../actions';

function* guestCommentOnRecipeRequest(action: AsyncAction<GuestCommentOnRecipeMeta, GuestCommentOnRecipePayload>): any {
  const state: Store = yield select();
  try {
    const { data } = yield call(guestCommentOnRecipe, action.meta);
    yield put(guestCommentOnRecipeActions.success(data));
  } catch (e: any) {
    yield put(guestCommentOnRecipeActions.failed(e.message));
  }
}

// function* guestCommentOnRecipeSuccess(action: AsyncAction<GuestCommentOnRecipeMeta, GuestCommentOnRecipePayload>): any {
//   yield put()
// }

function* guestCommentOnRecipeWatcher(): any {
  yield takeLatest(guestCommentOnRecipeActions.REQUEST, guestCommentOnRecipeRequest);
  // yield takeLatest(guestCommentOnRecipeActions.SUCCESS, guestCommentOnRecipeSuccess);
}

export default guestCommentOnRecipeWatcher;
