import React from 'react';

import { SVGProps } from './SVG.props';

const Spain = (props: SVGProps): React.JSX.Element => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40 30.4444C40 32.8989 38.01 34.8889 35.5556 34.8889H4.44444C1.99 34.8889 0 32.8989 0 30.4444V10.4444C0 7.99 1.99 6 4.44444 6H35.5556C38.01 6 40 7.99 40 10.4444V30.4444Z"
        fill="#C60A1D"
      />
      <path d="M0 13.7778H40V27.1112H0V13.7778Z" fill="#FFC400" />
      <path
        d="M10 19.3335V22.6668C10 24.5079 11.4922 26.0002 13.3333 26.0002C15.1744 26.0002 16.6667 24.5079 16.6667 22.6668V19.3335H10Z"
        fill="#EA596E"
      />
      <path
        d="M13.3335 18.2222H16.6668V21.5555H13.3335V18.2222Z"
        fill="#F4A2B2"
      />
      <path d="M10 18.2222H13.3333V21.5555H10V18.2222Z" fill="#DD2E44" />
      <path
        d="M13.3333 18.222C15.1743 18.222 16.6667 17.4758 16.6667 16.5553C16.6667 15.6349 15.1743 14.8887 13.3333 14.8887C11.4924 14.8887 10 15.6349 10 16.5553C10 17.4758 11.4924 18.222 13.3333 18.222Z"
        fill="#EA596E"
      />
      <path
        d="M13.3333 16.5553C15.1743 16.5553 16.6667 16.1822 16.6667 15.722C16.6667 15.2618 15.1743 14.8887 13.3333 14.8887C11.4924 14.8887 10 15.2618 10 15.722C10 16.1822 11.4924 16.5553 13.3333 16.5553Z"
        fill="#FFAC33"
      />
      <path
        d="M7.77783 18.2222H8.88894V25.9999H7.77783V18.2222ZM17.7778 18.2222H18.8889V25.9999H17.7778V18.2222Z"
        fill="#99AAB5"
      />
      <path
        d="M6.6665 24.8891H9.99984V26.0002H6.6665V24.8891ZM16.6665 24.8891H19.9998V26.0002H16.6665V24.8891ZM7.77761 17.1113H8.88873V18.2224H7.77761V17.1113ZM17.7776 17.1113H18.8887V18.2224H17.7776V17.1113Z"
        fill="#66757F"
      />
    </svg>
  );
};

export default Spain;
