import React from 'react';

import { SVGProps } from './SVG.props';

const VideoPlay = (props: SVGProps): React.JSX.Element => {
  const { width = 11, height = 12, fill = '#fff' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={`0 0 ${width} ${height}`}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M9.21 4.563a1.667 1.667 0 010 2.873L3.066 11.05c-1.11.654-2.511-.147-2.511-1.436V2.387c0-1.29 1.4-2.09 2.511-1.437L9.21 4.563z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default VideoPlay;
