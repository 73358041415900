import React from 'react';

import { SVGProps } from './SVG.props';

const Lightning = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 8}
      height={height || 12}
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.945 4.777a.5.5 0 00-.445-.275H5v-3a.5.5 0 00-.905-.32l-4 5.5a.5.5 0 00-.04.5.5.5 0 00.445.32H3v3a.5.5 0 00.905.295l4-5.5a.5.5 0 00.04-.52zM4 8.962v-1.96a.5.5 0 00-.5-.5h-2L4 3.042v1.96a.5.5 0 00.5.5h2L4 8.962z"
        fill="#070550"
      />
      <path
        d="M4.562 5.064l-.187-3-3.75 4.688 2.812.375.375 3.187 3.75-5.437-3 .187z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Lightning;
