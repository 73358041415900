import { put, call, takeLatest, select } from 'redux-saga/effects';

import { shareVideoToLinkedin, shareVideoToLinkedinV2 } from 'services/linkedin';
import { AsyncAction } from 'types/Action';
import { ShareVideoToLinkedinMeta, ShareVideoToLinkedinPayload } from 'types/store/ShareVideoToLinkedin';
import { Store } from 'types/store/Store';

import { shareVideoToLinkedinActions } from '../actions';

function* shareVideoToLinkedinRequest(action: AsyncAction<ShareVideoToLinkedinMeta, ShareVideoToLinkedinPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(shareVideoToLinkedinV2, action.meta, state.auth.token);
      yield put(shareVideoToLinkedinActions.success(data));
    } catch (e: any) {
      yield put(shareVideoToLinkedinActions.failed(e.message));
    }
  }
}

// function* shareVideoToLinkedinSuccess(action: AsyncAction<ShareVideoToLinkedinMeta, ShareVideoToLinkedinPayload>): any {
//   yield put()
// }

function* shareVideoToLinkedinWatcher(): any {
  yield takeLatest(shareVideoToLinkedinActions.REQUEST, shareVideoToLinkedinRequest);
  // yield takeLatest(shareVideoToLinkedinActions.SUCCESS, shareVideoToLinkedinSuccess);
}

export default shareVideoToLinkedinWatcher;
