import { put, call, takeLatest, select } from 'redux-saga/effects';

import { updateShowcaseArticleHighlight } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateShowcaseArticleHighlightMeta,
  UpdateShowcaseArticleHighlightPayload,
} from 'types/store/UpdateShowcaseArticleHighlightState';

import {
  updateShowcaseArticleHighlightActions,
  getShowcaseCompanyActions,
} from '../actions';

function* updateShowcaseArticleHighlightRequest(
  action: AsyncAction<
    UpdateShowcaseArticleHighlightMeta,
    UpdateShowcaseArticleHighlightPayload
  >,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateShowcaseArticleHighlight,
        action.meta,
        state.auth.token,
      );
      yield put(updateShowcaseArticleHighlightActions.success(data));
    } catch (e: any) {
      yield put(updateShowcaseArticleHighlightActions.failed(e.message));
    }
  }
}

function* updateShowcaseArticleHighlightSuccess(
  action: AsyncAction<
    UpdateShowcaseArticleHighlightMeta,
    UpdateShowcaseArticleHighlightPayload
  >,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
) {
  yield put(updateShowcaseArticleHighlightActions.clear());
  //   yield put(getShowcaseCompanyActions.request({}));
}

function* updateShowcaseArticleHighlightWatcher(): any {
  yield takeLatest(
    updateShowcaseArticleHighlightActions.REQUEST,
    updateShowcaseArticleHighlightRequest,
  );
  yield takeLatest(
    updateShowcaseArticleHighlightActions.SUCCESS,
    updateShowcaseArticleHighlightSuccess,
  );
}

export default updateShowcaseArticleHighlightWatcher;
