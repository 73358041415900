import React from 'react';

import { PortalPublicProps } from './Portal.props';
import PortalView from './Portal.view';

const Portal = (props: PortalPublicProps): React.JSX.Element => {
  const generatedProps = {
    ...props,
  };
  return <PortalView {...generatedProps} />;
};

export default Portal;
