import React from 'react';

import { SVGProps } from './SVG.props';

const Filter = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '14'}
      height={height || '14'}
      viewBox="0 0 14 14"
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.667 2.999H3v-2a.667.667 0 00-1.333 0v2H1a.667.667 0 000 1.333h2.667a.667.667 0 000-1.333zM2.334 5.665a.667.667 0 00-.667.667v6.667a.667.667 0 101.333 0V6.332a.667.667 0 00-.667-.667zM7 11a.666.666 0 00-.667.666V13a.667.667 0 101.334 0v-1.334A.667.667 0 007 11zm6-5.334h-.666V1a.666.666 0 10-1.334 0v4.666h-.666a.667.667 0 100 1.334H13a.667.667 0 000-1.334zm-1.333 2.667a.667.667 0 00-.667.667v4a.667.667 0 101.334 0v-4a.666.666 0 00-.667-.667zm-3.333 0h-.667V.999a.667.667 0 00-1.333 0v7.333h-.667a.667.667 0 000 1.333h2.667a.667.667 0 100-1.333z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Filter;
