import { put, call, takeLatest, select } from 'redux-saga/effects';

import { createStoryVersion } from 'services/story-editor';
import { uploadFile } from 'services/upload';
import { AsyncAction } from 'types/Action';
import {
  CreateStoryVersionMeta,
  CreateStoryVersionPayload,
} from 'types/store/CreateStoryVersion';
import { Store } from 'types/store/Store';
import { avoidCharacters } from 'utils/AvoidCharacters';

import { createStoryVersionActions } from '../actions';

function* createStoryVersionRequest(
  action: AsyncAction<CreateStoryVersionMeta, CreateStoryVersionPayload>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    const { fileUrls } = action.meta;
    if (typeof fileUrls[0] !== 'string') {
      for (let i = 0; i < fileUrls.length; i++) {
        if (fileUrls[i] instanceof File) {
          let fileName = (fileUrls[i] as File)?.name;
          for (let i = 0; i < avoidCharacters.length; i++) {
            fileName = fileName.replaceAll(avoidCharacters[i], ' ');
          }
          try {
            const res = yield uploadFile({
              file: fileUrls[i] as File,
              token: state.auth.token,
              customFileName: encodeURI(fileName),
              folder: 'story',
            });
            fileUrls[i] = res;
          } catch (error: any) {
            console.log(error);
          }
        }
      }
    }
    try {
      const { data } = yield call(
        createStoryVersion,
        action.meta,
        state.auth.token,
      );
      yield put(createStoryVersionActions.success(data));
    } catch (e: any) {
      const errorResponse = e?.response;
      yield put(createStoryVersionActions.failed(errorResponse?.data?.message));
    }
  }
}

// function* createStoryVersionSuccess(action: AsyncAction<CreateStoryVersionMeta, CreateStoryVersionPayload>): any {
//   yield put()
// }

function* createStoryVersionWatcher(): any {
  yield takeLatest(
    createStoryVersionActions.REQUEST,
    createStoryVersionRequest,
  );
  // yield takeLatest(createStoryVersionActions.SUCCESS, createStoryVersionSuccess);
}

export default createStoryVersionWatcher;
