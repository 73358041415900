import { put, call, takeLatest, select } from 'redux-saga/effects';

import { discussionStories } from 'services/story';
import { AsyncAction } from 'types/Action';
import { DiscussionStoriesMeta, DiscussionStoriesPayload } from 'types/store/DiscussionStories';
import { Store } from 'types/store/Store';

import { discussionStoriesActions } from '../actions';

function* discussionStoriesRequest(action: AsyncAction<DiscussionStoriesMeta, DiscussionStoriesPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(discussionStories, action.meta, state.auth.token);
      yield put(discussionStoriesActions.success(data));
    } catch (e: any) {
      yield put(discussionStoriesActions.failed(e.message));
    }
  }
}

// function* discussionStoriesSuccess(action: AsyncAction<DiscussionStoriesMeta, DiscussionStoriesPayload>): any {
//   yield put()
// }

function* discussionStoriesWatcher(): any {
  yield takeLatest(discussionStoriesActions.REQUEST, discussionStoriesRequest);
  // yield takeLatest(discussionStoriesActions.SUCCESS, discussionStoriesSuccess);
}

export default discussionStoriesWatcher;
