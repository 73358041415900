import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getUnpaidStories } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  GetUnpaidStoriesMeta,
  GetUnpaidStoriesPayload
} from 'types/store/GetUnpaidStories';
import { Store } from 'types/store/Store';

import { getUnpaidStoriesActions } from '../actions';

function* getUnpaidStoriesRequest(
  action: AsyncAction<GetUnpaidStoriesMeta, GetUnpaidStoriesPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getUnpaidStories,
        action.meta,
        state.auth.token
      );
      yield put(getUnpaidStoriesActions.success(data));
    } catch (e: any) {
      yield put(getUnpaidStoriesActions.failed(e.message));
    }
  } else {
    yield put(getUnpaidStoriesActions.failed('Token not found'));
  }
}

function* getUnpaidStoriesSuccess(
  action: AsyncAction<GetUnpaidStoriesMeta, GetUnpaidStoriesPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getUnpaidStoriesWatcher(): any {
  yield takeLatest(getUnpaidStoriesActions.REQUEST, getUnpaidStoriesRequest);
  yield takeLatest(getUnpaidStoriesActions.SUCCESS, getUnpaidStoriesSuccess);
}

export default getUnpaidStoriesWatcher;
