import React from 'react';

import { SVGProps } from './SVG.props';

const SyncCircle = (props: SVGProps): React.JSX.Element => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="20" height="20" rx="4" fill="#98E0E5" />
      <path
        d="M13.955 11.755H11.69C11.5574 11.755 11.4302 11.8077 11.3364 11.9014C11.2427 11.9952 11.19 12.1224 11.19 12.255C11.19 12.3876 11.2427 12.5148 11.3364 12.6086C11.4302 12.7023 11.5574 12.755 11.69 12.755H12.89C12.3385 13.3314 11.6272 13.7297 10.8476 13.8987C10.068 14.0677 9.2556 13.9998 8.51489 13.7036C7.77417 13.4074 7.13893 12.8966 6.6908 12.2366C6.24266 11.5766 6.00209 10.7977 6 10C6 9.86739 5.94732 9.74022 5.85355 9.64645C5.75979 9.55268 5.63261 9.5 5.5 9.5C5.36739 9.5 5.24021 9.55268 5.14645 9.64645C5.05268 9.74022 5 9.86739 5 10C5.00264 10.9764 5.29111 11.9307 5.8298 12.745C6.36849 13.5594 7.13383 14.1982 8.03137 14.5826C8.92891 14.967 9.91937 15.0803 10.8805 14.9083C11.8417 14.7364 12.7314 14.2868 13.44 13.615V14.5C13.44 14.6326 13.4927 14.7598 13.5864 14.8536C13.6802 14.9473 13.8074 15 13.94 15C14.0726 15 14.1998 14.9473 14.2936 14.8536C14.3873 14.7598 14.44 14.6326 14.44 14.5V12.25C14.4388 12.1208 14.3876 11.9971 14.2972 11.9048C14.2068 11.8126 14.0841 11.7589 13.955 11.755ZM10 5C8.71819 5.00366 7.48675 5.49946 6.56 6.385V5.5C6.56 5.36739 6.50732 5.24021 6.41355 5.14645C6.31979 5.05268 6.19261 5 6.06 5C5.92739 5 5.80021 5.05268 5.70645 5.14645C5.61268 5.24021 5.56 5.36739 5.56 5.5V7.75C5.56 7.88261 5.61268 8.00979 5.70645 8.10355C5.80021 8.19732 5.92739 8.25 6.06 8.25H8.31C8.44261 8.25 8.56979 8.19732 8.66355 8.10355C8.75732 8.00979 8.81 7.88261 8.81 7.75C8.81 7.61739 8.75732 7.49022 8.66355 7.39645C8.56979 7.30268 8.44261 7.25 8.31 7.25H7.11C7.66123 6.67394 8.37205 6.27575 9.1512 6.10655C9.93035 5.93736 10.7423 6.00487 11.4828 6.30043C12.2233 6.59599 12.8586 7.10611 13.3071 7.7653C13.7556 8.4245 13.9969 9.20269 14 10C14 10.1326 14.0527 10.2598 14.1464 10.3536C14.2402 10.4473 14.3674 10.5 14.5 10.5C14.6326 10.5 14.7598 10.4473 14.8536 10.3536C14.9473 10.2598 15 10.1326 15 10C15 9.34339 14.8707 8.69321 14.6194 8.08658C14.3681 7.47995 13.9998 6.92876 13.5355 6.46447C13.0712 6.00017 12.52 5.63188 11.9134 5.3806C11.3068 5.12933 10.6566 5 10 5Z"
        fill="#070550"
      />
    </svg>
  );
};

export default SyncCircle;
