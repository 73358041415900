import { put, call, takeLatest, select } from 'redux-saga/effects';

import { MAIN_ROUTES } from 'consts';
import { getTimelineEvents } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  GetTimelineEventsMeta,
  GetTimelineEventsPayload,
} from 'types/store/GetTimelineEventsState';
import { Store } from 'types/store/Store';
import navigationService from 'utils/navigationService';

import { getTimelineEventsActions } from '../actions';

function* getTimelineEventsRequest(
  action: AsyncAction<GetTimelineEventsMeta, GetTimelineEventsPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getTimelineEvents,
        action.meta,
        state.auth.token,
      );
      yield put(getTimelineEventsActions.success(data));
    } catch (e: any) {
      if (e.response.status === 404) {
        yield call(navigationService.goTo, MAIN_ROUTES.NOT_FOUND);
      } else {
        yield put(getTimelineEventsActions.failed(e.message));
      }
    }
  } else {
    yield put(getTimelineEventsActions.failed('Token not found'));
  }
}

function* getTimelineEventsSuccess(
  action: AsyncAction<GetTimelineEventsMeta, GetTimelineEventsPayload>,
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getTimelineEventsWatcher(): any {
  yield takeLatest(getTimelineEventsActions.REQUEST, getTimelineEventsRequest);
  yield takeLatest(getTimelineEventsActions.SUCCESS, getTimelineEventsSuccess);
}

export default getTimelineEventsWatcher;
