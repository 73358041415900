import { put, call, takeLatest, select } from 'redux-saga/effects';

import { updateCardDetails } from 'services/payment';
import getSavedCards from 'store/reducers/getSavedCards';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateCardDetailsMeta,
  UpdateCardDetailsPayload
} from 'types/store/UpdateCardDetails';

import {
  updateCardDetailsActions,
  getSavedCardsActions
} from '../actions';

function* updateCardDetailsRequest(
  action: AsyncAction<UpdateCardDetailsMeta, UpdateCardDetailsPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(updateCardDetails, action.meta, state.auth.token);
      yield put(updateCardDetailsActions.success(data));
    } catch (e: any) {
      yield put(updateCardDetailsActions.failed(e.message));
    }
  }
}

function* updateCardDetailsSuccess(
  action: AsyncAction<UpdateCardDetailsMeta, UpdateCardDetailsPayload>
) {
  yield put(getSavedCardsActions.request({}));
}

function* updateCardDetailsWatcher(): any {
  yield takeLatest(updateCardDetailsActions.REQUEST, updateCardDetailsRequest);
  yield takeLatest(updateCardDetailsActions.SUCCESS, updateCardDetailsSuccess);
}

export default updateCardDetailsWatcher;
