import axios, { AxiosPromise, AxiosResponse } from 'axios';

import { API } from 'consts';
import { ISuggestionAdvocatesMeta } from 'store/actions/contentPlanner/suggestionAdvocates';
import { GenericResponse } from 'types/GenericResponse';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const RECIPE_URL = `${BASE_URL}/recipe-detail`;
const CONTENT_PLANNER_URL = `${BASE_URL}/content-planner`;
const PUBLIC_RECIPE_URL = `${BASE_URL}/public/stories-by-recipe`;

export const fetchSuggestionAdvocates = (
  data: ISuggestionAdvocatesMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${CONTENT_PLANNER_URL}/suggestion-advocates`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const toggleStoryLinkType = (
  token: string,
  data: any,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/story/toggle-share-link-type`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export interface IRecipeStoryMeta {
  recipeId: string;
  contentPackageId: string;
  companyId?: string;
}

export const getRecipeStory = (
  data: IRecipeStoryMeta,
  token: string
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${RECIPE_URL}/get-story-by-recipe/${data.recipeId}/${data.contentPackageId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export interface IShortenShareLinkMeta {
  originalLink: string;
  contentPackageId: string;
}

export const getShortenShareLink = (
  data: IShortenShareLinkMeta,
  token: string
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${CONTENT_PLANNER_URL}/get-shorten-link?originalLink=${data.originalLink}&contentPackageId=${data.contentPackageId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getStoryByRecipe = (
  data: IRecipeStoryMeta
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${PUBLIC_RECIPE_URL}/${data.companyId}/${data.contentPackageId}/${data.recipeId}`,
    headers: {
    },
  });
};
