import { put, call, takeLatest, select } from 'redux-saga/effects';

import { addCard } from 'services/payment';
import getSavedCards from 'store/reducers/getSavedCards';
import { AsyncAction } from 'types/Action';
import {
  AddCardMeta,
  AddCardPayload
} from 'types/store/AddCard';
import { Store } from 'types/store/Store';

import {
  addCardActions,
  getSavedCardsActions
} from '../actions';

function* addCardRequest(
  action: AsyncAction<AddCardMeta, AddCardPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(addCard, action.meta, state.auth.token);
      yield put(addCardActions.success(data));
    } catch (e: any) {
      yield put(addCardActions.failed(e.message));
    }
  }
}

function* addCardSuccess(
  action: AsyncAction<AddCardMeta, AddCardPayload>
) {
  yield put(getSavedCardsActions.request({}));
}

function* addCardWatcher(): any {
  yield takeLatest(addCardActions.REQUEST, addCardRequest);
  yield takeLatest(addCardActions.SUCCESS, addCardSuccess);
}

export default addCardWatcher;
