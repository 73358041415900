import {
  UpdateRecipeDetailNoteMeta,
  UpdateRecipeDetailNotePayload,
} from 'types/store/ContentPlanner';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_REPICE_DETAIL_NOTE';
const asyncAction = createAsyncAction<
  UpdateRecipeDetailNoteMeta,
  UpdateRecipeDetailNotePayload
>(ns);

const updateRecipeDetailNoteActions = {
  ...asyncAction,
};

export default updateRecipeDetailNoteActions;
