import {
  CreateRecipeDetailNoteMeta,
  CreateRecipeDetailNotePayload,
} from 'types/store/ContentPlanner';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CREATE_REPICE_DETAIL_NOTE';
const asyncAction = createAsyncAction<
  CreateRecipeDetailNoteMeta,
  CreateRecipeDetailNotePayload
>(ns);

const createRecipeDetailNoteActions = {
  ...asyncAction,
};

export default createRecipeDetailNoteActions;
