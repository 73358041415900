import { put, takeLatest } from 'redux-saga/effects';

import { Action } from 'types/Action';
import { WriteStoryPayload } from 'types/store/WriteStoryState';

import { writeStoryActions } from '../actions';

function* writeStoryRunner(action: Action<WriteStoryPayload>) {
  //
}

function* writeStoryWatcher(): any {
  yield takeLatest(writeStoryActions.UPDATE, writeStoryRunner);
}

export default writeStoryWatcher;
