import React from 'react';

import { Camera, Subtract, UploadFile } from 'components/base/SVG';
import Add from 'components/module/Add';

import { AddFileProps } from './AddFile.props';
import {
  Container,
  PreviewContainer,
  PreviewFile,
  DeleteBadge,
} from './AddFile.style';

const AddFile = (props: AddFileProps): React.JSX.Element => {
  const {
    file,
    onSelectFile,
    onRemoveFile,
    icon = UploadFile,
    fileTypes = '',
    height,
    title,
    svgColor,
    svgSize = 20,
    disabled,
    width,
    margin,
    acceptFile,
    multiple,
  } = props;

  //configured to accept blob or File types
  let determineBlob: any = file;
  if (file instanceof File) {
    determineBlob = URL.createObjectURL(file);
  }

  return (
    <Add
      onClickImage={onSelectFile}
      title={title || 'Drag or Click to upload a file'}
      withTitle={true}
      Svg={icon}
      svgColor={svgColor}
      svgSize={svgSize}
      height={height || '120px'}
      fileTypes={fileTypes}
      disabled={disabled}
      width={width}
      margin={margin}
      acceptFile={acceptFile}
      multiple={multiple}
    />
  );
};

export default React.memo(AddFile);
