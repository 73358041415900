import { put, call, takeLatest, select } from 'redux-saga/effects';

import { EMPLOYER_ROUTES } from 'consts';
import { register } from 'services/auth';
import { AsyncAction } from 'types/Action';
import { RegisterMeta, RegisterPayload } from 'types/store/RegisterState';
import { Store } from 'types/store/Store';

import { registerActions, loginActions } from '../actions';

function* registerRequest(action: AsyncAction<RegisterMeta, RegisterPayload>) {
  try {
    const { data } = yield call(register, action.meta);

    yield put(registerActions.success({ ...data, ...action.meta }));
  } catch (e: any) {
    yield put(registerActions.failed(e.message));
  }
}

function* registerSuccess(action: AsyncAction<RegisterMeta, RegisterPayload>) {
  // Add token from auth has been call wehn access register page
  const state: Store = yield select();
  const { email, password } = action.payload;
  yield put(
    loginActions.request({
      email,
      password,
      fromSignup: true,
      token: state.auth.sessionToken,
    }),
  );
}

// function* registerFailed(action: AsyncAction<RegisterMeta, RegisterPayload>) {}

function* registerWatcher(): any {
  yield takeLatest(registerActions.REQUEST, registerRequest);
  yield takeLatest(registerActions.SUCCESS, registerSuccess);
  // yield takeLatest(registerActions.FAILED, registerFailed);
}

export default registerWatcher;
