import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getValueCompanyTrackingContentPackage } from 'services/company';
import { AsyncAction } from 'types/Action';
import { GetValueCompanyTrackingContentPackageMeta, GetValueCompanyTrackingContentPackagePayload } from 'types/store/GetValueCompanyTrackingContentPackage';
import { Store } from 'types/store/Store';

import { getValueCompanyTrackingContentPackageActions } from '../actions';

function* getValueCompanyTrackingContentPackageRequest(action: AsyncAction<GetValueCompanyTrackingContentPackageMeta, GetValueCompanyTrackingContentPackagePayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getValueCompanyTrackingContentPackage, state.auth.token);
      yield put(getValueCompanyTrackingContentPackageActions.success(data));
    } catch (e: any) {
      yield put(getValueCompanyTrackingContentPackageActions.failed(e.message));
    }
  }
}

// function* getValueCompanyTrackingContentPackageSuccess(action: AsyncAction<GetValueCompanyTrackingContentPackageMeta, GetValueCompanyTrackingContentPackagePayload>): any {
//   yield put()
// }

function* getValueCompanyTrackingContentPackageWatcher(): any {
  yield takeLatest(getValueCompanyTrackingContentPackageActions.REQUEST, getValueCompanyTrackingContentPackageRequest);
  // yield takeLatest(getValueCompanyTrackingContentPackageActions.SUCCESS, getValueCompanyTrackingContentPackageSuccess);
}

export default getValueCompanyTrackingContentPackageWatcher;
