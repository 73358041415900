import React from 'react';

import NotFoundView from './NotFound.view';

const NotFound = (): React.JSX.Element => {
  const generatedProps = {
    // generated props here
  };
  return <NotFoundView {...generatedProps} />;
};

export default NotFound;
