import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getMoreQuestionCustomML } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  GetMoreQuestionForCustomMeta,
  GetMoreQuestionForCustomPayload,
} from 'types/store/GetMoreQuestionForCustom';
import { Store } from 'types/store/Store';

import {
  getMoreQuestionForCustomActions,
  storyBuilderActions,
} from '../actions';

function UrlSearchParams(state: Store, action: GetMoreQuestionForCustomMeta) {
  const keyPoint = state.storyBuilder.customData?.keyPoint;
  const topic = state.storyBuilder.customData?.type || action.topic;
  const headline = state.storyBuilder.customData?.topic;
  const listKeyPoint: any = keyPoint && keyPoint.split('\n');
  const params = new URLSearchParams();
  headline && params.append('headline', headline);
  topic && params.append('topic', topic !== 'Custom Story' ? topic : '');
  for (const i in listKeyPoint) {
    params.append('keyPoints[]', listKeyPoint[i].toString());
  }
  return params.toString() || '';
}

function* getMoreQuestionForCustomRequest(
  action: AsyncAction<
    GetMoreQuestionForCustomMeta,
    GetMoreQuestionForCustomPayload
  >,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getMoreQuestionCustomML,
        UrlSearchParams(state, action.meta),
        state.auth.token,
      );

      if (data.data.length) {
        const selectedTopic = {
          id: data.data[0]?.audience_research_id || '',
          headline: action.meta.headline,
          translated_headline: '',
          headline_edit: '',
          is_custom: null,
          link: '',
        };
        yield put(getMoreQuestionForCustomActions.success(data.data));
        yield put({
          type: 'Audient_id',
          payload: data.data[0].audience_research_id,
        });
        yield put(storyBuilderActions.update(selectedTopic));
      } else yield put(getMoreQuestionForCustomActions.success([]));
    } catch (e: any) {
      console.log('GET_MORE_QUESTION_FOR_CUSTOM', e);
      yield put(getMoreQuestionForCustomActions.failed([]));
    }
  }
}

function* getMoreQuestionForCustomSuccess(
  action: AsyncAction<
    GetMoreQuestionForCustomMeta,
    GetMoreQuestionForCustomPayload
  >,
) {
  // yield put()
}

function* getMoreQuestionForCustomWatcher(): any {
  yield takeLatest(
    getMoreQuestionForCustomActions.REQUEST,
    getMoreQuestionForCustomRequest,
  );
  yield takeLatest(
    getMoreQuestionForCustomActions.SUCCESS,
    getMoreQuestionForCustomSuccess,
  );
}

export default getMoreQuestionForCustomWatcher;
