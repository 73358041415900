import React from 'react';

import { SVGProps } from './SVG.props';

const Subtract = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill={fill || 'none'}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.444 1.685a10 10 0 1111.111 16.63A10 10 0 014.444 1.685zm9.265 4.605a1 1 0 00-1.42 0L10 8.59l-2.29-2.3a1.004 1.004 0 00-1.42 1.42L8.59 10l-2.3 2.29a.999.999 0 000 1.42 1 1 0 001.42 0l2.29-2.3 2.29 2.3a1 1 0 001.42 0 1 1 0 000-1.42L11.41 10l2.3-2.29a1 1 0 000-1.42z"
        fill={fill || '#ffffff'}
      />
    </svg>
  );
};

export default Subtract;
