import React from 'react';

import { SVGProps } from './SVG.props';

const ChevronRight = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 184 184"
      fill="none"
      {...props}
    >
      <circle
        className="first-ring"
        opacity={0.16}
        cx={92}
        cy={92}
        r={92}
        fill="#FFB8AD"
      />
      <circle
        className="second-ring"
        opacity={0.16}
        cx={92}
        cy={92}
        r={76}
        fill="#FFB8AD"
      />
      <circle className="third-ring" cx={92} cy={92} r={56} fill="#FFB8AD" />
      <path
        d="M91.999 96a5.333 5.333 0 005.333-5.333v-8a5.333 5.333 0 10-10.667 0v8A5.333 5.333 0 0092 96zm-2.667-13.333a2.667 2.667 0 015.333 0v8a2.667 2.667 0 01-5.333 0v-8zm13.333 8a1.333 1.333 0 10-2.666 0 8 8 0 01-16 0 1.334 1.334 0 00-2.667 0 10.667 10.667 0 009.333 10.573V104H88a1.334 1.334 0 000 2.667h8a1.334 1.334 0 000-2.667h-2.667v-2.76a10.664 10.664 0 009.333-10.573z"
        fill="#070550"
      />
    </svg>
  );
};

export default ChevronRight;
