import { put, call, takeLatest, select } from 'redux-saga/effects';

import { transcribeVideo } from 'services/question';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import { TranscribeVideoMeta, TranscribeVideoPayload } from 'types/store/TranscribeVideo';

import { transcribeVideoActions } from '../actions';

function* transcribeVideoRequest(action: AsyncAction<TranscribeVideoMeta, TranscribeVideoPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(transcribeVideo, action.meta, state.auth.token);
      yield put(transcribeVideoActions.success(data));
    } catch (e: any) {
      yield put(transcribeVideoActions.failed(e.message));
    }
  }
}

// function* transcribeVideoSuccess(action: AsyncAction<TranscribeVideoMeta, TranscribeVideoPayload>): any {
//   yield put()
// }

function* transcribeVideoWatcher(): any {
  yield takeLatest(transcribeVideoActions.REQUEST, transcribeVideoRequest);
  // yield takeLatest(transcribeVideoActions.SUCCESS, transcribeVideoSuccess);
}

export default transcribeVideoWatcher;
