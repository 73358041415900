import pathOr from 'ramda/es/pathOr';

import { storyEditorUploadFinalActions } from 'store/actions';
import { Action } from 'types/Action';
import {
    StoryEditorUploadFinalState,
    StoryEditorUploadFinalPayload
} from 'types/store/StoryEditorUploadFinalState';


const initState: StoryEditorUploadFinalState = {
    uploadedFile: undefined,
    enteredLink: ''
}

const initAction: Action<StoryEditorUploadFinalPayload> = {
    type: '',
    payload: {}
}

export default (state = initState, action = initAction): any => {
    const reducer = () => {
        return {
            ...state,
            ...action.payload
        }
    }

    return pathOr(state, [action.type], {
        [storyEditorUploadFinalActions.UPDATE]: reducer(),
        [storyEditorUploadFinalActions.CLEAR]: initState
    })
}