import { put, call, takeLatest, select } from 'redux-saga/effects';

import { maskAsDefaultEmailTemplate } from 'services/email-template';
import { AsyncAction } from 'types/Action';
import { MaskAsDefaultEmailTemplateMeta, MaskAsDefaultEmailTemplatePayload } from 'types/store/MaskAsDefaultEmailTemplate';
import { Store } from 'types/store/Store';

import { maskAsDefaultEmailTemplateActions } from '../actions';

function* maskAsDefaultEmailTemplateRequest(action: AsyncAction<MaskAsDefaultEmailTemplateMeta, MaskAsDefaultEmailTemplatePayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(maskAsDefaultEmailTemplate, action.meta, state.auth.token);
      yield put(maskAsDefaultEmailTemplateActions.success(data));
    } catch (e: any) {
      yield put(maskAsDefaultEmailTemplateActions.failed(e.message));
    }
  }
}

// function* maskAsDefaultEmailTemplateSuccess(action: AsyncAction<MaskAsDefaultEmailTemplateMeta, MaskAsDefaultEmailTemplatePayload>): any {
//   yield put()
// }

function* maskAsDefaultEmailTemplateWatcher(): any {
  yield takeLatest(maskAsDefaultEmailTemplateActions.REQUEST, maskAsDefaultEmailTemplateRequest);
  // yield takeLatest(maskAsDefaultEmailTemplateActions.SUCCESS, maskAsDefaultEmailTemplateSuccess);
}

export default maskAsDefaultEmailTemplateWatcher;
