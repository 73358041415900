import { put, call, takeLatest, select } from 'redux-saga/effects';

import { EMPLOYER_ROUTES } from 'consts';
import { updateRevision } from 'services/story';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateRevisionMeta,
  UpdateRevisionPayload,
} from 'types/store/UpdateRevisionState';

import { updateRevisionActions, getFinalStoryPiecesActions } from '../actions';

function* updateRevisionRequest(
  action: AsyncAction<UpdateRevisionMeta, UpdateRevisionPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateRevision,
        action.meta,
        state.auth.token,
      );
      yield put(updateRevisionActions.success(data));
    } catch (e: any) {
      yield put(updateRevisionActions.failed(e.message));
    }
  }
}

function* updateRevisionSuccess(
  action: AsyncAction<UpdateRevisionMeta, UpdateRevisionPayload>,
) {
  // yield put(getFinalStoryPiecesActions.request({}));
  // window.location.reload();
}

function* updateRevisionWatcher(): any {
  yield takeLatest(updateRevisionActions.REQUEST, updateRevisionRequest);
  yield takeLatest(updateRevisionActions.SUCCESS, updateRevisionSuccess);
}

export default updateRevisionWatcher;
