export enum DRAFTAI {
  DRAFTAI_ACTION = '@@_DRAFTAI_ACTION',
  DRAFTAI_VERSION_STORY_ID = '@@_DRAFTAI_VERSION_STORY_ID',
  LANGUAGE_TRANSLATE = '@@_LANGUAGE_TRANSLATE',
  CONTENT_LANGUAGE = '@@_CONTENT_LANGUAGE',
}
export enum CREATE_AI_PROMP {
  CREATE_AI_PROMP_REQUEST = '@@_CREATE_AI_PROMP_REQUEST',
  CREATE_AI_PROMP_SUCCESS = '@@_CREATE_AI_PROMP_SUCCESS',
  CREATE_AI_PROMP_ERROR = '@@_CREATE_AI_PROMP_SUCCESS',
  CREATE_AI_PROMP_DELETE = '@@_CREATE_AI_PROMP_DELETE',
  CREATE_AI_PROMP_CLEAR = '@@_CREATE_AI_PROMP_CLEAR',
}

export enum DRAFT_AI {
  DRAFT_AI_REQUEST = '@@_DRAFT_AI_REQUEST',
  DRAFT_AI_SUCCESS = '@@_DRAFT_AI_SUCCESS',
  DRAFT_AI_ERROR = '@@_DRAFT_AI_ERROR',
  DRAFT_AI_REFINE = '@@_DRAFT_AI_REFINE',
}

export enum DRAFT_AI_REWRITE {
  DRAFT_AI_REWRITE_REQUEST = '@@_DRAFT_AI_REWRITE_REQUEST',
  DRAFT_AI_REWRITE_SUCCESS = '@@_DRAFT_AI_REWRITE_REQUEST',
}

export enum DRAFT_AI_RESTFUL {
  DRAFT_AI_RESTFUL_REQUEST = '@@_DRAFT_AI_RESTFUL_REQUEST',
  DRAFT_AI_RESTFUL_SUCCESS = '@@_DRAFT_AI_RESTFUL_SUCCESS',
  DRAFT_AI_RESTFUL_ERROR = '@@_DRAFT_AI_RESTFUL_ERROR',
  DRAFT_AI_RESTFUL_REFINE = '@@_DRAFT_AI_RESTFUL_REFINE',
}

export enum DRAFT_AI_SK {
  DRAFT_AI_SK_REQUEST = '@@_DRAFT_AI_SK_REQUEST',
  DRAFT_AI_SK_SUCCESS = '@@_DRAFT_AI_SK_SUCCESS',
  DRAFT_AI_SK_ERROR = '@@_DRAFT_AI_SK_ERROR',
  DRAFT_AI_SK_UPDATE = '@@DRAFT_AI_SK_UPDATE',
  DRAFT_AI_SK_UPDATE_GENERATED = '@@DRAFT_AI_SK_UPDATE_GENERATED',
  DRAFT_AI_SK_INIT = '@@_DRAFT_AI_SK_INIT',
  ADD_DRAFT_CONTENT_FROM_STORY_DETAIL = '@@_ADD_DRAFT_CONTENT_FROM_STORY_DETAIL',
  TRANSLATE_REQUEST = '@@_TRANSLATE_REQUEST',
  TRANSLATE_SUCCESS = '@@_TRANSLATE_SUCCESS',
}

export enum UPDATE_PROMP_AI {
  UPDATE_PROMP_AI_REQUEST = '@@_UPDATE_PROMP_AI_REQUEST',
  UPDATE_PROMP_AI_SUCCESS = '@@_UPDATE_PROMP_AI_SUCCESS',
}

export enum GENERATE_KEY_TOPIC {
  GENERATE_KEY_TOPIC_REQUEST = '@@_GENERATE_KEY_TOPIC_REQUEST',
  GENERATE_KEY_TOPIC_HEADLINE_REQUEST = '@@_GENERATE_KEY_TOPIC_HEADLINE_REQUEST',
  GENERATE_KEY_TOPIC_INTRODUCTION_REQUEST = '@@_GENERATE_KEY_TOPIC_INTRODUCTION_REQUEST',
  GENERATE_KEY_TOPIC_CONCLUSION_REQUEST = '@@_GENERATE_KEY_TOPIC_CONCLUSION_REQUEST',
  GENERATE_KEY_TOPIC_SUCCESS = '@@_GENERATE_KEY_TOPIC_SUCCESS',
  GENERATE_KEY_TOPIC_ERROR = '@@_GENERATE_KEY_TOPIC_ERROR',
  GENERATE_KEY_TOPIC_OVERALL_SUCCESS = '@@_GENERATE_KEY_TOPIC_OVERALL_SUCCESS',
  GENERATE_KEY_TOPIC_CUSTOM_REQUEST = '@@_GENERATE_KEY_TOPIC_CUSTOM_REQUEST',
  GENERATE_KEY_TOPIC_CUSTOM_SUCCESS = '@@_GENERATE_KEY_TOPIC_CUSTOM_SUCCESS',
}

export enum CREATE_NEW_STORY {
  CREATE_NEW_STORY_REQUEST = '@@_CREATE_NEW_STORY_REQUEST',
  CREATE_NEW_STORY_SUCCESS = '@@_CREATE_NEW_STORY_SUCCESS',
  CREATE_NEW_STORY_ERROR = '@@_CREATE_NEW_STORY_ERROR',
}

export enum OVERWRITE_STORY {
  OVERWRITE_STORY_REQUEST = '@@_OVERWRITE_STORY_REQUEST',
  OVERWRITE_STORY_SUCCESS = '@@_OVERWRITE_STORY_SUCCESS',
  OVERWRITE_STORY_ERROR = '@@_OVERWRITE_STORY_ERROR',
}

export enum APPLY_PROMPT {
  APPLY_PROMPT_REQUEST = '@@_APPLY_PROMPT_REQUEST',
  APPLY_PROMPT_CLEAR = '@@_APPLY_PROMPT_CLEAR',
}

export enum INDEX_APPLY_PROMPT {
  INDEX_APPLY_PROMPT_REQUEST = '@@_INDEX_APPLY_PROMPT_REQUEST',
  INDEX_APPLY_PROMPT_CLEAR = '@@_INDEX_APPLY_PROMPT_CLEAR',
}

export enum GENERATE_INTRODUCTION {
  GENERATE_INTRODUCTION_REQUEST = '@@_GENERATE_INTRODUCTION_REQUEST',
  GENERATE_INTRODUCTION_CLEAR = '@@_GENERATE_INTRODUCTION_CLEAR',
}

export enum GENERATE_CONCLUSION {
  GENERATE_CONCLUSION_REQUEST = '@@_GENERATE_CONCLUSION_REQUEST',
  GENERATE_CONCLUSION_CLEAR = '@@_GENERATE_CONCLUSION_CLEAR',
}

export enum GET_BRAND_GUIDELINE {
  GET_BRAND_GUIDELINE_REQUEST = '@@_GET_BRAND_GUIDELINE_REQUEST',
  GET_BRAND_GUIDELINE_SUCCESS = '@@_GET_BRAND_GUIDELINE_SUCCESS',
  GET_BRAND_GUIDELINE_ERROR = '@@_GET_BRAND_GUIDELINE_ERROR',
}

export enum UPDATE_BRAND_GUIDELINE {
  UPDATE_BRAND_GUIDELINE_REQUEST = '@@_UPDATE_BRAND_GUIDELINE_REQUEST',
  UPDATE_BRAND_GUIDELINE_SUCCESS = '@@_UPDATE_BRAND_GUIDELINE_SUCCESS',
  UPDATE_BRAND_GUIDELINE_ERROR = '@@_UPDATE_BRAND_GUIDELINE_ERROR',
}

export enum NAVBAR_NAVIGATE {
  SHOW_AI_PROMP = 'SHOW_AI_PROMP',
  STEP_AI_PROMP = 'STEP_AI_PROMP',
  STYLING_REVIEW = 'STYLING_REVIEW',
  SKIP_STYLING_REVIEW = 'SKIP_STYLING_REVIEW',
  MOVE_STYLING_REVIEW = 'MOVE_STYLING_REVIEW',
  CLEAR_ALL = 'CLEAR_ALL',
  BACK_AIPROMP = 'BACK_AIPROMP',
  NEXT_DRAFTAI = '',
}

export enum DOWNLOAD_CONTENT {
  DOWNLOAD_CONTENT_TRIGGER = 'DOWNLOAD_CONTENT_TRIGGER',
  DOWNLOAD_CONTENT_REQUEST = 'DOWNLOAD_CONTENT_REQUEST',
  DOWNLOAD_CONTENT_SUCCESS = 'DOWNLOAD_CONTENT_SUCCESS',
  DOWNLOAD_CONTENT_CLEAR = 'DOWNLOAD_CONTENT_CLEAR',
}

export enum DRAFT_PREVIEW {
  DRAFT_PREVIEW_TOGGLE_HIDDEN = 'DRAFT_PREVIEW_TOGGLE_HIDDEN',
  DRAFT_PREVIEW_REQUEST = 'DRAFT_PREVIEW_REQUEST',
  DRAFT_PREVIEW_SUCCESS = 'DRAFT_PREVIEW_SUCCESS',
  DRAFT_PREVIEW_NEW_SECTION_SUCCESS = 'DRAFT_PREVIEW_NEW_SECTION_SUCCESS',
  DRAFT_PREVIEW_NEW_SECTION_CLEAR = 'DRAFT_PREVIEW_NEW_SECTION_CLEAR',
  DRAFT_LIST_SECTIONS = 'DRAFT_LIST_SECTIONS',
}

export enum DRAFT_FORM_AI_BLOG {
  DRAFT_FORM_AI_BLOG_REQUEST = 'DRAFT_FORM_AI_BLOG_REQUEST',
  DRAFT_FORM_AI_BLOG_CLEAR = 'DRAFT_FORM_AI_BLOG_CLEAR',
}

export enum LIST_QUESTION_ANSWER {
  LIST_QUESTION_ANSWER_UPDATE = 'LIST_QUESTION_ANSWUPDATEEST',
  LIST_QUESTION_ANSWER_INIT = 'LIST_QUESTION_ANSWER_INIT',
  LIST_QUESTION_ANSWER_CLEAR = 'LIST_QUESTION_ANSWER_CLEAR',
}

export enum CONTENT_TYPE {
  HEADLINE = 'HEADLINE',
  CONCLUSION = 'CONCLUSION',
  INTRODUCTION = 'INTRODUCTION',
  PARAGRAPH = 'PARAGRAPH',
}

export enum GET_PARAGRAPH_VERSION {
  GET_PARAGRAPH_VERSION_REQUEST = '@@_GET_PARAGRAPH_VERSION_REQUEST',
  GET_PARAGRAPH_VERSION_SUCCESS = '@@_GET_PARAGRAPH_VERSION_SUCCESS',
  GET_PARAGRAPH_VERSION_ERROR = '@@_GET_PARAGRAPH_VERSION_ERROR',
}

export enum GET_STORY_VERSION {
  GET_STORY_VERSION_REQUEST = '@@_GET_STORY_VERSION_REQUEST',
  GET_STORY_VERSION_SUCCESS = '@@_GET_STORY_VERSION_SUCCESS',
  GET_STORY_VERSION_ERROR = '@@_GET_STORY_VERSION_ERROR',
  GET_STORY_VERSION_CLEAR = '@@_GET_STORY_VERSION_CLEAR',
}
