import { put, call, takeLatest, select } from 'redux-saga/effects';

import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import { TrackAutoLoginMeta } from 'types/store/TrackLoginMeta';
import { GenericResponse } from 'types/GenericResponse';
import { trackLogin } from 'services/auth';

import { autoTrackLoginAction } from '../actions';

function* trackAutoLogin(
  action: AsyncAction<TrackAutoLoginMeta, GenericResponse>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(trackLogin, action.meta, state.auth.token);
      yield put(autoTrackLoginAction.success(data));
    } catch (e: any) {
      yield put(autoTrackLoginAction.failed(e.message));
    }
  }
}

function* trackAutoLoginWatcher(): any {
  yield takeLatest(autoTrackLoginAction.REQUEST, trackAutoLogin);
}

export default trackAutoLoginWatcher;
