import FileSaver from 'file-saver';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import { updateFeedbackPosition } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateFeedbackPositionMeta,
  UpdateFeedbackPositionPayload
} from 'types/store/UpdateFeedbackPosition';

import { updateFeedbackPositionActions } from '../actions';
function* updateFeedbackPositionRequest(
  action: AsyncAction<UpdateFeedbackPositionMeta, UpdateFeedbackPositionPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(updateFeedbackPosition, action.meta, state.auth.token);
      yield put(updateFeedbackPositionActions.success(data));
    } catch (e: any) {
      yield put(updateFeedbackPositionActions.failed(e.message));
    }
  }
}

function* updateFeedbackPositionSuccess(
  action: AsyncAction<UpdateFeedbackPositionMeta, UpdateFeedbackPositionPayload>
) {
  //
}

function* updateFeedbackPositionWatcher(): any {
  yield takeLatest(updateFeedbackPositionActions.REQUEST, updateFeedbackPositionRequest);
  yield takeLatest(updateFeedbackPositionActions.SUCCESS, updateFeedbackPositionSuccess);
}

export default updateFeedbackPositionWatcher;
