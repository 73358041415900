import { put, call, takeLatest, select } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import _get from 'lodash/get';

import { generateJobAIContent, syncAPIToSmartRecruiter } from 'services/jobs';
import { Store } from 'types/store/Store';
import { AsyncAction, Action } from 'types/Action';
import CountWords from 'utils/CountWords';
import {
  JobGenerateMeta,
  JobGeneratePayLoad,
  JobContent,
} from 'types/store/JobsState';
import {
  GET_ALL_JOB_GENERATE,
  SYNC_TO_API,
  getAddditional,
  getJobDescription,
  getJobDetail,
  getQualifications,
} from 'store/actions/jobs/generateContent';
import { jobDescriptionSRDB } from 'indexDB';
import navigationService from 'utils/navigationService';

import { generateJobAIContentActions, openToast } from '../../actions';

function exportHTML(data: string, userInfo: any) {
  const current_time = new Date().getTime().toString();
  const nameDocs = `${userInfo.user.first_name}-${userInfo.user.last_name}-${userInfo.audience_research.headline}`;
  const header =
    "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
    "xmlns:w='urn:schemas-microsoft-com:office:word' " +
    "xmlns='http://www.w3.org/TR/REC-html40'>" +
    "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
  const footer = '</body></html>';
  const sourceHTML = header + data + footer;

  const source =
    'data:application/vnd.ms-word;charset=utf-8,' +
    encodeURIComponent(sourceHTML);
  const fileDownload = document.createElement('a');
  document.body.appendChild(fileDownload);
  fileDownload.href = source;
  fileDownload.download = `${nameDocs}_${current_time}.doc`;
  fileDownload.click();
  document.body.removeChild(fileDownload);
}

const mapContent = (value: string[]) => {
  let string = '';
  if (!value.length) return string;
  for (const i in value) {
    if (!i) {
      string += `<p><strong>${value[i]}</strong></p>`;
    }
    string += '<br/>' + value[i];
  }
  return string.replaceAll('\r\n', '</br></br>').replaceAll('\n', '</br>');
};

function* jobsGenerateAIcontent(
  action: AsyncAction<JobGenerateMeta, JobGeneratePayLoad>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { ...requestData } = action.meta;
      const { data } = yield generateJobAIContent(
        requestData,
        state.auth.token,
      );

      yield put(
        generateJobAIContentActions.success(buildJobContent(data.data)),
      );

      if (data?.data?.sections?.length >= 4) {
        yield put(getJobDetail(mapContent(data.data.sections[1])));
        yield put(getJobDescription(mapContent(data.data.sections[2])));
        yield put({
          type: GET_ALL_JOB_GENERATE.QUALIFICATIONS,
          payload: mapContent(data.data.sections[3]),
        });

        const mapDataContent = (data: string[][]) => {
          console.log('mapDataContent - data', data);
          const total = data.length;
          let value = '';
          for (let i = 4; i < total; i++) {
            value += mapContent(data[i]) + '<br />';
          }
          return value;
        };

        yield put({
          type: GET_ALL_JOB_GENERATE.ADITIONAL,
          payload: mapDataContent(data?.data.sections),
        });
      }
      //}
    } catch (e: any) {
      yield put(
        openToast(
          'Unsupported document structure. Please ensure the job description follows the correct layout.',
          'error',
          'An error has occurred',
        ),
      );
      yield put(generateJobAIContentActions.failed(e.message));
    }
  }
}

const buildJobContent = (data: any): string => {
  if (data.raw_text?.length > 0) {
    /*
        const h_title = `Job Title</br>`;
        const title = `${data.options.rawResponse.job_title.trim() || ''}</br></br>`;
        const introduction = `${data.options.rawResponse.introduction.trim() || ''}</br></br>`;
        const h_description = `Job Description</br>`;
        const description = `${data.options.rawResponse.job_description.trim() || ''}</br></br>`;
        const h_qualifications = `Qualifications</br>`;
        const qualifications = `${data.options.rawResponse.job_qualification.trim() || ''}</br></br>`;
        const h_culture = `Culture</br>`;
        const culture = `${data.options.rawResponse.company_culture.trim() || ''}</br></br>`;
        const h_benefits = `Benefits</br>`;
        const benefits = `${data.options.rawResponse.company_benefit.trim() || ''}</br></br>`;
        const cta = `${data.options.rawResponse.call_to_action.trim()}</br></br>`;
        const conclusion = `${data.options.rawResponse.conclusion.trim()}</br>`;
        const html = `${h_title}${title}${introduction}${h_description}${description}${h_qualifications}${qualifications}${h_culture}${culture}${h_benefits}${benefits}${cta}${conclusion}`.replaceAll(
          '\n',
          '</br>',
        );
        return html;
    */

    const html = `${data.raw_text.trim() || ''}`.replaceAll('\n', '</br>');
    return html;
  } else return '';
  /*
  const html = `${data.options.rawResponse.raw_ja_text.trim() || ''}</br>`.replaceAll(
    '\r\n',
    '</br></br>',
  ).replaceAll('\n', '</br>');
  */
};

function* jobsSyncContent(action: Action) {
  const state: Store = yield select();

  const {
    sycnJob,
    jobTitleEdit,
    jobQualifications,
    jobDecription,
    jobDetail,
    aditional,
    videos,
  } = state.JobsReducer;

  if (state.auth.token) {
    try {
      const result = {
        jobId: action.payload.jobId,
        jobAdsId: action.payload.jobAdsId,
        provider: 'SMART_RECRUITER',
        title: jobTitleEdit,
        language: {
          code: 'en',
        },
        jobAd: {
          companyDescription: {
            title: 'Company Description',
            text: jobDetail,
          },
          jobDescription: {
            title: 'Job Description',
            text: jobDecription,
          },
          qualifications: {
            title: 'Qualifications',
            text: jobQualifications,
          },
          additionalInformation: {
            title: 'Additional Information',
            text: aditional,
          },
          videos: {
            urls: videos.filter((e) => e),
          },
        },
      };

      const { data } = yield syncAPIToSmartRecruiter(result, state.auth.token);
      if (data) {
        //put action sync to store in page detail job
        yield put(
          openToast(
            'Job ads updates have been saved in your SmartRecruiter portal.',
            'success',
            'Updated on SmartRecruiter!',
          ),
        );
        yield call(
          navigationService.goTo,
          `/employer/jobs-system/editor?id=${action.payload.jobAdsId}&jobId=${action.payload.jobId}`,
        );
        yield put(generateJobAIContentActions.clear({}));
      } else {
        openToast('Job ads updated fail', 'error', 'An error has occurred');
      }
    } catch (e: any) {
      console.log(e);
      yield put(generateJobAIContentActions.failed(e.message));
    }
  }
}

function* generateJobAIContentWatcher(): any {
  yield takeLatest(generateJobAIContentActions.REQUEST, jobsGenerateAIcontent);
  yield takeLatest(SYNC_TO_API.SYNC_TO_API_REQUEST, jobsSyncContent);
}

export default generateJobAIContentWatcher;

/*

const buildCompanyContent = (content: [[string, string]]): string => {
  return content?.reduce((out, value) => {
    const heading = value[0] || '';
    const paragraph = value[1] || '';
    return out.concat(
      `<p><span style="font-size:20px"><strong>${heading.trim()}</strong></span></p>${paragraph.trim()}</br>`,
    );
  }, '');
};

function* generateAIContentForAdvocateReponses(
  response: IndividualContent,
  headline: string,
  isDownLoad?: boolean,
  idStory?: string,
) {
  const headlineFormatted = `<p><span style="font-size:20px"><strong>${headline}</strong></span></p>`;
  const dataGenerate: string = buildIndividualContent(response);
  const noteAI: string = `${headlineFormatted}${dataGenerate}`.replaceAll(
    '\r\n',
    '</br></br>',
  );
  const replaceAll = noteAI.replaceAll('\n', '</br>')
  yield put(storyEditorActions.success(replaceAll));
  const noteAIContent = JSON.parse(
    localStorage.getItem('noteAIContent') || '[]',
  );
  if (response.introduction || response.sections.length) {
    noteAIContent.push({
      noteAIContent: noteAI,
      idStory,
    });
  }
  localStorage.setItem('noteAIContent', JSON.stringify(noteAIContent));
  if (isDownLoad) {
    const state: Store = yield select();
    exportHTML(dataGenerate, state?.getStoryForEditor.data?.data?.story);
  }
}
*/
/*
function* getDataAISupport(action: any) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const findListAdvocate = JSON.parse(
        localStorage.getItem('listQuestion') || '[]',
      );
      const activeQuestion = findListAdvocate.find(
        (item: any) =>
          item.idAdvocate === action.payload.idAdvocate &&
          item.idQuestion === action.payload.idQuestion,
      );

      if (!activeQuestion || activeQuestion?.count < 5) {
        const { data } = yield aiSupportBAPortal(
          action.payload,
          state.auth.token,
        );
        yield put({
          type: AI_GENERATE.AI_GENERATE_SUCCESS,
          payload: data.data,
        });
        action.payload.handleChange(data.data);
        const newAnswerIsGenerate = action.payload.currentAnswer + data.data;
        const length = CountWords(newAnswerIsGenerate as string);
        yield put({
          type: SAVE_LENG_WORD.SAVE_LENG_WORD_BEGIN,
          payload: {
            length,
            answer: newAnswerIsGenerate,
          },
        });
        if (!activeQuestion) {
          const newActiveQuestion = {
            count: 1,
            idQuestion: action.payload.idQuestion,
            idAdvocate: action.payload.idAdvocate,
          };
          findListAdvocate.push(newActiveQuestion);
          localStorage.setItem(
            'listQuestion',
            JSON.stringify(findListAdvocate),
          );
          yield put({
            type: AI_GENERATE.AI_GENERATE_LIMIT,
            payload: 1,
            wordCound: action.payload.wordCound,
          });
        }

        if (activeQuestion?.count < 5) {
          const newListAdvocate = findListAdvocate.map((item: any) => {
            if (
              item.idAdvocate === action.payload.idAdvocate &&
              item.idQuestion === action.payload.idQuestion
            ) {
              return {
                ...item,
                count: item.count + 1,
              };
            }
            return item;
          });
          localStorage.setItem('listQuestion', JSON.stringify(newListAdvocate));
          yield put({
            type: AI_GENERATE.AI_GENERATE_LIMIT,
            payload: activeQuestion?.count + 1,
            wordCound: action.payload.wordCound,
          });
        }
      } else {
        yield put({
          type: AI_GENERATE.AI_GENERATE_LIMIT,
          payload: 5,
          wordCound: action.payload.wordCound,
        });
      }
    } catch (e: any) {
      console.log('ai support error', e);
    }
  }
}

function* checkLimitedAISupport(action: any) {
  const state: Store = yield select();
  const limitCallApi = state.getUserById.data?.data.profile.limit_call_ai;
  const findListAdvocate = JSON.parse(
    localStorage.getItem('listQuestion') || '[]',
  );
  const activeQuestion = findListAdvocate.find(
    (item: any) =>
      item.idAdvocate === action.payload.idAdvocate &&
      item.idQuestion === action.payload.idQuestion,
  );

  if (
    !activeQuestion ||
    (limitCallApi && activeQuestion?.count < limitCallApi)
  ) {
    if (limitCallApi && activeQuestion?.count < limitCallApi) {
      yield put({
        type: AI_GENERATE.AI_GENERATE_LIMIT,
        payload: activeQuestion?.count,
      });
    }
    if (!activeQuestion) {
      yield put({
        type: AI_GENERATE.AI_GENERATE_LIMIT,
        payload: 0,
      });
    }
  } else {
    yield put({
      type: AI_GENERATE.AI_GENERATE_LIMIT,
      payload: limitCallApi,
    });
  }
}*/
