import { createAsyncReducer } from 'utils/Redux';
import {
  CreateRecipeDetailNoteMeta,
  CreateRecipeDetailNotePayload,
} from 'types/store/ContentPlanner';

import { createRecipeDetailNoteActions } from '../actions';

export default createAsyncReducer<
  CreateRecipeDetailNoteMeta,
  CreateRecipeDetailNotePayload
>(createRecipeDetailNoteActions);
