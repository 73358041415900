import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getTrendingByCommunity } from 'services/community';
import { AsyncAction } from 'types/Action';
import {
  GetTrendingByCommunityMeta,
  GetTrendingByCommunityPayload,
} from 'types/store/GetTrendingByCommunityState';
import { Store } from 'types/store/Store';

import { getTrendingByCommunityActions } from '../actions';

function* getTrendingByCommunityRequest(
  action: AsyncAction<GetTrendingByCommunityMeta, GetTrendingByCommunityPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getTrendingByCommunity,
        action.meta,
        state.auth.token
      );
      yield put(getTrendingByCommunityActions.success(data));
      // yield put(getPagesbyCompanyIdActions.success(data));
    } catch (e: any) {
      // yield put(getPagesbyCompanyIdActions.failed(e.message));
    }
  } else {
    // yield put(getPagesbyCompanyIdActions.failed('Token not found'));
  }
}

//   function* forgotPasswordSuccess(action: AsyncAction<ForgotPasswordMeta, ForgotPasswordPayload>) {
//     yield put(push(EMPLOYER_ROUTES.LOGIN));
//   }

function* getTrendingByCommunityWatcher(): any {
  yield takeLatest(
    getTrendingByCommunityActions.REQUEST,
    getTrendingByCommunityRequest
  );
  // yield takeLatest(forgotPasswordActions.SUCCESS, forgotPasswordSuccess);
}

export default getTrendingByCommunityWatcher;
