import { put, call, takeLatest, select } from 'redux-saga/effects';

import { upsertShowcasePage, uploadCompanyLogo } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
    UpsertShowcasePageMeta,
    UpsertShowcasePagePayload
} from 'types/store/UpsertShowcasePageState';

import {
    upsertShowcasePageActions,
    getShowcaseCompanyActions
} from '../actions';

function* upsertShowcasePageRequest(
    action: AsyncAction<UpsertShowcasePageMeta, UpsertShowcasePagePayload>
) {
    const state: Store = yield select();
    const logo: any = action.meta?.showcasePageCompany?.logo;
    const heroBanner: any = action.meta?.showcasePageCompany?.heroBanner;
    if (state.auth.token) {
        try {
            if (logo instanceof File) {
                const { data } = yield call(
                    uploadCompanyLogo,
                    logo,
                    state.auth.token
                );
                if (action.meta.showcasePageCompany != undefined) action.meta.showcasePageCompany.logo = data.status === 200 ? data.data?.image?.Location : '';
            }
            if (heroBanner instanceof File) {
                const { data } = yield call(
                    uploadCompanyLogo,
                    heroBanner,
                    state.auth.token
                )
                action.meta.showcasePageCompany.heroBanner = data.data?.image?.Location
            }
            const { data } = yield call(
                upsertShowcasePage,
                action.meta,
                state.auth.token
            )
            yield put(upsertShowcasePageActions.success(data));

        } catch (error: any) {
            yield put(upsertShowcasePageActions.failed(error.message));
        }
    }
}


function* upsertShowcasePageSuccess() {
    // yield put(upsertShowcasePageActions.clear());
    // yield put(getShowcaseCompanyActions.request({}));
}

function* upsertShowcasePageWatcher(): any {
    yield takeLatest(
        upsertShowcasePageActions.REQUEST,
        upsertShowcasePageRequest
    );
    yield takeLatest(
        upsertShowcasePageActions.SUCCESS,
        upsertShowcasePageSuccess
    )
}

export default upsertShowcasePageWatcher;
