import React from 'react';

import { SVGProps } from './SVG.props';

const UploadImage = (props: SVGProps): React.JSX.Element => {
  const { fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 1.5h21v21h-21v-21zm4.825 6.558c.129.119.204.291.183.464h.01v6.246a.724.724 0 01-.193.614L4.839 17.18v.237h4.2v-.237l-1.476-1.81a.721.721 0 01-.204-.613V9.362l3.672 8.044h.43l3.167-8.044v6.407c0 .172 0 .205-.108.312l-1.141 1.11v.237h5.535v-.237l-1.099-1.077a.326.326 0 01-.129-.313V7.866a.35.35 0 01.13-.313l1.13-1.077v-.237h-3.898l-2.768 6.936-3.166-6.936H5.022v.237l1.303 1.583z"
        fill={fill === undefined ? '#12100E' : fill}
      />
    </svg>
  );
};

export default UploadImage;
