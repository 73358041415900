import axios from 'axios';

import { API } from 'consts';
import { AddGetSupportNotificationStateMeta } from 'types/store/AddGetSupportNotificationState';
import { GetNotificationsByUserIdMeta } from 'types/store/GetNotificationsByUserIdState';
import { MarkReadNotificationsStateMeta } from 'types/store/MarkReadNotificationsState';
import { SendApprovalNotificationMeta } from 'types/store/SendApprovalNotificationState';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const NOTIFICATIONS_URL = `${BASE_URL}/notification`;

export const getNotificationsByUserId = (
  data: GetNotificationsByUserIdMeta,
  token: string
): any => {
  return axios({
    method: 'get',
    url: `${NOTIFICATIONS_URL}/getNotificationsByUserId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const markReadNotifications = (
  data: MarkReadNotificationsStateMeta,
  token: string
): any => {
  return axios({
    method: 'post',
    url: `${NOTIFICATIONS_URL}/markRead`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const addGetSupportNotification = (
  data: AddGetSupportNotificationStateMeta,
  token: string
): any => {
  return axios({
    method: 'post',
    url: `${NOTIFICATIONS_URL}/addGetSupportNotification`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const sendApprovalNotification = (
  data: SendApprovalNotificationMeta,
  token: string
): any => {
  return axios({
    method: 'post',
    url: `${NOTIFICATIONS_URL}/sendApprovalNotification`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};
