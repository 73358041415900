import { put, call, takeLatest, select } from 'redux-saga/effects';

import { answerMultiQuestion } from 'services/question';
import { AsyncAction } from 'types/Action';
import { AnswerQuestionsMeta, AnswerQuestionsPayload } from 'types/store/AnswerQuestions';
import { Store } from 'types/store/Store';

import { answerQuestionsActions } from '../actions';

function* answerQuestionsRequest(action: AsyncAction<AnswerQuestionsMeta, AnswerQuestionsPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(answerMultiQuestion, action.meta, state.auth.token);
      yield put(answerQuestionsActions.success(data));
    } catch (e: any) {
      yield put(answerQuestionsActions.failed(e.message));
    }
  }
}

// function* answerQuestionsSuccess(action: AsyncAction<AnswerQuestionsMeta, AnswerQuestionsPayload>): any {
//   yield put()
// }

function* answerQuestionsWatcher(): any {
  yield takeLatest(answerQuestionsActions.REQUEST, answerQuestionsRequest);
  // yield takeLatest(answerQuestionsActions.SUCCESS, answerQuestionsSuccess);
}

export default answerQuestionsWatcher;
