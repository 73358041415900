import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getContentPackageDiscussion } from 'services/content-package-discussion';
import { AsyncAction } from 'types/Action';
import { GetContentPackageDiscussionByIdAndEmailMeta, GetContentPackageDiscussionByIdAndEmailPayload } from 'types/store/GetContentPackageDiscussionByIdAndEmail';
import { Store } from 'types/store/Store';

import { getContentPackageDiscussionByIdAndEmailActions } from '../actions';

function* getContentPackageDiscussionByIdAndEmailRequest(action: AsyncAction<GetContentPackageDiscussionByIdAndEmailMeta, GetContentPackageDiscussionByIdAndEmailPayload>): any {
  try {
    const { data } = yield call(getContentPackageDiscussion, action.meta);
    yield put(getContentPackageDiscussionByIdAndEmailActions.success(data));
  } catch (e: any) {
    yield put(getContentPackageDiscussionByIdAndEmailActions.failed(e.message));
  }
}

// function* getContentPackageDiscussionByIdAndEmailSuccess(action: AsyncAction<GetContentPackageDiscussionByIdAndEmailMeta, GetContentPackageDiscussionByIdAndEmailPayload>): any {
//   yield put()
// }

function* getContentPackageDiscussionByIdAndEmailWatcher(): any {
  yield takeLatest(getContentPackageDiscussionByIdAndEmailActions.REQUEST, getContentPackageDiscussionByIdAndEmailRequest);
  // yield takeLatest(getContentPackageDiscussionByIdAndEmailActions.SUCCESS, getContentPackageDiscussionByIdAndEmailSuccess);
}

export default getContentPackageDiscussionByIdAndEmailWatcher;
