import { put, call, takeLatest, select } from 'redux-saga/effects';

import { fetchContentCost } from 'services/analytics';
import { analyticContentCostActions } from 'store/actions';
import { IAnalyticContentCostMeta } from 'store/actions/analytics/ROI/contentCost';
import { AsyncAction } from 'types/Action';
import { GenericResponse } from 'types/GenericResponse';
import { Store } from 'types/store/Store';

function* getContentCost(
  action: AsyncAction<IAnalyticContentCostMeta, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        fetchContentCost,
        action.meta,
        state.auth.token,
      );
      yield put(analyticContentCostActions.success(data));
    } catch (e: any) {
      yield put(analyticContentCostActions.failed(e.message));
    }
  }
}

function* analyticWatcher(): any {
  yield takeLatest(analyticContentCostActions.REQUEST, getContentCost);
}

export default analyticWatcher;
