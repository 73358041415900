import React from 'react';

import { SVGProps } from './SVG.props';

const InfoFilled = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '28'}
      height={height || '28'}
      viewBox="0 0 28 28"
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.59175 2.9137C8.78441 1.44861 11.3623 0.666626 13.9994 0.666626C15.7503 0.666626 17.4841 1.0115 19.1018 1.68157C20.7195 2.35163 22.1893 3.33375 23.4274 4.57187C24.6656 5.80998 25.6477 7.27984 26.3177 8.89751C26.9878 10.5152 27.3327 12.249 27.3327 14C27.3327 16.637 26.5507 19.2149 25.0856 21.4076C23.6205 23.6002 21.5382 25.3092 19.1018 26.3184C16.6655 27.3275 13.9846 27.5916 11.3982 27.0771C8.81174 26.5626 6.43597 25.2928 4.57126 23.4281C2.70656 21.5634 1.43669 19.1876 0.922218 16.6012C0.407748 14.0148 0.671793 11.3339 1.68096 8.89751C2.69013 6.46117 4.3991 4.37878 6.59175 2.9137ZM14.5064 7.43995C14.1818 7.3066 13.8177 7.3066 13.4931 7.43995C13.3294 7.50341 13.1799 7.59857 13.0531 7.71995C12.9353 7.84955 12.8406 7.99837 12.7731 8.15995C12.6984 8.31819 12.6619 8.49172 12.6664 8.66662C12.6654 8.8421 12.699 9.01604 12.7654 9.1785C12.8317 9.34095 12.9295 9.4887 13.0531 9.61329C13.1827 9.73106 13.3315 9.82576 13.4931 9.89329C13.6951 9.97627 13.9143 10.0084 14.1316 9.98677C14.349 9.96517 14.5576 9.89051 14.7393 9.76938C14.921 9.64824 15.0702 9.48433 15.1737 9.29205C15.2773 9.09977 15.332 8.885 15.3331 8.66662C15.3281 8.3136 15.19 7.97546 14.9464 7.71995C14.8196 7.59857 14.6701 7.50341 14.5064 7.43995ZM13.9997 12.6666C13.6461 12.6666 13.307 12.8071 13.0569 13.0571C12.8069 13.3072 12.6664 13.6463 12.6664 14V19.3333C12.6664 19.6869 12.8069 20.026 13.0569 20.2761C13.307 20.5261 13.6461 20.6666 13.9997 20.6666C14.3533 20.6666 14.6925 20.5261 14.9425 20.2761C15.1926 20.026 15.3331 19.6869 15.3331 19.3333V14C15.3331 13.6463 15.1926 13.3072 14.9425 13.0571C14.6925 12.8071 14.3533 12.6666 13.9997 12.6666Z"
        fill={fill || '#E35D32'}
      />
    </svg>
  );
};

export default InfoFilled;
