import React from 'react';

import { useField } from 'formik';

import TextArea from 'components/base/TextArea';

import { FormikTextAreaProps } from './FormikTextArea.props';

const FormikTextArea = (props: FormikTextAreaProps): React.JSX.Element => {
  const {
    name,
    onFocus = () => null,
    errorString,
    noBorder,
    noRightBorder,
    RightComponentWidth,
    mt,
    ...textFieldProps
  } = props;
  const [field, meta] = useField<string>(name);

  const onChange = (event: React.ChangeEvent<any>) => {
    if (!props.readOnly) {
      field.onChange(event);
      if (props.onChange) {
        props.onChange(event);
      }
    }
  };

  return (
    <TextArea
      {...field}
      {...textFieldProps}
      id={name}
      onChange={onChange}
      onFocus={onFocus}
      error={errorString ?? (meta.touched ? meta.error : undefined)}
      mt={mt}
      highlightError={props.highlightError}
    />
  );
};

export default React.memo(FormikTextArea);
