import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getBillingAddress } from 'services/payment';
import { AsyncAction } from 'types/Action';
import {
  GetBillingAddressMeta,
  GetBillingAddressPayload
} from 'types/store/GetBillingAddress';
import { Store } from 'types/store/Store';

import { getBillingAddressActions } from '../actions';

function* getBillingAddressRequest(
  action: AsyncAction<GetBillingAddressMeta, GetBillingAddressPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getBillingAddress, action.meta, state.auth.token);
      yield put(getBillingAddressActions.success(data));
    } catch (e: any) {
      yield put(getBillingAddressActions.failed(e.message));
    }
  } else {
    yield put(getBillingAddressActions.failed('Token not found'));
  }
}

function* getBillingAddressSuccess(
  action: AsyncAction<GetBillingAddressMeta, GetBillingAddressPayload>
) {
  // 
}

function* getBillingAddressWatcher(): any {
  yield takeLatest(getBillingAddressActions.REQUEST, getBillingAddressRequest);
  yield takeLatest(getBillingAddressActions.SUCCESS, getBillingAddressSuccess);
}

export default getBillingAddressWatcher;
