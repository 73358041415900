import { Reducer } from 'redux';

import { defaultSettingForm } from 'routes/Employer/Widget/Setting/Setting.props';
import {
  createDefaultFormAction,
  createWidgetAction,
  editWidgetAction,
  resetWidgetAction,
  updateWidgetAction,
  widgetAction,
} from 'store/actions';
import { Action, AsyncAction } from 'types/Action';
import { Widget } from 'types/store/Widget';

const initState: Widget = {
  isLoading: false,
  listWidget: {
    pageNumber: 1,
    perPage: 15,
    pageCount: 2,
    totalRowCount: 16,
    rows: [],
  },
  initForm: defaultSettingForm,
  idWidget: '',
  filterParam: {
    page: 1,
    categories: [],
  },
};

const WidgetReducer: Reducer<Widget, AsyncAction> = (
  state = initState,
  { type, payload, meta },
) => {
  switch (type) {
    case widgetAction.list_widget.REQUEST: {
      return {
        ...state,
        filterParam: { ...state.filterParam, ...meta },
        isLoading: true,
      };
    }
    case widgetAction.list_widget.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        listWidget: payload,
        filterParam: {
          ...state.filterParam,
          page: payload.pageNumber,
        },
      };
    }
    case createWidgetAction.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case createWidgetAction.SUCCESS: {
      return {
        ...state,
        idWidget: payload,
        isLoading: false,
      };
    }
    case updateWidgetAction.SUCCESS: {
      return {
        ...state,
        idWidget: payload,
      };
    }
    case editWidgetAction.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case editWidgetAction.SUCCESS: {
      return {
        ...state,
        initForm: payload,
        isLoading: false,
      };
    }
    case resetWidgetAction.REQUEST: {
      return {
        ...state,
        initForm: meta,
      };
    }
    case createDefaultFormAction.REQUEST: {
      return {
        ...state,
        initForm: meta,
      };
    }
    default: {
      return state;
    }
  }
};

export default WidgetReducer;
