import { put, call, takeLatest, select } from 'redux-saga/effects';

import { deleteSmsTemplate } from 'services/sms-template';
import { AsyncAction } from 'types/Action';
import { DeleteSmsTemplateMeta, DeleteSmsTemplatePayload } from 'types/store/DeleteSmsTemplate';
import { Store } from 'types/store/Store';

import { deleteSmsTemplateActions } from '../actions';

function* deleteSmsTemplateRequest(action: AsyncAction<DeleteSmsTemplateMeta, DeleteSmsTemplatePayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(deleteSmsTemplate, action.meta, state.auth.token);
      yield put(deleteSmsTemplateActions.success(data));
    } catch (e: any) {
      yield put(deleteSmsTemplateActions.failed(e.message));
    }
  }
}

// function* deleteSmsTemplateSuccess(action: AsyncAction<DeleteSmsTemplateMeta, DeleteSmsTemplatePayload>): any {
//   yield put()
// }

function* deleteSmsTemplateWatcher(): any {
  yield takeLatest(deleteSmsTemplateActions.REQUEST, deleteSmsTemplateRequest);
  // yield takeLatest(deleteSmsTemplateActions.SUCCESS, deleteSmsTemplateSuccess);
}

export default deleteSmsTemplateWatcher;
