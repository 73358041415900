import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getFinalLibraryCalendar } from 'services/story';
import { AsyncAction } from 'types/Action';
import { getFinalLibraryCalendarMeta, getFinalLibraryCalendarPayload } from 'types/store/getFinalLibraryCalendarState';
import { Store } from 'types/store/Store';

import { getFinalLibraryCalendarActions } from '../actions';

function* getFinalLibraryCalendarRequest(
  action: AsyncAction<getFinalLibraryCalendarMeta, getFinalLibraryCalendarPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(getFinalLibraryCalendar, action.meta, state.auth.token);
      yield put(getFinalLibraryCalendarActions.success(data));
    } catch (e: any) {
      yield put(getFinalLibraryCalendarActions.failed(e.message));
    }
  } else {
    yield put(getFinalLibraryCalendarActions.failed('Token not found'));
  }
}

function* getStoryCalendarByCompanySuccess(
  action: AsyncAction<getFinalLibraryCalendarMeta, getFinalLibraryCalendarPayload>
) {
  // yield put();
}

function* getStoryCalendarByCompanyWatcher(): any {
  yield takeLatest(
    getFinalLibraryCalendarActions.REQUEST,
    getFinalLibraryCalendarRequest
  );

  yield takeLatest(
    getFinalLibraryCalendarActions.SUCCESS,
    getStoryCalendarByCompanySuccess
  );
}

export default getStoryCalendarByCompanyWatcher;
