import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getShowcaseFeedbacks } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import {
  GetShowcaseFeedbacksMeta,
  GetShowcaseFeedbacksPayload,
} from 'types/store/GetShowcaseFeedbacksState';
import { Store } from 'types/store/Store';

import { getShowcaseFeedbacksActions } from '../actions';

function* getShowcaseFeedbacksRequest(
  action: AsyncAction<GetShowcaseFeedbacksMeta, GetShowcaseFeedbacksPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(
        getShowcaseFeedbacks,
        action.meta,
        state.auth.token
      );
      yield put(getShowcaseFeedbacksActions.success(data));
    } catch (e: any) {
      yield put(getShowcaseFeedbacksActions.failed(e.message));
    }
  } else {
    yield put(getShowcaseFeedbacksActions.failed('Token not found'));
  }
}

function* getShowcaseFeedbacksSuccess(
  action: AsyncAction<GetShowcaseFeedbacksMeta, GetShowcaseFeedbacksPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getShowcaseFeedbacksWatcher(): any {
  yield takeLatest(
    getShowcaseFeedbacksActions.REQUEST,
    getShowcaseFeedbacksRequest
  );
  yield takeLatest(
    getShowcaseFeedbacksActions.SUCCESS,
    getShowcaseFeedbacksSuccess
  );
}

export default getShowcaseFeedbacksWatcher;
