import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getStoryRepurposedEventLogs } from 'services/story';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  GetStoryRepurposedEventLogMeta,
  GetStoryRepurposedEventLogPayload,
} from 'types/store/GetStoryRepurposedEventLog';

import { getStoryRepurposedEventLogActions } from '../actions';

function* getStoryRepurposedEventLogRequest(
  action: AsyncAction<
    GetStoryRepurposedEventLogMeta,
    GetStoryRepurposedEventLogPayload
  >,
): any {
  const state: Store = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        getStoryRepurposedEventLogs,
        action.meta,
        state.auth.token,
      );
      yield put(getStoryRepurposedEventLogActions.success(data));
    } catch (e: any) {
      yield put(getStoryRepurposedEventLogActions.failed(e.message));
    }
  }
}

// function* getStoryRepurposedEventLogSuccess(action: AsyncAction<getStoryRepurposedEventLogMeta, getStoryRepurposedEventLogPayload>): any {
//   yield put()
// }

function* getStoryRepurposedEventLogWatcher(): any {
  yield takeLatest(
    getStoryRepurposedEventLogActions.REQUEST,
    getStoryRepurposedEventLogRequest,
  );
  // yield takeLatest(getStoryRepurposedEventLogActions.SUCCESS, getStoryRepurposedEventLogSuccess);
}

export default getStoryRepurposedEventLogWatcher;
