import { put, call, takeLatest, select } from 'redux-saga/effects';

import { removeStory } from 'services/story';
import { AsyncAction } from 'types/Action';
import { RemoveStoryMeta, RemoveStoryPayload } from 'types/store/RemoveStory';
import { Store } from 'types/store/Store';

import { removeStoryActions } from '../actions';

function* removeStoryRequest(action: AsyncAction<RemoveStoryMeta, RemoveStoryPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(removeStory, action.meta, state.auth.token);
      yield put(removeStoryActions.success(data));
    } catch (e: any) {
      yield put(removeStoryActions.failed(e.message));
    }
  }
}

// function* removeStorySuccess(action: AsyncAction<RemoveStoryMeta, RemoveStoryPayload>): any {
//   yield put()
// }

function* removeStoryWatcher(): any {
  yield takeLatest(removeStoryActions.REQUEST, removeStoryRequest);
  // yield takeLatest(removeStoryActions.SUCCESS, removeStorySuccess);
}

export default removeStoryWatcher;
