import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getDefaultContentPackages } from 'services/content-package';
import { AsyncAction } from 'types/Action';
import { GetListDefaultContentPackagesMeta, GetListDefaultContentPackagesPayload } from 'types/store/GetListDefaultContentPackages';
import { Store } from 'types/store/Store';

import { getListDefaultContentPackagesActions } from '../actions';

function* getListDefaultContentPackagesRequest(action: AsyncAction<GetListDefaultContentPackagesMeta, GetListDefaultContentPackagesPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getDefaultContentPackages, state.auth.token);
      yield put(getListDefaultContentPackagesActions.success(data));
    } catch (e: any) {
      yield put(getListDefaultContentPackagesActions.failed(e.message));
    }
  }
}

// function* getListDefaultContentPackagesSuccess(action: AsyncAction<GetListDefaultContentPackagesMeta, GetListDefaultContentPackagesPayload>): any {
//   yield put()
// }

function* getListDefaultContentPackagesWatcher(): any {
  yield takeLatest(getListDefaultContentPackagesActions.REQUEST, getListDefaultContentPackagesRequest);
  // yield takeLatest(getListDefaultContentPackagesActions.SUCCESS, getListDefaultContentPackagesSuccess);
}

export default getListDefaultContentPackagesWatcher;
