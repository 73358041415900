import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';

import { useSelector } from 'react-redux';

import Button from 'components/base/Button';
import {
  SESendBack,
  SESendBack2,
  SEDownload,
  SEUploadFinal,
  Edit,
  Transcribe,
} from 'components/base/SVG';
import VerticalDots from 'components/base/SVG/ThreeDots';
import VideoModal from 'components/module/VideoModal';
import { Store } from 'types/store/Store';
import Portal from 'components/layout/Portal';

import { VideoPreviewProps } from './VideoPreview.props';
import {
  Container,
  VideoPlayer,
  PlayIcon,
  VideoContainer,
  UploadFinalAction,
  SendBackAction,
  DownloadAction,
  ActionList,
  ActionListLeft,
  EditAction,
  ContainerAction,
  ActionItem,
  Action,
  TextActionItem,
} from './VideoPreview.style';
import DownloadVideo from './DownloadVideo';

const VideoPreviewView = (props: VideoPreviewProps): React.JSX.Element => {
  const {
    url,
    poster,
    question,
    is_button,
    fullscreen,
    width,
    height,
    playIconSize,
    status,
    onDownload,
    onUploadFinal,
    onSendBack,
    showActionIcons = false,
    videoId,
    transcribe,
    noneTranscribe,
    onTranscribe = () => null,
    wasTranscribed,
  } = props;
  const [video, setVideo] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const actionRef = useRef<any>(null);

  let portrait = true;
  const videoRef = useRef<HTMLVideoElement>(null);

  if (videoRef !== undefined) {
    const video = videoRef.current;
    if (video !== undefined && video !== null) {
      if (video['videoWidth'] >= video['videoHeight']) {
        portrait = false;
      }
    }
  }

  const getPopupPosition = useMemo(() => {
    const getChildrenPosition = actionRef.current?.getBoundingClientRect();
    const top = getChildrenPosition?.y ? getChildrenPosition?.y : 0;
    const left = getChildrenPosition?.x ? getChildrenPosition?.x - 60 : 0;
    return {
      top: top,
      left: left,
    };
  }, [showPopup]);

  // const onEditVideo = () => {
  //   window.open(
  //     `${process.env.REACT_APP_STUDIO_DOMAIN}/home?contentId=${videoId}&contentType=STORY_VIDEO`,
  //     '__blank',
  //   );
  // };

  const onClick = useCallback(() => {
    setVideo(true);
  }, [video]);

  const porTraitonChange = useCallback(() => {
    portrait = false;
  }, []);

  let previewMode = (
    <DownloadVideo
      transcribe={transcribe}
      noneTranscribe={noneTranscribe}
      wasTranscribed={wasTranscribed}
      url={url}
      question={question}
      portrait={porTraitonChange}
      status={status}
      onDownload={onDownload}
      onSendBack={onSendBack}
      onTranscribe={onTranscribe}
      onUploadFinal={onUploadFinal}
      width={width}
      height={height}
      onClick={onClick}
      playIconSize={playIconSize}
      showActionIcons={showActionIcons}
      poster={poster}
    />
    // <VideoContainer
    //   onClick={() => setVideo(true)}
    //   heightCustom={height}
    //   widthCustom={width}
    // >
    //   <VideoPlayer
    //     height={height || '265'}
    //     width={width || '160'}
    //     ref={videoRef}
    //     preload="metadata"
    //   >
    //     <source src={url} type={'video/mp4'}></source>
    //   </VideoPlayer>
    //   <PlayIcon width={20} height={20} playIconSize={playIconSize}></PlayIcon>
    //   {showActionIcons && (
    //     <>
    //       <ActionList
    //         onClick={(e) => e.stopPropagation()}
    //         className="action_btn"
    //       >
    //         {status?.toUpperCase() !== 'SENT_BACK' && (
    //           <UploadFinalAction
    //             title="Upload Final"
    //             onClick={() =>
    //               typeof onUploadFinal === 'function' && onUploadFinal()
    //             }
    //           >
    //             <SEUploadFinal width={13} height={16} />
    //           </UploadFinalAction>
    //         )}
    //         <DownloadAction
    //           title="Download"
    //           onClick={() => typeof onDownload === 'function' && onDownload()}
    //         >
    //           <SEDownload width={13} height={15} />
    //         </DownloadAction>
    //         <SendBackAction
    //           title={'More'}
    //           ref={actionRef}
    //           onClick={(e) => {
    //             e.stopPropagation();
    //             setShowPopup(true);
    //           }}
    //         >
    //           <VerticalDots width={25} height={25} />
    //         </SendBackAction>
    //         <Portal visible={showPopup} onClick={() => setShowPopup(false)}>
    //           <ContainerAction
    //             top={getPopupPosition.top}
    //             left={getPopupPosition.left}
    //           >
    //             <ActionItem
    //               onClick={() => {
    //                 setShowPopup(false);
    //                 // onEdit && onEdit();
    //               }}
    //             >
    //               <div
    //                 title={
    //                   status?.toUpperCase() !== 'SENT_BACK'
    //                     ? 'Send back'
    //                     : 'Sent back'
    //                 }
    //                 onClick={() =>
    //                   status?.toUpperCase() !== 'SENT_BACK' &&
    //                   typeof onSendBack === 'function' &&
    //                   onSendBack()
    //                 }
    //               >
    //                 {status?.toUpperCase() !== 'SENT_BACK' ? (
    //                   <Action>
    //                     <SESendBack width={25} height={25} />
    //                     <TextActionItem>Send back</TextActionItem>
    //                   </Action>
    //                 ) : (
    //                   <Action>
    //                     <SESendBack2 width={25} height={25} />
    //                     <TextActionItem>Sent back</TextActionItem>
    //                   </Action>
    //                 )}
    //               </div>
    //             </ActionItem>
    //             {transcribe || noneTranscribe || wasTranscribed ? null : (
    //               <ActionItem
    //                 onClick={() => {
    //                   setShowPopup(false);
    //                 }}
    //               >
    //                 <div
    //                   title={'Transcribe'}
    //                   onClick={() => {
    //                     onTranscribe();
    //                   }}
    //                 >
    //                   <Action>
    //                     <div style={{ margin: '4px 4px 0 6px' }}>
    //                       <Transcribe width={16} height={16} />
    //                     </div>
    //                     <TextActionItem>Transcribe</TextActionItem>
    //                   </Action>
    //                 </div>
    //               </ActionItem>
    //             )}
    //           </ContainerAction>
    //         </Portal>
    //       </ActionList>
    //     </>
    //   )}
    // </VideoContainer>
  );

  if (is_button === true) {
    previewMode = (
      <Button
        variant={'secondary'}
        onClick={() => setVideo(true)}
        type={'button'}
        style={{ width: '184px' }}
        textVariant={'body'}
        text={'See Video'}
        smaller
      />
    );
  }

  // reload video thumbnail
  useEffect(() => {
    videoRef?.current?.load();
  }, [url]);

  return (
    <Container style={is_button === true ? { margin: 0 } : {}}>
      {previewMode}
      <VideoModal
        question={question}
        isOpen={video}
        onClickClose={() => {
          setVideo(false);
        }}
        onClickDone={() => console.log('done')}
        url={url}
        isPortrait={portrait}
        fullscreen={fullscreen}
        videoId={videoId}
      ></VideoModal>
    </Container>
  );
};

export default React.memo(VideoPreviewView);
