import axios, { AxiosPromise } from 'axios';

import { API } from 'consts';
import { SignInOKtaMeta } from 'store/actions/okta.action';
import { GenericResponse } from 'types/GenericResponse';

const BASE_URL = `${API.URL}/${API.VERSION}`;

export const authWithOkta = (
  data: SignInOKtaMeta,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/auth/sso-login`,
    data: data,
  });
};

export const authOktaLogout = (data: { companyId: string, action: 'SSO' | '' }, token: string): any => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/auth/logout`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data
  });
};
