import { put, call, takeLatest, select } from 'redux-saga/effects';

import { deleteEmailTemplate } from 'services/email-template';
import { AsyncAction } from 'types/Action';
import { DeleteEmailTemplateMeta, DeleteEmailTemplatePayload } from 'types/store/DeleteEmailTemplate';
import { Store } from 'types/store/Store';

import { deleteEmailTemplateActions } from '../actions';

function* deleteEmailTemplateRequest(action: AsyncAction<DeleteEmailTemplateMeta, DeleteEmailTemplatePayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(deleteEmailTemplate, action.meta, state.auth.token);
      yield put(deleteEmailTemplateActions.success(data));
    } catch (e: any) {
      yield put(deleteEmailTemplateActions.failed(e.message));
    }
  }
}

// function* deleteEmailTemplateSuccess(action: AsyncAction<DeleteEmailTemplateMeta, DeleteEmailTemplatePayload>): any {
//   yield put()
// }

function* deleteEmailTemplateWatcher(): any {
  yield takeLatest(deleteEmailTemplateActions.REQUEST, deleteEmailTemplateRequest);
  // yield takeLatest(deleteEmailTemplateActions.SUCCESS, deleteEmailTemplateSuccess);
}

export default deleteEmailTemplateWatcher;
