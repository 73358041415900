import { put, call, takeLatest, select } from 'redux-saga/effects';

import { changeStoryDueDate } from 'services/story';
import { AsyncAction } from 'types/Action';
import { ChangeStoryDueDateMeta, ChangeStoryDueDatePayload } from 'types/store/ChangeStoryDueDate';
import { Store } from 'types/store/Store';

import { changeStoryDueDateActions } from '../actions';

function* changeStoryDueDateRequest(action: AsyncAction<ChangeStoryDueDateMeta, ChangeStoryDueDatePayload>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(changeStoryDueDate, action.meta, state.auth.token);
      yield put(changeStoryDueDateActions.success(data));
    } catch (e: any) {
      yield put(changeStoryDueDateActions.failed(e.message));
    }
  }
}

function* changeStoryDueDateSuccess(action: AsyncAction<ChangeStoryDueDateMeta, ChangeStoryDueDatePayload>) {
  // yield put(changeStoryDueDateActions.clear());
}

function* changeStoryDueDateWatcher(): any {
  yield takeLatest(changeStoryDueDateActions.REQUEST, changeStoryDueDateRequest);
  yield takeLatest(changeStoryDueDateActions.SUCCESS, changeStoryDueDateSuccess);
}

export default changeStoryDueDateWatcher;
