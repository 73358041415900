import { boolean } from 'yup';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Action, AsyncAction } from 'types/Action';
import {
  Conclusion,
  DraftAI,
  Introduction,
  LanguageTranslate,
  SectionDraftAI,
} from 'types/store/StoryEditorV2';

export interface SuggestionTopicPayload {
  story_id: string;
  status: string;
  prompt: string;
  type: PromptType;
  per_page: number;
  exclude_ids: string[];
}

export interface CreateNewStoryPayLoad {
  story_id: string;
  story_content: {
    headline: Introduction;
    introduction: Introduction;
    sections: SectionDraftAI[];
    conclusion: Conclusion;
  };
  format: string;
  content_language: string;
}
export interface UpdateStoryPayLoad {
  id: string;
  story_id: string;
  story_content: {
    headline: Introduction;
    introduction: Introduction;
    sections: SectionDraftAI[];
    conclusion: Conclusion;
  };
  format: string;
}

export interface FetchStoryTransalatePayload {
  headline: Introduction | undefined;
  introduction: Introduction;
  sections: SectionDraftAI[] | undefined;
  conclusion: Conclusion | undefined;
  format: number;
  storyId: any;
  sourceLang: string;
  targetLang: string;
}
export interface FetchSoundbitePayload {
  texts: any[] | any;
  instruction: string;
  mode: number;
  format: number;
  storyId: any;
  headline: string;
  paraId: string;
}

export interface FetchHeadlinePayload {
  keywords: any;
  instruction: string;
  mode: number;
  format: number;
  storyId: any;
  paraId: string | undefined;
}

export interface ParagraphVersionPayload {
  storyId: string;
  paraId: string;
  createdAt: string;
  action: string;
  language: string;
}
export interface StoryVersionPayload {
  storyId: string;
  page: number;
  perPage: number;
  noPaginate: boolean;
}
export interface TranslatedStoryPayload {
  storyId: string;
  contentLanguage: string;
}
export interface SaveParagraphVersionPayload {
  contents: {
    heading: string;
    paragraphs: string[];
  };
  createdAt: string;
  content_language: string;
}

export interface CreatePromptPayload {
  ref_id: string;
  prompt: string;
  type: PromptType;
}

export interface markReadyToShare {
  storyId?: string;
  libraryId?: string;
}
export interface downloadContentPayload {
  htmlString: string;
  fileName: string;
  isDownload?: boolean;
}

export interface StylingReviewPayload {
  type: CONTENT_TYPE;
  value: string;
  idx?: number;
}

export enum PromptType {
  OVERALL = 'OVERALL',
  HEADLINE = 'HEADLINE',
  INTRODUCTION = 'INTRODUCTION',
  PARAGRAPH = 'PARAGRAPH',
  CONCLUSION = 'CONCLUSION',
}

import {
  CREATE_AI_PROMP,
  DRAFT_AI,
  DRAFT_AI_SK,
  GENERATE_KEY_TOPIC,
  UPDATE_PROMP_AI,
  CREATE_NEW_STORY,
  APPLY_PROMPT,
  INDEX_APPLY_PROMPT,
  DRAFT_AI_REWRITE,
  GENERATE_INTRODUCTION,
  GET_BRAND_GUIDELINE,
  UPDATE_BRAND_GUIDELINE,
  NAVBAR_NAVIGATE,
  GENERATE_CONCLUSION,
  DOWNLOAD_CONTENT,
  DRAFT_PREVIEW,
  DRAFT_FORM_AI_BLOG,
  LIST_QUESTION_ANSWER,
  CONTENT_TYPE,
  GET_PARAGRAPH_VERSION,
  GET_STORY_VERSION,
  DRAFTAI,
  OVERWRITE_STORY,
  DRAFT_AI_RESTFUL,
} from './storyDetailV2.enum';

export const createAIPrompRequest = (): Action => ({
  type: CREATE_AI_PROMP.CREATE_AI_PROMP_REQUEST,
  payload: {},
});

export const backAndExitAIPromp = (): Action => ({
  type: CREATE_AI_PROMP.CREATE_AI_PROMP_CLEAR,
  payload: {},
});

export const generateAIDraft = (data: any): Action => ({
  type: DRAFT_AI_SK.DRAFT_AI_SK_REQUEST,
  payload: data,
});

export const generateAIDraftSuccess = (data: any): Action => ({
  type: DRAFT_AI_SK.DRAFT_AI_SK_SUCCESS,
  payload: data,
});

export const generateAIDraftHttp = (data: any): Action => ({
  type: DRAFT_AI_RESTFUL.DRAFT_AI_RESTFUL_REQUEST,
  payload: data,
});

export const updateAIPromp = (data: any): Action => ({
  type: UPDATE_PROMP_AI.UPDATE_PROMP_AI_REQUEST,
  payload: data,
});

export const initResponseAIDraft = (data: any): Action => ({
  type: DRAFT_AI_SK.DRAFT_AI_SK_INIT,
  payload: data,
});

export const updateDraftAI = (): Action => ({
  type: DRAFT_AI.DRAFT_AI_REQUEST,
  payload: {},
});

export const getSuggestionTopic = (data: SuggestionTopicPayload): Action => {
  return {
    type: GENERATE_KEY_TOPIC.GENERATE_KEY_TOPIC_REQUEST,
    payload: data,
  };
};

export const createPromptCustom = (data: CreatePromptPayload): Action => {
  return {
    type: GENERATE_KEY_TOPIC.GENERATE_KEY_TOPIC_CUSTOM_REQUEST,
    payload: data,
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getSuggestionTopicSuccess = (data: any): Action => ({
  type: GENERATE_KEY_TOPIC.GENERATE_KEY_TOPIC_SUCCESS,
  payload: data,
});

export const requestCreateNewStoryRequest = (
  data: CreateNewStoryPayLoad,
): Action => {
  return {
    type: CREATE_NEW_STORY.CREATE_NEW_STORY_REQUEST,
    payload: data,
  };
};

export const overwriteStoryRequest = (data: UpdateStoryPayLoad): Action => {
  return {
    type: OVERWRITE_STORY.OVERWRITE_STORY_REQUEST,
    payload: data,
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const requestCreateNewStorySuccess = (data: any): Action => ({
  type: CREATE_NEW_STORY.CREATE_NEW_STORY_SUCCESS,
  payload: data,
});

export const requestApplyPrompt = (): Action => ({
  type: APPLY_PROMPT.APPLY_PROMPT_REQUEST,
  payload: {},
});

export const clearApplyPrompt = (): Action => ({
  type: APPLY_PROMPT.APPLY_PROMPT_CLEAR,
  payload: {},
});

export const updateDraftAISk = (data: DraftAI): Action => ({
  type: DRAFT_AI_SK.DRAFT_AI_SK_UPDATE,
  payload: data,
});

export const addDraftContent = (data: DraftAI): Action => ({
  type: DRAFT_AI_SK.ADD_DRAFT_CONTENT_FROM_STORY_DETAIL,
  payload: data,
});

export const requestIndexApplyPrompt = (data: number): Action => ({
  type: INDEX_APPLY_PROMPT.INDEX_APPLY_PROMPT_REQUEST,
  payload: data,
});

export const clearIndexApplyPrompt = (): Action => ({
  type: INDEX_APPLY_PROMPT.INDEX_APPLY_PROMPT_CLEAR,
  payload: {},
});

export const rewriteDraftAI = (): Action => ({
  type: DRAFT_AI_REWRITE.DRAFT_AI_REWRITE_REQUEST,
  payload: {},
});

export const requestGenerateIntroduction = (): Action => ({
  type: GENERATE_INTRODUCTION.GENERATE_INTRODUCTION_REQUEST,
  payload: {},
});

export const clearGenerateIntroduction = (): Action => ({
  type: GENERATE_INTRODUCTION.GENERATE_INTRODUCTION_CLEAR,
  payload: {},
});

export const getDefaultBrandGuideline = (id: string): Action => ({
  type: GET_BRAND_GUIDELINE.GET_BRAND_GUIDELINE_REQUEST,
  payload: id,
});

export const updateDetaultBrandGuideline = (
  payload: any,
  meta: boolean,
): AsyncAction => ({
  type: UPDATE_BRAND_GUIDELINE.UPDATE_BRAND_GUIDELINE_REQUEST,
  payload,
  meta,
  error: '',
});

export const holdOnStep3 = (): Action => ({
  type: NAVBAR_NAVIGATE.STEP_AI_PROMP,
  payload: {},
});

export const holdOnStep4 = (): Action => ({
  type: NAVBAR_NAVIGATE.STYLING_REVIEW,
  payload: {},
});

export const showAIPromp = (): Action => ({
  type: NAVBAR_NAVIGATE.SHOW_AI_PROMP,
  payload: {},
});

export const skipStylingReview = (): Action => ({
  type: NAVBAR_NAVIGATE.SKIP_STYLING_REVIEW,
  payload: {},
});

export const moveToStylingReview = (): Action => ({
  type: NAVBAR_NAVIGATE.MOVE_STYLING_REVIEW,
  payload: {},
});

export const clearAllStory = (): Action => ({
  type: NAVBAR_NAVIGATE.CLEAR_ALL,
  payload: {},
});

export const requestGenerateConclusion = (): Action => ({
  type: GENERATE_CONCLUSION.GENERATE_CONCLUSION_REQUEST,
  payload: {},
});

export const clearGenerateConclusion = (): Action => ({
  type: GENERATE_CONCLUSION.GENERATE_CONCLUSION_CLEAR,
  payload: {},
});

export const backToAIPromp = (): Action => ({
  type: NAVBAR_NAVIGATE.BACK_AIPROMP,
  payload: {},
});

export const nextDraftAI = (): Action => ({
  type: NAVBAR_NAVIGATE.NEXT_DRAFTAI,
  payload: {},
});

export const updateRefine = (payload: any): Action => ({
  type: DRAFT_AI.DRAFT_AI_REFINE,
  payload,
});

export const downloadStoryContent = (data: {
  htmlString: string;
  fileName: string;
}): Action => ({
  type: DOWNLOAD_CONTENT.DOWNLOAD_CONTENT_REQUEST,
  payload: data,
});

export const patchingHTMLPreview = (data: StylingReviewPayload): Action => ({
  type: DRAFT_PREVIEW.DRAFT_PREVIEW_REQUEST,
  payload: data,
});

export const triggerDownloadContent = (data: boolean): Action => ({
  type: DOWNLOAD_CONTENT.DOWNLOAD_CONTENT_TRIGGER,
  payload: data,
});

export const clearFileGenerate = (): Action => ({
  type: DOWNLOAD_CONTENT.DOWNLOAD_CONTENT_CLEAR,
  payload: {},
});

export const requestSaveFormikAIBlog = (data: any): Action => ({
  type: DRAFT_FORM_AI_BLOG.DRAFT_FORM_AI_BLOG_REQUEST,
  payload: data,
});

export const clearFormikAIBlog = (): Action => ({
  type: DRAFT_FORM_AI_BLOG.DRAFT_FORM_AI_BLOG_CLEAR,
  payload: {},
});

export const updateListQuestionAnswer = (data: any): Action => ({
  type: LIST_QUESTION_ANSWER.LIST_QUESTION_ANSWER_UPDATE,
  payload: data,
});

export const initListQuestionAnswer = (data: any): Action => ({
  type: LIST_QUESTION_ANSWER.LIST_QUESTION_ANSWER_INIT,
  payload: data,
});

export const clearListQuestionAnswer = (): Action => ({
  type: LIST_QUESTION_ANSWER.LIST_QUESTION_ANSWER_CLEAR,
  payload: {},
});

export const blockToggleHidden = (payload: {
  idx?: number;
  hidden: boolean;
  type: string;
}): Action => ({
  type: DRAFT_PREVIEW.DRAFT_PREVIEW_TOGGLE_HIDDEN,
  payload: payload,
});

export const updateStylingPreviewDraft = (data: any): Action => ({
  type: DRAFT_PREVIEW.DRAFT_PREVIEW_SUCCESS,
  payload: data,
});

export const updateDrafPreviewNewSection = (data: any): Action => ({
  type: DRAFT_PREVIEW.DRAFT_PREVIEW_NEW_SECTION_SUCCESS,
  payload: data,
});

export const updateDrafListSections = (data: any): Action => ({
  type: DRAFT_PREVIEW.DRAFT_LIST_SECTIONS,
  payload: data,
});

export const clearDrafPreviewNewSection = (): Action => ({
  type: DRAFT_PREVIEW.DRAFT_PREVIEW_NEW_SECTION_CLEAR,
  payload: {},
});

export const getParagraphVersion = (data: any): Action => ({
  type: GET_PARAGRAPH_VERSION.GET_PARAGRAPH_VERSION_REQUEST,
  payload: data,
});

export const getStoryVersion = (data: StoryVersionPayload): Action => ({
  type: GET_STORY_VERSION.GET_STORY_VERSION_REQUEST,
  payload: data,
});

export const draftAIAction = (data: any): Action => ({
  type: DRAFTAI.DRAFTAI_ACTION,
  payload: data,
});

export const setContentLanguage = (data: string): Action => ({
  type: DRAFTAI.CONTENT_LANGUAGE,
  payload: data,
});

export const saveLanguageTransalate = (data: LanguageTranslate): Action => ({
  type: DRAFTAI.LANGUAGE_TRANSLATE,
  payload: data,
});

export const translateRequest = (): Action => ({
  type: DRAFT_AI_SK.TRANSLATE_REQUEST,
  payload: {},
});

export const translateSuccess = (): Action => ({
  type: DRAFT_AI_SK.TRANSLATE_SUCCESS,
  payload: {},
});

export const saveVersionaStoryId = (data: string): Action => ({
  type: DRAFTAI.DRAFTAI_VERSION_STORY_ID,
  payload: data,
});
