import React, { useEffect } from 'react';

import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';

import ModalContainer from 'components/layout/Modal';
import getSubtitlesActions from 'store/actions/getSubtitles';
import { Store } from 'types/store/Store';

import { VideoModalProps } from './VideoModal.props';
import { VideoWrapper } from './VideoModal.style';

const VideoModal = (props: VideoModalProps): React.JSX.Element => {
  const { isOpen, onClickClose, url, question, isPortrait, fullscreen } = props;
  const dispatch = useDispatch();
  const getSubtitlesRes = useSelector((state: Store) => state.getSubtitles);

  const videoConfig = {
    file: {
      // attributes: { crossOrigin: 'true' },
      attributes: { crossOrigin: 'anonymous' },
      tracks:
        // eslint-disable-next-line no-extra-boolean-cast
        !!getSubtitlesRes?.data?.data?.length
          ? getSubtitlesRes?.data?.data?.map((sub: any) => {
              return {
                kind: 'captions',
                src: sub.pathFile,
                srcLang: sub.language?.langCode,
                label: sub.language?.langName,
                default: false,
              };
            })
          : [],
    },
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(
        getSubtitlesActions.request({
          video_id: url,
        }),
      );
    }
  }, [isOpen]);

  return (
    <ModalContainer
      title={question}
      isOpen={isOpen}
      onClickClose={onClickClose}
      customMinWidth={isPortrait === true ? 375 : 750}
      customWidth={isPortrait === true ? 375 : 750}
      fullscreen={fullscreen}
    >
      <ReactPlayer
        light={true}
        wrapper={VideoWrapper}
        height={'100%'}
        width={'100%'}
        url={url}
        controls={true}
        playing={isOpen}
        style={{ outline: 'none', borderRadius: '12px' }}
        config={videoConfig}
      />
    </ModalContainer>
  );
};

export default React.memo(VideoModal);
