import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getHomePageHeader } from 'services/homepage';
import { AsyncAction } from 'types/Action';
import {
  GetHomePageHeaderMeta,
  GetHomePageHeaderMetaPayload,
} from 'types/store/GetHomePageHeaderState';
import { Store } from 'types/store/Store';

import { getHomePageHeaderActions } from '../actions';

function* getHomePageHeaderRequest(
  action: AsyncAction<GetHomePageHeaderMeta, GetHomePageHeaderMetaPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getHomePageHeader,
        state.auth.token
      );
      yield put(getHomePageHeaderActions.success(data));
    } catch (e: any) {
      yield put(getHomePageHeaderActions.failed(e.message));
    }
  } else {
    yield put(getHomePageHeaderActions.failed('Token not found'));
  }
}

function* getHomePageHeaderSuccess(
  action: AsyncAction<GetHomePageHeaderMeta, GetHomePageHeaderMetaPayload>
) {
  // yield put(getHomePageHeaderActions.clear());
}

function* getHomePageHeaderWatcher(): any {
  yield takeLatest(
    getHomePageHeaderActions.REQUEST,
    getHomePageHeaderRequest
  );
  yield takeLatest(
    getHomePageHeaderActions.SUCCESS,
    getHomePageHeaderSuccess
  );
}

export default getHomePageHeaderWatcher;
