import React from 'react';

import { SVGProps } from './SVG.props';

const SESendBack2 = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="comment-alt-check">
        <path
          id="Vector"
          d="M4.51333 6.09947L8.14 2.47281C8.20216 2.41065 8.25147 2.33685 8.28511 2.25564C8.31875 2.17442 8.33606 2.08738 8.33606 1.99947C8.33606 1.91157 8.31875 1.82452 8.28511 1.74331C8.25147 1.66209 8.20216 1.5883 8.14 1.52614C8.07784 1.46398 8.00405 1.41467 7.92283 1.38103C7.84162 1.34739 7.75457 1.33008 7.66667 1.33008C7.48913 1.33008 7.31887 1.4006 7.19333 1.52614L4.04 4.66614L2.80667 3.44614C2.68113 3.3206 2.51087 3.25008 2.33333 3.25008C2.1558 3.25008 1.98554 3.3206 1.86 3.44614C1.73447 3.57168 1.66394 3.74194 1.66394 3.91947C1.66394 4.09701 1.73447 4.26727 1.86 4.39281L3.56667 6.09947C3.62864 6.16196 3.70238 6.21156 3.78362 6.2454C3.86486 6.27925 3.95199 6.29667 4.04 6.29667C4.12801 6.29667 4.21515 6.27925 4.29639 6.2454C4.37763 6.21156 4.45136 6.16196 4.51333 6.09947ZM12.3333 3.99947H8.66667C8.48986 3.99947 8.32029 4.06971 8.19526 4.19474C8.07024 4.31976 8 4.48933 8 4.66614C8 4.84295 8.07024 5.01252 8.19526 5.13754C8.32029 5.26257 8.48986 5.33281 8.66667 5.33281H12.3333C12.5101 5.33281 12.6797 5.40304 12.8047 5.52807C12.9298 5.65309 13 5.82266 13 5.99947V12.4795L11.9533 11.5128C11.8304 11.3977 11.6684 11.3334 11.5 11.3328H5.66667C5.48986 11.3328 5.32029 11.2626 5.19526 11.1375C5.07024 11.0125 5 10.8429 5 10.6661V8.33281C5 8.15599 4.92976 7.98643 4.80474 7.8614C4.67971 7.73638 4.51015 7.66614 4.33333 7.66614C4.15652 7.66614 3.98695 7.73638 3.86193 7.8614C3.73691 7.98643 3.66667 8.15599 3.66667 8.33281V10.6661C3.66667 11.1966 3.87738 11.7053 4.25245 12.0804C4.62753 12.4554 5.13623 12.6661 5.66667 12.6661H11.24L13.24 14.4861C13.363 14.6012 13.5249 14.6656 13.6933 14.6661C13.7848 14.6651 13.8752 14.6471 13.96 14.6128C14.0793 14.5608 14.1807 14.475 14.2517 14.366C14.3228 14.257 14.3605 14.1296 14.36 13.9995V5.99947C14.36 5.73457 14.3074 5.4723 14.2052 5.2279C14.1031 4.98349 13.9534 4.76182 13.7648 4.57575C13.5763 4.38968 13.3526 4.24292 13.1069 4.14399C12.8612 4.04506 12.5982 3.99594 12.3333 3.99947Z"
          fill={fill || '#070550'}
        />
      </g>
    </svg>
  );
};

export default SESendBack2;
