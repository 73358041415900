import { put, call, takeLatest, select } from 'redux-saga/effects';

import { cloneStory } from 'services/story';
import { AsyncAction } from 'types/Action';
import { CloneStoryMeta, CloneStoryPayload } from 'types/store/CloneStory';
import { Store } from 'types/store/Store';

import { cloneStoryActions } from '../actions';

function* cloneStoryRequest(action: AsyncAction<CloneStoryMeta, CloneStoryPayload>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(cloneStory, action.meta, state.auth.token);
      yield put(cloneStoryActions.success(data));
    } catch (e: any) {
      yield put(cloneStoryActions.failed(e.message));
    }
  }
}

function* cloneStorySuccess(action: AsyncAction<CloneStoryMeta, CloneStoryPayload>) {
  // yield put()
}

function* cloneStoryWatcher(): any {
  yield takeLatest(cloneStoryActions.REQUEST, cloneStoryRequest);
  yield takeLatest(cloneStoryActions.SUCCESS, cloneStorySuccess);
}

export default cloneStoryWatcher;
