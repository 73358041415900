import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getSmsTemplate } from 'services/sms-template';
import { AsyncAction } from 'types/Action';
import { GetSmsTemplateMeta, GetSmsTemplatePayload } from 'types/store/GetSmsTemplate';
import { Store } from 'types/store/Store';

import { getSmsTemplateActions } from '../actions';

function* getSmsTemplateRequest(action: AsyncAction<GetSmsTemplateMeta, GetSmsTemplatePayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(getSmsTemplate, action.meta, state.auth.token);
      yield put(getSmsTemplateActions.success(data));
    } catch (e: any) {
      yield put(getSmsTemplateActions.failed(e.message));
    }
  }
}

// function* getSmsTemplateSuccess(action: AsyncAction<GetSmsTemplateMeta, GetSmsTemplatePayload>): any {
//   yield put()
// }

function* getSmsTemplateWatcher(): any {
  yield takeLatest(getSmsTemplateActions.REQUEST, getSmsTemplateRequest);
  // yield takeLatest(getSmsTemplateActions.SUCCESS, getSmsTemplateSuccess);
}

export default getSmsTemplateWatcher;
