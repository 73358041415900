import { createAsyncReducer } from 'utils/Redux';
import {
  UpdateRecipeDetailNoteMeta,
  UpdateRecipeDetailNotePayload,
} from 'types/store/ContentPlanner';

import { updateRecipeDetailNoteActions } from '../actions';

export default createAsyncReducer<
  UpdateRecipeDetailNoteMeta,
  UpdateRecipeDetailNotePayload
>(updateRecipeDetailNoteActions);
