import { AxiosError } from 'axios';
import _get from 'lodash/get';
import { put, call, takeLatest, select, all, delay } from 'redux-saga/effects';

import { EMAIL_CREATE } from 'routes/Employer/EmployeeHub/InvitePeople/InvitePeople.props';
import { TemplateType } from 'routes/Employer/Settings/Items/Template/Template.props';
import {
  qrCodeServices,
  getEmailTemplateInvitePeople,
  getSMSTemplateInvitePeople,
  createInviteTemplate,
  sendSMS,
  createSMS,
  createEmail,
  sendInvite,
  coppyMessage,
  replacePlaceHolder,
} from 'services/employee-hub';
import {
  QR_CODE,
  GET_TEMPLATE_INVITE,
  CREATE_INVITE_TEMPLATE,
  SENT_INVITE,
  CHOOSE_EXAMPLE_TEMPLATE,
  COPPY_MESSAGE,
  getCoppyMessage,
  REPLACE_PLACHOLDER_ZOOM_IN,
} from 'store/actions/employeeHub.action';
import { openToast, TOAST, TypeToast } from 'store/actions/toast.action';
import { EmployeeHubStore, inviteObj } from 'types/store/EmployeeHub';
import { Store } from 'types/store/Store';
import { ErrorResponse } from 'utils/handleAxiosError';

function* getQRCodeRequest(action: any): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(qrCodeServices, state.auth.token);
      yield put({
        type: QR_CODE.QR_CODE_SUCCESS,
        payload: data.data,
      });
    } catch (e: any) {
      console.log('e', e);
    }
  }
}

function* getInvitePeopleTemplateRequest(action: any): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      let result;
      if (action.payload.template === TemplateType.Email) {
        result = yield call(
          getEmailTemplateInvitePeople,
          action.payload.type,
          state.auth.token,
        );
      } else
        result = yield call(
          getSMSTemplateInvitePeople,
          action.payload.type,
          state.auth.token,
        );

      const smsData = result.data.data.map((item: any) => {
        if (item.template) {
          return {
            sms: true,
            id: item.id,
            type: item.type,
            body: item.template,
            name: item.template_name,
            is_default: item.is_default,
          };
        }
        return;
      });

      const emailData = result.data.data.map((item: any) => {
        if (item.body) {
          return {
            sms: false,
            id: item.id,
            type: item.type,
            body: item.body,
            name: item.name,
            is_default: item.is_default,
            subject: item.subject,
          };
        }
        return;
      });

      const filterEmail = emailData.filter((item: any) => item);
      const filterSms = smsData.filter((item: any) => item);

      const data: any[] =
        action.payload.template === TemplateType.Email
          ? filterEmail
          : filterSms;

      const dataTmp = data.filter((item) => item.is_default);
      const findDefaultValue = dataTmp.length > 0 ? dataTmp : [data[0]];
      // const initDataFirstValue = findDefaultValue[0].id;
      // const subjectLineValue =
      //   findDefaultValue[0].subject ?? 'this is subject line hard code';
      yield put({
        type: GET_TEMPLATE_INVITE.GET_TEMPLATE_INVITE_SUCCESS,
        payload: data,
      });

      yield put({
        type: CHOOSE_EXAMPLE_TEMPLATE.CHOOSE_EXAMPLE_TEMPLATE_REQUEST,
        payload: {
          ...findDefaultValue[0],
          subject: findDefaultValue[0].subject ?? '',
        },
      });
    } catch (e: any) {
      console.log('e', e);
    }
  }
}

function* createInviteTemplateRequest(action: any): any {
  const token = yield select((state: Store) => state.auth.token);
  if (token) {
    try {
      const {
        message,
        currentTab,
        email,
        phoneInput,
        invitationType,
        title,
        name,
        subject,
      } = action.payload;
      //create sms
      const createSMSData = {
        template: message,
        invitation_type: invitationType,
        type: 'INVITE_PEOPLE',
        template_name: name !== 'coppy' ? name : undefined,
      };

      const createEmailData = {
        name: name !== 'coppy' ? name : undefined,
        body: message,
        type: 'INVITE_FOR_ADVOCATE',
        invitationType: invitationType,
        subject: subject ?? undefined,
      };
      if (currentTab === 'SMS') {
        const { data } = yield call(createSMS, createSMSData, token);
        if (data.status === 200) {
          const dataTemplate = {
            sms: true,
            id: data.data.id,
            type: data.data.type,
            body: data.data.template,
            name: data.data.template_name,
            is_default: data.data.is_default,
          };

          yield put({
            type: CREATE_INVITE_TEMPLATE.CREATE_INVITE_TEMPLATE_SAVE,
            payload: dataTemplate,
          });

          yield put({
            type: CREATE_INVITE_TEMPLATE.CREATE_INVITE_TEMPLATE_SUCCESS,
            payload: {
              ...data.data,
              templateId: data.data.id,
              emails: [],
              name: data.data.template_name,
            },
          });
        }
      } else {
        const { data } = yield call(createEmail, createEmailData, token);
        if (name !== 'coppy') {
          const dataTemplate = {
            sms: false,
            id: data.data.id,
            type: data.data.type,
            body: data.data.body,
            name: data.data.name,
            subject: data.data.subject,
            is_default: data.data.is_default,
          };

          yield put({
            type: CREATE_INVITE_TEMPLATE.CREATE_INVITE_TEMPLATE_SAVE,
            payload: dataTemplate,
          });

          yield put({
            type: CREATE_INVITE_TEMPLATE.CREATE_INVITE_TEMPLATE_SUCCESS,
            payload: {
              ...data.data,
              templateId: data.data.id,
              emails: email,
              name: data.data.name,
            },
          });
        }
      }
    } catch (error: any) {
      const errorMessage = error as AxiosError<any>;
      const message =
        errorMessage.response?.data.status === 400
          ? errorMessage.response?.data?.message
          : errorMessage.response?.data?.errors[0];
      yield put({
        type: CREATE_INVITE_TEMPLATE.CREATE_INVITE_TEMPLATE_ERROR,
        payload: message,
      });
    }
  }
}

function* watchInviteRequest(action: any): any {
  const token = yield select((state: Store) => state.auth.token);
  if (token) {
    try {
      yield sendInvite({ ...action.payload, isNew: true }, token);
      yield put({
        type: SENT_INVITE.SENT_INVITE_SUCCESS,
      });
    } catch (error: any) {
      const errorMessage = error as AxiosError<ErrorResponse>;
      yield put(
        openToast(
          errorMessage.response?.data.message ?? 'An unexpected error occurred',
          'error',
          'Error',
        ),
      );
    }
  }
}
interface ApiResponse {
  data: string;
  status: number;
}

interface ApiError {
  message: string;
  status: number;
}
function* watchCoppyRequest(action: any): any {
  const token = yield select((state: Store) => state.auth.token);
  if (token) {
    try {
      const template = action.payload.messageReview;
      const notRemoveHtml = true;
      const notAlwaysMagicLink = true;
      const { data } = yield coppyMessage(
        { template, notAlwaysMagicLink, notRemoveHtml },
        token,
      );
      // yield put({
      //   type: COPPY_MESSAGE.COPPY_MESSAGE_SUCCESS,
      // });
      // Update data when copied to type : text/html to support display on social media , instead use plain/text
      // action.payload.callback('Message copied to clipboard!');
      const type = 'text/html';
      const blob = new Blob([data.data], { type });
      const clipboard = [new window.ClipboardItem({ [type]: blob })];
      navigator.clipboard.write(clipboard);
      // update show meesge from api and useToast module to handle
      yield put(openToast(data?.message, 'success', 'Success'));
      // navigator.clipboard.writeText(data.data);
    } catch (error: any) {
      console.log('send invite error', error);
    }
  }
}

function* watchReplacePlaceholderZoomInRequest(action: any): any {
  const token = yield select((state: Store) => state.auth.token);
  if (token) {
    try {
      const template = action.payload;
      const { data } = yield replacePlaceHolder({ template }, token);
      yield put({
        type: REPLACE_PLACHOLDER_ZOOM_IN.REPLACE_PLACHOLDER_ZOOM_IN_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      console.log('replâce error', error);
    }
  }
}

function* watchSaveTemplateSMS(action: any): any {
  const token = yield select((state: Store) => state.auth.token);
  if (token) {
    try {
      const { data } = yield sendSMS(action.payload.payloadSMS, token);

      let type: TypeToast = 'success';
      if (data.status > 200) {
        type = 'warning';
      }
      yield put({
        type: TOAST.TOAST_OPEN,
        payload: { message: data.message, type },
      });
      // action.payload.callback(data.message)
    } catch (error) {
      console.log('save template sms error', error);
    }
  }
}

function* employeeHubWatcher(): any {
  yield takeLatest(QR_CODE.QR_CODE_REQUEST, getQRCodeRequest);
  yield takeLatest(
    GET_TEMPLATE_INVITE.GET_TEMPLATE_INVITE_REQUEST,
    getInvitePeopleTemplateRequest,
  );
  yield takeLatest(
    CREATE_INVITE_TEMPLATE.CREATE_INVITE_TEMPLATE_REQUEST,
    createInviteTemplateRequest,
  );
  yield takeLatest(SENT_INVITE.SENT_INVITE_REQUEST, watchInviteRequest);
  yield takeLatest(COPPY_MESSAGE.COPPY_MESSAGE_REQUEST, watchCoppyRequest);
  yield takeLatest(
    REPLACE_PLACHOLDER_ZOOM_IN.REPLACE_PLACHOLDER_ZOOM_IN_REQUEST,
    watchReplacePlaceholderZoomInRequest,
  );
  yield takeLatest(SENT_INVITE.SENT_INVITE_SMS_REQUEST, watchSaveTemplateSMS);
}

export default employeeHubWatcher;
