import React from 'react';

import { SVGProps } from './SVG.props';

const Underlined = (props: SVGProps): React.JSX.Element => {
  const { width, height } = props;

  return (
    <svg
      width={width || '18'}
      height={height || '18'}
      viewBox="0 0 18 18"
      fill="#070550"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 4.40625C4.5 4.04381 4.80274 3.75 5.1762 3.75H6.81597C7.18942 3.75 7.49216 4.04381 7.49216 4.40625C7.49216 4.76869 7.18942 5.0625 6.81597 5.0625H5.1762C4.80274 5.0625 4.5 4.76869 4.5 4.40625Z"
        fill={'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5078 4.40625C10.5078 4.04381 10.8106 3.75 11.184 3.75H12.8238C13.1973 3.75 13.5 4.04381 13.5 4.40625C13.5 4.76869 13.1973 5.0625 12.8238 5.0625H11.184C10.8106 5.0625 10.5078 4.76869 10.5078 4.40625Z"
        fill={'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.95706 4.07812C6.33051 4.07812 6.63325 4.37194 6.63325 4.73438V8.67188C6.63325 9.28104 6.8826 9.86526 7.32644 10.296C7.77028 10.7268 8.37225 10.9688 8.99993 10.9688C9.62762 10.9688 10.2296 10.7268 10.6734 10.296C11.1173 9.86526 11.3666 9.28104 11.3666 8.67188V4.73438C11.3666 4.37194 11.6694 4.07812 12.0428 4.07812C12.4163 4.07812 12.719 4.37194 12.719 4.73438V8.67188C12.719 9.62914 12.3272 10.5472 11.6297 11.2241C10.9323 11.901 9.98629 12.2812 8.99993 12.2812C8.01357 12.2812 7.06761 11.901 6.37015 11.2241C5.67269 10.5472 5.28086 9.62914 5.28086 8.67188V4.73438C5.28086 4.37194 5.5836 4.07812 5.95706 4.07812Z"
        fill={'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.94276 13.5938C4.94276 13.2313 5.24551 12.9375 5.61896 12.9375H12.3809C12.7544 12.9375 13.0571 13.2313 13.0571 13.5938C13.0571 13.9562 12.7544 14.25 12.3809 14.25H5.61896C5.24551 14.25 4.94276 13.9562 4.94276 13.5938Z"
        fill={'currentColor'}
      />
    </svg>
  );
};

export default Underlined;
