/* eslint-disable @typescript-eslint/no-empty-function */
import './index.css';
import './output.css';

import React, { useState } from 'react';

import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';
import { QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'ckeditor5/ckeditor5.css';
import 'react-tagsinput/react-tagsinput.css';
import { ConfigProvider } from 'antd';

import FeedBackOverlay from 'components/module/FeedBackOverlay';
import SnackbarContainer from 'components/module/SnackbarContainer';
import { i18n } from 'locales/i18n';
import { SnackbarProvider } from 'providers/snackbar';
import Routes from 'routes/index.routes';
import { store, persistor, sagaMiddleware } from 'store';
import sagas from 'store/sagas';
import theme from 'utils/Theme';
import { queryClient } from 'utils/QueryClient';
import Toast from 'components/module/Toast';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import useNavigationSetup from 'hooks/useNavigationSetup';
import ScrollToTop from 'components/layout/ScrollToTop/ScrollToTop';
import ErrorBoundaryWithLocation from 'components/module/ErrorBoundary';

// import { analytics } from './segmentAnalytics';
import * as serviceWorker from './serviceWorker';

// Initialize language to English
i18n('en');

// create the saga middleware
sagaMiddleware.run(sagas);

const UATBanner = () => (
  <div className="uat-banner">
    <span className="uat-banner__text">
      UAT Environment - This is a testing site. Data may be reset or modified at
      any time.
    </span>
  </div>
);

const App = () => {
  useNavigationSetup();
  const [rehydrated, setRehydrated] = useState(false);
  const onRehydate = async () => {
    setRehydrated(true);
  };

  // listen service workers
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/sw.js')
      .then(() => {})
      .catch(() => {});
  }

  return (
    // <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      {process.env.REACT_APP_ENV === 'uat' && <UATBanner />}
      <ReduxProvider store={store}>
        {/* <PersistGate loading={null} persistor={persistor(onRehydate)}> */}
        <PersistGate
          loading={null}
          persistor={persistor()}
          onBeforeLift={() => {}}
        >
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#91DBB6',
              },
            }}
          >
            {/* <BrowserRouter> */}
            <ThemeProvider theme={theme}>
              <SnackbarProvider>
                <ErrorBoundaryWithLocation>
                  <Routes />
                </ErrorBoundaryWithLocation>
                <ToastContainer style={{ zIndex: 10000 }} />
                <Toast />
                <FeedBackOverlay />
                <SnackbarContainer />
              </SnackbarProvider>
            </ThemeProvider>
            {/* </BrowserRouter> */}
          </ConfigProvider>
        </PersistGate>
      </ReduxProvider>
    </QueryClientProvider>
  );
};

// Root Component Wrapped with BrowserRouter
const Root = () => (
  <BrowserRouter>
    <App />
    <ScrollToTop />
  </BrowserRouter>
);

// Root Component Wrapped with HistoryRouter
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
