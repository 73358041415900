import { FilterRequest } from 'routes/Employer/LibraryV2/LibraryV2.interface';
import { createUpdateAction, createClearAction } from 'utils/Redux';

const ns = 'FILTER_LIBRARY';
const updateAction = createUpdateAction<FilterRequest>(ns);
const clearAction = createClearAction(ns);

const filterActions = {
  ...updateAction,
  ...clearAction,
};

export default filterActions;
