import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getListGoogleFonts } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import {
    GetListGoogleFontsMeta,
    GetListGoogleFontsPayload
} from 'types/store/GetListGoogleFontsState';

import { getListGoogleFontsActions } from '../actions';

function* getListGoogleFontsRequest(
    action: AsyncAction<GetListGoogleFontsMeta, GetListGoogleFontsPayload>
) {
    try {
        const { data } = yield call(getListGoogleFonts, action.meta);
        const formatData = { ...data };
        let arr: any[] = [];
        arr = formatData?.items?.map((entry: any) => ({
            ...entry, value: entry.family, label: entry.family
        }))
        formatData.items = arr;
        yield put(getListGoogleFontsActions.success(formatData));
    } catch (e: any) {
        yield put(getListGoogleFontsActions.failed(e.message));
    }
}

function* getListGoogleFontsSuccess(
    action: AsyncAction<GetListGoogleFontsMeta, GetListGoogleFontsPayload>
) {
    // 
}

function* getListGoogleFontsWatcher(): any {
    yield takeLatest(getListGoogleFontsActions.REQUEST, getListGoogleFontsRequest);
    yield takeLatest(getListGoogleFontsActions.SUCCESS, getListGoogleFontsSuccess);
}

export default getListGoogleFontsWatcher;
