const EMPLOYER_ROOT = '/employer';
const ADVOCATE_ROOT = '/advocate';
const COMPANY_ROOT = '/company';
const VIDEO_ROOT = '/video';
const INTEGRATION_ROOT = '/integration';

export const MAIN_ROUTES = {
  LANDING: '/',
  NOT_FOUND: '/not-found',
  PUBLIC_PAGE: (companyUrl = ':companyUrl', pageUrl = ':pageUrl?'): string =>
    `/${companyUrl}/${pageUrl}`,
  CONTENT_PLANNER: '/content-planner/:companyId/:contentPackageId/:email?',
  RECIPE_DETAIL: `/recipe/:recipeId/:contentPackageId/:companyId/:email?`,
  SSO_AUTH: `/sso-auth`,
  NANL: `/story/collection-link/:storyName/:token/*`,
  NANL_STORY: '/story/collection-link/:storyName/:token/story',
  NANL_FINAL: '/story/collection-link/:storyName/:token/story/final',
};

export const EMPLOYER_ROUTES = {
  ROOT: `${EMPLOYER_ROOT}`,
  LOGIN: `${EMPLOYER_ROOT}/login`,
  CANVA_AUTH: `${EMPLOYER_ROOT}/canva/configuration`,
  CANVA_AUTHORIZE: `${EMPLOYER_ROOT}/canva/authorize`,
  CANVA_AUTHORIZE_CALLBACK: `${EMPLOYER_ROOT}/canva/authorize/callback`,
  REGISTER: `${EMPLOYER_ROOT}/register/:token?`,
  REGISTER_DETAILS: `${EMPLOYER_ROOT}/register-details`,
  FORGOT_PASSWORD: `${EMPLOYER_ROOT}/forgot-password`,
  SSO: `${EMPLOYER_ROOT}/sso`,
  SSO_REGISTER: `${EMPLOYER_ROOT}/sso/register/:companyId/:token`,
  OKTALOGIN: `${EMPLOYER_ROOT}/sso/login`,
  OKATLOGOUT: `${EMPLOYER_ROOT}/sso/logout`,
  INSIDE_LOOK: `${EMPLOYER_ROOT}/inside-look`,
  ONBOARDING: `${EMPLOYER_ROOT}/onboarding`,
  INSIDE_LOOK_VIEW: `${EMPLOYER_ROOT}/inside-look/preview`,
  INSIDE_LOOK_UPDATE: (page_id = ':page_id'): string =>
    `${EMPLOYER_ROOT}/inside-look/update/${page_id}`,
  STORY_EDITOR: `${EMPLOYER_ROOT}/story-editor`,
  STORY_EDITOR_DETAIL: `dashboard/:storyId`,
  STORY_EDITOR_VERSION: `dashboard/version/:storyId`,
  DASHBOARD: `${EMPLOYER_ROOT}/dashboard`,
  SHOWCASE_EDITOR: (id = ':id?'): string =>
    `${EMPLOYER_ROOT}/showcase/editor/${id}`,
  SHOWCASE_PAGE_DETAILS: (page_id = ':page_id'): string =>
    `${EMPLOYER_ROOT}/showcase/page-details/${page_id}`,
  STORY_BUILDER_DETAIL: `${EMPLOYER_ROOT}/story-builder/:storyId`,
  HOMEPAGE: `${EMPLOYER_ROOT}/content-recipe`,
  STORY_UPSELLING: `${EMPLOYER_ROOT}/story-upselling`,
  CONTENT_CALENDAR: `${EMPLOYER_ROOT}/content-calendar`,
  CALENDAR_CONTENT_PACKAGE: `${EMPLOYER_ROOT}/content-calendar/:contentPackageId`,
  RECIPE_DETAIL: `${EMPLOYER_ROOT}/content-recipe/:recipeId/:contentPackageId/:companyId`,
  CALENDAR_RECIPE_DETAIL: `${EMPLOYER_ROOT}/content-calendar/:recipeId/:contentPackageId/:companyId`,
  HOMEPAGE_JD: `${EMPLOYER_ROOT}/jobs`,
};

export const ADVOCATE_ROUTES = {
  ROOT: `${ADVOCATE_ROOT}`,
  LOGIN: `${ADVOCATE_ROOT}/login`,
  REGISTER: (reference = ':reference', token = ':token'): string =>
    `${ADVOCATE_ROOT}/register/${reference}/${token}`,
  REGISTER_RECIPES: (
    reference = ':reference', //companyId
    token = ':token', // token
    safeUrl = ':safeURL', // storyId
  ): string => `${ADVOCATE_ROOT}/register/${reference}/${token}/${safeUrl}`,
  REGISTER_PROFILE: (
    reference = ':reference', //companyId
  ): string => `${ADVOCATE_ROOT}/profile/${reference}`,
  REGISTER_DEFAULT: `${ADVOCATE_ROOT}/register`,
  REGISTER_DETAILS: `${ADVOCATE_ROOT}/register-details`,
  FORGOT_PASSWORD: `${ADVOCATE_ROOT}/forgot-password`,
  WRITE_STORY: `${ADVOCATE_ROOT}/write-story`,
  STORY_MANAGEMENT: `${ADVOCATE_ROOT}/story`,
  STORY_DETAILS: `${ADVOCATE_ROOT}/story/:storyId`,
  TOPIC_CALIBRATION: `${ADVOCATE_ROOT}/topic-calibration`,
  SETTINGS: `${ADVOCATE_ROOT}/settings`,
  TRIBES: `${ADVOCATE_ROOT}/tribes`,
  TRIBES_DETAIL: `${ADVOCATE_ROOT}/tribes/detail`,
  ONBOARDING: `${ADVOCATE_ROOT}/onboarding`,
  DOWNLOAD_APP: `${ADVOCATE_ROOT}/download-app`,
  SSO_REGISTER: `${ADVOCATE_ROOT}/sso/register/:companyId/:token`,
  SSO_REGISTER_INVITE: `${ADVOCATE_ROOT}/sso/register/:companyId/:token/:inviteId`,
};

export const COMPANY_ROUTES = {
  ROOT: `${COMPANY_ROOT}`,
  VIEW_PAGE: (url = ':url'): string => `${COMPANY_ROUTES.ROOT}/${url}`,
};

export const VIDEOS = {
  VIDEOS_PUBLIC: `${VIDEO_ROOT}/public`,
};

export const INTEGRATION_ROUTES = {
  TEAMS_REDIRECT: `${INTEGRATION_ROOT}/teams/redirect`,
};
