import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getHomePageMenu } from 'services/homepage';
import { AsyncAction } from 'types/Action';
import { GetHomePageMenuMeta, GetHomePageMenuPayload } from 'types/store/GetHomePageMenu';
import { Store } from 'types/store/Store';

import { getHomePageMenuActions } from '../actions';

function* getHomePageMenuRequest(action: AsyncAction<GetHomePageMenuMeta, GetHomePageMenuPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getHomePageMenu, action.meta, state.auth.token);
      yield put(getHomePageMenuActions.success(data));
    } catch (e: any) {
      yield put(getHomePageMenuActions.failed(e.message));
    }
  }
}

// function* getHomePageMenuSuccess(action: AsyncAction<GetHomePageMenuMeta, GetHomePageMenuPayload>): any {
//   yield put()
// }

function* getHomePageMenuWatcher(): any {
  yield takeLatest(getHomePageMenuActions.REQUEST, getHomePageMenuRequest);
  // yield takeLatest(getHomePageMenuActions.SUCCESS, getHomePageMenuSuccess);
}

export default getHomePageMenuWatcher;
