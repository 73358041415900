import { ContentCalendarState } from 'types/store/ContentPlanner';

import {
  getContentPlannerRecipesActions,
  editPlanActions,
  getAllContentPlannerRecipeDetailsActions,
  createRecipeActions,
  editAssetRecipeActions,
  movingPlanActions,
} from '../actions';

const DEFAULT_STATE: ContentCalendarState = {
  recipes: { data: [], loading: null, error: null },
  recipeTeams: [],
  calendarEvents: { data: [], loading: null, error: null },
  loadingRecipe: null,
  loadingRecipeDetail: null,
  loadingCalendar: null,
  editPlanLoading: null,
  creatingRecipe: null,
  editAssetRecipe: null,
  contentPackage: null,
};

const DEFAULT_ACTION: any = {
  type: '',
  payload: {},
};

export default (state = DEFAULT_STATE, action = DEFAULT_ACTION): any => {
  switch (action.type) {
    case getContentPlannerRecipesActions.CLEAR:
      return {
        ...state,
        recipes: DEFAULT_STATE.recipes,
      };
    case getContentPlannerRecipesActions.REQUEST:
      return {
        ...state,
        recipes: { ...state.recipes, loading: true },
      };
    case getContentPlannerRecipesActions.SUCCESS:
      return {
        ...state,
        recipes: {
          ...state.recipes,
          data: action.payload,
          loading: false,
        },
      };
    case getContentPlannerRecipesActions.FAILED:
      return {
        ...state,
        recipes: {
          ...state.recipes,
          error: action.error,
          loading: false,
        },
      };
    case getAllContentPlannerRecipeDetailsActions.REQUEST:
      return {
        ...state,
        calendarEvents: {
          ...state.calendarEvents,
          loading: true,
        },
      };
    case getAllContentPlannerRecipeDetailsActions.SUCCESS:
      return {
        ...state,
        calendarEvents: {
          ...state.calendarEvents,
          data: Array.isArray(action.payload) ? action.payload : action.payload.recipes,
          loading: false,
        },
        contentPackage: action.payload.contentPackage,
      };
    case getAllContentPlannerRecipeDetailsActions.FAILED:
      return {
        ...state,
        calendarEvents: {
          ...state.calendarEvents,
          loading: false,
          error: action.error,
        },
      };
    case getAllContentPlannerRecipeDetailsActions.CLEAR:
      return {
        ...state,
        calendarEvents: DEFAULT_STATE.calendarEvents,
      };
    case editPlanActions.REQUEST:
      return {
        ...state,
        editPlanLoading: true,
      };
    case editPlanActions.SUCCESS:
      return {
        ...state,
        editPlanLoading: false,
        recipes: {
          ...state.recipes,
          data: action.payload,
        },
      };
    case editPlanActions.FAILED:
      return {
        ...state,
        editPlanLoading: false,
      };
    case editPlanActions.CLEAR:
      return {
        ...state,
        editPlanLoading: null,
      };
    case editAssetRecipeActions.UPDATE:
      return {
        ...state,
        editAssetRecipe: action.payload.edit_asset_recipe,
      };
    case editAssetRecipeActions.CLEAR:
      return {
        ...state,
        editAssetRecipe: null,
      };
    case createRecipeActions.REQUEST:
      return {
        ...state,
        creatingRecipe: true,
      };
    case createRecipeActions.SUCCESS:
      return {
        ...state,
        recipes: { ...state.recipes, data: action.payload },
        creatingRecipe: false,
      };
    case movingPlanActions.REQUEST: {
      return {
        ...state,
        calendarEvents: {
          ...state.calendarEvents,
          loading: true,
        },
      };
    }
    case movingPlanActions.SUCCESS: {
      return {
        ...state,
        calendarEvents: { ...action.payload, loading: false },
      };
    }
    case movingPlanActions.FAILED: {
      return {
        ...state,
        calendarEvents: { ...state.calendarEvents, loading: false },
      };
    }

    default:
      return state;
  }
};
