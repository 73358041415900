import axios, { AxiosPromise } from 'axios';

import { API } from 'consts';
import { GenericResponse } from 'types/GenericResponse';
import { SR_JobAdsApiResponse, SR_JobsApiResponse } from 'types/JobSR';
import {
  GetListJobAdsMeta,
  GetListSmartRecruiterMeta,
} from 'types/store/SmartRecruiter';
import { scope } from 'utils/SmartRecruiters';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const JOB_DESCRIPTION_URL = `${BASE_URL}/job-description`;

export const getListSmartRecuiter = (
  payload: GetListSmartRecruiterMeta,
  token: string,
): AxiosPromise<SR_JobsApiResponse> => {
  const url = `${JOB_DESCRIPTION_URL}/ats/list-jobs?provider=${payload.provider}&mode=${payload.mode}&page=${payload.page}&limit=${payload.limit}`;
  return axios({
    method: 'get',
    url: url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getListJobAds = (
  payload: GetListJobAdsMeta,
  token: string,
): AxiosPromise<SR_JobAdsApiResponse> => {
  const url = `${JOB_DESCRIPTION_URL}/ats/list-job-ads/${payload.idAds}?provider=${payload.provider}&mode=${payload.mode}`;
  return axios({
    method: 'get',
    url: url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCheckAuthSmartRecruiter = (
  token: string,
): AxiosPromise<GenericResponse<{ connected: boolean; authLink: string }>> => {
  const url = `${JOB_DESCRIPTION_URL}/ats/auth-check?provider=SMART_RECRUITER&scope=${scope}`;
  return axios({
    method: 'get',
    url: url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getSmartRecuiter = (
  token: string,
): AxiosPromise<GenericResponse> => {
  // const queryParams = queryString.stringify(payLoad)
  const url = `${JOB_DESCRIPTION_URL}?${''}`;
  return axios({
    method: 'get',
    url: url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getSRToken = (
  token: string,
  code: string,
): AxiosPromise<GenericResponse> => {
  const url = `${JOB_DESCRIPTION_URL}/ats/auth-token?code=${code}&provider=SMART_RECRUITER`;
  return axios({
    method: 'get',
    url: url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const logoutSR = (token: string): AxiosPromise<GenericResponse> => {
  const url = `${BASE_URL}/company-settings/ats/logout`;
  return axios({
    method: 'put',
    url: url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      provider: 'SMART_RECRUITER',
    },
  });
};

export enum JobAdsPublishStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export interface ChangeJobAdsPublishStatusParams {
  token: string | null;
  jobId: string;
  jobAdsId: string;
  isDefault: boolean;
  status: JobAdsPublishStatus;
}

export const changeJobAdsPublishStatus = ({
  token = '',
  jobAdsId,
  jobId,
  isDefault,
  status
}: ChangeJobAdsPublishStatusParams): AxiosPromise<GenericResponse> => {
  const url = `${JOB_DESCRIPTION_URL}/ats/publish-job-ads`;
  return axios({
    method: status == JobAdsPublishStatus.PUBLISHED ? 'POST' : 'DELETE',
    url: url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      provider: 'SMART_RECRUITER',
      jobId,
      jobAdsId,
      mode: 'ATS',
      isDefault,
    },
  });
};
