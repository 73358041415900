import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getSharing } from 'services/auth';
import { AsyncAction } from 'types/Action';
import {
    GetSharingMeta,
    GetSharingPayload,
} from 'types/store/GetSharing';
import { Store } from 'types/store/Store';

import { getSharingActions } from '../actions';

function* getSharingRequest(
    action: AsyncAction<
        GetSharingMeta,
        GetSharingPayload
    >
) {
    const state: Store = yield select();
    if (state.auth.token) {
        try {
            // company id already embeded in the token
            const { data } = yield call(
                getSharing,
                action.meta,
                state.auth.token
            );
            yield put(getSharingActions.success(data));
        } catch (e: any) {
            yield put(getSharingActions.failed(e.message));
        }
    } else {
        yield put(getSharingActions.failed('Token not found'));
    }
}

function* getSharingSuccess(
    action: AsyncAction<
        GetSharingMeta,
        GetSharingPayload
    >
) {
    // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getSharingWatcher(): any {
    yield takeLatest(
        getSharingActions.REQUEST,
        getSharingRequest
    );
    yield takeLatest(
        getSharingActions.SUCCESS,
        getSharingSuccess
    );
}

export default getSharingWatcher;
