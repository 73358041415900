import { put, call, select, takeLatest } from 'redux-saga/effects';

import { checkEBInvite } from 'services/employee';
import { AsyncAction } from 'types/Action';
import {
  CheckEbInviteMeta,
  CheckEbInvitePayload,
} from 'types/store/CheckEbInvite';
import { Store } from 'types/store/Store';

import {
  checkEbInviteActions,
  registerEbWithExistedAccountActions,
} from '../actions';

function* checkEbInviteRequest(
  action: AsyncAction<CheckEbInviteMeta, CheckEbInvitePayload>,
) {
  try {
    const { data } = yield call(checkEBInvite, action.meta);
    yield put(checkEbInviteActions.success(data));
  } catch (e: any) {
    yield put(checkEbInviteActions.failed(e.message));
  }
}

// function* checkEbInviteSuccess(
//   action: AsyncAction<CheckEbInviteMeta, CheckEbInvitePayload>
// ) {
//   const {
//     payload: { data },
//   } = action;
//   const state: Store = yield select();
//   if (data?.exist) {
//     if (state.auth.token && state.auth.platform === 'ADVOCATE') {
//       console.log('check eb invite success');
//       yield put(registerEbWithExistedAccountActions.request(data.eb_fill_data));
//     }
//   }
// }

function* checkEbInviteWatcher(): any {
  yield takeLatest(checkEbInviteActions.REQUEST, checkEbInviteRequest);
  // yield takeLatest(checkEbInviteActions.SUCCESS, checkEbInviteSuccess);
}

export default checkEbInviteWatcher;
