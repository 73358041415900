import { AxiosError } from 'axios';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getRoleInviteTeam, inviteTeam } from 'services/team';
import { AsyncAction } from 'types/Action';
import { InviteTeamMeta, InviteTeamPayload } from 'types/store/InviteTeam';
import { Store } from 'types/store/Store';

import { getListRoleActions, inviteTeamActions, openToast } from '../actions';

function* inviteTeamRequest(action: AsyncAction<InviteTeamMeta, InviteTeamPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(inviteTeam, action.meta, state.auth.token);
      if (data.status === 200 && data.message === 'Email address already used') {
        yield put(openToast(data.message, 'error'))
      } else {
        yield put(openToast(data.message, 'success'))
        data.data = undefined
        yield put(inviteTeamActions.success(data));
      }
    } catch (error: any) {
      yield put(inviteTeamActions.failed(error.message));
    }
  }
}

function* getListRoleRequest(action: AsyncAction): any {
  const token: string | null = yield select((_: Store) => _.auth.token);
  if (token) {
    try {
      const { data } = yield call(getRoleInviteTeam, token);
      if (data.status === 200) {
        data.data = data.data.map((item: { name: string, value: string }) => ({ label: item.name, value: item.value }))
      }
      yield put(getListRoleActions.success(data));
    } catch (e: any) {
      yield put(getListRoleActions.failed(e.message));
    }
  }
}

function* inviteTeamWatcher(): any {
  yield takeLatest(inviteTeamActions.REQUEST, inviteTeamRequest);
  yield takeLatest(getListRoleActions.REQUEST, getListRoleRequest);
}

export default inviteTeamWatcher;
