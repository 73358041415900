import React from 'react';

import { SVGProps } from './SVG.props';

const ChevronLeft = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 4}
      height={height || 7}
      viewBox="0 0 8 12"
      fill={fill || 'none'}
      {...props}
    >
      <path
        d="M5.41 1L1.17 5.29a1 1 0 000 1.42l4.24 4.24a1.001 1.001 0 101.42-1.41L3.29 6l3.54-3.54a1 1 0 000-1.41A1 1 0 005.41 1z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default ChevronLeft;
