import { put, call, takeLatest, select } from 'redux-saga/effects';

import { fetchBrandAdvocates } from 'services/analytics';
import { analyticBrandAdvocatesActions } from 'store/actions';
import { IAnalyticBrandAdvocatesMeta } from 'store/actions/analytics/brandAdvocates';
import { AsyncAction } from 'types/Action';
import { GenericResponse } from 'types/GenericResponse';
import { Store } from 'types/store/Store';

function* getBrandAdvocates(
  action: AsyncAction<IAnalyticBrandAdvocatesMeta, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        fetchBrandAdvocates,
        action.meta,
        state.auth.token,
      );
      yield put(analyticBrandAdvocatesActions.success(data));
    } catch (e: any) {
      yield put(analyticBrandAdvocatesActions.failed(e.message));
    }
  }
}

function* analyticWatcher(): any {
  yield takeLatest(analyticBrandAdvocatesActions.REQUEST, getBrandAdvocates);
}

export default analyticWatcher;
