import { put, call, takeLatest } from 'redux-saga/effects';

import { EMPLOYER_ROUTES } from 'consts';
import { resetPassword } from 'services/auth';
import { AsyncAction } from 'types/Action';
import {
  ResetPasswordMeta,
  ResetPasswordPayload,
} from 'types/store/ResetPasswordState';
import navigationService from 'utils/navigationService';

import { openToast, resetPasswordActions } from '../actions';

function* resetPasswordRequest(
  action: AsyncAction<ResetPasswordMeta, ResetPasswordPayload>,
) {
  try {
    console.log('action', action);
    const { data } = yield call(resetPassword, action.meta);
    yield put(resetPasswordActions.success(data));
  } catch (e: any) {
    yield put(resetPasswordActions.failed(e.message));
  }
}

function* resetPasswordSuccess(
  action: AsyncAction<ResetPasswordMeta, ResetPasswordPayload>,
) {
  yield put(openToast(action.payload.message, 'success'));
  yield call(navigationService.goTo, EMPLOYER_ROUTES.LOGIN);
}

function* resetPasswordWatcher(): any {
  yield takeLatest(resetPasswordActions.REQUEST, resetPasswordRequest);
  yield takeLatest(resetPasswordActions.SUCCESS, resetPasswordSuccess);
}

export default resetPasswordWatcher;
