import { put, call, takeLatest, select } from 'redux-saga/effects';

import { disconnectLinkedin } from 'services/linkedin';
import { AsyncAction } from 'types/Action';
import { DisconnectLinkedinMeta, DisconnectLinkedinPayload } from 'types/store/DisconnectLinkedin';
import { Store } from 'types/store/Store';

import { disconnectLinkedinActions } from '../actions';

function* disconnectLinkedinRequest(action: AsyncAction<DisconnectLinkedinMeta, DisconnectLinkedinPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(disconnectLinkedin, action.meta, state.auth.token);
      yield put(disconnectLinkedinActions.success(data));
    } catch (e: any) {
      yield put(disconnectLinkedinActions.failed(e.message));
    }
  }
}

// function* disconnectLinkedinSuccess(action: AsyncAction<DisconnectLinkedinMeta, DisconnectLinkedinPayload>): any {
//   yield put()
// }

function* disconnectLinkedinWatcher(): any {
  yield takeLatest(disconnectLinkedinActions.REQUEST, disconnectLinkedinRequest);
  // yield takeLatest(disconnectLinkedinActions.SUCCESS, disconnectLinkedinSuccess);
}

export default disconnectLinkedinWatcher;
