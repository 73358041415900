import React from 'react';

import { useTheme } from 'utils/Theme';

import { SVGProps } from './SVG.props';

const StoryBuilder = (props: SVGProps): React.JSX.Element => {
  const theme = useTheme();
  const { fill } = props;
  return (
    <svg width={14} height={14} viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M1 6h4a.667.667 0 00.667-.666v-4A.667.667 0 005 .667H1a.667.667 0 00-.667.667v4A.667.667 0 001 6zm.667-4h2.666v2.667H1.667V2zm6 1.334H13A.667.667 0 1013 2H7.667a.667.667 0 100 1.334zm0 6.666H1a.667.667 0 000 1.334h6.667a.667.667 0 000-1.334zM13 7.334H1a.667.667 0 000 1.333h12a.667.667 0 100-1.333zm0-2.667H7.667a.667.667 0 000 1.333H13a.667.667 0 100-1.333z"
        fill={fill || theme.grey.shade8}
      />
    </svg>
  );
};

export default StoryBuilder;
