import React from 'react';

import { useTheme } from 'utils/Theme';

import { SVGProps } from './SVG.props';

const EyeOpenFilled = (props: SVGProps): React.JSX.Element => {
  const theme = useTheme();
  const { width, height, fill } = props;
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.89697 9.87525C9.00154 9.87525 9.89697 8.97981 9.89697 7.87524C9.89697 6.77067 9.00154 5.87524 7.89697 5.87524C6.7924 5.87524 5.89698 6.77067 5.89698 7.87524C5.89698 8.97981 6.7924 9.87525 7.89697 9.87525Z"
        fill={fill || '#070550'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14C12.4183 14 16 9.66667 16 8C16 6.33333 12.4183 2 8 2C3.58172 2 0 6 0 8C0 10 3.58172 14 8 14ZM7.89697 11.2086C9.73792 11.2086 11.2303 9.71619 11.2303 7.87524C11.2303 6.03429 9.73792 4.54191 7.89697 4.54191C6.05602 4.54191 4.56364 6.03429 4.56364 7.87524C4.56364 9.71619 6.05602 11.2086 7.89697 11.2086Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default EyeOpenFilled;
