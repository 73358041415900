import React from 'react';

import { SVGProps } from './SVG.props';

const UserAdd = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || 17}
      height={height || 16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4999 4.66663C10.4999 6.87576 8.70906 8.66663 6.49992 8.66663C4.29078 8.66663 2.49992 6.87576 2.49992 4.66663C2.49992 2.45749 4.29078 0.666626 6.49992 0.666626C8.70906 0.666626 10.4999 2.45749 10.4999 4.66663ZM9.16658 4.66663C9.16658 6.13939 7.97268 7.33329 6.49992 7.33329C5.02716 7.33329 3.83325 6.13939 3.83325 4.66663C3.83325 3.19387 5.02716 1.99996 6.49992 1.99996C7.97268 1.99996 9.16658 3.19387 9.16658 4.66663Z"
        fill="#070550"
      />
      <path
        d="M15.1666 9.33339C15.5348 9.3334 15.8333 9.03492 15.8333 8.66673C15.8333 8.29854 15.5348 8.00006 15.1666 8.00006H13.8333V6.66663C13.8333 6.29844 13.5348 5.99996 13.1666 5.99996C12.7984 5.99996 12.4999 6.29844 12.4999 6.66663V8.00006H11.1666C10.7984 8.00006 10.4999 8.29854 10.4999 8.66673C10.4999 9.03492 10.7984 9.33339 11.1666 9.33339H12.4999V10.6666C12.4999 11.0348 12.7984 11.3333 13.1666 11.3333C13.5348 11.3333 13.8333 11.0348 13.8333 10.6666V9.33339H15.1666Z"
        fill="#070550"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.49995 9.33329C4.93262 9.33329 3.78709 9.75142 2.96519 10.3823C2.14942 11.0085 1.70563 11.8033 1.46494 12.4724C1.2531 13.0613 1.4015 13.6377 1.75672 14.0463C2.09978 14.4408 2.61918 14.6666 3.16661 14.6666H9.83325C10.3807 14.6666 10.9001 14.4408 11.2431 14.0463C11.5983 13.6377 11.7468 13.0613 11.5349 12.4724C11.2943 11.8033 10.8505 11.0085 10.0347 10.3823C9.21281 9.75143 8.06728 9.33329 6.49995 9.33329ZM2.71957 12.9237C2.89888 12.4252 3.21563 11.8709 3.77705 11.44C4.33235 11.0137 5.18066 10.6666 6.49995 10.6666C7.81924 10.6666 8.66755 11.0137 9.22284 11.44C9.78426 11.8709 10.101 12.4252 10.2803 12.9237C10.3177 13.0277 10.2971 13.1022 10.237 13.1714C10.1646 13.2546 10.0222 13.3333 9.83325 13.3333H3.16661C2.97764 13.3333 2.83524 13.2546 2.7629 13.1714C2.70273 13.1022 2.68215 13.0277 2.71957 12.9237Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default UserAdd;
