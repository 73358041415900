import React from 'react';

import { SVGProps } from './SVG.props';

const Add = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.888 3.37A20 20 0 1131.11 36.63 20 20 0 018.888 3.37zM27.998 18h-6v-6a2 2 0 10-4 0v6h-6a2 2 0 000 4h6v6a2 2 0 004 0v-6h6a2 2 0 100-4z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Add;
