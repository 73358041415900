import axios, { AxiosPromise } from 'axios';

import { API } from 'consts';
import { GenericResponse } from 'types/GenericResponse';
import { AddQuestionsForAdvocateMeta } from 'types/store/AddQuestionsForAdvocate';
import { ApproversApproveMeta } from 'types/store/ApproversApprove';
import { CreateStoryVersionMeta } from 'types/store/CreateStoryVersion';
import { EbGetStoriesEditorMeta } from 'types/store/EbGetStoriesEditor';
import { GetStoryForEditorMeta } from 'types/store/GetStoryForEditor';
import { GetStoryVersionOptionsMeta } from 'types/store/GetStoryVersionOptions';
import { MarkStoryAsPublishedMeta } from 'types/store/MarkStoryAsPublished';
import { MarkStoryAsReadyToPublishMeta } from 'types/store/MarkStoryAsReadyToPublish';
import { NudgeApprovalMeta } from 'types/store/NudgeApproval';
import { StoryEditorGenerate } from 'types/store/StoryEditorState';
import { UpdateVersionDetailMeta } from 'types/store/UpdateVersionDetail';

import { uploadFile } from './upload';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const STORY_URL = `${BASE_URL}/story-editor`;

const STORY_EDITOR_URL = `${BASE_URL}/story`;
const RECIPE_EXAMPLE_URL = `${BASE_URL}/recipe-examples`;

export const ebGetStoriesEditor = (
  data: EbGetStoriesEditorMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getEbReviewedStories`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getStoryForEditor = (
  data: GetStoryForEditorMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/${data.storyId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const markStoryAsPublished = (
  data: MarkStoryAsPublishedMeta,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${STORY_URL}/markStoryAsPublished`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const getStoryVersionOptions = (
  data: GetStoryVersionOptionsMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getNewVersionOptions`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const createStoryVersion = (
  data: CreateStoryVersionMeta,
  token: string,
): Promise<any> => {
  return axios({
    method: 'post',
    url: `${STORY_URL}/createStoryVersion`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const nudgeApproval = (
  data: NudgeApprovalMeta,
  token: string,
): Promise<any> => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/nudgeApproval`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const markStoryAsReadyToPublish = async (
  data: MarkStoryAsReadyToPublishMeta,
  token: string,
): Promise<any> => {
  let fileVersions: any = [];
  if (data.filesVersion) {
    fileVersions = await Promise.all(
      data.filesVersion.map((file) =>
        uploadFile({
          file: file,
          token,
        }),
      ),
    );
  }

  if(data.linkFileDocx) {
    fileVersions = [data.linkFileDocx]
  }  

  return axios({
    method: 'post',
    url: `${STORY_URL}/markStoryAsReadyToPublish`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      storyId: data.storyId,
      file_urls: fileVersions,
      link_urls: data.linkUrls,
      answer_ids: data.answerIds,
      file_url_media_histories: data.file_url_media_histories,
      version_name: data.versionName,
      version_comment: data.versionComment,
    },
  });
};

export const updateVersionDetail1 = (
  data: ApproversApproveMeta,
  token: string,
): Promise<any> => {
  return axios({
    method: 'post',
    url: `${STORY_URL}/updateVersionDetail`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const updateVersionDetail = (
  data: UpdateVersionDetailMeta,
  token: string,
): Promise<any> => {
  return axios({
    method: 'post',
    url: `${STORY_URL}/updateVersionDetail`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const checkVersionDetail = (data: any, token: string): Promise<any> => {
  return axios({
    method: 'post',
    url: `${STORY_URL}/checkVersionDetail`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const addQuestionsForAdvocate = (
  data: AddQuestionsForAdvocateMeta,
  token: string,
): Promise<any> => {
  return axios({
    method: 'post',
    url: `${STORY_URL}/addQuestionsForAdvocate`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const generateAiContent = (
  data: StoryEditorGenerate,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${STORY_EDITOR_URL}/augmented-writing/paragraph`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const generateAIContentInvidual = (
  data: StoryEditorGenerate,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${STORY_EDITOR_URL}/augmented-writing/content-individual`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const genrateAiHeadingContent = (
  data: StoryEditorGenerate,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${STORY_EDITOR_URL}/augmented-writing/heading`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
export const getEmailTemplate = (token: string): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/email-template`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAdvocateAnswer = (id: string, token: string): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/get-advocate-answer-by-story/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getEmployeeByAuthApi = (
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/employee/getEmployeeProfileByAuth`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getArticleCompany = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${STORY_EDITOR_URL}/augmented-writing/content-company`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const aiSupportBAPortal = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${STORY_EDITOR_URL}/augmented-writing/qna`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const getBetaVersion = (
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/setting/get-beta-version-endpoint?group=2`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const shareLinkContentRecipes = (data: any, token: string): any => {
  return axios({
    method: 'post',
    url: `${STORY_EDITOR_URL}/share-link`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

const uploadLinkCancelToken = axios.CancelToken;
export let sourceUploadLink = uploadLinkCancelToken.source();
export const cancelUploadLink = (): void => {
  sourceUploadLink.cancel();
  sourceUploadLink = uploadLinkCancelToken.source();
};
export const uploadLinkLinkedIn = (
  data: { recipeId: string; url: string },
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${RECIPE_EXAMPLE_URL}/create-from-url`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
    cancelToken: sourceUploadLink.token,
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createTemplateEmployeeHub = (data: any, token: string): any => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/people-invitation/create-story`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const downloadStory = (query: string, token: string): any => {
  return axios({
    method: 'get',
    url: `${STORY_EDITOR_URL}/generate-response-file?${query}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getMediaStory = (
  storyId: string,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/studio/media-history`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { storyId },
  });
};

export const getSettingAW = (token: string): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${STORY_EDITOR_URL}/augmented-writing/ai-template-setting`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const generateHTMLDocx = (
  data: { htmlString: string; fileName: string },
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'POST',
    url: `${STORY_EDITOR_URL}/generate-docx`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};
