import { put, call, takeLatest, select } from 'redux-saga/effects';

import { deleteInvitedTeam } from 'services/team';
import { AsyncAction } from 'types/Action';
import { DeleteInvitedTeamMeta, DeleteInvitedTeamPayload } from 'types/store/DeleteInvitedTeam';
import { Store } from 'types/store/Store';

import { deleteInvitedTeamActions } from '../actions';

function* deleteInvitedTeamRequest(action: AsyncAction<DeleteInvitedTeamMeta, DeleteInvitedTeamPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(deleteInvitedTeam, action.meta, state.auth.token);
      yield put(deleteInvitedTeamActions.success(data));
    } catch (e: any) {
      yield put(deleteInvitedTeamActions.failed(e.message));
    }
  }
}

// function* deleteInvitedTeamSuccess(action: AsyncAction<DeleteInvitedTeamMeta, DeleteInvitedTeamPayload>): any {
//   yield put()
// }

function* deleteInvitedTeamWatcher(): any {
  yield takeLatest(deleteInvitedTeamActions.REQUEST, deleteInvitedTeamRequest);
  // yield takeLatest(deleteInvitedTeamActions.SUCCESS, deleteInvitedTeamSuccess);
}

export default deleteInvitedTeamWatcher;
