import React from 'react';

import { SVGProps } from './SVG.props';

const Video = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 10}
      height={height || 6}
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.765.575a.5.5 0 00-.5 0l-1.765.87A1.5 1.5 0 006 0H1.5A1.5 1.5 0 000 1.5v3A1.5 1.5 0 001.5 6H6a1.5 1.5 0 001.5-1.445l1.78.89a.5.5 0 00.22.055.5.5 0 00.5-.5V1a.5.5 0 00-.235-.425zM6.5 4.5A.5.5 0 016 5H1.5a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5H6a.5.5 0 01.5.5v3zM9 4.19l-1.5-.75v-.88L9 1.81v2.38z"
        fill={fill || '#070550'}
      />
      <path
        d="M6.5.75H2l-1.5.563.375 3.937 6-.188V2.626L6.5.75z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Video;
