import axios from 'axios';

import { API } from 'consts';
import { GenericResponse } from 'types/GenericResponse';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const CANVA_URL = `${BASE_URL}/canva`;

export const createCanvaAuth = (
  payload: any,
  token: string,
): Promise<GenericResponse<any>> => {
  return axios({
    method: 'post',
    url: CANVA_URL + '/sdk-auth',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  }).then((res) => res.data);
};

export const createCanvaAuthorize = (
  payload: any,
  token: string,
): Promise<any> => {
  return axios({
    method: 'get',
    url: CANVA_URL + '/oauth/authorize?' + new URLSearchParams(payload).toString(),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.data);
};
