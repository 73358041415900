import { put, call, takeLatest, select } from 'redux-saga/effects';

import { verifyCodeLinkedin } from 'services/linkedin';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  VerifyCodeLinkedinMeta,
  VerifyCodeLinkedinPayload,
} from 'types/store/VerifyCodeLinkedin';

import { verifyCodeLinkedinActions } from '../actions';

function* verifyCodeLinkedinRequest(
  action: AsyncAction<VerifyCodeLinkedinMeta, VerifyCodeLinkedinPayload>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        verifyCodeLinkedin,
        action.meta,
        state.auth.token,
      );
      yield put(verifyCodeLinkedinActions.success(data));
    } catch (e: any) {
      yield put(verifyCodeLinkedinActions.failed(e.message));
    }
  }
}

// function* verifyCodeLinkedinSuccess(action: AsyncAction<VerifyCodeLinkedinMeta, VerifyCodeLinkedinPayload>): any {
//   yield put()
// }

function* verifyCodeLinkedinWatcher(): any {
  yield takeLatest(
    verifyCodeLinkedinActions.REQUEST,
    verifyCodeLinkedinRequest,
  );
  // yield takeLatest(verifyCodeLinkedinActions.SUCCESS, verifyCodeLinkedinSuccess);
}

export default verifyCodeLinkedinWatcher;
