import React from 'react';

import { useTheme } from 'utils/Theme';

import { SVGProps } from './SVG.props';

const StoryEditor = (props: SVGProps): React.JSX.Element => {
  const theme = useTheme();
  const { fill } = props;
  return (
    <svg width={14} height={14} viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M2.333 11H5.16a.666.666 0 00.473-.194l4.614-4.62 1.893-1.853a.666.666 0 000-.947L9.313.526a.667.667 0 00-.946 0l-1.88 1.887-4.627 4.62a.667.667 0 00-.193.473v2.827a.667.667 0 00.666.667zM8.84 1.94l1.887 1.886-.947.947-1.887-1.887.947-.946zM3 7.78l3.953-3.954L8.84 5.713 4.887 9.666H3V7.78zm10 4.553H1a.667.667 0 100 1.333h12a.666.666 0 100-1.333z"
        fill={fill || theme.grey.shade8}
      />
    </svg>
  );
};

export default StoryEditor;
