import { put, call, takeLatest, select } from 'redux-saga/effects';

import { createDiscussStory } from 'services/story';
import { AsyncAction } from 'types/Action';
import { CreateDiscussStoryMeta, CreateDiscussStoryPayload } from 'types/store/CreateDiscussStory';
import { Store } from 'types/store/Store';

import { createDiscussStoryActions } from '../actions';

function* createDiscussStoryRequest(action: AsyncAction<CreateDiscussStoryMeta, CreateDiscussStoryPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(createDiscussStory, action.meta, state.auth.token);
      yield put(createDiscussStoryActions.success(data));
    } catch (e: any) {
      yield put(createDiscussStoryActions.failed(e.message));
    }
  }
}

// function* createDiscussStorySuccess(action: AsyncAction<CreateDiscussStoryMeta, CreateDiscussStoryPayload>): any {
//   yield put()
// }

function* createDiscussStoryWatcher(): any {
  yield takeLatest(createDiscussStoryActions.REQUEST, createDiscussStoryRequest);
  // yield takeLatest(createDiscussStoryActions.SUCCESS, createDiscussStorySuccess);
}

export default createDiscussStoryWatcher;
