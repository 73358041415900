import { put, call, takeLatest, select } from 'redux-saga/effects';

import { MAIN_ROUTES } from 'consts';
import { updateStoryById } from 'services/story';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateStoryByIdMeta,
  UpdateStoryByIdPayload,
} from 'types/store/UpdateStoryById';
import navigationService from 'utils/navigationService';

import { updateStoryByIdActions } from '../actions';

function* updateStoryByIdRequest(
  action: AsyncAction<UpdateStoryByIdMeta, UpdateStoryByIdPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateStoryById,
        action.meta,
        state.auth.token,
      );
      yield put(updateStoryByIdActions.success(data));
    } catch (e: any) {
      if (e.response.status === 404) {
        yield call(navigationService.goTo, MAIN_ROUTES.NOT_FOUND);
      } else {
        let message = e.message;
        if (e?.response?.data?.status == 400 && e?.response?.data?.message) {
          message = e?.response?.data?.message;
        }
        yield put(updateStoryByIdActions.failed(message));
      }
    }
  }
}

function* updateStoryByIdSuccess(
  action: AsyncAction<UpdateStoryByIdMeta, UpdateStoryByIdPayload>,
) {
  //
}

function* updateStoryByIdWatcher(): any {
  yield takeLatest(updateStoryByIdActions.REQUEST, updateStoryByIdRequest);
  yield takeLatest(updateStoryByIdActions.SUCCESS, updateStoryByIdSuccess);
}

export default updateStoryByIdWatcher;
