import { createAsyncReducer } from 'utils/Redux';
import {
  DeleteRecipeDetailNoteMeta,
  DeleteRecipeDetailNotePayload,
} from 'types/store/ContentPlanner';

import { deleteRecipeDetailNoteActions } from '../actions';

export default createAsyncReducer<
  DeleteRecipeDetailNoteMeta,
  DeleteRecipeDetailNotePayload
>(deleteRecipeDetailNoteActions);
