import { AxiosError } from 'axios';
import { put, call, takeLatest, select, all } from 'redux-saga/effects';

import {
  createWidget,
  getListContentById,
  getWidgetByCompany,
  getWidgetById,
  updateWidget,
} from 'services/widget';
import {
  createDefaultFormAction,
  createWidgetAction,
  editWidgetAction,
  openToast,
  updateWidgetAction,
  widgetAction,
} from 'store/actions';
import { Action, AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import { uploadFile } from 'services/upload';
import { WIDGET_TYPE, widgetFilterParam } from 'types/store/Widget';
import { initFormData } from 'routes/Employer/Widget/Setting/Setting.function';
import { handleAxiosError } from 'utils/handleAxiosError';
import navigationService from 'utils/navigationService';

function* widgetRequest(action: AsyncAction<widgetFilterParam>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getWidgetByCompany,
        state.widget.filterParam,
        state.auth.token,
      );

      yield put(widgetAction.list_widget.success(data.data));
    } catch (err: unknown) {
      const errorMessage = err as AxiosError;
      // yield put(
      // openToast(errorMessage.response?.data.message, 'error', 'Error'),
      // );
    }
  }
}

function* createWidgetRequest(action: AsyncAction) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const router = action.meta.router;
      action.meta.router = undefined;
      const { data } = yield call(createWidget, action.meta, state.auth.token);
      const initForm = state.widget.initForm;
      const type = () => {
        switch (action.meta.category) {
          case 'LADING_PAGE':
            return WIDGET_TYPE.EMBED;
          case 'JOB_DESCRIPTION':
            return WIDGET_TYPE.EMBED;
          default:
            return WIDGET_TYPE.POPUP;
        }
      };
      initForm.landingType = type();
      initForm.widgetCodeEmbed = data.data.widgetCodeEmbed;
      initForm.widgetCodePopup = data.data.widgetCodePopup;
      yield put(createDefaultFormAction.request(initForm));
      yield put(createWidgetAction.success(data.data.id));
      yield call(navigationService.goTo, `${router}&id=${data.data.widget.id}`);
    } catch (err: unknown) {
      // const errorMessage = err as AxiosError;
      const errorMessage = handleAxiosError(err);
      yield put(
        openToast(
          errorMessage ?? 'An unexpected error occurred',
          'error',
          'Error',
        ),
      );
    }
  }
}

function* updateWidgetRequest(action: AsyncAction): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      if (action.meta.isCreated) {
        action.meta.isCreated = undefined;

        if (
          action.meta?.setting?.logo &&
          typeof action.meta?.setting?.logo !== 'string'
        ) {
          const urlLogo: any = yield uploadFile({
            file: action.meta.setting.logo,
            token: state.auth.token,
            folder: 'library',
          });
          action.meta.setting.logo = urlLogo;
        }
        if (
          action.meta?.setting?.sliders?.bgImage &&
          typeof action.meta?.setting?.sliders?.bgImage !== 'string'
        ) {
          const banner: any = yield uploadFile({
            file: action.meta.setting.sliders.bgImage,
            token: state.auth.token,
            folder: 'library',
          });
          action.meta.setting.sliders.bgImage = banner;
        }
        const { data } = yield call(
          updateWidget,
          action.meta,
          state.auth.token,
        );
        yield put(openToast(data.message, 'success', data.message));
      } else {
        const { data } = yield call(
          updateWidget,
          action.meta,
          state.auth.token,
        );
        if (data.data?.id) {
          yield put(updateWidgetAction.success(data.data.id));
        } else {
          yield put(widgetAction.list_widget.request({ page: 1, perPage: 15 }));
        }
        yield put(openToast(data.message, 'success', data.message));
      }

      yield call(navigationService.goTo, '/employer/widget');
    } catch (err: unknown) {
      // const errorMessage = err as AxiosError;
      const errorMessage = handleAxiosError(err);
      yield put(
        openToast(
          errorMessage ?? 'An unexpected error occurred',
          'error',
          'Error',
        ),
      );
    }
  }
}

function* editWidgetRequest(action: AsyncAction): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const [listStory, editData] = yield all([
        call(getListContentById, action.meta, state.auth.token),
        call(getWidgetById, action.meta, state.auth.token),
      ]);
      // yield put(
      //   openToast(editData.data.message, 'success', editData.data.message),
      // );
      yield put(
        editWidgetAction.success(
          initFormData(editData.data.data, listStory.data.data),
        ),
      );
      yield put(updateWidgetAction.success(editData.data.data.widget.id));
    } catch (err: unknown) {
      // const errorMessage = err as AxiosError;
      const errorMessage = handleAxiosError(err);
      yield put(
        openToast(
          errorMessage ?? 'An unexpected error occurred',
          'error',
          'Error',
        ),
      );
    }
  }
}

function* widgetWatcher(): any {
  yield takeLatest(widgetAction.list_widget.REQUEST, widgetRequest);
  yield takeLatest(createWidgetAction.REQUEST, createWidgetRequest);
  yield takeLatest(updateWidgetAction.REQUEST, updateWidgetRequest);
  yield takeLatest(editWidgetAction.REQUEST, editWidgetRequest);
}

export default widgetWatcher;
