/* eslint-disable import/order */
import pathOr from 'ramda/es/pathOr';
import { Action } from 'types/Action';
import { FilterRequest } from 'routes/Employer/LibraryV2/LibraryV2.interface';

import { libraryFilter } from '../../actions';

const DEFAULT_STATE: FilterRequest = {};

const DEFAULT_ACTION: Action<FilterRequest> = {
  type: '',
  payload: {
    sortBy: '',
  },
};

export default (state = DEFAULT_STATE, action = DEFAULT_ACTION): any => {
  return pathOr(state, [action.type], {
    [libraryFilter.UPDATE]: {
      ...state,
      ...action.payload,
    },
    [libraryFilter.CLEAR]: {},
  });
};
