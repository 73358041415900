import React, { useEffect, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
  getContentMarketplaceActions,
  upsertContentMarketplaceActions,
  updateContentMarketplaceActions,
  getBrandGuidelinesByCompanyIdActions,
  getAdvocatesByUserIdActions,
} from 'store/actions';
import { Store } from 'types/store/Store';

import { FormProps, VideoQuestion } from './VideoForm.props';
import VideoFormView from './VideoForm.view';

const VideoFormContainer = (props: FormProps): React.JSX.Element => {
  const {
    storyId,
    onClose,
    marketplaceId,
    activeStoryDetails,
    updatePaidStatus,
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (marketplaceId) {
      dispatch(
        getContentMarketplaceActions.request({
          marketplaceId,
        }),
      );
    }

    return () => {
      dispatch(getContentMarketplaceActions.clear());
    };
  }, []);

  const contentMarketplace = useSelector(
    (state: Store) => state.getContentMarketplace.data?.data,
  );
  const upsertContentMarketplaceStatus = useSelector(
    (state: Store) => state.upsertContentMarketplace,
  );
  const updateContentMarketplaceStatus = useSelector(
    (state: Store) => state.updateContentMarketplace,
  );
  const advocatesInfo = useSelector(
    (state: Store) => state.getAdvocatesByUserId.data?.data,
  );

  const handleUpsertContentMarketplace = (payload: any) => {
    if (payload.marketplace.id) {
      const newPayload = {
        ...payload,
        marketplace: {
          ...contentMarketplace,
          topic: payload.marketplace.topic,
          mail_items: [...payload.marketplace.mail_items],
          marketplace_attachments: [
            ...payload.marketplace.marketplace_attachments,
          ],
        },
      };
      dispatch(updateContentMarketplaceActions.request(newPayload));
    } else {
      dispatch(upsertContentMarketplaceActions.request(payload));
    }
    updatePaidStatus(true);
  };

  useEffect(() => {
    if (
      upsertContentMarketplaceStatus.data?.status === 200 ||
      updateContentMarketplaceStatus.data?.status === 200
    ) {
      onClose();
      // dispatch(upsertContentMarketplaceActions.clear());
      // dispatch(updateContentMarketplaceActions.clear());
    }
  }, [upsertContentMarketplaceStatus, updateContentMarketplaceStatus]);

  useEffect(() => {
    if (activeStoryDetails) {
      const userIds = activeStoryDetails.user_story_statuses?.map(
        (_: any) => _?.user_id,
      );
      dispatch(
        getAdvocatesByUserIdActions.request({
          userIds,
        }),
      );
    }
  }, [activeStoryDetails]);

  const videoQuestions: VideoQuestion[] = useMemo(() => {
    const result: VideoQuestion[] = [];
    if (
      activeStoryDetails?.story_questions &&
      activeStoryDetails?.story_questions.length > 0
    ) {
      activeStoryDetails?.story_questions.map((question, _) => {
        if (
          question.user_story_question_answers &&
          question.user_story_question_answers.length > 0
        ) {
          question.user_story_question_answers.map((answer, _) => {
            if (
              answer.story_video?.video_link &&
              answer.story_video?.video_link != null
            ) {
              const questionTitle =
                question.question.edit_questions.length > 0
                  ? question.question.edit_questions[0].value
                  : question.question.question;
              const advocateId = answer.user_id;
              const filteredUser =
                activeStoryDetails.user_story_statuses.filter(
                  (user, _) => user.user_id === advocateId,
                );

              result.push({
                questionTitle,
                video_link: answer.story_video?.video_link,
                isSelected: false,
                username: `${answer.user?.first_name} ${answer.user?.last_name}`,
                isShowHide: false,
                userrole:
                  filteredUser.length > 0 ? filteredUser[0].user.role : '',
              });
            }
          });
        }
      });
    }
    return result;
  }, [activeStoryDetails]);

  const generatedProps = {
    onClose,
    storyId,
    handleUpsertContentMarketplace,
    contentMarketplace,
    pending:
      upsertContentMarketplaceStatus.pending ||
      updateContentMarketplaceStatus.pending,
    activeStoryDetails,
    advocatesInfo,
    answeredVideos: videoQuestions,
  };
  return <VideoFormView {...generatedProps} />;
};

export default VideoFormContainer;
