import React, { useState } from 'react';

import { Eye, EyeOff } from 'components/base/SVG';
import Touchable from 'components/base/Touchable';
import Typography from 'components/base/Typography';
import { useTheme } from 'utils/Theme';

import { TextFieldProps } from './TextField.props';
import {
  Container,
  LeftComponentContainer,
  FieldContainer,
  Field,
  VisibilityContainer,
  Error,
  LabelMain,
  RightComponentContainer,
} from './TextField.style';

const TextField = (props: TextFieldProps): React.JSX.Element => {
  const theme = useTheme();
  const {
    id,
    type,
    label,
    value = '',
    disabled = false,
    onChange = () => null,
    onChangeText = () => null,
    placeholder,
    secured,
    error = '',
    LeftComponent = null,
    RightComponent = null,
    noLeftCompBorder = false,
    noRightCompBorder = false,
    className = undefined,
    onBlur = () => null,
    onFocus = () => null,
    onPaste,
    onKeyDown = () => null,
    autoFocus = false,
    labelStyle = undefined,
    height = '24px',
    LeftComponentWidth,
    bills,
    autoComplete = 'on',
    staticText,
    RightComponentWidth,
    onClickIcon = () => null,
    isColorBlack,
    style,
    prefix,
    highlightError,
    customSmallText,
    customRadius,
  } = props;

  const [showSecuredText, setShowSecuredText] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);
    onChangeText(event.target.value);
  };

  const VisibilityIcon = showSecuredText ? EyeOff : Eye;
  const defaultInputType = type || 'text';
  return (
    <Container className={className}>
      <LabelMain
        variant={labelStyle === undefined ? 'label' : labelStyle}
        type={labelStyle === undefined ? 'label' : labelStyle}
        color={isColorBlack ? 'black' : 'shade6'}
        className="label-text-field"
      >
        {label}
      </LabelMain>
      <FieldContainer
        bills={bills}
        error={error}
        highlightError={highlightError}
        className="text-field"
        style={{ borderRadius: customRadius || '12px' }}
      >
        {LeftComponent && (
          <LeftComponentContainer
            noLeftCompBorder={noLeftCompBorder}
            LeftComponentWidth={LeftComponentWidth}
            onClick={onClickIcon}
            clickable={!!onClickIcon}
          >
            {LeftComponent}
          </LeftComponentContainer>
        )}
        {staticText && (
          <Typography variant="label" color="shade6">
            {staticText}
          </Typography>
        )}
        <Field
          staticText={staticText}
          bills={bills}
          style={{ ...style, height: height }}
          id={id}
          autoFocus={autoFocus}
          disabled={disabled}
          type={secured && !showSecuredText ? 'password' : defaultInputType}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={placeholder}
          autoComplete={autoComplete}
          onPaste={onPaste}
          onKeyDown={onKeyDown}
          prefix={prefix}
        />
        {secured && (
          <VisibilityContainer>
            <Touchable dark onClick={() => setShowSecuredText((v) => !v)}>
              <VisibilityIcon fill={theme.brand.primary} />
            </Touchable>
          </VisibilityContainer>
        )}
        {RightComponent && (
          <RightComponentContainer
            onClick={onClickIcon}
            clickable={!!onClickIcon}
            RightComponentWidth={RightComponentWidth}
            noRightCompBorder={noRightCompBorder}
          >
            {RightComponent}
          </RightComponentContainer>
        )}
      </FieldContainer>
      {(error || '').length > 0 && (
        <Error variant="caption" color="error" className="error">
          {error}
        </Error>
      )}
      {!error && customSmallText}
    </Container>
  );
};

export default TextField;
