import React from 'react';

import { SVGProps } from './SVG.props';

const Germany = (props: SVGProps): React.JSX.Element => {
  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.909668 30.2589C0.909668 32.7133 2.89967 34.7033 5.35411 34.7033H36.4652C38.9197 34.7033 40.9097 32.7133 40.9097 30.2589V25.8145H0.909668V30.2589Z"
        fill="#FFCD05"
      />
      <path
        d="M0.909668 15.8145H40.9097V25.8145H0.909668V15.8145Z"
        fill="#ED1F24"
      />
      <path
        d="M36.4652 5.81445H5.35411C2.89967 5.81445 0.909668 7.80445 0.909668 10.2589V15.8145H40.9097V10.2589C40.9097 7.80445 38.9197 5.81445 36.4652 5.81445Z"
        fill="#141414"
      />
    </svg>
  );
};

export default Germany;
