import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getStoryDiscussionComments } from "services/story";
import { AsyncAction } from 'types/Action';
import { GetStoryDiscussionCommentsMeta, GetStoryDiscussionCommentsPayload } from 'types/store/GetStoryDiscussionComments';
import { Store } from 'types/store/Store';

import { getStoryDiscussionCommentsActions } from '../actions';

function* getStoryDiscussionCommentsRequest(action: AsyncAction<GetStoryDiscussionCommentsMeta, GetStoryDiscussionCommentsPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(getStoryDiscussionComments, action.meta, state.auth.token);
      yield put(getStoryDiscussionCommentsActions.success(data));
    } catch (e: any) {
      yield put(getStoryDiscussionCommentsActions.failed(e.message));
    }
  }
}

// function* getStoryDiscussionCommentsSuccess(action: AsyncAction<GetStoryDiscussionCommentsMeta, GetStoryDiscussionCommentsPayload>): any {
//   yield put()
// }

function* getStoryDiscussionCommentsWatcher(): any {
  yield takeLatest(getStoryDiscussionCommentsActions.REQUEST, getStoryDiscussionCommentsRequest);
  // yield takeLatest(getStoryDiscussionCommentsActions.SUCCESS, getStoryDiscussionCommentsSuccess);
}

export default getStoryDiscussionCommentsWatcher;
