import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getTranscribeVideo } from 'services/question';
import { AsyncAction } from 'types/Action';
import { GetTranscribeVideoMeta, GetTranscribeVideoPayload } from 'types/store/GetTranscribeVideo';
import { Store } from 'types/store/Store';

import { getTranscribeVideoActions } from '../actions';

function* getTranscribeVideoRequest(action: AsyncAction<GetTranscribeVideoMeta, GetTranscribeVideoPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(getTranscribeVideo, action.meta, state.auth.token);
      yield put(getTranscribeVideoActions.success(data));
    } catch (e: any) {
      yield put(getTranscribeVideoActions.failed(e.message));
    }
  }
}

// function* getTranscribeVideoSuccess(action: AsyncAction<GetTranscribeVideoMeta, GetTranscribeVideoPayload>): any {
//   yield put()
// }

function* getTranscribeVideoWatcher(): any {
  yield takeLatest(getTranscribeVideoActions.REQUEST, getTranscribeVideoRequest);
  // yield takeLatest(getTranscribeVideoActions.SUCCESS, getTranscribeVideoSuccess);
}

export default getTranscribeVideoWatcher;
