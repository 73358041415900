import React from 'react';

import { createPortal } from 'react-dom';

import {
  CheckFilled,
  Close,
  QuestionFilled,
  ExclamationFilled,
  CloseFilled,
} from 'components/base/SVG';
import Typography from 'components/base/Typography';
import { useSnackbar } from 'providers/snackbar';
import { SnackbarType, ISnackbar } from 'providers/snackbar/types';
import theme from 'utils/Theme';

import { SnackbarContainerProps } from './SnackbarContainer.props';
import {
  Container,
  Snackbar,
  Content,
  LeftBorder,
  CloseContainer,
  ActionBox,
} from './SnackbarContainer.style';

const generateConfig = (type: keyof typeof SnackbarType) => {
  switch (type) {
    default:
    case SnackbarType.info:
      return {
        color: theme.product.information.primary,
        Icon: ExclamationFilled,
      };
    case SnackbarType.success:
      return {
        color: theme.product.success.primary,
        Icon: CheckFilled,
      };
    case SnackbarType.alert:
      return {
        color: theme.product.alert.primary,
        Icon: QuestionFilled,
      };
    case SnackbarType.warning:
      return {
        color: theme.product.warning.primary,
        Icon: ExclamationFilled,
      };
    case SnackbarType.error:
      return {
        color: theme.product.error.primary,
        Icon: CloseFilled,
      };
  }
};

const SnackbarContainer = (
  props: SnackbarContainerProps,
): React.JSX.Element => {
  const { queue, remove } = useSnackbar();

  if (queue.length === 0) {
    return <></>;
  }

  return createPortal(
    <Container>
      {queue.map((snackbar: ISnackbar) => {
        const { type, description, title, id, onClose } = snackbar;
        const { color, Icon } = generateConfig(type);
        return (
          <Snackbar key={id}>
            <LeftBorder color={color} />
            <Icon width={20} height={20} fill={color} />
            <Content>
              <Typography variant="label" weight="700" color="primary">
                {title}
              </Typography>
              {description && (
                <Typography variant="caption" color="primary">
                  {description}
                </Typography>
              )}
            </Content>
            <ActionBox>
              <CloseContainer
                onClick={() => {
                  remove(id);
                  if (onClose) {
                    onClose();
                  }
                }}
              >
                <Close width={12} height={12} />
              </CloseContainer>
            </ActionBox>
          </Snackbar>
        );
      })}
    </Container>,
    document.body,
  );
};

export default React.memo(SnackbarContainer);
