import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getStoryEventLogs } from 'services/story';
import { AsyncAction } from 'types/Action';
import { GetStoryEventLogMeta, GetStoryEventLogPayload } from 'types/store/GetStoryEventLog';
import { Store } from 'types/store/Store';

import { getStoryEventLogActions } from '../actions';

function* getStoryEventLogRequest(action: AsyncAction<GetStoryEventLogMeta, GetStoryEventLogPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getStoryEventLogs, action.meta, state.auth.token);
      yield put(getStoryEventLogActions.success(data));
    } catch (e: any) {
      yield put(getStoryEventLogActions.failed(e.message));
    }
  }
}

// function* getStoryEventLogSuccess(action: AsyncAction<GetStoryEventLogMeta, GetStoryEventLogPayload>): any {
//   yield put()
// }

function* getStoryEventLogWatcher(): any {
  yield takeLatest(getStoryEventLogActions.REQUEST, getStoryEventLogRequest);
  // yield takeLatest(getStoryEventLogActions.SUCCESS, getStoryEventLogSuccess);
}

export default getStoryEventLogWatcher;
