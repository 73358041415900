import React from 'react';

import { useTheme } from 'utils/Theme';

import { SVGProps } from './SVG.props';

const EyeOff = (props: SVGProps): React.JSX.Element => {
  const theme = useTheme();
  const { width, height, fill } = props;
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.4714 3.13807C6.73175 2.87772 6.73175 2.45561 6.4714 2.19526C6.21106 1.93491 5.78894 1.93491 5.5286 2.19526L2.19526 5.5286C1.93491 5.78894 1.93491 6.21106 2.19526 6.4714L5.5286 9.80474C5.78894 10.0651 6.21106 10.0651 6.4714 9.80474C6.73175 9.54439 6.73175 9.12228 6.4714 8.86193L4.27614 6.66667H8C9.53355 6.66667 10.6965 7.12507 11.4682 7.82663C12.2361 8.52471 12.6667 9.5075 12.6667 10.6667V13.3332C12.6667 13.7013 12.9651 14 13.3333 14C13.7015 14 14 13.7015 14 13.3333V10.6667C14 9.15916 13.4306 7.80863 12.3651 6.84004C11.3035 5.87493 9.79979 5.33333 8 5.33333H4.27614L6.4714 3.13807Z"
        fill={fill || '#060548'}
      />
    </svg>
  );
};

export default EyeOff;
