import axios, { AxiosPromise } from 'axios';

import { API } from 'consts';
import { GenericResponse } from 'types/GenericResponse';
import { GetTransactionByStripeIdMeta} from 'types/store/GetTransactionByStripeId';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const PAYMENT_URL = `${BASE_URL}/transaction`;




export const getTransactionByStripeId = (
    data: GetTransactionByStripeIdMeta,
    token: string
  ): AxiosPromise<GenericResponse> => {
      return axios({
      method: 'get',
      url: `${PAYMENT_URL}/get-transaction-history`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  };
  