import { put, call, takeLatest, select } from 'redux-saga/effects';

import { inviteAdvocate } from 'services/advocate';
import { AsyncAction } from 'types/Action';
import {
  InviteAdvocateMeta,
  InviteAdvocatePayload,
} from 'types/store/InviteAdvocateState';
import { Store } from 'types/store/Store';

import { inviteAdvocateActions } from '../actions';

function* inviteAdvocateRequest(
  action: AsyncAction<InviteAdvocateMeta, InviteAdvocatePayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        inviteAdvocate,
        action.meta,
        state.auth.token,
      );
      yield put(inviteAdvocateActions.success(data));
    } catch (e: any) {
      yield put(inviteAdvocateActions.failed(e.message));
    }
  }
}

function* inviteAdvocateSuccess(
  action: AsyncAction<InviteAdvocateMeta, InviteAdvocatePayload>,
) {
  // yield put(inviteAdvocateActions.clear());
}

function* inviteAdvocateWatcher(): any {
  yield takeLatest(inviteAdvocateActions.REQUEST, inviteAdvocateRequest);
  yield takeLatest(inviteAdvocateActions.SUCCESS, inviteAdvocateSuccess);
}

export default inviteAdvocateWatcher;
