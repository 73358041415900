import axios, { AxiosPromise } from 'axios';

import { API } from 'consts';
import { PayloadAIAssitant } from 'routes/NoAppNoLogin/Content/ContentWrapper/Written/components/Questions/Questions.props';
import { GenericResponse } from 'types/GenericResponse';
import { AddStoryDiscussionCommentMeta } from 'types/store/AddStoryDiscussionComment';
import { AssignAdvocatesToStoryMeta } from 'types/store/AssignAdvocatesToStory';
import { ChangeStoryDueDateMeta } from 'types/store/ChangeStoryDueDate';
import { CloneStoryMeta } from 'types/store/CloneStory';
import { CreateDiscussStoryMeta } from 'types/store/CreateDiscussStory';
import { CreateRevisionMeta } from 'types/store/CreateRevisionState';
import { CreateStoryMeta } from 'types/store/CreateStoryState';
import { DiscussionStoriesMeta } from 'types/store/DiscussionStories';
import { DiscussionStoryDetailsMeta } from 'types/store/DiscussionStoryDetails';
import { DownloadResponsesMeta } from 'types/store/DownloadResponsesState';
import { DownloadResponsesVideoStoryMeta } from 'types/store/DownloadResponsesVideoStoryState';
import { DownloadStoryVideoMeta } from 'types/store/DownloadStoryVideo';
import { GenerateSocialCopyMeta } from 'types/store/GenerateSocialCopy';
import { GetAdvocateByStoryMeta } from 'types/store/GetAdvocateByStory';
import { GetApprovalsByStoryIdMeta } from 'types/store/GetApprovalsByStoryIdState';
import { GetDashboardStoriesMeta } from 'types/store/GetDashboardStories';
import { GetFinalStoryPiecesMeta } from 'types/store/GetFinalStoryPieces';
import { GetFinalWrittenStoryMeta } from 'types/store/GetFinalWrittenStoryState';
import { GetLibraryStoriesMeta } from 'types/store/GetLibraryStories';
import { GetOnboardMeta } from 'types/store/GetOnboardState';
import { GetReviewedStoriesMeta } from 'types/store/GetReviewedStoriesState';
import { GetSaveTemplatesCompanyIdMeta } from 'types/store/GetSaveTemplatesByCompanyId';
import { GetShareSocialStoryStateMeta } from 'types/store/getShareSocialStoryState';
import { GetStoriesByCompanyIdMeta } from 'types/store/GetStoriesByCompanyIdState';
import { GetStoriesByUserIdMeta } from 'types/store/GetStoriesByUserId';
import { GetStoryActivityLogsMeta } from 'types/store/GetStoryActivityLogs';
import { GetStoryByIdMeta } from 'types/store/GetStoryByIdState';
import { getStoryCalendarByCompanyMeta } from 'types/store/getStoryCalendarByCompanyState';
import { GetStoryDiscussionCommentsMeta } from 'types/store/GetStoryDiscussionComments';
import { GetStoryEventLogMeta } from 'types/store/GetStoryEventLog';
import { GetStoryEventsMeta } from 'types/store/GetStoryEventsState';
import { GetStoryForBuilderMeta } from 'types/store/GetStoryForBuilder';
import { GetStoryLinkMeta } from 'types/store/GetStoryLink';
import { GetSubtitlesMeta } from 'types/store/GetSubtitles';
import { GetTimelineEventsMeta } from 'types/store/GetTimelineEventsState';
import { GetUnpaidStoriesMeta } from 'types/store/GetUnpaidStories';
import { InviteToShareStoryMeta } from 'types/store/InviteToShareStory';
import { LoadDocxByFinalStoryMeta } from 'types/store/LoadDocxByFinalStory';
import { RemoveStoryMeta } from 'types/store/RemoveStory';
import { ArchiveStoryMeta } from 'types/store/ArchiveStory';
import { ShareStoryMeta } from 'types/store/ShareStory';
import {
  GetAssignedAdvocatesByStoryMeta,
  UpdateAdvocatesForStoryMeta,
} from 'types/store/Story';
import { UpdateFinalStoryMeta } from 'types/store/UpdateFinalStoryState';
import { UpdateQuestionForStoryMeta } from 'types/store/UpdateQuestionForStory';
import { UpdateRevisionMeta } from 'types/store/UpdateRevisionState';
import { UpdateSfpSkippedAllMeta } from 'types/store/UpdateSfpSkippedAll';
import { UpdateSfpStatusMeta } from 'types/store/UpdateSfpStatusState';
import { UpdateStoryByIdMeta } from 'types/store/UpdateStoryById';
import { UpdateStoryDiscussionMeta } from 'types/store/UpdateStoryDiscussion';
import { UpdateStoryDiscussionCommentMeta } from 'types/store/UpdateStoryDiscussionComment';
import { UpdateStoryForBuilderMeta } from 'types/store/UpdateStoryForBuilder';
import { UpdateStoryTopicMeta } from 'types/store/UpdateStoryTopic';
import { UpdateStoryToPublishMeta } from 'types/store/UpdateStoryToPublishState';
import { UploadFinalStoryMeta } from 'types/store/UploadFinalStoryState';
import { UpsertStoryApprovalsMeta } from 'types/store/UpsertStoryApprovalsState';
import { UpdateVideoTranscribeMeta } from 'types/store/UpdateVideoTranscribe';
import { GetStoryRepurposedEventLogMeta } from 'types/store/GetStoryRepurposedEventLog';
import { UpdateAdditionPublishLinksMeta } from 'types/store/UpdateAdditionPublishLinks';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const STORY_URL = `${BASE_URL}/story`;

export const getStoryEventLogs = (
  data: GetStoryEventLogMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getStoryEventLog`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getStoryRepurposedEventLogs = (
  data: GetStoryRepurposedEventLogMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/repurposed-story/event-log`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getExternalPublishLinks = (
  // data: GetStoryExternalPublishLinksMeta,
  story_id: string,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/get-additional-publish-links?story_id=${story_id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    // params: story_id,
  });
};

export const updateExternalPublishLinks = (
  data: UpdateAdditionPublishLinksMeta,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${STORY_URL}/create-additional-publish-link`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};
export type IVideoTranscribe = {
  story_video_id: string;
  transcribe_text: string;
};

export const updateVideoTranscribe = (
  data: UpdateVideoTranscribeMeta,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${STORY_URL}/update-transcribe-story-video`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const cloneStory = (data: CloneStoryMeta, token: string): any => {
  return axios({
    method: 'post',
    url: `${STORY_URL}/clone`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const changeStoryDueDate = (
  data: ChangeStoryDueDateMeta,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${STORY_URL}/updateStoryDuedate`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const createStory = async (
  data: CreateStoryMeta,
  token: string,
): Promise<any> => {
  return axios({
    method: 'POST',
    url: `${STORY_URL}/create`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getSaveTemplatesByCompanyId = (
  data: GetSaveTemplatesCompanyIdMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getSaveTemplatesByCompanyId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getStoriesByUserId = (
  data: GetStoriesByUserIdMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getStoriesByUserId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getStoryById = (data: GetStoryByIdMeta, token: string): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getStoryById`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const updateStoryById = async (
  data: UpdateStoryByIdMeta,
  token: string,
): Promise<any> => {
  return axios({
    method: 'PUT',
    url: `${STORY_URL}/updateStoryById`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getReviewedStories = (
  data: GetReviewedStoriesMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getReviewedStories`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const downloadResponses = (
  data: DownloadResponsesMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/downloadResponses`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const downloadResponsesVideoStory = (
  data: DownloadResponsesVideoStoryMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/downloadResponsesVideoStory`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const updateStoryToPublish = (
  data: UpdateStoryToPublishMeta,
  token: string,
): any => {
  return axios({
    method: 'POST',
    url: `${STORY_URL}/updateStoryToPublish`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
  // const fd = new FormData();

  // if (data.document_story !== undefined) {
  //   fd.append('document_story', data.document_story);
  // }
  // fd.append('data', JSON.stringify(data));

  // return axios({
  //   method: 'post',
  //   url: `${STORY_URL}/updateStoryToPublish`,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  //   data: fd,
  // });
};

export const uploadFinalStory = (
  data: UploadFinalStoryMeta,
  token: string,
): any => {
  const fd = new FormData();

  if (data.final_story !== undefined) {
    fd.append('story', data.final_story);
  }
  fd.append('data', JSON.stringify(data));

  return axios({
    method: 'post',
    url: `${STORY_URL}/uploadFinalStory`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: fd,
  });
};

export const updateFinalStory = (
  data: UpdateFinalStoryMeta,
  token: string,
): any => {
  return axios({
    method: 'POST',
    url: `${STORY_URL}/updateFinalStory`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getStoriesByCompanyId = (
  data: GetStoriesByCompanyIdMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getStoriesByCompanyId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getShareSocialStory = (
  data: GetShareSocialStoryStateMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/sharingStories`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getStoriesLibraryByCompanyId = (
  data: GetStoriesByCompanyIdMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getStoriesLibraryByCompanyId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getStoryCalendarByCompany = (
  data: getStoryCalendarByCompanyMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getStoriesCalendarByCompany`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getFinalLibraryCalendar = (
  data: getStoryCalendarByCompanyMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getFinalLibraryCalendar`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getApprovalsByStoryId = (
  data: GetApprovalsByStoryIdMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getApprovalsByStoryId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const upsertStoryApprovals = async (
  data: UpsertStoryApprovalsMeta,
  token: string,
): Promise<any> => {
  return axios({
    method: 'POST',
    url: `${STORY_URL}/upsertStoryApprovals`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getFinalWrittenStory = (
  data: GetFinalWrittenStoryMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/extractFinalStory`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getUnpaidStories = (
  data: GetUnpaidStoriesMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getUnpaidStories`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const createRevision = async (
  data: CreateRevisionMeta,
  token: string,
): Promise<any> => {
  const fd = new FormData();

  if (data.story !== undefined) {
    fd.append('story', data.story);
  }
  fd.append('data', JSON.stringify(data));

  return axios({
    method: 'POST',
    url: `${STORY_URL}/createRevision`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: fd,
  });
};

export const updateRevision = (
  data: UpdateRevisionMeta,
  token: string,
): any => {
  return axios({
    method: 'POST',
    url: `${STORY_URL}/updateRevision`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getFinalStoryPieces = (
  data: GetFinalStoryPiecesMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getFinalPieces`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const updateSfpStatus = (
  data: UpdateSfpStatusMeta,
  token: string,
): any => {
  return axios({
    method: 'POST',
    url: `${STORY_URL}/updateSfpStatus`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const updateSfpSkippedAll = (
  data: UpdateSfpSkippedAllMeta,
  token: string,
): any => {
  return axios({
    method: 'POST',
    url: `${STORY_URL}/updateSfpSkippedAll`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

//GetStoryEventsMeta
export const getStoryEvents = (
  data: GetStoryEventsMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getStoryEvents`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

//GetTimelineEventsMeta
export const getTimelineEvents = (
  data: GetTimelineEventsMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getTimelineEvents`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const loadDocxByFinalStory = (
  data: LoadDocxByFinalStoryMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/loadDocxByFinalStory`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { final_story: data.final_story },
  });
};

export const assignAdvocatesToStory = (
  data: AssignAdvocatesToStoryMeta,
  token: string,
): Promise<any> => {
  return axios({
    method: 'POST',
    url: `${STORY_URL}/assignAdvocatesToStory`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getDashboardStories = (
  data: GetDashboardStoriesMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getDashboardStories`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const countDashboardStories = (token: string): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/countDashboardStories`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getOnboard = (data: GetOnboardMeta, token: string): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getOnboard`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const downloadStoryVideo = (
  data: DownloadStoryVideoMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/downloadStoryVideo`,
    responseType: 'arraybuffer',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getStoryForBuilder = (
  data: GetStoryForBuilderMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getStoryForBuilder/${data.storyId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateStoryForBuilder = (
  data: UpdateStoryForBuilderMeta,
  token: string,
): any => {
  return axios({
    method: 'put',
    url: `${STORY_URL}/updateStoryForBuilder`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const removeStory = (data: RemoveStoryMeta, token: string): any => {
  return axios({
    method: 'POST',
    url: `${STORY_URL}/remove`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const archiveStory = (data: ArchiveStoryMeta, token: string): any => {
  return axios({
    method: 'POST',
    url: `${STORY_URL}/archive`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};


export const discussionStories = async (
  data: DiscussionStoriesMeta,
  token: string,
): Promise<any> => {
  return axios({
    method: 'GET',
    url: `${STORY_URL}/discussion`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const createDiscussStory = async (
  data: CreateDiscussStoryMeta,
  token: string,
): Promise<any> => {
  return axios({
    method: 'POST',
    url: `${STORY_URL}/discussion`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const discussionStoryDetails = async (
  data: DiscussionStoryDetailsMeta,
  token: string,
): Promise<any> => {
  return axios({
    method: 'GET',
    url: `${STORY_URL}/discussion/${data.storyId}/details`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateStoryDiscussion = async (
  data: UpdateStoryDiscussionMeta,
  token: string,
): Promise<any> => {
  return axios({
    method: 'PUT',
    url: `${STORY_URL}/discussion/${data.storyId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const updateTopic = (data: UpdateStoryTopicMeta, token: string): any => {
  return axios({
    method: 'POST',
    url: `${STORY_URL}/updateTopic`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getStoryDiscussionComments = (
  data: GetStoryDiscussionCommentsMeta,
  token: string,
): any => {
  return axios({
    method: 'GET',
    url: `${STORY_URL}/${data.storyId}/comment`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addStoryDiscussionComment = (
  data: AddStoryDiscussionCommentMeta,
  token: string,
): any => {
  return axios({
    method: 'POST',
    url: `${STORY_URL}/${data.storyId}/comment`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const updateStoryDiscussionComment = (
  data: UpdateStoryDiscussionCommentMeta,
  token: string,
): any => {
  return axios({
    method: 'PUT',
    url: `${STORY_URL}/${data.storyId}/comment`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const updateQuestionForStory = (
  data: UpdateQuestionForStoryMeta,
  token: string,
): any => {
  return axios({
    method: 'put',
    url: `${STORY_URL}/updateQuestionForStory/${data.storyId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      newQuestions: data.newQuestions,
      deleteQuestionIds: data.deleteQuestionIds,
      editQuestions: data.editQuestions,
    },
  });
};

export const getStoryActivityLogs = (
  data: GetStoryActivityLogsMeta,
  token: string,
): any => {
  return axios({
    method: 'GET',
    url: `${STORY_URL}/${data.storyId}/activity/log`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const generateSocialCopy = (
  data: GenerateSocialCopyMeta,
  token: string,
): any => {
  const fd = new FormData();

  if (data.file !== undefined) {
    fd.append('file', data.file);
  }
  if (data.story_id) {
    fd.append('story_id', data.story_id);
  }
  if (data.library_id) fd.append('library_id', data.library_id);
  if (data.versionFileId) fd.append('versionFileId', data.versionFileId);
  if (data?.third_person) {
    fd.append('third_person', 'true');
  } else {
    fd.append('third_person', 'false');
  }

  return axios({
    method: 'POST',
    url: `${STORY_URL}/generateSocialPost`,
    data: fd,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAdvocateByStory = (
  data: GetAdvocateByStoryMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getAdvocateByStory`,
    params: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const inviteToShareStory = (
  data: InviteToShareStoryMeta,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${STORY_URL}/inviteToShareStory`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const shareStory = (data: ShareStoryMeta, token: string): any => {
  return axios({
    method: 'post',
    url: `${STORY_URL}/share`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAssignedAdvocatesByStory = (
  data: GetAssignedAdvocatesByStoryMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/${data.storyId}/advocate`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateAdvocatesForStory = (
  data: UpdateAdvocatesForStoryMeta,
  token: string,
): any => {
  return axios({
    method: 'put',
    url: `${STORY_URL}/${data.storyId}/advocate`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const getLibraryStories = (
  data: GetLibraryStoriesMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/getDataLibraryStoryView`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getMoreQuestionCustome = (data: string, token: string): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/question/getMoreQuestionForCustom?${data}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getMoreQuestionCustomML = (data: string, token: string): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/question/generate-questions-custom?${data}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getStoryLinkRq = (data: GetStoryLinkMeta, token: string): any => {
  return axios({
    method: 'post',
    url: `${STORY_URL}/share-link`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      storyId: data.story_id,
    },
  });
};

export const getNanlMagicLink = (
  data: GetStoryLinkMeta,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${STORY_URL}/collection-link`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      storyId: data.story_id,
    },
  });
};

export const sentNanlMagicLinkViaEmail = (
  data: {
    storyId: string;
    emails: string[];
    template: string;
    subject: string;
  },
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${STORY_URL}/collection-link/email`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      storyId: data.storyId,
      emails: data.emails,
      template: data.template,
      subject: data.subject,
    },
  });
};

export const getSubtitles = (data: GetSubtitlesMeta, token: string): any => {
  return axios({
    method: 'get',
    url: `${STORY_URL}/get-subtitles-for-video?videoLink=${data.video_id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// generate content on NANL / no authentication
export const AIGenerateContent = (
  data: PayloadAIAssitant,
  token?: string,
): any => {
  return axios({
    method: 'post',
    url: `${STORY_URL}/augmented-writing/qna`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// generate content on Share Content
export const generateContentSocialCopy = (
  data: { text: string },
  token?: string,
): any => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/social-copy-template/generate-sc-paragraph`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
