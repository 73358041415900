import React from 'react';

import { SVGProps } from './SVG.props';

const ChatBubleMessage = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill, stroke } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_16562_100193)">
        <path
          d="M4.66406 5.99984C4.66406 5.63165 4.96254 5.33317 5.33073 5.33317H10.6641C11.0323 5.33317 11.3307 5.63165 11.3307 5.99984C11.3307 6.36803 11.0323 6.6665 10.6641 6.6665H5.33073C4.96254 6.6665 4.66406 6.36803 4.66406 5.99984Z"
          fill={fill || '#9CB4C9'}
        />
        <path
          d="M5.33073 7.99984C4.96254 7.99984 4.66406 8.29831 4.66406 8.6665C4.66406 9.03469 4.96254 9.33317 5.33073 9.33317H7.9974C8.36558 9.33317 8.66406 9.03469 8.66406 8.6665C8.66406 8.29831 8.36558 7.99984 7.9974 7.99984H5.33073Z"
          fill={fill || '#9CB4C9'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.91359 2.60109C3.01798 1.34609 4.92353 0.666504 7.9974 0.666504C11.0713 0.666504 12.9768 1.34609 14.0812 2.60109C15.1733 3.84205 15.3307 5.48508 15.3307 6.99984C15.3307 8.51805 15.1691 10.1037 14.3922 11.3175C13.6168 12.5292 12.3114 13.2536 10.3354 13.327L9.06406 15.0221C8.53073 15.7332 7.46406 15.7332 6.93073 15.0221L5.65943 13.327C3.68342 13.2536 2.37801 12.5292 1.60255 11.3175C0.825669 10.1037 0.664062 8.51805 0.664062 6.99984C0.664062 5.48508 0.82154 3.84205 1.91359 2.60109ZM2.91454 3.48192C2.17325 4.32429 1.9974 5.5146 1.9974 6.99984C1.9974 8.48162 2.16912 9.72934 2.72558 10.5988C3.24006 11.4027 4.16366 11.9998 5.9974 11.9998C6.20723 11.9998 6.40483 12.0986 6.53073 12.2665L7.9974 14.2221L9.46406 12.2665C9.58996 12.0986 9.78756 11.9998 9.9974 11.9998C11.8311 11.9998 12.7547 11.4027 13.2692 10.5988C13.8257 9.72934 13.9974 8.48162 13.9974 6.99984C13.9974 5.5146 13.8215 4.32429 13.0803 3.48192C12.3513 2.65358 10.9235 1.99984 7.9974 1.99984C5.07126 1.99984 3.64348 2.65358 2.91454 3.48192Z"
          fill={fill || '#9CB4C9'}
        />
      </g>
      <defs>
        <clipPath id="clip0_16562_100193">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChatBubleMessage;
