import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { feedbackOverlayActions } from 'store/actions';
import { Store } from 'types/store/Store';

import { FeedBackOverlayProps } from './FeedBackOverlay.props';
import { Container, Message } from './FeedBackOverlay.style';

const FeedBackOverlay = (props: FeedBackOverlayProps): React.JSX.Element => {
  const dispatch = useDispatch();

  const feedbackSettings = useSelector((state: Store) => state.feedbackOverlay);

  const fade = () => {
    dispatch(
      feedbackOverlayActions.update({
        message: '',
        display: false,
      }),
    );
  };

  useEffect(() => {
    if (feedbackSettings.display === true) {
      setTimeout(fade, 2000);
    }
  }, [feedbackSettings]);

  return (
    <Container shouldDisplay={feedbackSettings.display}>
      <Message color={'shade7'}>{feedbackSettings.message}</Message>
    </Container>
  );
};

export default React.memo(FeedBackOverlay);
