import React from 'react';

import { SVGProps } from './SVG.props';

const AddUnfilled = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill={fill || '#DED9DB'}
      {...props}
    >
      <path
        d="M10 0a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16zm4-9h-3V6a1 1 0 00-2 0v3H6a1 1 0 000 2h3v3a1 1 0 102 0v-3h3a1 1 0 000-2z"
        fill={fill || '#DED9DB'}
      />
    </svg>
  );
};

export default AddUnfilled;
