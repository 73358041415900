import { put, call, takeLatest, select } from 'redux-saga/effects';
import _get from 'lodash/get';

import { getStoryForEditor } from 'services/story-editor';
import { AsyncAction } from 'types/Action';
import {
  GetStoryForEditorMeta,
  GetStoryForEditorPayload,
} from 'types/store/GetStoryForEditor';
import { Store } from 'types/store/Store';
import { EMPLOYER_ROUTES } from 'consts/routes';
import { advocateRepsonseAction } from 'store/actions/storyHub.action';
import {
  addDraftContent,
  saveLanguageTransalate,
  saveVersionaStoryId,
  setContentLanguage,
  updateDraftAISk,
  updateRefine,
} from 'store/actions/storyDetailV2/storyDetailV2.action';
import { getBrandGuidelinesByCompanyId } from 'services/company';
import navigationService from 'utils/navigationService';

import {
  getBrandGuidelinesByCompanyIdActions,
  getStoryForEditorActions,
} from '../actions';

//clear value '' in list
const clearEmptyValue = (list: any) => {
  return list?.filter((x: any) => x);
};

//init prompAI history
const formatData = (data: any, toneOfVoice?: string) => {
  const value = {
    //Blog format
    blogFormat: 'QNA', //default value
    //Basic Information
    headline: data?.headline
      ? clearEmptyValue(data?.headline?.split(', '))
      : [],
    outline: data?.outline ? clearEmptyValue(data?.outline?.split(', ')) : [],
    tone_of_voice: toneOfVoice
      ? clearEmptyValue(toneOfVoice.split(','))
      : data?.tone_of_voice
      ? clearEmptyValue(data?.tone_of_voice?.split(', '))
      : [],
    language: data?.language || '',

    //Additional Information
    key_points: clearEmptyValue(data?.key_points) || [],
    target_audience: data?.target_audience
      ? clearEmptyValue(data?.target_audience?.split(', '))
      : [],
    intent: data?.intent ? clearEmptyValue(data?.intent?.split(', ')) : [],
    where_published: data?.where_to_publish
      ? clearEmptyValue(data?.where_to_publish?.split(', '))
      : [],
    cta: data?.call_to_action
      ? clearEmptyValue(data?.call_to_action?.split(', '))
      : [],
  };
  return value;
};

function* getStoryForEditorRequest(
  action: AsyncAction<GetStoryForEditorMeta, GetStoryForEditorPayload>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getStoryForEditor,
        action.meta,
        state.auth.token,
      );
      yield put(advocateRepsonseAction.request(action.meta));
      yield put(getStoryForEditorActions.success(data));

      const versionContent = _get(
        data,
        'data.story.story_blog_content_version.contents',
        {},
      );
      if (Object.keys(versionContent).length) {
        yield put(
          // REPLACE NEW ACTION HANDLE STORE CONTENT DATA TO STORE
          addDraftContent(
            data.data?.story?.story_blog_content_version?.contents,
          ),
          // updateDraftAISk(
          //   data.data?.story?.story_blog_content_version?.contents,
          // ),
        );
        yield put(
          // save current story id version
          saveVersionaStoryId(data.data?.story?.story_blog_content_version?.id),
        );
        yield put(
          // save translated lanaguage
          saveLanguageTransalate(
            data.data?.story?.story_blog_content_version?.languages_translate,
          ),
        );
        yield put(
          // save content lanaguage
          setContentLanguage(
            data.data?.story?.story_blog_content_version?.content_language,
          ),
        );
        yield put(
          updateRefine(
            formatData(data.data?.story?.story_blog_content_version?.payload),
          ),
        );
      } else {
        const { data: result } = yield call(
          getBrandGuidelinesByCompanyId,
          {},
          state.auth.token,
        );
        yield put(getBrandGuidelinesByCompanyIdActions.success(result));
        yield put(
          updateRefine(
            formatData(undefined, result?.data?.data?.tone_content_a || ''),
          ),
        );
      }
    } catch (e: any) {
      yield call(navigationService.goTo, EMPLOYER_ROUTES.DASHBOARD);
      yield put(getStoryForEditorActions.failed(e.message));
    }
  }
}

// function* getStoryForEditorSuccess(action: AsyncAction<GetStoryForEditorMeta, GetStoryForEditorPayload>): any {
//   yield put()
// }

function* getStoryForEditorWatcher(): any {
  yield takeLatest(getStoryForEditorActions.REQUEST, getStoryForEditorRequest);
  // yield takeLatest(getStoryForEditorActions.SUCCESS, getStoryForEditorSuccess);
}

export default getStoryForEditorWatcher;
