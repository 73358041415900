import { put, call, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { createLibrary, updateLibrary } from 'services/library';
import { AsyncAction } from 'types/Action';
import {
  CreateLibraryMeta,
  CreateLibraryPayload,
} from 'types/store/CreateLibraryState';
import { Store } from 'types/store/Store';

import { createLibraryActions } from '../actions';

function* createLibraryRequest(
  action: AsyncAction<CreateLibraryMeta, CreateLibraryPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const callback = action.meta.callbackAfterSuccess;
      delete action.meta.callbackAfterSuccess;

      let message = '';
      if (action.meta.library_id) {
        const { data } = yield call(
          updateLibrary,
          action.meta,
          state.auth.token,
        );
        yield put(createLibraryActions.success(data));
        message = data.message || 'Library updated successfully';
        callback?.();
      } else {
        const { data } = yield call(
          createLibrary,
          action.meta,
          state.auth.token,
        );
        yield put(createLibraryActions.success(data));
        message = data.message || 'Library successfully added';
        callback?.();
      }
      toast.success(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch (e: any) {
      yield put(createLibraryActions.failed(e.message));
      toast.error(
        e.response?.data?.message ||
          'Something went wrong, please try again later.',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        },
      );
    }
  }
}

function* createLibrarySuccess(
  action: AsyncAction<CreateLibraryMeta, CreateLibraryPayload>,
) {
  // yield put(createLibraryActions.clear());
}

function* createLibraryWatcher(): any {
  yield takeLatest(createLibraryActions.REQUEST, createLibraryRequest);
  yield takeLatest(createLibraryActions.SUCCESS, createLibrarySuccess);
}

export default createLibraryWatcher;
