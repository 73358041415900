import { put, call, takeLatest, select } from 'redux-saga/effects';

import { deleteShowcasePage } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import {
    DeleteShowcasePageMeta,
    DeleteShowcasePagePayload
} from 'types/store/DeleteShowcasePageState';
import { Store } from 'types/store/Store';

import { deleteShowcasePageActions, showcaseArticleActions, getShowcaseCompanyActions } from '../actions';

function* deleteShowcasePageRequest(
    action: AsyncAction<DeleteShowcasePageMeta, DeleteShowcasePagePayload>
) {
    const state: Store = yield select();
    if (state.auth.token) {
        try {
            const { data } = yield call(
                deleteShowcasePage,
                action.meta,
                state.auth.token
            );
            yield put(deleteShowcasePageActions.success(data));
        } catch (e: any) {
            yield put(deleteShowcasePageActions.failed(e.message));
        }
    }
}

function* deleteShowcasePageSuccess() {
    yield put(showcaseArticleActions.clear());
    // yield put(getShowcaseCompanyActions.request({}));
}

function* deleteShowcasePageWatcher(): any {
    yield takeLatest(
        deleteShowcasePageActions.REQUEST,
        deleteShowcasePageRequest
    );
    yield takeLatest(
        deleteShowcasePageActions.SUCCESS,
        deleteShowcasePageSuccess
    )
}

export default deleteShowcasePageWatcher;
