import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getStoriesAnalytics } from 'services/analytics';
import { AsyncAction } from 'types/Action';
import {
  GetStoriesAnalyticsMeta,
  GetStoriesAnalyticsPayload,
} from 'types/store/GetStoriesAnalytics';
import { Store } from 'types/store/Store';

import { getStoriesAnalyticsAction } from '../actions';

function* getStoriesAnalyticsRequest(
  action: AsyncAction<GetStoriesAnalyticsMeta, GetStoriesAnalyticsPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(
        getStoriesAnalytics,
        action.meta,
        state.auth.token
      );
      yield put(getStoriesAnalyticsAction.success(data));
    } catch (e: any) {
      yield put(getStoriesAnalyticsAction.failed(e.message));
    }
  } else {
    yield put(getStoriesAnalyticsAction.failed('Token not found'));
  }
}

function* getStoriesAnalyticsSuccess(
  action: AsyncAction<GetStoriesAnalyticsMeta, GetStoriesAnalyticsPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getCategoriesWatcher(): any {
  yield takeLatest(
    getStoriesAnalyticsAction.REQUEST,
    getStoriesAnalyticsRequest
  );
  yield takeLatest(
    getStoriesAnalyticsAction.SUCCESS,
    getStoriesAnalyticsSuccess
  );
}

export default getCategoriesWatcher;
