import FileSaver from 'file-saver';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import { updateStoryArticlesPosition } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateArticlePositionStoryPageMeta,
  UpdateArticlePositionStoryPagePayload
} from 'types/store/UpdateArticlePositionStoryPage';

import { updateArticlePositionStoryPageActions } from '../actions';
function* updateStoryArticlePositionRequest(
  action: AsyncAction<UpdateArticlePositionStoryPageMeta, UpdateArticlePositionStoryPagePayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(updateStoryArticlesPosition, action.meta, state.auth.token);
      yield put(updateArticlePositionStoryPageActions.success(data));
    } catch (e: any) {
      yield put(updateArticlePositionStoryPageActions.failed(e.message));
    }
  }
}

function* updateStoryArticlePositionSuccess(
  action: AsyncAction<UpdateArticlePositionStoryPageMeta, UpdateArticlePositionStoryPagePayload>
) {
  //
}

function* updateStoryArticlePositionWatcher(): any {
  yield takeLatest(updateArticlePositionStoryPageActions.REQUEST, updateStoryArticlePositionRequest);
  yield takeLatest(updateArticlePositionStoryPageActions.SUCCESS, updateStoryArticlePositionSuccess);
}

export default updateStoryArticlePositionWatcher;
