import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getSaveTemplatesByCompanyId } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  GetSaveTemplatesCompanyIdMeta,
  GetSaveTemplatesCompanyIdPayload,
} from 'types/store/GetSaveTemplatesByCompanyId';
import { Store } from 'types/store/Store';

import { getSaveTemplatesByCompanyIdActions } from '../actions';

function* getSaveTemplatesByCompanyIdRequest(
  action: AsyncAction<
    GetSaveTemplatesCompanyIdMeta,
    GetSaveTemplatesCompanyIdPayload
  >
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(
        getSaveTemplatesByCompanyId,
        action.meta,
        state.auth.token
      );
      yield put(getSaveTemplatesByCompanyIdActions.success(data));
    } catch (e: any) {
      yield put(getSaveTemplatesByCompanyIdActions.failed(e.message));
    }
  } else {
    yield put(getSaveTemplatesByCompanyIdActions.failed('Token not found'));
  }
}

function* getSaveTemplatesByCompanyIdSuccess(
  action: AsyncAction<
    GetSaveTemplatesCompanyIdMeta,
    GetSaveTemplatesCompanyIdPayload
  >
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getSaveTemplatesByCompanyIdWatcher(): any {
  yield takeLatest(
    getSaveTemplatesByCompanyIdActions.REQUEST,
    getSaveTemplatesByCompanyIdRequest
  );
  yield takeLatest(
    getSaveTemplatesByCompanyIdActions.SUCCESS,
    getSaveTemplatesByCompanyIdSuccess
  );
}

export default getSaveTemplatesByCompanyIdWatcher;
