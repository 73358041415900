import React from 'react';

import { SVGProps } from './SVG.props';

const China = (props: SVGProps): React.JSX.Element => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40 30.4444C40 32.8989 38.01 34.8889 35.5556 34.8889H4.44444C1.99 34.8889 0 32.8989 0 30.4444V10.4444C0 7.99 1.99 6 4.44444 6H35.5556C38.01 6 40 7.99 40 10.4444V30.4444Z"
        fill="#DE2910"
      />
      <path
        d="M12.3732 10.4189L13.191 10.8144L13.8455 10.1855L13.7221 11.0855L14.5221 11.5144L13.6288 11.6744L13.4688 12.5678L13.0399 11.7678L12.1399 11.8911L12.7688 11.2367L12.3732 10.4189ZM17.5566 13.6867L17.161 14.5033L17.7899 15.1589L16.891 15.0344L16.4621 15.8355L16.3021 14.9411L15.4077 14.7811L16.2088 14.3522L16.0844 13.4533L16.7399 14.0822L17.5566 13.6867ZM16.4932 17.8855L16.791 18.7433L17.6988 18.7622L16.9755 19.31L17.2388 20.18L16.4932 19.6611L15.7477 20.18L16.0099 19.31L15.2866 18.7622L16.1944 18.7433L16.4932 17.8855ZM12.3732 21.53L13.191 21.9255L13.8455 21.2967L13.7221 22.1967L14.5221 22.6255L13.6288 22.7855L13.4688 23.6789L13.0399 22.8789L12.1399 23.0022L12.7688 22.3478L12.3732 21.53ZM7.77769 12.6122L8.80991 15.58L11.9499 15.6444L9.44658 17.5422L10.3566 20.5489L7.77769 18.7544L5.1988 20.5489L6.1088 17.5422L3.60547 15.6444L6.74547 15.58L7.77769 12.6122Z"
        fill="#FFDE02"
      />
    </svg>
  );
};

export default China;
