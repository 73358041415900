export enum CONTENT_REPURPOSING_ACTION {
  GET_LIST_CONTENT = '@_@_GET_LIST_CONTENT',
  GET_LIST_CONTENT_SUCCESS = '@_@_GET_LIST_CONTENT_SUCCESS',
  GET_LIST_CONTENT_FAILURE = '@_@_GET_LIST_CONTENT_FAILURE',
  GET_LIST_CONTENT_CLEAR = '@_@_GET_LIST_CONTENT_CLEAR',
}

export enum GET_FILTER_ACTION {
  GET_FILTER = '@_@_GET_FILTER',
  GET_FILTER_SUCCESS = '@_@_GET_FILTER_SUCCESS',
  GET_FILTER_FAILURE = '@_@_GET_FILTER_FAILURE',
}

export enum CREATE_REPURPOSE_STORY {
  CREATE_REPURPOSE_STORY = '@_@_CREATE_REPURPOSE_STORY',
  CREATE_REPURPOSE_STORY_SUCCESS = '@_@_CREATE_REPURPOSE_STORY_SUCCESS',
  CREATE_REPURPOSE_STORY_FAILURE = '@_@_CREATE_REPURPOSE_STORY_FAILURE',
  CREATE_REPURPOSE_STORY_CLEAR = '@_@_CREATE_REPURPOSE_STORY_CLEAR',
}

export enum UPDATE_REPURPOSE_STORY {
  UPDATE_REPURPOSE_STORY = '@_@_UPDATE_REPURPOSE_STORY',
  UPDATE_REPURPOSE_STORY_SUCCESS = '@_@_UPDATE_REPURPOSE_STORY_SUCCESS',
  UPDATE_REPURPOSE_STORY_FAILURE = '@_@_UPDATE_REPURPOSE_STORY_FAILURE',
  UPDATE_REPURPOSE_STORY_CLEAR = '@_@_UPDATE_REPURPOSE_STORY_CLEAR',
}
