import React from 'react';

import { SVGProps } from './SVG.props';

const Star = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 11}
      height={height || 10}
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.834 3.605a.5.5 0 01.166.23.5.5 0 01-.16.51l-2.065 2 .5 2.84a.5.5 0 01-.725.536L5 8.38 2.44 9.716a.5.5 0 01-.725-.535l.5-2.84-2.065-2a.5.5 0 01-.125-.5.5.5 0 01.405-.34l2.845-.42L4.55.5a.5.5 0 01.9 0l1.275 2.585L9.57 3.5a.5.5 0 01.264.104z"
        fill={fill || '#FFC937'}
      />
    </svg>
  );
};

export default Star;
