import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getStoryActivityLogs } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  GetStoryActivityLogsMeta,
  GetStoryActivityLogsPayload,
} from 'types/store/GetStoryActivityLogs';
import { Store } from 'types/store/Store';

import { getStoryActivityLogsActions } from '../actions';

function* getStoryActivityLogsRequest(
  action: AsyncAction<GetStoryActivityLogsMeta, GetStoryActivityLogsPayload>
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getStoryActivityLogs,
        action.meta,
        state.auth.token
      );
      yield put(getStoryActivityLogsActions.success(data));
    } catch (e: any) {
      yield put(getStoryActivityLogsActions.failed(e.message));
    }
  }
}

// function* getStoryActivityLogsSuccess(action: AsyncAction<GetStoryActivityLogsMeta, GetStoryActivityLogsPayload>): any {
//   yield put()
// }

function* getStoryActivityLogsWatcher(): any {
  yield takeLatest(
    getStoryActivityLogsActions.REQUEST,
    getStoryActivityLogsRequest
  );
  // yield takeLatest(getStoryActivityLogsActions.SUCCESS, getStoryActivityLogsSuccess);
}

export default getStoryActivityLogsWatcher;
