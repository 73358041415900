import { put, call, takeLatest, select } from 'redux-saga/effects';

import { MAIN_ROUTES } from 'consts';
import { updateSfpStatus } from 'services/story';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateSfpStatusMeta,
  UpdateSfpStatusPayload,
} from 'types/store/UpdateSfpStatusState';
import navigationService from 'utils/navigationService';

import { updateSfpStatusActions, getFinalStoryPiecesActions } from '../actions';

function* updateSfpStatusRequest(
  action: AsyncAction<UpdateSfpStatusMeta, UpdateSfpStatusPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateSfpStatus,
        action.meta,
        state.auth.token,
      );
      yield put(updateSfpStatusActions.success(data));
    } catch (e: any) {
      if (e.response.status === 404) {
        yield call(navigationService.goTo, MAIN_ROUTES.NOT_FOUND);
      } else {
        yield put(updateSfpStatusActions.failed(e.message));
      }
    }
  }
}

// function* updateSfpStatusSuccess(
//   action: AsyncAction<UpdateSfpStatusMeta, UpdateSfpStatusPayload>
// // eslint-disable-next-line @typescript-eslint/no-empty-function
// ) {
// }

function* updateSfpStatusWatcher(): any {
  yield takeLatest(updateSfpStatusActions.REQUEST, updateSfpStatusRequest);
  // yield takeLatest(updateSfpStatusActions.SUCCESS, updateSfpStatusSuccess);
}

export default updateSfpStatusWatcher;
