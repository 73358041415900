import axios from 'axios';

import { API } from 'consts';
import { GetCommunitiesMeta } from 'types/store/GetCommunitiesState';
import { GetInterestByCommunityMeta } from 'types/store/GetInterestByCommunityState';
import { GetListeningPathsByCommunityMeta } from 'types/store/GetListeningPathsByCommunityState';
import { GetTrendingByCommunityMeta } from 'types/store/GetTrendingByCommunityState';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const AUDIENCE_RESEARCH_URL = `${BASE_URL}/audience-research`;

export const getCommunities = (
    data: GetCommunitiesMeta, token: string
): any => {
  return axios({
    method: 'get',
    url: `${AUDIENCE_RESEARCH_URL}/community`,
    params: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getInterestByCommunity = (
  data: GetInterestByCommunityMeta,
  token: string
): any => {
  return axios({
    method: 'get',
    url: `${AUDIENCE_RESEARCH_URL}/getInterestByCommunity/${data.community_id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getListeningPathsByCommunity = (
  data: GetListeningPathsByCommunityMeta,
  token: string
): any => {
  return axios({
    method: 'get',
    url: `${AUDIENCE_RESEARCH_URL}/getListeningPathsByCommunity/${data.community_id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getTrendingByCommunity = (
  data: GetTrendingByCommunityMeta,
  token: string
): any => {
  return axios({
    method: 'get',
    url: `${AUDIENCE_RESEARCH_URL}/getTrendingByCommunity/${data.community_id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};
