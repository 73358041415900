import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getShareSocialStory } from 'services/story';
import { FEEDBACK_CHECKING } from 'store/actions/rating.action';
import { AsyncAction } from 'types/Action';
import {
    GetShareSocialStoryStateMeta,
    GetShareSocialStoryStatePayload,
} from 'types/store/getShareSocialStoryState';
import { Store } from 'types/store/Store';

import { getShareSocialStoryActions } from '../actions';

function* getShareSocialStoryRequest(
    action: AsyncAction<
        GetShareSocialStoryStateMeta,
        GetShareSocialStoryStatePayload
    >
) {
    const state: Store = yield select();
    if (state.auth.token) {
        try {
            // company id already embeded in the token
            const { data } = yield call(
                getShareSocialStory,
                action.meta,
                state.auth.token
            );
            yield put(getShareSocialStoryActions.success(data));
        } catch (e: any) {
            yield put(getShareSocialStoryActions.failed(e.message));
        }
    } else {
        yield put(getShareSocialStoryActions.failed('Token not found'));
    }
}

function* getShareSocialStorySuccess(
    action: AsyncAction<
        GetShareSocialStoryStateMeta,
        GetShareSocialStoryStatePayload
    >
) {
    const isShownFeedBack: boolean = yield select((_: Store) => _.ratingFeedBack.isShownFeedBack)
    if (!isShownFeedBack) {
        yield put({
            type: FEEDBACK_CHECKING.SUCCESS,
            payload: Boolean(action.payload.data.invited_sharing_story.length || action.payload.data.sharing_stories_length)
        })
    }
    // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getShareSocialStoryWatcher(): any {
    yield takeLatest(
        getShareSocialStoryActions.REQUEST,
        getShareSocialStoryRequest
    );
    yield takeLatest(
        getShareSocialStoryActions.SUCCESS,
        getShareSocialStorySuccess
    );
}

export default getShareSocialStoryWatcher;
