import { put, call, takeLatest, select } from 'redux-saga/effects';

import { upsertShowcaseArticle, uploadCompanyLogo } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpsertShowcaseArticleMeta,
  UpsertShowcaseArticlePayload,
} from 'types/store/UpsertShowcaseArticleState';

import {
  upsertShowcaseArticleActions,
  showcaseArticleActions,
  getShowcaseArticlesActions,
  getShowcasePageDetailsActions
} from '../actions';

function* upsertShowcaseArticleRequest(
  action: AsyncAction<UpsertShowcaseArticleMeta, UpsertShowcaseArticlePayload>
) {
  const state: Store = yield select();
  const image: any = action.meta?.image;
  if (state.auth.token) {
    try {
      if (image instanceof File) {
        const { data } = yield call(
          uploadCompanyLogo,
          image,
          state.auth.token
        );
        action.meta.image = data.status === 200 ? data.data?.image?.Location : '';
      }
      const { data } = yield call(
        upsertShowcaseArticle,
        action.meta,
        state.auth.token
      );
      yield put(upsertShowcaseArticleActions.success(data));
    } catch (e: any) {
      yield put(upsertShowcaseArticleActions.failed(e.message));
    }
  }
}

function* upsertShowcaseArticleSuccess(
  action: AsyncAction<UpsertShowcaseArticleMeta, UpsertShowcaseArticlePayload>
) {
  // yield put(upsertShowcaseArticleActions.clear());
  // yield put(getShowcasePageDetailsActions.request({
  //   page_id: action.meta.id
  // }));
}

function* upsertShowcaseArticleWatcher(): any {
  yield takeLatest(
    upsertShowcaseArticleActions.REQUEST,
    upsertShowcaseArticleRequest
  );
  yield takeLatest(
    upsertShowcaseArticleActions.SUCCESS,
    upsertShowcaseArticleSuccess
  );
}

export default upsertShowcaseArticleWatcher;
