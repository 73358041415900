import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getShowcaseArticles } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import {
  GetShowcaseArticlesMeta,
  GetShowcaseArticlesPayload,
} from 'types/store/GetShowcaseArticlesState';
import { Store } from 'types/store/Store';

import { getShowcaseArticlesActions } from '../actions';

function* getShowcaseArticlesRequest(
  action: AsyncAction<GetShowcaseArticlesMeta, GetShowcaseArticlesPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(
        getShowcaseArticles,
        action.meta,
        state.auth.token
      );
      yield put(getShowcaseArticlesActions.success(data));
    } catch (e: any) {
      yield put(getShowcaseArticlesActions.failed(e.message));
    }
  } else {
    yield put(getShowcaseArticlesActions.failed('Token not found'));
  }
}

function* getShowcaseArticlesSuccess(
  action: AsyncAction<GetShowcaseArticlesMeta, GetShowcaseArticlesPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getShowcaseArticlesWatcher(): any {
  yield takeLatest(
    getShowcaseArticlesActions.REQUEST,
    getShowcaseArticlesRequest
  );
  yield takeLatest(
    getShowcaseArticlesActions.SUCCESS,
    getShowcaseArticlesSuccess
  );
}

export default getShowcaseArticlesWatcher;
