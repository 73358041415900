import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getDiscussionStatus } from 'services/content-package';
import { AsyncAction } from 'types/Action';
import { GetContentPackageDiscussionStatusMeta, GetContentPackageDiscussionStatusPayload } from 'types/store/GetContentPackageDiscussionStatus';
import { Store } from 'types/store/Store';

import { getContentPackageDiscussionStatusActions } from '../actions';

function* getContentPackageDiscussionStatusRequest(action: AsyncAction<GetContentPackageDiscussionStatusMeta, GetContentPackageDiscussionStatusPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getDiscussionStatus, state.auth.token);
      yield put(getContentPackageDiscussionStatusActions.success(data));
    } catch (e: any) {
      yield put(getContentPackageDiscussionStatusActions.failed(e.message));
    }
  }
}

// function* getContentPackageDiscussionStatusSuccess(action: AsyncAction<GetContentPackageDiscussionStatusMeta, GetContentPackageDiscussionStatusPayload>): any {
//   yield put()
// }

function* getContentPackageDiscussionStatusWatcher(): any {
  yield takeLatest(getContentPackageDiscussionStatusActions.REQUEST, getContentPackageDiscussionStatusRequest);
  // yield takeLatest(getContentPackageDiscussionStatusActions.SUCCESS, getContentPackageDiscussionStatusSuccess);
}

export default getContentPackageDiscussionStatusWatcher;
