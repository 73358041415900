import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getListOptions } from 'services/advocate';
import { AsyncAction } from 'types/Action';
import {
  GetListOptionAdvocateMeta,
  GetListOptionAdvocatePayload,
} from 'types/store/GetListOptionAdvocateState';
import { Store } from 'types/store/Store';

import { getListOptionAdvocateActions } from '../actions';

function* getListOptionAdvocateRequest(
  action: AsyncAction<
    GetListOptionAdvocateMeta,
    GetListOptionAdvocatePayload
  >
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getListOptions,
        state.auth.token
      );
      yield put(getListOptionAdvocateActions.success(data));
    } catch (e: any) {
      yield put(getListOptionAdvocateActions.failed(e.message));
    }
  } else {
    yield put(getListOptionAdvocateActions.failed('Token not found'));
  }
}

//   function* forgotPasswordSuccess(action: AsyncAction<ForgotPasswordMeta, ForgotPasswordPayload>) {
//     yield put(push(EMPLOYER_ROUTES.LOGIN));
//   }

function* getListOptionAdvocateWatcher(): any {
  yield takeLatest(
    getListOptionAdvocateActions.REQUEST,
    getListOptionAdvocateRequest
  );
  // yield takeLatest(forgotPasswordActions.SUCCESS, forgotPasswordSuccess);
}

export default getListOptionAdvocateWatcher;
