import { put, call, takeLatest, select } from 'redux-saga/effects';

import { upsertApprovers } from 'services/approvers';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpsertApproversStateMeta,
  UpsertApproversStatePayload,
} from 'types/store/UpsertApproversState';

import {
  upsertApproversActions,
} from '../actions';

function* upsertApproversRequest(
  action: AsyncAction<
    UpsertApproversStateMeta,
    UpsertApproversStatePayload
  >
) {
  const state: Store = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        upsertApprovers,
        action.meta,
        state.auth.token
      );
      yield put(upsertApproversActions.success(data));
    } catch (e: any) {
      yield put(upsertApproversActions.failed(e.message));
    }
  } else {
    yield put(upsertApproversActions.failed('Token not found'));
  }
}

function* upsertApproversWatcher(): any {
  yield takeLatest(
    upsertApproversActions.REQUEST,
    upsertApproversRequest
  );
}

export default upsertApproversWatcher;
