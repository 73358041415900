import React from 'react';

import { SVGProps } from './SVG.props';

const PenFilled = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.667 4.827a.668.668 0 00-.194-.473l-2.826-2.827a.667.667 0 00-.474-.193.666.666 0 00-.473.193L8.813 3.414l-7.286 7.287a.666.666 0 00-.194.473v2.827a.667.667 0 00.667.666h2.827a.666.666 0 00.506-.193l7.247-7.287 1.893-1.853a.791.791 0 00.147-.22.67.67 0 000-.16.47.47 0 000-.093l.047-.034zm-2.554.947l-1.886-1.887.946-.94 1.88 1.88-.94.947z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default PenFilled;
