import * as React from 'react';

import { useTheme } from 'utils/Theme';

function SvgComponent(props: React.SVGProps<SVGSVGElement>): React.JSX.Element {
  const theme = useTheme();
  const { width, height, fill } = props;
  return (
    <svg
      width={width || 16}
      height={height || 22}
      viewBox="0 0 17 22"
      fill="none"
      {...props}
    >
      <path
        d="M17.334 5.033a.833.833 0 00-.242-.591L13.559.908a.833.833 0 00-.592-.241.833.833 0 00-.592.241l-2.358 2.359-9.108 9.108a.833.833 0 00-.242.591V16.5a.833.833 0 00.833.833h3.534a.835.835 0 00.633-.241l9.058-9.109 2.367-2.316a.991.991 0 00.183-.275.826.826 0 000-.2.583.583 0 000-.117l.059-.042zm-3.192 1.184l-2.358-2.359 1.183-1.175 2.35 2.35-1.175 1.184z"
        fill={fill || theme.grey.shade5}
      />
    </svg>
  );
}

export default SvgComponent;
