import React from 'react';

import { SVGProps } from './SVG.props';

const AlignRight = (props: SVGProps): React.JSX.Element => {
  const { width, height } = props;

  return (
    <svg
      width={width || '18'}
      height={height || '18'}
      viewBox="0 0 18 18"
      fill="#070550"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4 6.32143H3.6C3.44087 6.32143 3.28826 6.26499 3.17574 6.16452C3.06321 6.06406 3 5.92779 3 5.78571C3 5.64363 3.06321 5.50737 3.17574 5.40691C3.28826 5.30644 3.44087 5.25 3.6 5.25H14.4C14.5591 5.25 14.7117 5.30644 14.8243 5.40691C14.9368 5.50737 15 5.64363 15 5.78571C15 5.92779 14.9368 6.06406 14.8243 6.16452C14.7117 6.26499 14.5591 6.32143 14.4 6.32143V6.32143ZM3.6 11.6786H12C12.1591 11.6786 12.3117 11.735 12.4243 11.8355C12.5368 11.9359 12.6 12.0722 12.6 12.2143C12.6 12.3564 12.5368 12.4926 12.4243 12.5931C12.3117 12.6936 12.1591 12.75 12 12.75H3.6C3.44087 12.75 3.28826 12.6936 3.17574 12.5931C3.06321 12.4926 3 12.3564 3 12.2143C3 12.0722 3.06321 11.9359 3.17574 11.8355C3.28826 11.735 3.44087 11.6786 3.6 11.6786ZM3.6 7.39286H12C12.1591 7.39286 12.3117 7.4493 12.4243 7.54976C12.5368 7.65023 12.6 7.78649 12.6 7.92857C12.6 8.07065 12.5368 8.20691 12.4243 8.30738C12.3117 8.40784 12.1591 8.46429 12 8.46429H3.6C3.44087 8.46429 3.28826 8.40784 3.17574 8.30738C3.06321 8.20691 3 8.07065 3 7.92857C3 7.78649 3.06321 7.65023 3.17574 7.54976C3.28826 7.4493 3.44087 7.39286 3.6 7.39286ZM3.6 9.53571H14.4C14.5591 9.53571 14.7117 9.59216 14.8243 9.69262C14.9368 9.79309 15 9.92935 15 10.0714C15 10.2135 14.9368 10.3498 14.8243 10.4502C14.7117 10.5507 14.5591 10.6071 14.4 10.6071H3.6C3.44087 10.6071 3.28826 10.5507 3.17574 10.4502C3.06321 10.3498 3 10.2135 3 10.0714C3 9.92935 3.06321 9.79309 3.17574 9.69262C3.28826 9.59216 3.44087 9.53571 3.6 9.53571Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AlignRight;
