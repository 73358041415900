import React from 'react';

import { useLocation } from 'react-router-dom';

import { useCanvaAuthorize } from 'hooks/canvaAuth/useCanvaAuthorize';
import styled from 'utils/styled';

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  height: 90dvh;
  width: 100dvw;
  padding-top: 15dvh;
`;

export const CanvaAuthorizePage = () => {
  const { data, isLoading, error } = useCanvaAuthorize();

  if (isLoading)
    return <CenteredContainer>Authenticating...</CenteredContainer>;
  if (error)
    return (
      <CenteredContainer style={{ color: 'red' }}>
        Error {JSON.stringify(error)}
      </CenteredContainer>
    );

  return <CenteredContainer>Authenticated!</CenteredContainer>;
};
