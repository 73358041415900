import { put, call, takeLatest, select } from 'redux-saga/effects';

import { updateUserById } from 'services/advocate';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateUserByIdMeta,
  UpdateUserByIdPayload,
} from 'types/store/UpdateUserById';

import { openToast, updateUserByIdActions } from '../actions';

function* updateUserByIdRequest(
  action: AsyncAction<UpdateUserByIdMeta, UpdateUserByIdPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateUserById,
        action.meta,
        state.auth.token,
      );
      yield put(updateUserByIdActions.success(data));
    } catch (e: any) {
      yield put(updateUserByIdActions.failed(e.message));
    }
  }
}

function* updateUserByIdSuccess(
  action: AsyncAction<UpdateUserByIdMeta, UpdateUserByIdPayload>,
) {
  yield put(openToast(action.payload.message, 'success'));
}

function* updateUserByIdWatcher(): any {
  yield takeLatest(updateUserByIdActions.REQUEST, updateUserByIdRequest);
  yield takeLatest(updateUserByIdActions.SUCCESS, updateUserByIdSuccess);
}

export default updateUserByIdWatcher;
