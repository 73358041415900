import React from 'react';

import { SVGProps } from './SVG.props';

const Warning = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill={fill || '#9D0D11'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_3114)">
        <path
          d="M13.5 16.5C13.5 17.3284 12.8284 18 12 18C11.1716 18 10.5 17.3284 10.5 16.5C10.5 15.6716 11.1716 15 12 15C12.8284 15 13.5 15.6716 13.5 16.5Z"
          fill={fill || '#9D0D11'}
        />
        <path
          d="M11.5644 6C10.9876 6 10.5304 6.48668 10.5664 7.06238L10.9707 13.5312C10.9872 13.7947 11.2057 14 11.4697 14H12.5303C12.7943 14 13.0128 13.7947 13.0293 13.5312L13.4336 7.06238C13.4696 6.48668 13.0124 6 12.4355 6H11.5644Z"
          fill={fill || '#9D0D11'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z"
          fill={fill || '#9D0D11'}
        />
      </g>
      <defs>
        <clipPath id="clip0_2_3114">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Warning;
