import React, { Component, ErrorInfo, ReactNode } from 'react';

import { useLocation, Location } from 'react-router-dom';

import NotFound from 'routes/NotFound';

interface Props {
  children: ReactNode;
  location: Location;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  // Listen for changes to the location prop. When the route changes, reset the error state.
  componentDidUpdate(prevProps: Props) {
    if (prevProps.location !== this.props.location && this.state.hasError) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      // Render your NotFound fallback UI when an error is caught.
      return <NotFound />;
    }
    return this.props.children;
  }
}

// Create a wrapper that injects the current location into your ErrorBoundary.
const ErrorBoundaryWithLocation = (props: { children: ReactNode }) => {
  const location = useLocation();
  return <ErrorBoundary location={location}>{props.children}</ErrorBoundary>;
};

export default ErrorBoundaryWithLocation;
