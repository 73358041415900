import { put, call, takeLatest, select } from 'redux-saga/effects';

import { createAppointmentByAdvocate } from 'services/appointment';
import { AsyncAction } from 'types/Action';
import { CreateAppointmentByAdvocateMeta, CreateAppointmentByAdvocatePayload } from 'types/store/CreateAppointmentByAdvocate';
import { Store } from 'types/store/Store';

import { createAppointmentByAdvocateActions } from '../actions';

function* createAppointmentByAdvocateRequest(action: AsyncAction<CreateAppointmentByAdvocateMeta, CreateAppointmentByAdvocatePayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(createAppointmentByAdvocate, action.meta, state.auth.token);
      yield put(createAppointmentByAdvocateActions.success(data));
    } catch (e: any) {
      yield put(createAppointmentByAdvocateActions.failed(e.message));
    }
  }
}

// function* createAppointmentByAdvocateSuccess(action: AsyncAction<CreateAppointmentByAdvocateMeta, CreateAppointmentByAdvocatePayload>): any {
//   yield put()
// }

function* createAppointmentByAdvocateWatcher(): any {
  yield takeLatest(createAppointmentByAdvocateActions.REQUEST, createAppointmentByAdvocateRequest);
  // yield takeLatest(createAppointmentByAdvocateActions.SUCCESS, createAppointmentByAdvocateSuccess);
}

export default createAppointmentByAdvocateWatcher;
