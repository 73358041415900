import axios from 'axios';

import { API } from 'consts';
import { GetHeadlineAIMeta } from 'types/store/GetAdvocateInterest';
import { GetTopicsMeta } from 'types/store/GetTopics';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const TOPIC_URL = `${BASE_URL}/topic`;

//company_id is already embeded in the token
export const getTopics = (data: GetTopicsMeta, token: string): any => {
  return axios({
    method: 'get',
    url: `${TOPIC_URL}/getTopics`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getHeadlineAI = (data: GetHeadlineAIMeta, token: string): any => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/story-editor/generate-headline`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};
