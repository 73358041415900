import React, { useEffect, useState } from 'react';

import {
  ProcessingDownload,
  ProgressBar,
} from 'components/module/LibraryCardMulti/LibraryCardBodyFinal/PreviewImageWriten/PreviewImageWriten.style';
import { Percent } from 'routes/Employer/StoryEditor/StoryEditorDetail/StoryEditorDetailV2/Review/ReviewContent.style';
import Typography from 'components/base/Typography';
import { Refresh } from 'components/base/SVG';
import theme from 'utils/Theme';

import { BlurDownloadProps } from './BlurDownload.props';

const BlurDownload = ({
  process,
  processBar,
}: BlurDownloadProps): React.JSX.Element => {
  return (
    <>
      {(process || processBar) && (
        <React.Fragment>
          <ProcessingDownload className="background-blur"></ProcessingDownload>
          <ProcessingDownload className="zIndex-2">
            {processBar ? (
              <>
                <ProgressBar>
                  <Percent />
                </ProgressBar>

                <Typography variant="small" color="noshade">
                  Processing...
                </Typography>
              </>
            ) : (
              <>
                <Refresh width={16} height={16} fill={theme.altGrey.noshade} />
                <Typography variant="small" color="noshade">
                  Processing...
                </Typography>
              </>
            )}
          </ProcessingDownload>
        </React.Fragment>
      )}
    </>
  );
};

export default React.memo(BlurDownload);
