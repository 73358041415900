import { put, call, takeLatest, select } from 'redux-saga/effects';

import { uploadShowcaseFeedbackPhoto } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpsertShowcaseFeedbackImageMeta,
  UpsertShowcaseFeedbackImagePayload,
} from 'types/store/UpsertShowcaseFeedbackImageState';

import {
  upsertShowcaseFeedbackImageActions,
  showcaseArticleActions,
  getShowcaseArticlesActions,
} from '../actions';

function* upsertShowcaseFeedbackImageRequest(
  action: AsyncAction<
    UpsertShowcaseFeedbackImageMeta,
    UpsertShowcaseFeedbackImagePayload
  >
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        uploadShowcaseFeedbackPhoto,
        action.meta,
        state.auth.token
      );
      yield put(upsertShowcaseFeedbackImageActions.success(data));
    } catch (e: any) {
      yield put(upsertShowcaseFeedbackImageActions.failed(e.message));
    }
  }
}

function* upsertShowcaseFeedbackImageSuccess(
  action: AsyncAction<
    UpsertShowcaseFeedbackImageMeta,
    UpsertShowcaseFeedbackImagePayload
  >
) {
  //
}

function* upsertShowcaseFeedbackImageWatcher(): any {
  yield takeLatest(
    upsertShowcaseFeedbackImageActions.REQUEST,
    upsertShowcaseFeedbackImageRequest
  );
  yield takeLatest(
    upsertShowcaseFeedbackImageActions.SUCCESS,
    upsertShowcaseFeedbackImageSuccess
  );
}

export default upsertShowcaseFeedbackImageWatcher;
