import { Reducer } from 'redux';

import {
  CHOOSE_EXAMPLE_TEMPLATE,
  CREATE_INVITE_TEMPLATE,
  GET_TEMPLATE_INVITE,
  QR_CODE,
  REPLACE_PLACHOLDER_ZOOM_IN,
  SENT_INVITE,
} from 'store/actions/employeeHub.action';
import { Action } from 'types/Action';
import { EmployeeHubStore } from 'types/store/EmployeeHub';

const initState: EmployeeHubStore = {
  isLoading: false,
  qrCode: {},
  inviteTemplate: [],
  templateExample: {},
  chooseTemplate: {},
  smsMessage: '',
  createError: false,
  isSendInvite: false,
  messageReplace: '',
};

const EmployeeHubReducer: Reducer<EmployeeHubStore, Action> = (
  state = initState,
  { type, payload },
) => {
  switch (type) {
    case QR_CODE.QR_CODE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case QR_CODE.QR_CODE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        qrCode: payload,
      };
    }
    case GET_TEMPLATE_INVITE.GET_TEMPLATE_INVITE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        inviteTemplate: [],
      };
    }
    case GET_TEMPLATE_INVITE.GET_TEMPLATE_INVITE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        inviteTemplate: payload,
      };
    }
    case CREATE_INVITE_TEMPLATE.CREATE_INVITE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        templateExample: payload,
        isLoading: false,
      };
    }
    case CHOOSE_EXAMPLE_TEMPLATE.CHOOSE_EXAMPLE_TEMPLATE_REQUEST: {
      return {
        ...state,
        chooseTemplate: payload,
      };
    }
    case CREATE_INVITE_TEMPLATE.CREATE_INVITE_TEMPLATE_SMS_SUCCESS: {
      return {
        ...state,
        smsMessage: payload,
        isLoading: false,
      };
    }
    case CREATE_INVITE_TEMPLATE.CREATE_INVITE_TEMPLATE_SMS_CLEAR: {
      return {
        ...state,
        smsMessage: '',
      };
    }
    case CREATE_INVITE_TEMPLATE.CREATE_INVITE_TEMPLATE_ERROR: {
      return {
        ...state,
        smsMessage: payload,
        createError: true,
        isLoading: false,
      };
    }
    case CREATE_INVITE_TEMPLATE.CREATE_INVITE_TEMPLATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        createError: false,
        smsMessage: '',
        templateExample: {},
      };
    }
    case CREATE_INVITE_TEMPLATE.CREATE_INVITE_TEMPLATE_CLEAR_ID: {
      return {
        ...state,
        isLoading: false,
        qrCode: {},
        inviteTemplate: [],
        templateExample: {},
        chooseTemplate: {},
        smsMessage: '',
        createError: false,
      };
    }
    case SENT_INVITE.SENT_INVITE_SUCCESS: {
      return {
        ...state,
        isSendInvite: true,
      };
    }
    case SENT_INVITE.SENT_INVITE_CLEAR: {
      return {
        ...state,
        isSendInvite: false,
      };
    }
    case CREATE_INVITE_TEMPLATE.CREATE_NEW_INVITE: {
      return {
        ...state,
        chooseTemplate: {},
      };
    }
    case CREATE_INVITE_TEMPLATE.CLEAR_MESSAGE_CLEAR: {
      return {
        ...state,
        templateExample: {},
      };
    }
    case CREATE_INVITE_TEMPLATE.CREATE_INVITE_TEMPLATE_SAVE: {
      return {
        ...state,
        inviteTemplate: [...state.inviteTemplate, payload],
      };
    }
    case REPLACE_PLACHOLDER_ZOOM_IN.REPLACE_PLACHOLDER_ZOOM_IN_REQUEST: {
      return {
        ...state,
        isLoading: true,
        messageReplace: '',
      };
    }
    case REPLACE_PLACHOLDER_ZOOM_IN.REPLACE_PLACHOLDER_ZOOM_IN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        messageReplace: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default EmployeeHubReducer;
