import axios, { AxiosPromise } from 'axios';

import { API } from 'consts';
import { UpdateRemainingCreditMeta } from 'store/actions/settings/updateRemainingCredit';
import { GenericResponse } from 'types/GenericResponse';
import { AddCardMeta } from 'types/store/AddCard';
import { ChargeCardMeta } from 'types/store/ChargeCard';
import { UpdateContactSaleInfoShoppingCartMeta } from 'types/store/ContentPackage';
import { CreateNewCustomerMeta } from 'types/store/CreateNewCustomer';
import { GetBillingAddressMeta } from 'types/store/GetBillingAddress';
import { GetSavedCardsMeta } from 'types/store/GetSavedCards';
import { GetStripeCustomerIdMeta } from 'types/store/GetStripeCustomerId';
import { PurchaseCreditsMeta } from 'types/store/PurchaseCredits';
import { RemoveCardMeta } from 'types/store/RemoveCard';
import { UpdateBillingAddressMeta } from 'types/store/UpdateBillingAddress';
import { UpdateCardDetailsMeta } from 'types/store/UpdateCardDetails';
import { DefaultCardMeta } from 'types/store/UpdateDefaultCard';
import {
  UpdateStripeCustomerDetailsMeta,
  UpdateStripeCustomerDetailsPayload,
} from 'types/store/UpdateStripeCustomerDetails';
import { UseCreditsMeta } from 'types/store/UseCredits';
import { SetStoryCostMeta } from 'store/actions/settings/setStoryCost';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const PAYMENT_URL = `${BASE_URL}/payment`;

export const createNewCustomer = (
  data: CreateNewCustomerMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${PAYMENT_URL}/new-customer`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const purchaseCredits = (
  data: PurchaseCreditsMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${PAYMENT_URL}/purchase-credit`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
//retrieve customer is base on company id
export const getStripeCustomerId = (
  data: GetStripeCustomerIdMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${PAYMENT_URL}/retrieve-customer`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

//update billing email
export const updateCustomerDetails = (
  data: UpdateStripeCustomerDetailsMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${PAYMENT_URL}/update-customer`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

//update billing address
export const updateBillingAddress = (
  data: UpdateBillingAddressMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${PAYMENT_URL}/update-billing-address`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
//get billing address
export const getBillingAddress = (
  data: GetBillingAddressMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${PAYMENT_URL}/get-billing-address`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const chargeCard = (
  data: ChargeCardMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${PAYMENT_URL}/charge-card`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

//add a new card to the customer
export const addCard = (
  data: AddCardMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${PAYMENT_URL}/add-new-card`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

//get saved cards registered to a company
export const getSavedCards = (
  data: GetSavedCardsMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${PAYMENT_URL}/get-saved-cards`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const updateDefaultCard = (
  data: DefaultCardMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${PAYMENT_URL}/update-default-card`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
export const removeCard = (
  data: RemoveCardMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${PAYMENT_URL}/delete-card`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const useCredits = (
  data: UseCreditsMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${PAYMENT_URL}/use-credits`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const updateCardDetails = (
  data: UpdateCardDetailsMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${PAYMENT_URL}/update-card-details`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const getContactSaleInfoShoppingCart = (
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${PAYMENT_URL}/purchase/cart-info`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateContactSaleInfoShoppingCart = (
  data: UpdateContactSaleInfoShoppingCartMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${PAYMENT_URL}/purchase/cart-info`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const updateRemainingCredit = (
  data: UpdateRemainingCreditMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'put',
    url: `${BASE_URL}/company/updateCompanyCredits`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const getStoryCost = (token: string): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${PAYMENT_URL}/get-cost-story`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const setStoryCost = (
  data: SetStoryCostMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${PAYMENT_URL}/post-cost-story`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
