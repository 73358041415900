import { put, call, takeLatest, select } from 'redux-saga/effects';

import { updateShowcaseFeedbackHighlight } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateShowcaseFeedbackHighlightMeta,
  UpdateShowcaseFeedbackHighlightPayload,
} from 'types/store/UpdateShowcaseFeedbackHighlightState';

import {
  updateShowcaseFeedbackHighlightActions,
  getShowcaseCompanyActions,
} from '../actions';

function* updateShowcaseFeedbackHighlightRequest(
  action: AsyncAction<
    UpdateShowcaseFeedbackHighlightMeta,
    UpdateShowcaseFeedbackHighlightPayload
  >,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateShowcaseFeedbackHighlight,
        action.meta,
        state.auth.token,
      );
      yield put(updateShowcaseFeedbackHighlightActions.success(data));
    } catch (e: any) {
      yield put(updateShowcaseFeedbackHighlightActions.failed(e.message));
    }
  }
}

function* updateShowcaseFeedbackHighlightSuccess(
  action: AsyncAction<
    UpdateShowcaseFeedbackHighlightMeta,
    UpdateShowcaseFeedbackHighlightPayload
  >,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
) {
  yield put(updateShowcaseFeedbackHighlightActions.clear());
  //   yield put(getShowcaseCompanyActions.request({}));
}

function* updateShowcaseFeedbackHighlightWatcher(): any {
  yield takeLatest(
    updateShowcaseFeedbackHighlightActions.REQUEST,
    updateShowcaseFeedbackHighlightRequest,
  );
  yield takeLatest(
    updateShowcaseFeedbackHighlightActions.SUCCESS,
    updateShowcaseFeedbackHighlightSuccess,
  );
}

export default updateShowcaseFeedbackHighlightWatcher;
