import { SaveDynamicMetaState } from 'types/store/saveDynamicFilter';
import {
  createClearAction,
  createUpdateAction,
} from 'utils/Redux';

const ns = 'SAVE_DYNAMIC_FILTER_TAGS_STORY_HUB';
const asyncAction = createUpdateAction<SaveDynamicMetaState>(ns);

const clearAction = createClearAction(ns);

const saveDynamicFilterTagStoryHubActions = {
  ...asyncAction,
  ...clearAction,
};

export default saveDynamicFilterTagStoryHubActions;
