import React from 'react';

import { SVGProps } from './SVG.props';

const HistoryArrow = (props: SVGProps): React.JSX.Element => {
  const { width = 16, height = 16, fill = '#060548' } = props;
  const viewBox = `0 0 ${width} ${height}`;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 2.66602C14.3681 2.66602 14.6666 2.96449 14.6666 3.33268V12.666C14.6666 13.0342 14.3682 13.3327 14 13.3327C13.6318 13.3327 13.3333 13.0342 13.3333 12.666V3.33268C13.3333 2.96449 13.6318 2.66602 14 2.66602Z"
        fill={fill}
      />
      <path
        d="M7.52855 4.19461C7.7889 3.93426 8.21101 3.93426 8.47136 4.19461L11.8047 7.52794C12.065 7.78829 12.065 8.2104 11.8047 8.47075L8.47136 11.8041C8.21101 12.0644 7.7889 12.0644 7.52855 11.8041C7.26821 11.5437 7.26821 11.1216 7.52855 10.8613L9.72382 8.66602H1.33329C0.965103 8.66602 0.666626 8.36754 0.666626 7.99935C0.666626 7.63116 0.965103 7.33268 1.33329 7.33268H9.72382L7.52855 5.13742C7.26821 4.87707 7.26821 4.45496 7.52855 4.19461Z"
        fill={fill}
      />
    </svg>
  );
};

export default HistoryArrow;
