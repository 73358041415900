import axios from 'axios';

import { API } from 'consts';
import { GetAssetStateMeta } from 'types/store/getAssetState';
import { GetHomePageMenuMeta } from 'types/store/GetHomePageMenu';
import { GetHomePageShortcutsMeta } from 'types/store/GetHomePageShortcutsState';
import { GetRecipesMeta } from 'types/store/GetRecipes';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const HOMEPAGE_URL = `${BASE_URL}/home-page`;

export const getHomePageHeader = (token: string): any => {
  return axios({
    method: 'get',
    url: `${HOMEPAGE_URL}/header`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getHomePageShortcuts = (data: GetHomePageShortcutsMeta, token: string): any => {
  return axios({
    method: 'get',
    url: `${HOMEPAGE_URL}/shortcut`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getHomePageMenu = (data: GetHomePageMenuMeta, token: string): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/content-package/home-page`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getRecipes = (data: GetRecipesMeta, token: string): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/recipe`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getAsset = (data: GetAssetStateMeta, token: string): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/asset`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};
