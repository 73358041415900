import React from 'react';

import { SVGProps } from './SVG.props';

const ArrowFrontierRight = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M13.9998 2.6665C14.368 2.6665 14.6665 2.96498 14.6665 3.33317V12.6665C14.6665 13.0347 14.368 13.3332 13.9998 13.3332C13.6316 13.3332 13.3332 13.0347 13.3332 12.6665V3.33317C13.3332 2.96498 13.6316 2.6665 13.9998 2.6665Z"
        fill={fill || '#060548'}
      />
      <path
        d="M7.52843 4.1951C7.78878 3.93475 8.21089 3.93475 8.47124 4.1951L11.8046 7.52843C12.0649 7.78878 12.0649 8.21089 11.8046 8.47124L8.47124 11.8046C8.21089 12.0649 7.78878 12.0649 7.52843 11.8046C7.26808 11.5442 7.26808 11.1221 7.52843 10.8618L9.7237 8.6665H1.33317C0.96498 8.6665 0.666504 8.36803 0.666504 7.99984C0.666504 7.63165 0.96498 7.33317 1.33317 7.33317H9.7237L7.52843 5.13791C7.26808 4.87756 7.26808 4.45545 7.52843 4.1951Z"
        fill={fill || '#060548'}
      />
    </svg>
  );
};

export default ArrowFrontierRight;
