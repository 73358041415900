import React from 'react';

import { SVGProps } from './SVG.props';

const Minus = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99968 1.33325C6.68114 1.33325 5.3922 1.72425 4.29588 2.45679C3.19955 3.18933 2.34506 4.23052 1.84048 5.4487C1.3359 6.66687 1.20387 8.00731 1.46111 9.30052C1.71834 10.5937 2.35328 11.7816 3.28563 12.714C4.21798 13.6463 5.40587 14.2813 6.69908 14.5385C7.99228 14.7957 9.33273 14.6637 10.5509 14.1591C11.7691 13.6545 12.8103 12.8 13.5428 11.7037C14.2754 10.6074 14.6663 9.31846 14.6663 7.99992C14.6663 7.12444 14.4939 6.25753 14.1589 5.4487C13.8238 4.63986 13.3328 3.90493 12.7137 3.28587C12.0947 2.66682 11.3597 2.17575 10.5509 1.84072C9.74206 1.50569 8.87516 1.33325 7.99968 1.33325V1.33325ZM7.99968 13.3333C6.94484 13.3333 5.9137 13.0205 5.03664 12.4344C4.15957 11.8484 3.47599 11.0154 3.07232 10.0409C2.66865 9.06636 2.56304 7.994 2.76882 6.95944C2.97461 5.92487 3.48256 4.97456 4.22844 4.22868C4.97432 3.4828 5.92463 2.97485 6.9592 2.76906C7.99376 2.56328 9.06612 2.66889 10.0407 3.07256C11.0152 3.47623 11.8481 4.15982 12.4342 5.03688C13.0202 5.91394 13.333 6.94509 13.333 7.99992C13.333 9.41441 12.7711 10.771 11.7709 11.7712C10.7707 12.7713 9.41417 13.3333 7.99968 13.3333V13.3333ZM10.6663 7.33325H5.33301C5.1562 7.33325 4.98663 7.40349 4.86161 7.52851C4.73658 7.65354 4.66634 7.82311 4.66634 7.99992C4.66634 8.17673 4.73658 8.3463 4.86161 8.47132C4.98663 8.59635 5.1562 8.66659 5.33301 8.66659H10.6663C10.8432 8.66659 11.0127 8.59635 11.1377 8.47132C11.2628 8.3463 11.333 8.17673 11.333 7.99992C11.333 7.82311 11.2628 7.65354 11.1377 7.52851C11.0127 7.40349 10.8432 7.33325 10.6663 7.33325Z"
        fill={fill || '#FF647C'}
      />
    </svg>
  );
};

export default Minus;
