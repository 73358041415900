import React from 'react';

import { SVGProps } from './SVG.props';

const Apple = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '18'}
      height={height || '18'}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.26.818s.223 1.334-.847 2.616c-1.14 1.371-2.436 1.148-2.436 1.148s-.244-1.078.713-2.339C10.765.826 12.26.818 12.26.818zM15.233 6.391s-1.769.903-1.769 3.1c0 2.476 2.207 3.328 2.207 3.328s-1.543 4.339-3.624 4.339c-.472 0-.892-.157-1.317-.317-.437-.163-.88-.328-1.39-.328-.57 0-1.136.205-1.641.389-.406.147-.773.28-1.069.28-1.9 0-4.3-4.116-4.3-7.422 0-3.253 2.031-4.96 3.935-4.96.722 0 1.348.241 1.873.444.378.145.703.27.974.27.217 0 .506-.116.858-.258.547-.22 1.246-.502 2.058-.502 2.299 0 3.205 1.637 3.205 1.637z"
        fill="#000"
      />
    </svg>
  );
};

export default Apple;
