import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getCommunities } from 'services/community';
import { AsyncAction } from 'types/Action';
import {
  GetCommunitiesMeta,
  GetCommunitiesPayload,
} from 'types/store/GetCommunitiesState';
import { Store } from 'types/store/Store';

import { getCommunitiesActions } from '../actions';

function* getCommunitiesRequest(
  action: AsyncAction<GetCommunitiesMeta, GetCommunitiesPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getCommunities, action.meta, state.auth.token);
      yield put(getCommunitiesActions.success(data));
      // yield put(getPagesbyCompanyIdActions.success(data));
    } catch (e: any) {
      yield put(getCommunitiesActions.failed(e.message));
    }
  } else {
    yield put(getCommunitiesActions.failed('Token not found'));
    // yield put(getPagesbyCompanyIdActions.failed('Token not found'));
  }
}

//   function* forgotPasswordSuccess(action: AsyncAction<ForgotPasswordMeta, ForgotPasswordPayload>) {
//     yield put(push(EMPLOYER_ROUTES.LOGIN));
//   }

function* getCommunitiesWatcher(): any {
  yield takeLatest(getCommunitiesActions.REQUEST, getCommunitiesRequest);
  // yield takeLatest(forgotPasswordActions.SUCCESS, forgotPasswordSuccess);
}

export default getCommunitiesWatcher;
