import { applyMiddleware, createStore, Store, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore, Persistor } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import createRootReducer from 'store/reducers';

const isDevMode =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'pageName', 'libraryFilter', 'saveDynamicFilterLibrary', 'saveDynamicFilterStoryHub', 'saveDynamicFilterTagStoryHub'],
};

const persistedReducer = persistReducer(persistConfig, createRootReducer());

export const sagaMiddleware = createSagaMiddleware();

const enhancer = applyMiddleware(sagaMiddleware);

export const store: Store<any, any> = createStore(
  persistedReducer,
  isDevMode ? composeWithDevTools(enhancer) : enhancer,
);

export const persistor = (callback?: () => void): Persistor =>
  persistStore(store, undefined, callback);

export default store;
