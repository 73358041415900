import pathOr from 'ramda/es/pathOr';

import { GTMActions } from 'store/actions';
import { Action } from 'types/Action';
import {
    GTMState,
    GTMPayload
} from 'types/store/GTMState';


const initState: GTMState = {
    isEmbeddedGtm: false
}

const initAction: Action<GTMPayload> = {
    type: '',
    payload: {}
}

export default (state = initState, action = initAction): any => {
    const reducer = () => {
        return {
            ...state,
            ...action.payload
        }
    }

    return pathOr(state, [action.type], {
        [GTMActions.UPDATE]: reducer(),
        [GTMActions.CLEAR]: initState
    })
}