import React from 'react';

import { SVGProps } from './SVG.props';

const DoubleQuote = (props: SVGProps): React.JSX.Element => {
  return (
    <svg width={50} height={50} viewBox="0 0 50 30" fill="none" {...props}>
      <path
        d="M.708 18.597a10.934 10.934 0 002.688 7.084 11.117 11.117 0 006.672 3.683 11.174 11.174 0 007.493-1.465 11.009 11.009 0 004.76-5.919 10.82 10.82 0 00-.641-8.35 10.93 10.93 0 00-2.665-3.356 11.04 11.04 0 00-3.76-2.085 10.028 10.028 0 00-.485-.147c1.319-3.524 4.017-5.402 5.87-6.368a.606.606 0 00.316-.643.603.603 0 00-.524-.491 17.797 17.797 0 00-7.534.729 17.681 17.681 0 00-6.532 3.79 17.486 17.486 0 00-4.329 6.153 17.347 17.347 0 00-1.329 7.385zM27.644 18.597a10.9 10.9 0 001.623 5.731 11.036 11.036 0 004.403 4.049 11.158 11.158 0 0011.53-.87 10.991 10.991 0 003.734-4.663c.787-1.865 1.04-3.91.728-5.909a10.93 10.93 0 00-2.491-5.416 11.08 11.08 0 00-5.463-3.477c1.326-3.524 4.014-5.402 5.87-6.368a.607.607 0 00.315-.644.603.603 0 00-.526-.49 17.797 17.797 0 00-7.533.729 17.681 17.681 0 00-6.532 3.79 17.486 17.486 0 00-4.329 6.153 17.349 17.349 0 00-1.329 7.385z"
        fill="#FFC937"
      />
    </svg>
  );
};

export default DoubleQuote;
