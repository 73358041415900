import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getShowcasePagesByCompanyId } from 'services/showcase';
import { Store } from 'types/store/Store';

import { getShowcasePagesByCompanyIdActions } from '../actions';

function* getShowcasePagesByCompanyIdRequest() {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(
        getShowcasePagesByCompanyId,
        state.auth.token
      );
      yield put(getShowcasePagesByCompanyIdActions.success(data));
    } catch (e: any) {
      yield put(getShowcasePagesByCompanyIdActions.failed(e.message));
    }
  } else {
    yield put(getShowcasePagesByCompanyIdActions.failed('Token not found'));
  }
}

function* getShowcasePagesByCompanyIdSuccess() {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getShowcasePagesByCompanyIdWatcher(): any {
  yield takeLatest(
    getShowcasePagesByCompanyIdActions.REQUEST,
    getShowcasePagesByCompanyIdRequest
  );
  yield takeLatest(
    getShowcasePagesByCompanyIdActions.SUCCESS,
    getShowcasePagesByCompanyIdSuccess
  );
}

export default getShowcasePagesByCompanyIdWatcher;
