import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getShowcaseArticlesByUrl } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import {
  GetShowcaseArticlesByUrlMeta,
  GetShowcaseArticlesByUrlPayload,
} from 'types/store/GetShowcaseArticlesByUrlState';
import { Store } from 'types/store/Store';

import { getShowcaseArticlesByUrlActions } from '../actions';

function* getShowcaseArticlesByUrlRequest(
  action: AsyncAction<
    GetShowcaseArticlesByUrlMeta,
    GetShowcaseArticlesByUrlPayload
  >
) {
  const state: Store = yield select();
  try {
    // company id already embeded in the token
    const { data } = yield call(getShowcaseArticlesByUrl, action.meta);
    yield put(getShowcaseArticlesByUrlActions.success(data));
  } catch (e: any) {
    yield put(getShowcaseArticlesByUrlActions.failed(e.message));
  }
}

function* getShowcaseArticlesByUrlSuccess(
  action: AsyncAction<
    GetShowcaseArticlesByUrlMeta,
    GetShowcaseArticlesByUrlPayload
  >
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getShowcaseArticlesByUrlWatcher(): any {
  yield takeLatest(
    getShowcaseArticlesByUrlActions.REQUEST,
    getShowcaseArticlesByUrlRequest
  );
  yield takeLatest(
    getShowcaseArticlesByUrlActions.SUCCESS,
    getShowcaseArticlesByUrlSuccess
  );
}

export default getShowcaseArticlesByUrlWatcher;
