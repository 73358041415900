import React, { useEffect } from 'react';

import BrandV4 from 'components/base/SVG/BrandV4';
import Close from 'components/base/SVG/Close';
import { Share } from 'components/base/SVG';
import theme from 'utils/Theme';

import SquircleBox from '../SquircleBox';
import { ConfirmDialogV2Props } from './ConfirmDialogV2.props';
import {
  BackdropDialog,
  ContainerDialog,
  TitleContainer,
  BrandContainer,
  Title,
  Strong,
  MessageContainerDiaLog,
  Message,
  ButtonContainer,
  ActionButton,
  CloseButton,
} from './ConfirmDialogV2.style';

const ConfirmDialogV2 = (props: ConfirmDialogV2Props): React.JSX.Element => {
  const {
    isOpen,
    title,
    message,
    confirmText,
    cancelText,
    customActions,
    customMinWidth,
    customWidth,
    customPadding,
    showCloseBtn = false,
    onClose,
    onSubmit,
    isLoading,
  } = props;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.touchAction = 'none';
    } else {
      document.body.style.overflow = '';
      document.body.style.touchAction = '';
    }
  }, [isOpen]);

  return isOpen ? (
    <BackdropDialog>
      <ContainerDialog
        minWidth={customMinWidth}
        padding={customPadding}
        customWidth={customWidth}
      >
        <TitleContainer>
          <Title>
            <Strong>{title || 'Confirmation'}</Strong>
          </Title>
        </TitleContainer>
        {
          <MessageContainerDiaLog>
            {/* <Message>{message}</Message> */}
            <div dangerouslySetInnerHTML={{ __html: message ?? '' }} />
          </MessageContainerDiaLog>
        }
        {customActions ? (
          customActions
        ) : (
          <ButtonContainer>
            <ActionButton onClick={onClose} border white>
              {cancelText || 'Cancel'}
            </ActionButton>
            <ActionButton
              iconPosition="after"
              loading={isLoading}
              icon={
                isLoading ? (
                  <Share fill={theme.altGrey.shade7} width={16} height={16} />
                ) : undefined
              }
              ml={10}
              onClick={onSubmit}
              disabled={isLoading}
            >
              {confirmText || 'OK'}
            </ActionButton>
          </ButtonContainer>
        )}
        {showCloseBtn && (
          <CloseButton onClick={onClose}>
            <Close width={12} height={12} />
          </CloseButton>
        )}
      </ContainerDialog>
    </BackdropDialog>
  ) : (
    <></>
  );
};

export default React.memo(ConfirmDialogV2);
