import pathOr from 'ramda/es/pathOr';

import { Action } from 'types/Action';
import {
  PreviewPageState,
  PreviewPagePayload,
} from 'types/store/PreviewPageState';

import { previewPageActions } from '../actions';

const DEFAULT_STATE: PreviewPageState = {
  title: '',
  about: '',
  jobs: [
    {
      title: '',
      location: '',
      url: '',
    },
  ],
  logo: '',
  media: [{ type: 'VIDEO', url: '' }],
  perks: [
    { perk: '', section: 'Office Perks' },
    { perk: '', section: 'Office Perks' },
    { perk: '', section: 'Office Perks' },
    { perk: '', section: 'Health and Wellness' },
    { perk: '', section: 'Health and Wellness' },
    { perk: '', section: 'Health and Wellness' },
    { perk: '', section: 'Paid Time Off' },
    { perk: '', section: 'Paid Time Off' },
    { perk: '', section: 'Paid Time Off' },
    { perk: '', section: 'Financial Benefits' },
    { perk: '', section: 'Financial Benefits' },
    { perk: '', section: 'Financial Benefits' },
  ],
  values: [{ image: '', text: '', title: '' }],
  colour: '#d3d3d3',
  stories: [{ headline: '', url: '', author: '', role: '', image: '' }],
  url: '',
  from: '',
  value_intro: '',
  footer_links: [
    {
      title: '',
      url: '',
      order: 1,
    },
  ],
};

const DEFAULT_ACTION: Action<PreviewPagePayload> = {
  type: '',
  payload: {
    title: '',
    about: '',
    jobs: [],
    logo: '',
    colour: '#d3d3d3',
    from: '',
    footer_links: [],
  },
};

export default (state = DEFAULT_STATE, action = DEFAULT_ACTION): any => {
  return pathOr(state, [action.type], {
    [previewPageActions.UPDATE]: {
      ...state,
      ...action.payload,
    },
    [previewPageActions.CLEAR]: DEFAULT_STATE,
  });
};
