// src/utils/navigationService.ts

import { log } from 'console';

import { Location } from 'react-router-dom';

type NavigateFunctionType = (to: string, options?: any) => void;

let navigate: NavigateFunctionType | null = null;
let currentLocation: Location | null = null;

/**
 * Sets the navigate function from React Router.
 * This should be called once, typically in a useEffect within a React component.
 */
const setNavigate = (nav: NavigateFunctionType): void => {
  navigate = nav;
};

/**
 * Sets the current location. This should be called whenever the location changes.
 */
const setLocation = (loc: Location): void => {
  currentLocation = loc;
};

/**
 * Navigates to the specified path using the navigate function.
 * If navigate hasn't been set yet, it logs a warning.
 * @param path The path to navigate to.
 * @param options Optional navigation options.
 */
const goTo = (path: string, options?: any): void => {
  if (navigate) {
    navigate(path, options);
  } else {
    console.warn('Navigate function not set yet');
  }
};

/**
 * Navigates back in the browser history using window.history.back().
 */
const goBack = (): void => {
  window.history.back();
};

/**
 * Retrieves the current location.
 * @returns The current location or null if not set.
 */
const getCurrentLocation = (): Location | null => currentLocation;

export default {
  setNavigate,
  setLocation,
  goTo,
  goBack,
  getCurrentLocation,
};
