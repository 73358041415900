import { put, call, takeLatest, select } from 'redux-saga/effects';

import { fetchSuggestionAdvocates } from 'services/contentPlanner';
import { getSuggestionAdvocatesActions } from 'store/actions';
import { ISuggestionAdvocatesMeta } from 'store/actions/contentPlanner/suggestionAdvocates';
import { AsyncAction } from 'types/Action';
import { GenericResponse } from 'types/GenericResponse';
import { Store } from 'types/store/Store';

function* getSuggestionAdvocatesSaga(
  action: AsyncAction<ISuggestionAdvocatesMeta, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        fetchSuggestionAdvocates,
        action.meta,
        state.auth.token,
      );
      yield put(getSuggestionAdvocatesActions.success(data));
    } catch (e: any) {
      yield put(getSuggestionAdvocatesActions.failed(e.message));
    }
  }
}

function* recipeStoryWatcher(): any {
  yield takeLatest(
    getSuggestionAdvocatesActions.REQUEST,
    getSuggestionAdvocatesSaga,
  );
}

export default recipeStoryWatcher;
