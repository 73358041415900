import styled from 'utils/styled';
import theme from 'utils/Theme';

import Typography from '../Typography';
import { ButtonStyleProps, Size, Variants } from './Button.props';

export const backgroundColor: Record<Variants, string> = {
  primary: theme.brand.secondary,
  outline: theme.grey.noshade,
  disabled: theme.grey.shade3,
  alert: theme.brand.alert,
  normal: 'transparent',
  blue: theme.brand.primary,
  secondary: theme.brand.tertiary,
  plain: theme.grey.shade3,
  tertiary: theme.brand.tertiary,
  deny: '#FF7991',
  linkedin: '#0065A1',
  canary: theme.brand.canary,
  arctic: theme.brand.arctic,
  transparent: 'transparent',
  white: '#fff',
  badge: theme.altGrey.shade3,
};

const border: Record<Variants, string> = {
  primary: '1.5px solid transparent',
  outline: `1.5px solid ${theme.brand.primary}`,
  disabled: `1.5px solid transparent`,
  alert: '1.5px solid transparent',
  normal: `1.5px solid ${theme.brand.secondary}`,
  blue: '1.5px solid transparent',
  secondary: '1.5px solid transparent',
  plain: '1.5px solid transparent',
  tertiary: '1.5px solid transparent',
  deny: '1.5px solid transparent',
  linkedin: '1.5px solid transparent',
  canary: '1.5px solid transparent',
  arctic: '1.5px solid transparent',
  transparent: 'none',
  white: '1.5px solid transparent',
  badge: '1.5px solid transparent',
};

const Padding: Record<Size, string> = {
  lg: '19px 24px 16px',
  md: '15px 16px 12px',
  sm: '11px 12px 8px',
  xs: '11px 12px 8px',
};

export const IconContainer = styled.div``;

export const ButtonContainer = styled.button<
  ButtonStyleProps & { hasText: boolean }
>`
  // max-height: 48px;
  padding: ${({ smaller, size }) =>
    smaller ? `11px 12px 8px` : size ? Padding[size] : `15px 16px 12px`};

  background-color: ${({ variant }) => backgroundColor[variant]};
  border: ${({ variant }) => border[variant]};
  border-radius: 12px; // Base on figma the general radius is 12
  width: ${(props) => (props.takeFullWidth ? '100%' : 'auto')};

  ${({ loading, disabled, isDownloading }) => {
    if (loading || disabled) {
      return `
    cursor: not-allowed;
    `;
    }
    if (isDownloading) {
      return `
        pointer-events: none;
      `;
    }
    return `
  cursor: pointer;
  `;
  }}

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;

  svg {
    margin-left: ${(props) =>
    props.hasText && props.iconPosition === 'after' ? '10px' : '0'};
    margin-right: ${(props) =>
    props.hasText && props.iconPosition === 'before' ? '10px' : '0'};
  }

  :focus {
    outline: none;
  }

  &:hover {
    ${({ disabled }) => {
    if (!disabled) {
      return 'opacity: 0.5;';
    }
  }}
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    ${({ isNotResponse }) => !isNotResponse && 'padding: 8px 12px'};
    &:hover {
      opacity: 1;
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 0;
  }
  margin-left: 5px;
`;

export const Text = styled(Typography)`
  font-size: 11px;
  line-height: 17px;
  font-family: 'Objective Bold' !important;
  letter-spacing: 1px;
`;

export const IconWrapper = styled.div`
  margin-bottom: 2px;
  display: flex;
  align-items: center;
`;
