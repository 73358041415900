import React from 'react';

import { SVGProps } from './SVG.props';

const ThunderV2 = (props: SVGProps): React.JSX.Element => {
  //const { width, height, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g id="thunder-v2">
        <path
          id="line"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.63518 0.739275C9.9124 0.880045 10.0555 1.19375 9.9801 1.49539L8.85386 6.00036H13.3333C13.5977 6.00036 13.8371 6.15659 13.9436 6.39859C14.05 6.64059 14.0034 6.92263 13.8248 7.11751L6.49144 15.1175C6.27572 15.3528 5.92345 15.4022 5.65135 15.2353C5.37924 15.0683 5.26369 14.7319 5.37579 14.4329L7.03801 10.0004H2.66667C2.408 10.0004 2.17267 9.85072 2.06295 9.61647C1.95323 9.38221 1.98893 9.10563 2.15453 8.90691L8.82119 0.906907C9.02024 0.668055 9.35796 0.598504 9.63518 0.739275ZM4.09004 8.66703H8.00001C8.21866 8.66703 8.42341 8.77425 8.54796 8.95397C8.6725 9.13368 8.701 9.36305 8.62423 9.56778L7.83104 11.6829L11.8179 7.3337H8.00001C7.79472 7.3337 7.60088 7.23912 7.47454 7.0773C7.3482 6.91549 7.30346 6.7045 7.35325 6.50534L7.97973 3.99939L4.09004 8.66703Z"
          fill="#060548"
        />
      </g>
    </svg>
  );
};

export default ThunderV2;
