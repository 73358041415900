import React from 'react';

import { SVGProps } from './SVG.props';

const UploadImage = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;
  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill={fill || 'none'}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.5C6.2 1.5 1.5 6.229 1.5 12.064c0 5.274 3.84 9.644 8.86 10.436v-7.382H7.692v-3.054h2.666V9.737c0-2.648 1.567-4.11 3.967-4.11 1.148 0 2.35.206 2.35.206v2.6H15.35c-1.304 0-1.71.815-1.71 1.65v1.981h2.912l-.466 3.054h-2.446V22.5c5.02-.792 8.859-5.162 8.859-10.436C22.5 6.229 17.8 1.5 12 1.5z"
        fill={fill || '#1877F2'}
      />
    </svg>
  );
};

export default UploadImage;
