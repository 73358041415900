import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getTags } from 'services/tags';
import { AsyncAction } from 'types/Action';
import { GenericResponse } from 'types/GenericResponse';
import { GetTagsMeta, GetTagsPayload } from 'types/store/GetTagsState';
import { Store } from 'types/store/Store';

import { getTagsActions } from '../actions';

const massageData = (response: GenericResponse) => {
  return {
    ...response,
    data: response.data?.map((datum: any) => {
      return { ...datum, label: datum.name, value: datum.id };
    }),
  };
};

function* getTagsRequest(action: AsyncAction<GetTagsMeta, GetTagsPayload>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getTags, state.auth.token);

      yield put(getTagsActions.success(massageData(data)));
    } catch (e: any) {
      yield put(getTagsActions.failed(e.message));
    }
  } else {
    yield put(getTagsActions.failed('Token not found'));
  }
}

function* getTagsSuccess(action: AsyncAction<GetTagsMeta, GetTagsPayload>) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getTagsWatcher(): any {
  yield takeLatest(getTagsActions.REQUEST, getTagsRequest);
  yield takeLatest(getTagsActions.SUCCESS, getTagsSuccess);
}

export default getTagsWatcher;
