import axios, { AxiosPromise, AxiosResponse } from 'axios';

import { API } from 'consts';
import { GenericResponse } from 'types/GenericResponse';
import { AnswerMultiQuestionMeta } from 'types/store/AnswerMultiQuestion';
import { CreateCustomQuestionMetaService } from 'types/store/CreateCustomQuestionState';
import { GetMoreQuestionMeta } from 'types/store/GetMoreQuestion';
import { GetMoreQuestionForCustomMeta } from 'types/store/GetMoreQuestionForCustom';
import { GetSuggestedQuestionsMeta } from 'types/store/GetSuggestedQuestions';
import { GetTranscribeVideoMeta } from 'types/store/GetTranscribeVideo';
import { SubmitVideoCommentMeta } from 'types/store/SubmitVideoCommentState';
import { TranscribeAudioMeta } from 'types/store/TranscribeAudio';
import { TranscribeCheckMeta } from 'types/store/TranscribeCheck';
import { TranscribeVideoMeta } from 'types/store/TranscribeVideo';
import { UpdateAnswerByIdMeta } from 'types/store/UpdateAnswerByIdState';
import { UploadAnswerVideoMeta } from 'types/store/UploadAnswerVideo';
import { UploadRevisedVideoMeta } from 'types/store/UploadRevisedVideo';

import { uploadFile } from './upload';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const QUESTION_URL = `${BASE_URL}/question`;

//company_id is already embeded in the token
export const getSuggestedQuestions = (
  data: GetSuggestedQuestionsMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${QUESTION_URL}/getSuggestedQuestions`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const updateAnwer = (data: UpdateAnswerByIdMeta, token: string): any => {
  return axios({
    method: 'put',
    url: `${QUESTION_URL}/answerQuestion`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const transcribeAudio = async (
  data: TranscribeAudioMeta,
  token: string,
): Promise<any> => {
  return axios({
    method: 'post',
    url: `${QUESTION_URL}/transcribeAudio`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const transcribeCheck = (
  data: TranscribeCheckMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${QUESTION_URL}/transcribeCheck`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const createCustomQuestion = (
  data: CreateCustomQuestionMetaService,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${QUESTION_URL}/create`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const submitVideoComment = (
  data: SubmitVideoCommentMeta,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${QUESTION_URL}/video_comment`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const uploadRevisedVideo = async (
  data: UploadRevisedVideoMeta,
  token: string,
): Promise<any> => {
  if (!data.video_file) throw new Error('Missing file');

  try {
    if (data.revised_video_link && data.revised_video_link !== '') {
      //mutate the db
      const fd = new FormData();
      fd.append('story_video_id', data.story_video_id);
      fd.append('revised_video_link', data.revised_video_link);
      console.log('fd data: ', fd);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      };
      return await axios.post(`${QUESTION_URL}/uploadRevisedVideo`, fd, config);
    }

    // Upload file
    const uploadUrl =
      typeof data.video_file === 'string'
        ? data.video_file
        : await uploadFile({
            file: data.video_file,
            token,
            folder: 'story',
          });
    //mutate the db
    const fd = new FormData();
    fd.append('story_video_id', data.story_video_id);
    fd.append('revised_video_link', uploadUrl);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    };

    return await axios.post(`${QUESTION_URL}/uploadRevisedVideo`, fd, config);
  } catch (error: any) {
    console.log('request error', error);
    throw new Error(error);
  }
};

export const uploadVideoNewFlow = async (
  data: UploadAnswerVideoMeta,
  token: string,
): Promise<any> => {
  if (!data.video_file) throw new Error('Missing file');

  try {
    // Upload file
    const uploadUrl =
      typeof data.video_file === 'string'
        ? data.video_file
        : await uploadFile({
            file: data.video_file,
            token,
          });

    return axios({
      method: 'post',
      url: `${QUESTION_URL}/uploadVideoNewFlow`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        video_link: uploadUrl,
        story_id: data.story_id,
        answer_id: data.answer_id,
        user_id: data.user_id,
        wait_transcribe: data?.wait_transcribe ? true : false,
      },
    });
  } catch (error: any) {
    console.log('request error', error);
    throw new Error(error);
  }
};

export const getMoreQuestion = (
  data: GetMoreQuestionMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${QUESTION_URL}/getMoreQuestion`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getMoreQuestionForCustom = (
  data: GetMoreQuestionForCustomMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${QUESTION_URL}/getMoreQuestionForCustom`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const answerMultiQuestion = (
  data: AnswerMultiQuestionMeta,
  token: string,
): any =>
  axios({
    method: 'put',
    url: `${QUESTION_URL}/answerMultiQuestion`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });

export const transcribeVideo = (
  data: TranscribeVideoMeta,
  token: string,
): any => {
  if (data.type === 'aws') {
    return axios({
      method: 'post',
      url: `${QUESTION_URL}/transcribeVideoAWS`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
  }

  return axios({
    method: 'post',
    url: `${QUESTION_URL}/transcribeVideo`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getTranscribeVideo = (
  data: GetTranscribeVideoMeta,
  token: string,
): any => {
  if (data.type === 'aws')
    return axios({
      method: 'get',
      url: `${QUESTION_URL}/getTranscribeVideoAWS`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: data,
    });

  return axios({
    method: 'get',
    url: `${QUESTION_URL}/getTranscribeVideos`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const transcribeStory = (story_video_id: string, token: string): any => {
  console.log('story_video_id: ', story_video_id);

  return axios({
    method: 'post',
    url: `${QUESTION_URL}/transcribeVideoAWS`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      story_video_id: story_video_id,
    },
  });
};
