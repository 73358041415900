import FileSaver from 'file-saver';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import { updateArticlePosition } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateArticlePositionMeta,
  UpdateArticlePositionPayload
} from 'types/store/UpdateArticlePosition';

import { updateArticlePositionActions } from '../actions';
function* updateArticlePositionRequest(
  action: AsyncAction<UpdateArticlePositionMeta, UpdateArticlePositionPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(updateArticlePosition, action.meta, state.auth.token);
      yield put(updateArticlePositionActions.success(data));
    } catch (e: any) {
      yield put(updateArticlePositionActions.failed(e.message));
    }
  }
}

function* updateArticlePositionSuccess(
  action: AsyncAction<UpdateArticlePositionMeta, UpdateArticlePositionPayload>
) {
  //
}

function* updateArticlePositionWatcher(): any {
  yield takeLatest(updateArticlePositionActions.REQUEST, updateArticlePositionRequest);
  yield takeLatest(updateArticlePositionActions.SUCCESS, updateArticlePositionSuccess);
}

export default updateArticlePositionWatcher;
