import React from 'react';

import { SVGProps } from './SVG.props';

const Loading = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '22'}
      height={height || '22'}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.4644 6.0508C7.855 6.4414 7.855 7.0742 7.4644 7.4649C7.2691 7.6602 7.0132 7.7579 6.7574 7.7579C6.5016 7.7579 6.2457 7.6602 6.0504 7.4649L3.2218 4.6358C2.8312 4.2452 2.8312 3.6124 3.2218 3.2217C3.6124 2.831 4.2452 2.8311 4.6359 3.2217L7.4644 6.0508ZM17.3643 3.2217L14.5357 6.0508C14.1451 6.4414 14.1451 7.0742 14.5357 7.4649C14.731 7.6602 14.9869 7.7579 15.2427 7.7579C15.4985 7.7579 15.7544 7.6602 15.9497 7.4649L18.7783 4.6358C19.1689 4.2452 19.1689 3.6124 18.7783 3.2217C18.3877 2.831 17.755 2.8311 17.3643 3.2217ZM6 11C6 10.4473 5.5522 10 5 10H1C0.4478 10 0 10.4473 0 11C0 11.5527 0.4478 12 1 12H5C5.5522 12 6 11.5527 6 11ZM6.0503 14.5352L3.2217 17.3643C2.8311 17.7549 2.8311 18.3877 3.2217 18.7784C3.417 18.9737 3.6729 19.0714 3.9287 19.0714C4.1845 19.0714 4.4404 18.9737 4.6357 18.7784L7.4643 15.9493C7.8549 15.5587 7.8549 14.9259 7.4643 14.5352C7.0737 14.1445 6.441 14.1446 6.0503 14.5352ZM11 0C10.4478 0 10 0.4473 10 1V5C10 5.5527 10.4478 6 11 6C11.5522 6 12 5.5527 12 5V1C12 0.4473 11.5522 0 11 0Z"
        fill={fill || '#91DBB6'}
      />
      <path
        opacity="0.3"
        d="M18.7783 17.3643C19.1689 17.7549 19.1689 18.3877 18.7783 18.7784C18.583 18.9737 18.3271 19.0714 18.0713 19.0714C17.8155 19.0714 17.5596 18.9737 17.3643 18.7784L14.5357 15.9493C14.1451 15.5587 14.1451 14.9259 14.5357 14.5352C14.9263 14.1445 15.5591 14.1446 15.9498 14.5352L18.7783 17.3643ZM21 10H17C16.4478 10 16 10.4473 16 11C16 11.5527 16.4478 12 17 12H21C21.5522 12 22 11.5527 22 11C22 10.4473 21.5522 10 21 10ZM11 16C10.4478 16 10 16.4473 10 17V21C10 21.5527 10.4478 22 11 22C11.5522 22 12 21.5527 12 21V17C12 16.4473 11.5522 16 11 16Z"
        fill={fill || '#91DBB6'}
      />
    </svg>
  );
};

export default Loading;
