import { put, call, takeLatest, select } from 'redux-saga/effects';

import { getEmployeeHubData } from 'services/advocate';
import { AsyncAction } from 'types/Action';
import { GetEmployeeHubDataMeta, GetEmployeeHubDataPayload } from 'types/store/GetEmployeeHubData';
import { Store } from 'types/store/Store';

import { getEmployeeHubDataActions } from '../actions';

function* getEmployeeHubDataRequest(action: AsyncAction<GetEmployeeHubDataMeta, GetEmployeeHubDataPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(getEmployeeHubData, action.meta, state.auth.token);
      yield put(getEmployeeHubDataActions.success(data));
    } catch (e: any) {
      yield put(getEmployeeHubDataActions.failed(e.message));
    }
  }
}

// function* getEmployeeHubDataSuccess(action: AsyncAction<GetEmployeeHubDataMeta, GetEmployeeHubDataPayload>): any {
//   yield put()
// }

function* getEmployeeHubDataWatcher(): any {
  yield takeLatest(getEmployeeHubDataActions.REQUEST, getEmployeeHubDataRequest);
  // yield takeLatest(getEmployeeHubDataActions.SUCCESS, getEmployeeHubDataSuccess);
}

export default getEmployeeHubDataWatcher;
