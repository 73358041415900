import FileSaver from 'file-saver';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import { transcribeCheck } from 'services/question';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  TranscribeCheckMeta,
  TranscribeCheckPayload,
} from 'types/store/TranscribeCheck';

import { transcribeCheckActions } from '../actions';
function* transcribeCheckRequest(
  action: AsyncAction<TranscribeCheckMeta, TranscribeCheckPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        transcribeCheck,
        action.meta,
        state.auth.token
      );
      yield put(transcribeCheckActions.success(data));
    } catch (e: any) {
      yield put(transcribeCheckActions.failed(e.message));
    }
  }
}

function* transcribeCheckSuccess(
  action: AsyncAction<TranscribeCheckMeta, TranscribeCheckPayload>
) {
  //
}

function* transcribeCheckWatcher(): any {
  yield takeLatest(transcribeCheckActions.REQUEST, transcribeCheckRequest);
  yield takeLatest(transcribeCheckActions.SUCCESS, transcribeCheckSuccess);
}

export default transcribeCheckWatcher;
