import axios, { AxiosPromise } from 'axios';

import { API } from 'consts';
import { GenericResponse } from 'types/GenericResponse';
import { GetAllAdvocatesMeta } from 'types/store/Company';
import { GetBrandGuidelinesByCompanyIdMeta } from 'types/store/GetBrandGuidelinesByCompanyId';
import { GetCompanyCreditsMeta } from 'types/store/GetCompanyCredits';
import { GetCompanyThresholdMeta } from 'types/store/GetCompanyThreshold';
import { GetEditAdvCustomFieldMeta } from 'types/store/GetEditAdvCustomField';
import { UpdateBrandGuidelinesByCompanyIdMeta } from 'types/store/UpdateBrandGuidelinesByCompanyId';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const COMPANY_URL = `${BASE_URL}/company`;
const BRAND_GUIDELINE = `${BASE_URL}/story-brand-guideline`;

//company_id is already embeded in the token
export const getCompanyCredits = (
  data: GetCompanyCreditsMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${COMPANY_URL}/getCompanyCredits`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getBrandGuidelinesByCompanyId = (
  data: GetBrandGuidelinesByCompanyIdMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${COMPANY_URL}/getBrandGuidelinesByCompanyId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const updateBrandGuidelinesByCompanyId = (
  data: UpdateBrandGuidelinesByCompanyIdMeta,
  token: string,
): any => {
  const fd = new FormData();
  fd.append('data', JSON.stringify(data));

  return axios({
    method: 'put',
    url: `${COMPANY_URL}/updateBrandGuidelinesByCompanyId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getCompanyThreshold = (
  data: GetCompanyThresholdMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${COMPANY_URL}/getThreshold`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const sendUpgradeMail = (
  data: GetCompanyThresholdMeta,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${COMPANY_URL}/sendUpgradeMail`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getAllAdvocatesByCompany = (
  data: GetEditAdvCustomFieldMeta,
  // data: any,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/advocate`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getValueCompanyTrackingContentPackage = (token: string): any => {
  return axios({
    method: 'get',
    url: `${COMPANY_URL}/get-tracking-content-package`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBrandGuidlineStory = (
  id: string,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${BRAND_GUIDELINE}/get-by-story-id/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getDefaultBrandGuidelineStory = (
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${BRAND_GUIDELINE}/get-default`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateDefaultBrandGuidelineStory = (
  token: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'put',
    url: `${BRAND_GUIDELINE}/update`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const createDefaultBrandGuidelineStory = (
  token: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${BRAND_GUIDELINE}/create`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
