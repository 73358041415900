import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  getRecipe,
  getAllRecipeDetail,
  createRecipeDetail,
  updateRecipeDetail,
  createRecipe,
  createRecipeDetailNote,
  deleteRecipeDetailNote,
  updateRecipeDetailNote,
} from 'services/recipe';
import { AsyncAction, AsyncActionReolver } from 'types/Action';
import {
  GetAllContentPlannerRecipeDetailsMeta,
  GetAllContentPlannerRecipeDetailsPayload,
  GetContentPlannerRecipeMeta,
  GetContentPlannerRecipePayload,
  EditPlanMeta,
  EditPlanPayload,
  CreateRecipeMeta,
  CreateRecipePayload,
  MovingPlanMeta,
  CreateRecipeDetailNoteMeta,
  CreateRecipeDetailNotePayload,
  DeleteRecipeDetailNoteMeta,
  DeleteRecipeDetailNotePayload,
  UpdateRecipeDetailNoteMeta,
  UpdateRecipeDetailNotePayload,
} from 'types/store/ContentPlanner';
import { Store } from 'types/store/Store';

import {
  getContentPlannerRecipesActions,
  editPlanActions,
  getAllContentPlannerRecipeDetailsActions,
  createRecipeActions,
  movingPlanActions,
  editRecipeActions,
  deleteRecipeDetailNoteActions,
  createRecipeDetailNoteActions,
  updateRecipeDetailNoteActions,
} from '../actions';
import { openToast } from '../actions/toast.action';

function* getAllContentPlannerRecipeDetailsRequest(
  action: AsyncAction<
    GetAllContentPlannerRecipeDetailsMeta,
    GetAllContentPlannerRecipeDetailsPayload
  >,
): any {
  const state: Store = yield select();
  // if (state.auth.token) {
  try {
    const { data } = yield call(
      getAllRecipeDetail,
      action.meta,
      !action.meta.isShare ? state.auth.token || '' : '',
    );
    yield put(getAllContentPlannerRecipeDetailsActions.success(data.data));
  } catch (e: any) {
    console.log(e);
    yield put(getAllContentPlannerRecipeDetailsActions.failed(e));
  }
  // }
}

function* getAllContentPlannerRecipe(
  action: AsyncAction<
    GetContentPlannerRecipeMeta,
    GetContentPlannerRecipePayload
  >,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getRecipe, action.meta, state.auth.token);
      yield put(getContentPlannerRecipesActions.success(data.data));
    } catch (e: any) {
      yield put(getContentPlannerRecipesActions.failed(e));
    }
  }
}

function* editPlanRequest(action: AsyncAction<EditPlanMeta, EditPlanPayload>) {
  const state: Store = yield select();
  if (state.auth.token) {
    delete action.meta.type;

    const requestData = { ...action.meta };
    try {
      let responseData: any = null;
      if (requestData.id) {
        const { data } = yield call(
          updateRecipeDetail,
          requestData,
          requestData.id,
          state.auth.token,
        );
        responseData = data.data ? { ...data.data } : { ...action.meta };
      } else {
        const { data } = yield call(
          createRecipeDetail,
          requestData,
          state.auth.token,
        );
        responseData = data.data ? { ...data.data } : { ...action.meta };
      }
      const currentKeyRecipes = [...state.contentPlanner.recipes.data];
      const editedItemIndex = currentKeyRecipes.findIndex(
        (_: any) => _.id === responseData.recipe_id,
      );
      if (editedItemIndex > -1) {
        const editedRecipeDetail =
          currentKeyRecipes[editedItemIndex].recipe_details || [];
        const editedItemRecipeDetailIndex = editedRecipeDetail.findIndex(
          (_: any) => _.id === responseData.id,
        );
        if (editedItemRecipeDetailIndex > -1) {
          currentKeyRecipes[editedItemIndex].recipe_details[
            editedItemRecipeDetailIndex
          ] = {
            ...currentKeyRecipes[editedItemIndex].recipe_details[
            editedItemRecipeDetailIndex
            ],
            ...requestData,
            ...responseData,
          };
        } else {
          currentKeyRecipes[editedItemIndex].recipe_details.push({
            ...requestData,
            ...responseData,
          });
        }
      }

      const calendarEvents = [...state.contentPlanner.calendarEvents.data];
      const eventIndex = calendarEvents.findIndex(
        (_: any) => _.id === requestData.id,
      );
      if (eventIndex === -1 && requestData.is_active) {
        calendarEvents.push(responseData);
        // Add
        yield put(
          requestData.use_campaign ?
            openToast(
              'Your campaign has been added to the schedule.',
              'success',
              'Campaign Added Successfully!',
            ) :
            openToast(
              'Your recipe has been added to the schedule.',
              'success',
              'Recipe Added Successfully!',
            ),
        );
      } else if (eventIndex > -1 && !requestData.is_active) {
        calendarEvents.splice(eventIndex, 1);
        // Remove or change repeat
        yield put(
          requestData.repeat ?
            requestData.use_campaign ?
              openToast(
                'Your campaign has been successfully updated.',
                'success',
                'Campaign Updated!',
              ) :
              openToast(
                'Your recipe has been successfully updated.',
                'success',
                'Recipe Updated!',
              ) :
            requestData.use_campaign ?
              openToast(
                'Your campaign has been removed from the schedule.',
                'success',
                'Campaign Removed Successfully!',
              ) :
              openToast(
                'Your recipe has been removed from the schedule.',
                'success',
                'Recipe Removed Successfully!',
              ),
        );
      }
      else {
        // Update
        yield put(
          requestData.use_campaign ?
            openToast(
              'Your campaign has been successfully updated.',
              'success',
              'Campaign Updated!',
            ) :
            openToast(
              'Your recipe has been successfully updated.',
              'success',
              'Recipe Updated!',
            ),
        );
      }

      yield put(
        getAllContentPlannerRecipeDetailsActions.success(calendarEvents),
      );

      yield put(editPlanActions.success(currentKeyRecipes));

    } catch (e: any) {
      console.log('error', e);
      yield put(editPlanActions.failed(e.message));
    }
  }
}

function* editRecipeDetails(
  action: AsyncAction<EditPlanMeta, EditPlanPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    delete action.meta.type;

    const requestData = { ...action.meta };
    try {
      const { data } = yield call(
        updateRecipeDetail,
        requestData,
        requestData.id,
        state.auth.token,
      );

      yield put(editPlanActions.success(data));
      yield put(openToast('Edit Recipe successfully', 'success'));
    } catch (e: any) {
      yield put(editPlanActions.failed(e.message));
      yield put(openToast('Error on edit recipe', 'error'));
    }
  }
}

function* movingPlanRequest(
  action: AsyncAction<MovingPlanMeta, EditPlanPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    const {
      selectedYear,
      id,
      from_date,
      is_active,
      recipe_id,
      selectedContentPackageId,
      use_campaign,
    } = action.meta;

    const requestData = { ...action.meta };
    try {
      const events = state.contentPlanner.calendarEvents.data;
      const event = events.find((e) => e.id === id);
      if (event) {
        event.from_date = from_date;
        event.updated_at = new Date().toISOString();
        yield put(
          movingPlanActions.success({
            ...state.contentPlanner.calendarEvents,
            data: events,
          }),
        );
      }

      yield call(
        updateRecipeDetail,
        { id, recipe_id, is_active, from_date },
        requestData.id,
        state.auth.token,
      );

      yield put(
        getAllContentPlannerRecipeDetailsActions.request({
          year: selectedYear,
          contentPackageId: selectedContentPackageId,
        }),
      );

      yield put(
        use_campaign ?
          openToast('Your campaign has been rescheduled to a new date.', 'success', 'Campaign Moved Successfully!') :
          openToast('Your recipe has been rescheduled to a new date.', 'success', 'Recipe Moved Successfully!'),
      );

    } catch (e: any) {
      yield put(
        openToast(e.response?.data?.message || e.message, 'error', 'Error'),
      );
      yield put(
        getAllContentPlannerRecipeDetailsActions.request({
          year: selectedYear,
          contentPackageId: selectedContentPackageId,
        }),
      );
      yield put(editPlanActions.failed(e.message));
    }
  }
}

function* createRecipeRequest(
  action: AsyncAction<CreateRecipeMeta, CreateRecipePayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(createRecipe, action.meta, state.auth.token);
      const { recipes } = state.contentPlanner;
      yield put(createRecipeActions.success([...recipes.data, data.data]));
    } catch (e: any) {
      yield put(createRecipeActions.failed(e));
    }
  }
}

function* createRecipeDetailNoteRequest(
  action: AsyncActionReolver<
    CreateRecipeDetailNoteMeta,
    CreateRecipeDetailNotePayload
  >,
) {
  const state: Store = yield select();
  const recipeDetails = state.contentPlanner.calendarEvents.data;
  let recipe;
  if (state.auth.token) {
    try {
      const { data } = yield call(
        createRecipeDetailNote,
        action.meta,
        state.auth.token,
      );
      const newRecipeDetails = recipeDetails.map((recipeDetail) => {
        if (recipeDetail?.id === action.meta.recipe_id) {
          recipeDetail.recipe_notes.push(data.data);
        }
        return recipeDetail;
      });
      yield put(
        getAllContentPlannerRecipeDetailsActions.success(newRecipeDetails),
      );
      yield put(createRecipeDetailNoteActions.success({}));

      return action.resolver.resolve({ data: recipe, success: true });
    } catch (e: any) {
      yield put(createRecipeDetailNoteActions.failed(e.message));
    }
  }
}

function* updateRecipeDetailNoteRequest(
  action: AsyncActionReolver<
    UpdateRecipeDetailNoteMeta,
    UpdateRecipeDetailNotePayload
  >,
) {
  const state: Store = yield select();
  const recipeDetails = state.contentPlanner.calendarEvents.data;
  let recipe;
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateRecipeDetailNote,
        action.meta,
        state.auth.token,
      );
      const newRecipeDetails = recipeDetails.map((recipeDetail) => {
        if (recipeDetail.recipe_notes.length > 0) {
          recipeDetail.recipe_notes = recipeDetail.recipe_notes.map(
            (note: any) => {
              if (note.id === data.data.id) {
                note.content = data.data.content;
              }
              return note;
            },
          );
        }
        return recipeDetail;
      });
      yield put(
        getAllContentPlannerRecipeDetailsActions.success(newRecipeDetails),
      );
      yield put(updateRecipeDetailNoteActions.success(true));

      return action.resolver.resolve({ data: recipe, success: true });
    } catch (e: any) {
      yield put(updateRecipeDetailNoteActions.failed(false));
      return action.resolver.resolve(false);
    }
  }
}

function* deleteRecipeDetailNoteRequest(
  action: AsyncActionReolver<
    DeleteRecipeDetailNoteMeta,
    DeleteRecipeDetailNotePayload
  >,
) {
  const state: Store = yield select();
  const recipeDetails = state.contentPlanner.calendarEvents.data;
  const recipe = undefined;
  if (state.auth.token) {
    try {
      const { data } = yield call(
        deleteRecipeDetailNote,
        action.meta,
        state.auth.token,
      );

      const newRecipeDetails = recipeDetails.map((recipeDetail) => {
        if (recipeDetail.recipe_notes.length > 0) {
          recipeDetail.recipe_notes = recipeDetail.recipe_notes.filter(
            (note: { id: string }) => note.id !== data.data.id,
          );
        }
        return recipeDetail;
      });
      yield put(
        getAllContentPlannerRecipeDetailsActions.success(newRecipeDetails),
      );
      yield put(deleteRecipeDetailNoteActions.success({}));
      return action.resolver.resolve(true);
    } catch (e: any) {
      yield put(deleteRecipeDetailNoteActions.failed(e.message));
      return action.resolver.reject(false);
    }
  }
}

function* contentPlannerWatcher(): any {
  yield takeLatest(
    getAllContentPlannerRecipeDetailsActions.REQUEST,
    getAllContentPlannerRecipeDetailsRequest,
  );
  yield takeLatest(
    getContentPlannerRecipesActions.REQUEST,
    getAllContentPlannerRecipe,
  );
  yield takeLatest(editPlanActions.REQUEST, editPlanRequest);
  yield takeLatest(createRecipeActions.REQUEST, createRecipeRequest);
  yield takeLatest(movingPlanActions.REQUEST, movingPlanRequest);
  yield takeLatest(editRecipeActions.REQUEST, editRecipeDetails);
  yield takeLatest(
    createRecipeDetailNoteActions.REQUEST,
    createRecipeDetailNoteRequest,
  );
  yield takeLatest(
    updateRecipeDetailNoteActions.REQUEST,
    updateRecipeDetailNoteRequest,
  );
  yield takeLatest(
    deleteRecipeDetailNoteActions.REQUEST,
    deleteRecipeDetailNoteRequest,
  );
}

export default contentPlannerWatcher;
