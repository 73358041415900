import React from 'react';

import { SVGProps } from './SVG.props';

const ArrowDownRight = (props: SVGProps): React.JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.3912 10.0001L10.1959 7.80482C9.93556 7.54447 9.93556 7.12236 10.1959 6.86201C10.4563 6.60166 10.8784 6.60166 11.1387 6.86201L14.4721 10.1953C14.7324 10.4557 14.7324 10.8778 14.4721 11.1382L11.1387 14.4715C10.8784 14.7318 10.4563 14.7318 10.1959 14.4715C9.93556 14.2111 9.93556 13.789 10.1959 13.5287L12.3912 11.3334H5.66732C3.27408 11.3334 1.33398 9.39332 1.33398 7.00008C1.33398 4.60685 3.27408 2.66675 5.66732 2.66675H10.0007C10.3688 2.66675 10.6673 2.96522 10.6673 3.33341C10.6673 3.7016 10.3688 4.00008 10.0007 4.00008H5.66732C4.01046 4.00008 2.66732 5.34323 2.66732 7.00008C2.66732 8.65694 4.01046 10.0001 5.66732 10.0001H12.3912Z"
        fill={fill || '#060548'}
      />
    </svg>
  );
};

export default ArrowDownRight;
